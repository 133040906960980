import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router-dom";
import { isArray } from "lodash";

import ModalContainer from "components/modals/ModalContainer";
import Table from "components/ui-kit/Table";
import { tableUsers } from "constants/tables";
import Pagination from "components/ui-kit/Pagination";
import { Header } from "./Header";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import ModalFilterUsers from "components/modals/ModalFilterUsers";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import ModalDelete from "components/modals/ModalDelete";

import { getFilQueryParams } from "utils/processingRequester/getLocalFilterOrders";
import { filterKitchenCodeOnId, filterRoleCodeOnId } from "utils/helpers/filterApiParams";
import { isMyKitchen, isMyUsersRole } from "utils/helpers/checks";
import { getFiltetUsersSearch } from "utils/processingRequester/getLocalFilterOrders"
// import { getData } from "utils/helpers/localStorage";

import { useKeyPress } from "hooks/useAddKey";

function UsersPage({
  // props
  user,
  users,
  view,
  kitchens,
  searchForm,
  roles,
  // dispatch
  getUsers,
  getUser,
  clearUsers,
  openModal,
  updateUserSearchForm,
  openModalFilter,
  deleteMultiUsers,
  deactivateMultiUsers,
  generateAuthLink,
}) {
  const [arrIdUsers, setArrIdUsers] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [loadModal, setLoadModal] = useState(false);
  const [deleteOrActive, setDeleteOrActive] = useState('delete');
  const history = useHistory();
  const location = useLocation();
  const isKeyPressed = useKeyPress('Control');

  const filMy = () => {
    let filterMy = {};

    if (isMyKitchen(user)) {
      filterMy = { ...filterMy, ...filterKitchenCodeOnId(user.kitchens, kitchens) };
    }

    if (isMyUsersRole(user)) {
      filterMy = { ...filterMy, ...filterRoleCodeOnId(user.available_users_roles, roles) };
    }

    return filterMy;
  }

  useEffect(() => {
    let par = getFilQueryParams(location?.query);
    getUsers(location?.query?.page || 1, getFiltetUsersSearch(par, filMy()));

    return () => clearUsers();
  }, [location?.query?.page]);

  const setPage = (page) => {
    let par = getFilQueryParams(location?.query);
    par.page = page;

    let parSrc = '';

    if (Object.keys(par).length > 0) {
      Object.keys(par).map((item) => {
        parSrc += `${item}=${par[item]}&`;
      })
    }

    parSrc = parSrc.slice(0, -1);

    history.push(`?${parSrc}`);
  }

  const handleEditUser = (user) => {
    if (isKeyPressed) {
      setArrIdUsers((prev) => {
        let isId = prev.find(el => (el == user.id));

        if (isId) {
          return prev.filter(el => (el !== user.id));
        }

        if (!isId) {
          return ([...prev, ...[user.id]])
        }
      });
    }

    if (!isKeyPressed) {
      getUser(user.id);
      openModal("user");
    }
  };

  const handlerOpenModal = () => {
    openModal("user");
  }

  const updateSearchForm = (item, data) => {
    updateUserSearchForm(item, data);
  }

  const handleІSybmitSearch = async () => {
    history.push(`?page=1`);
    await getUsers(-1, getFiltetUsersSearch({}, filMy()));
  }

  const handleІSybmitCleant = async () => {
    await updateSearchForm("phone", '');
    await updateSearchForm("name", '');
    await handleІSybmitSearch();
  }

  const handleShowFilter = () => {
    openModalFilter();
  }

  const handleOpenModal = (type) => {
    setDeleteOrActive(type);
    setIsOpenModal(true);
  }

  const cleanSelectUSers = () => {
    setArrIdUsers([]);
    setIsOpenModal(false);
  }

  const handleSend = () => {
    let par = getFilQueryParams(location?.query);

    if (deleteOrActive == 'delete') {
      deleteMultiUsers({ data: arrIdUsers, setLoadModal, filter: par, calback: cleanSelectUSers });
    }

    if (deleteOrActive != 'delete') {
      deactivateMultiUsers({ data: arrIdUsers, setLoadModal, filter: par, calback: cleanSelectUSers });
    }
  }

  return (
    <>
      <Header
        handleCreateOrder={handlerOpenModal}
        updateSearchForm={updateSearchForm}
        handleІSybmitSearch={handleІSybmitSearch}
        handleShowFilter={handleShowFilter}
        handleІSybmitCleant={handleІSybmitCleant}
        searchFormValue={searchForm}
        isBtnFilter={!isMyKitchen(user) || !isMyUsersRole(user)}
        isAtiveFilterBtn={getFilQueryParams(location?.query, true)}
      />

      <ViewRow>
        {
          !view?.isLoadPage ? (
            isArray(users?.users?.data) && (
              <Table
                onClick={handleEditUser}
                data={users?.users?.data}
                scheme={tableUsers}
                isStictyhead={true}
                isBgActiveRow={"red"}
                arrIds={arrIdUsers}
                isKeyPressed={isKeyPressed}
                actions={{ generateAuthLink }}
              />
            )
          ) : (
            <LoadingCircl />
          )
        }
      </ViewRow>

      {
        isArray(users?.users?.data) && (
          users?.users?.data.length > 0 && (
            <Pagination
              onClick={setPage}
              currentPage={users?.users?.meta.current_page}
              lastPage={users?.users?.meta.last_page}
            />
          )
        )
      }

      <ModalContainer filMy={handleІSybmitSearch} />
      {
        (!isMyKitchen(user) || !isMyUsersRole(user)) && (
          <ModalFilterUsers />
        )
      }

      {
        (arrIdUsers.length > 0) && (
          <div className="btn-fixed-right">
            <ButtonIcon title="Деактивувати" onClick={() => handleOpenModal('deact')} />
            <ButtonIcon title="Видалити" colorBg="red" onClick={() => handleOpenModal('delete')} />
          </div>
        )
      }

      <ModalDelete
        title={(deleteOrActive == 'delete') ? 'Видалити' : 'Деактивувати'}
        text={`Підтвердіть свої дії...`}
        isOpen={isOpenModal}
        isLoad={loadModal}
        funDelete={() => handleSend()}
        onCloseModal={() => { setIsOpenModal(false) }}
      />
    </>
  );
}

const ViewRow = styled.div`
  margin-top: 24px;
`;

export default React.memo(UsersPage);
