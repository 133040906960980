import * as React from "react";
import { useGridApiRef } from '@mui/x-data-grid-premium';

// reports table
import CouriersReport from "pages/Reports/Collection/CouriersReport";

export const ModalContent = React.forwardRef(({ data }, ref) => {
    const apiRef = useGridApiRef();
    return (
        <div className="cont-modal-info" ref={ref}>
            <CouriersReport
                apiRef={apiRef}
                data={data}
            />
        </div>
    );
});
