import styled, { useTheme } from "styled-components";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import InputPhone from "components/ui-kit/Modals/InputPhone";
import { TextInput } from "components/ui-kit/inputs/TextInput";

import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';

export const Header = ({
    handleCreateOrder = () => { },
    handleІSybmitSearch = () => { },
    updateSearchForm = () => { },
    handleShowFilter = () => { },
    handleІSybmitCleant = () => { },
    searchFormValue,
    isBtnFilter = true,
    isAtiveFilterBtn = false,
}) => {
    const theme = useTheme();

    const onEnter = (e) => {
        if (e.keyCode === 13) {
            handleІSybmitSearch();
        }
    }

    return (
        <Wr theme={theme}>
            <Left>
                <Label>Співробітники</Label>
            </Left>
            <Right>
                <WrSearch>
                    <InputPhone
                        title='Номер телефону'
                        type='text'
                        isTitle={false}
                        isStyle={true}
                        value={searchFormValue?.phone}
                        onChange={(value) => updateSearchForm("phone", value)}
                        onKeyDown={(e) => onEnter(e)}
                        width="180px"
                        disabled={searchFormValue?.name.length > 0}
                    />
                </WrSearch>
                <TextOR>Або</TextOR>
                <WrSearch>
                    <TextInput
                        type="text"
                        isTitle={false}
                        isStyle={true}
                        title={"Ім'я"}
                        width="180px"
                        onChange={(e) => updateSearchForm("name", e.target.value)}
                        onKeyDown={(e) => onEnter(e)}
                        value={searchFormValue.name}
                        disabled={searchFormValue?.phone.length > 3}
                    />
                </WrSearch>
                {
                    (!!searchFormValue?.phone || !!searchFormValue.name) && (
                        <WRBtnRI>
                            <ButtonIcon
                                onClick={() => handleІSybmitCleant()}
                                icon={<CloseIcon />}
                                positionIcon="left"
                                colorBg="red"
                            />
                        </WRBtnRI>
                    )
                }
                <ButtonIcon
                    onClick={handleІSybmitSearch}
                    title='Пошук'
                    icon={<SearchIcon />}
                    positionIcon="left"
                    disabled={!(searchFormValue.phone.length > 3) && !(searchFormValue.name.length > 0)}
                />
                {
                    isBtnFilter && (
                        <WrBtn>
                            <ButtonIcon
                                onClick={handleShowFilter}
                                title='Фільтр'
                                icon={<FilterAltIcon />}
                                colorBg={isAtiveFilterBtn ? "green" : "#9D9D9D"}
                            />
                        </WrBtn>
                    )
                }
                <WrBtn>
                    <ButtonIcon
                        onClick={handleCreateOrder}
                        icon={<AddIcon />}
                        positionIcon="left"
                    />
                </WrBtn>
            </Right>
        </Wr>
    )
}

const WRBtnRI = styled.div`
    margin-right: 24px;
`;

const Wr = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Left = styled.div`
    display: flex;
    align-items: center;    
`;

const Label = styled.div`
    font-weight: 600;
    font-size: 20px;
    color: ${(p) => p.theme.color25};
`;

const WrSearch = styled.div`
    margin: 0 24px;
`;

const Right = styled.div`
    display: flex;
    align-items: center;
`;

const TextOR = styled.div`
`;

const WrBtn = styled.div`
    margin-left: 24px;
`;