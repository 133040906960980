import { isArray } from "lodash";
import styles from "./styles.module.scss";

export const CheckKitchenAll = ({ data }) => {
    const {
        kitchen,
        time_open,
        date,
        customer,
        closed_orders,
        rejected_orders,
        payments,
        total_selling,
        total_returns
    } = data;


    return (
        <>
            <div className={styles.head_kitchen}>{`>> ${kitchen} <<`}</div>
            <div className={styles.mt_rowe}>
                <div className={styles.title_center}>Звіт</div>
            </div>
            <div className={styles.mt_rowe}>
                <div className={styles.it_li}><span>Зміна відкрита:</span><span>{time_open}</span></div>
                <div className={styles.it_li}><span>Поточний стан:</span><span>{date}</span></div>
                <div className={styles.it_li}><span>Поточний користувач:</span><span>{customer}</span></div>
            </div>
            <div className={styles.mt_rowe}>
                <ul className={styles.ul_u}>
                    {
                        isArray(payments) && payments.map((item, index) => (
                            <li className={styles.ul_li} key={index}>
                                <div className={styles.it_li}>{item.title}<span></span></div>
                                <div className={styles.it_li}><span>Продаж:</span><span>{item.selling}</span></div>
                                <div className={styles.it_li}><span>Повернення:</span><span>{item.returns}</span></div>
                            </li>
                        ))
                    }
                </ul>
            </div>
            <div className={styles.mt_rowe}>
                <div className={styles.title_center}>Звіт</div>
            </div>
            <div className={styles.mt_rowe}>
                <div className={styles.it_li}><span>Продаж:</span><span>{total_selling}</span></div>
                <div className={styles.it_li}><span>Повернення:</span><span>{total_returns}</span></div>
                <div className={styles.it_li}><span>Завершені:</span><span>{closed_orders}</span></div>
                <div className={styles.it_li}><span>Відміненні:</span><span>{rejected_orders}</span></div>
            </div>
            <div className={styles.mt_rowe}>
                <div className={styles.text_center}>Увага! Наведенні суми можуть ВІДРІЗНЯТИСЯ від сум фіксального господарства!</div>
            </div>
        </>
    )
}