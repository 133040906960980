import produce from 'immer';

import {
    ADD_NOTIFICATION,
    REMOVE_NOTIFICATION,
    REMOVE_NOTIFICATION_SHIFT
} from '../constants/notifications';

const initialState = {
    messages: [],
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        case ADD_NOTIFICATION:
            draft.messages.push(payload);
            break;

        case REMOVE_NOTIFICATION:
            draft.messages.splice(payload.index, 1);
            break;

        case REMOVE_NOTIFICATION_SHIFT:
            draft.messages.shift();
            break;

        default:
            return draft;
    }
}, initialState);
