import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as CityPage } from './City';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        data: settings.editCity,
        cities: [...[{ id: null, name_ua: '-', status: 1 }], ...settings.cities],
        isLoadPage: view.isLoadPage,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(CityPage);
