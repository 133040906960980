import React, { useState } from "react";
import styled, { useTheme } from "styled-components";
import { isArray } from "lodash";

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { typePrice } from "utils/helpers/price";
import { getTimeFlag } from "utils/helpers/getTimeFlag";
import { formatAddress } from "utils/helpers/formatAddress";
import { finishedOrderStatuses } from "constants/finishedOrderStatuses";

const OrdersList = ({
  isHidePrice = false,
  data,
  title,
  openEditorCallcenter,
  getCallcenterOrderItem,
  idOrder,
  status = null,
  showSum = false,
}) => {
  const theme = useTheme();
  const [isExpand, setExpand] = useState(true);

  const handleOpenEditor = async (item) => {
    await openEditorCallcenter(item);
    await getCallcenterOrderItem(item.id);
  };

  const renderBody = () => {
    return data.map((item, index) => {
      let price = '***';
      let bonus = '***';
      let typePayment = '***';

      if (!isHidePrice) {
        let prises = typePrice(item.payments, false);

        price = prises.price;
        bonus = prises.bonus;
        typePayment = prises.typePayment;
      }

      const timeCook = getTimeFlag(item, 'delivery_till');

      let isTechnical = (item?.technical == 1);
      let noCall = (item?.no_call == 1);
      let isClosed = status === 'closed'

      return (
        <div key={`orderItem_${index}`}>
          <Wr
            isOpen={idOrder == item.id}
          >
            <Rows theme={theme}>
              {
                isTechnical && (
                  <div className="technical-info">Технічне замовлення</div>
                )
              }
              {
                noCall && (
                  <div className="no-call-info">Не передзвонювати</div>
                )
              }
              <Item width={isClosed ? 7 : 7} color={timeCook.class} onClick={() => handleOpenEditor(item)}>
                {
                  Object.keys(finishedOrderStatuses).includes(item?.status) ? (
                    <div className="closed_time">
                      <i>{finishedOrderStatuses[item.status]}: </i>
                      <div>
                        {item.actual_status_time}
                      </div>
                    </div>
                  ) : (
                    <Time>{timeCook.time}</Time>
                  )
                }
              </Item>
              <Item width={isClosed ? 5 : 5} onClick={() => handleOpenEditor(item)}>
                <Name>{item.id}</Name>
              </Item>
              <Item width={isClosed ? 6 : 6} onClick={() => handleOpenEditor(item)}>{item.restaurant_info.name || "Не вибрано"}</Item>
              <Item width={isClosed ? 7 : 12} onClick={() => handleOpenEditor(item)}>
                <Name>{item.client.name}</Name>
              </Item>
              <Item width={isClosed ? 10 : 14} >
                <a className="tel" href={`tel:${item.client.phone}`}>{item.client.phone}</a>
              </Item>
              <Item className="address" width={isClosed ? 16 : (showSum ? 18 : 30)} onClick={() => handleOpenEditor(item)}>{formatAddress(item)} </Item>
              <Item width={isClosed ? 12 : (showSum ? 15 : 16)} onClick={() => handleOpenEditor(item)}>{item?.operator_full_name || "\\"}</Item>
              {
                isClosed && (
                  <Item width={13}>{item?.courier?.full_name || '-'}</Item>
                )
              }
              <Item isList={true} width={isClosed ? 10 : 12} onClick={() => handleOpenEditor(item)}>
                <ListPris>
                  {
                    (isArray(typePayment) && typePayment.map((itemPa, i) => (
                      <span key={i}>{itemPa}</span>
                    ))) || '-'
                  }
                </ListPris>
              </Item>
              {(showSum || isClosed) && (
                <>
                  <Item width={7} onClick={() => handleOpenEditor(item)}>-{item?.discount}</Item>
                  <Item width={7} onClick={() => handleOpenEditor(item)}>{item?.to_pay}</Item>
                </>
              )}
            </Rows>
          </Wr>
        </div>
      );
    });
  };

  return (
    <Wrapper theme={theme}>
      <Titles onClick={() => {
        if (data.length > 0) {
          setExpand(!isExpand)
        }
      }} isExpand={isExpand}>
        {title}
        {
          (data.length > 0) && (
            <IconWr isExpand={isExpand}>
              <ArrowDownwardIcon />
            </IconWr>
          )
        }
      </Titles>

      {data.length ? (
        <Table isExpand={isExpand}>
          {renderBody()}
        </Table>
      ) : (
        <Text>Немає замовлень у цьому статусі</Text>
      )}

    </Wrapper>
  );
};

const Wrapper = styled.section`
  margin-bottom: 15px;
                `;

const Text = styled.p`
                text-align: center;
                color: ${(p) => p.theme.lightAccentColor};
                `;

const Table = styled.div`
                display: ${(p) => (p.isExpand ? "table" : "none")};
                width: 100%;
                border-collapse: collapse;
                `;

const Wr = styled.div`
                margin-top: 16px;
                position: relative;
                z-index: ${(p) => p.isOpen ? 99 : 0}
                `;

const Titles = styled.div`
                font-weight: 600;
                font-size: 20px;
                color: ${(p) => p.theme.color25};
                display: flex;
                align-items: center;
                cursor: pointer;
                transition: 0.4s;
                &:hover {
                  color: ${(p) => p.theme.accentColor};
    }
                `;

const Rows = styled.div`
                display: flex;
                positione: relative;
                align-items: center;
                background: #FFFFFF;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
                margin-top: 16px;
                cursor: pointer;
                transition: 0.4s;
                &:hover {
                  background - color: #E6E6E6;
                }
                .address {
                  font-weight: 400;
                }
                `;

const Item = styled.div`
                min-height: 56px;
                width: ${(p) => p.width}%;
                font-size: 14px;
                color: ${(p) => p.theme.color42};
                padding: 5px 5px 5px 5px;
                background: ${(p) => p.color};
                white-space: nowrap;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-radius: 8px 0px 0px 8px;
    //             &:first-child {
    //               border-radius: 8px 0px 0px 8px;
    // }
                // &:last-child {
                //   border-radius: 0px 8px 8px 0px;
    // }

    .tel {
      padding: 8px 0;
      &:hover {
        color: red;
      }
    }

    .closed_time {
      display: flex;
      flex-direction: column;
      i {
        color: green;
      }
    }
                `;

const ListPris = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Time = styled.div`
                width: 100%;
                height: 100%;
                border-radius: inherit;
                font-weight: 500;
                font-size: 14px;
                color: #FFFFFF;
                text-align: center;
                `;

const Name = styled.div`
               padding-left: 10px;
                `;

const IconWr = styled.span`
                svg {
                  transform: rotate(${(p) => p.isExpand ? "-180deg" : "0deg"});
  }
                margin-left: 20px;
                `;

export default React.memo(OrdersList);
