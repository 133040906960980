import { isArray } from "lodash";
import md5 from "js-md5";
import api from "../../apiSingleton";

import { currentDate } from "utils/helpers/date";
import { withLoading } from "utils/helpers/withLoading";

import {
    GET_DAA_PRINT_CHECK,
    SET_KITCHEN_SHIFT_STATUS,
    SET_KITCHEN_SHIFT_LOADING,
} from "../constants/kitchen";

import { addNotification } from './notifications';

export function getDataPrintOrderAllKitchen() {
    return async (dispatch, state) => {
        let { user: { user: { kitchen_code } } } = state();
        const res = await api.kitchen.apiGetDataPrintOrderAllKitchen(kitchen_code);

        if (!res?.error) {
            await dispatch({
                type: GET_DAA_PRINT_CHECK,
                payload: res,
            });
        }

        if (!!res?.error) {
            let orders = (isArray(res?.orders) && res?.orders.join(", "));
            await dispatch(addNotification({ war: [`${res?.text || ''} ${orders}`] }, 'error'));
        }
    };
}


export async function getDailyReportsForKitchen(params, setLoad) {
    setLoad(true);
    const res = await api.kitchen.getDailyReportsForKitchen(params);
    setLoad(false);

    if (res?.status === "Ok")
        return res.data;

    return null;
}

export async function getCourierReports(params, setLoad) {
    setLoad(true);
    const res = await api.reports.getReportsOfType({
        ...params,
        code: 'courier_stats'
    });
    setLoad(false);

    if (res?.status === "Ok")
        return res.data;

    return null;
}

export function setKitchenShiftLoading(dispatch, isLoading) {
    return dispatch({
        type: SET_KITCHEN_SHIFT_LOADING,
        payload: isLoading,
    });
}

export function getKitchenShiftStatus() {
    return (dispatch) => withLoading(setKitchenShiftLoading.bind(this, dispatch), async () => {
        const { numFiscal: shiftId, isShiftClosed } = await api.kitchen.getShiftStatus();
        const payload = { shiftId, isShiftClosed };

        console.debug('Статус робочої зміни', payload);
        await dispatch({
            type: SET_KITCHEN_SHIFT_STATUS,
            payload,
        });
    });
}

/* export */ function shiftOperation(callable, ...args) { // should not be used manually
    return (dispatch) => withLoading(setKitchenShiftLoading.bind(this, dispatch), async () => {
        const { status, message, numFiscal } = await callable(...args);

        const severity = status ? 'success' : 'error';
        const shiftId = numFiscal || null; // null means shift is not opened

        if (!!status) {
            const payload = { shiftId, isShiftClosed: !shiftId };
            console.debug('Змінено статус робочої зміни кухні', payload);
            await dispatch({
                type: SET_KITCHEN_SHIFT_STATUS,
                payload,
            });
        }

        message && dispatch(addNotification({ war: [message] }, severity));
    });
}

export function toggleKitchenShiftStatus() {
    const callable = api.kitchen.toggleShiftStatus.bind(api.kitchen);
    return shiftOperation(callable);
}

export function executeKitchenShiftCashalotCommand(kitchenCode, command) {
    const callable = api.kitchen.executeKitchenCashalotCommand.bind(api.kitchen);
    return shiftOperation(callable, kitchenCode, command);
}

export function getActiveKitchenCityReturnPromis(params = {}) {
    return async () => {
        const { data } = await api.kitchen.getActiveKitchenCity(params);
        return data
    };
}

const requerySiteMap = async (api, nameKitchen, dataKitchen, dispatch) => {
    try {
        return await fetch(
            api,
            {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: md5(currentDate('YYYYMMDD')),
                    kitchens: dataKitchen
                })
            }
        )
            .then((res) => res.json())
            .then((data) => {
                if (data?.status === 'success') {
                    dispatch(addNotification({ war: [`На сайті ${nameKitchen} оновленно`] }));
                }

                if (data?.status !== 'success') {
                    dispatch(addNotification({ war: [`Не вдалося оновити на сайті ${nameKitchen}`] }, 'error'));
                }
            })
    } catch (error) {
        dispatch(addNotification({ war: [`Не вдалося оновити на сайті ${nameKitchen}`] }, 'error'));
    }
}

export function saveActivePolygonKitchen(dataForm, dataKitchen) {
    return async (dispatch) => {
        const data = await api.kitchen.saveActivePolygonKitchen(dataForm);

        if (!!data?.error) {
            dispatch(addNotification({ war: ['Помилка'] }, 'error'));
        }

        if (!data?.error) {
            await requerySiteMap(
                `https://smaki-maki.com/wp-json/check_work_kitchen/v1/receive`,
                'smaki-maki',
                dataKitchen,
                dispatch
            );

            await requerySiteMap(
                `https://donatellopizza.com.ua/wp-json/check_work_kitchen/v1/receive`,
                'donatellopizza',
                dataKitchen,
                dispatch
            );

            await requerySiteMap(
                `https://moonfish-sushi.com/wp-json/check_work_kitchen/v1/receive`,
                'moonfish-sushi',
                dataKitchen,
                dispatch
            );

            await requerySiteMap(
                `https://sushi-go.com.ua/wp-json/check_work_kitchen/v1/receive`,
                'sushi-go',
                dataKitchen,
                dispatch
            );
        }

        return data;
    }
}