export const currencyFormatter = new Intl.NumberFormat('uk-UA', {
    // commented uah currency display
    // style: 'currency',
    // currency: 'UAH',
    // currencyDisplay: 'narrowSymbol',

    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
});
