import { isArray } from "lodash";
import React, { useEffect, useState } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import Select from "components/ui-kit/Select";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import CalendarRange from "components/ui-kit/CalendarRange";
import Table from "components/ui-kit/Table";

import { mappedSelf } from "utils/mappers/statistic";
import { kitchenSearchSelect, kitchensMappedSelect } from "utils/mappers/kitchens";
import { isEditOwn, isEditCertain, isEditNone } from "utils/helpers/checks";

import { statisticSelf } from "constants/tables";

import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from "@mui/icons-material/Close";
import styles from "./../StatisticPage.module.scss";

const SelfPickup = ({
    // state
    filter,
    orders,
    user,
    kitchens,
    view,
    // dispatch
    getSelf,
    updateFilterSelf,
    downloadExcelSelf,
}) => {
    const [isLoadDownload, setIsLoadDownload] = useState(false);
    const [isFilter, setIsFilter] = useState(false);

    const arrayIdKitchens = () => {
        if (isEditOwn(user)) {
            return kitchenSearchSelect(user?.kitchens, kitchens);
        } else if (isEditCertain(user)) {
            return kitchenSearchSelect(user?.kitchens_statistic, kitchens);
        }

        return kitchensMappedSelect(kitchens);
    }

    const startFilter = async () => {
        if (isEditOwn(user)) {
            await updateFilterSelf("kitchens", kitchenSearchSelect(user?.kitchens, kitchens));
        } else if (isEditCertain(user)) {
            await updateFilterSelf("kitchens", kitchenSearchSelect(user?.kitchens_statistic, kitchens));
            await handlerSubmit();
        }
    }

    const handlerSubmit = async (isBtn = false) => {
        await getSelf();

        if (isBtn)
            closeFilter();
    }

    const handleDetail = (item) => {
        // не використовується
    }

    const handlerDownload = () => {
        downloadExcelSelf(setIsLoadDownload);
    }

    const closeFilter = () => {
        setIsFilter(false);
    }

    useEffect(async () => {
        await startFilter();
    }, [kitchens]);

    useEffect(async () => {
        if (!isEditOwn(user) && !isEditCertain(user)) {
            await handlerSubmit();
        }
    }, []);

    return (
        <div className={styles.statisticpage}>
            {
                isEditNone(user) && (
                    <>
                        <div className={styles.contain}>
                            <div className={styles.hed}>
                                <div className={styles.title_p}></div>
                                <div className={styles.hed_r}>
                                    <ButtonIcon
                                        title='Фільтр'
                                        onClick={() => setIsFilter(true)}
                                    />
                                </div>
                            </div>
                            {
                                !view?.isLoadPage ? (
                                    isArray(orders) && (orders.length > 0) ? (
                                        <div className={styles.contain_main}>
                                            <Table
                                                onClick={handleDetail}
                                                data={mappedSelf(orders)}
                                                scheme={statisticSelf}
                                                isStictyhead={true}
                                            />
                                        </div>
                                    ) : (
                                        <div className={styles.text_info}>Пусто... оберіть потрібні параметри і натисніть кнопку "Задіяти"</div>
                                    )
                                ) : (
                                    <div className={styles.modal_wr}>
                                        <LoadingCircl mins={true} />
                                    </div>
                                )
                            }
                        </div>

                        {
                            isFilter && (
                                <div className={`${styles.statisticpage__select}`}>
                                    <div className={`${styles.opas} bg_opas`} onClick={closeFilter}></div>
                                    <div className={`${styles.statisticpage__select_main}`}>
                                        <div className={styles.headers}>
                                            <div className={styles.title_white}>Фільтр</div>
                                            <div className={styles.btn_close} onClick={closeFilter}>
                                                <CloseIcon style={{ fontSize: 36 }} />
                                            </div>
                                        </div>
                                        <div className={`${styles.slick}`}>
                                            <Select
                                                selectOptions={arrayIdKitchens()}
                                                selectedOption={filter.kitchens}
                                                valueKey='kitchens'
                                                label='Кухні'
                                                isMulti
                                                mode={'secondary'}
                                                onChange={({ value }) => updateFilterSelf("kitchens", value)}
                                            />
                                            <CalendarRange
                                                title="Період з - по"
                                                valueIn={filter.date}
                                                ranges={filter.date}
                                                isAbs={true}
                                                onChangeCal={(date) => updateFilterSelf("date", [date.selection])}
                                            />
                                            <div className={`${styles.row_two_btn} ${styles.row_two_btn_marg_top}`}>
                                                <ButtonIcon
                                                    onClick={() => handlerSubmit(true)}
                                                    title='Задіяти'
                                                    icon={<AddIcon />}
                                                    positionIcon="left"
                                                />
                                                {
                                                    isArray(orders) && orders.length > 0 && (
                                                        <ButtonIcon
                                                            onClick={handlerDownload}
                                                            title='Excel'
                                                            icon={<FileDownloadIcon />}
                                                            positionIcon="left"
                                                            colorBg="#3d91ff"
                                                            isLoad={isLoadDownload}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </>
                )
            }
        </div>
    )
}

export default SelfPickup;