import React from "react";
import styled, { useTheme } from "styled-components";
import TextField from '@mui/material/TextField';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers/AdapterMoment';

export const DatePicker = ({
  title,
  value,
  onChange = () => { },
  width,
  min,
  max,
  disabled,
  white = false,
}) => {
  const theme = useTheme();

  return (
    <Wrapper theme={theme} width={width}>
      <Title isWhite={white}>{title}</Title>
      <Label isWhite={white}>
        <LocalizationProvider dateAdapter={DateAdapter}>
          <DesktopDatePicker
            inputFormat="YYYY-MM-DD"
            value={value}
            onChange={(value) => value.isValid() && onChange(value)}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={
                  {
                    ...params.inputProps,
                  }
                }
              />
            )}
            maxDate={max}
            minDate={min}
            disabled={disabled}
          />
        </LocalizationProvider>
      </Label>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: ${(p) => p.width};
  margin: 3px;
  padding-top: 4px;
`;

const Title = styled.span`
  margin-left: 4px;
  border-radius: 3px;
  color: ${(p) => p.isWhite ? "#000" : p.theme.secondaryColor};
  font-size: 14px;
`;

const Label = styled.label`
  position: relative;

  .MuiFormControl-root {
    margin-top: 5px;
    position: relative;
  }

  .MuiOutlinedInput-root {
    border-radius: 8px;
    border: ${(p) => p.isWhite ? "1px solid #DEDEDE" : p.theme.inputBorder};
    background-color: ${(p) => p.isWhite ? "#fff" : p.theme.backgroundLight};
    color: ${(p) => p.isWhite ? "#000" : p.theme.secondaryColor};
    box-shadow: none;
    outline: none;
    height: 44px;
    &:active,
    &:focus {
      border: 1px solid ${(p) => p.theme.accentColor};
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none!important;
  }

  .Mui-focused {
    border: 1px solid ${(p) => p.theme.accentColor};
  }

  svg {
    fill: ${(p) => p.isWhite ? "#DEDEDE" : "#fff"};
  }

  // .MuiButtonBase-root {
  //   position: inherit;
  //   &:before {
  //     content: '';
  //     position: absolute;
  //     left: 0;
  //     top: 0;
  //     width: 100%;
  //     height: 100%;
  //     border-radius: 8px;
  //   }
  // }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
      color: #597275;
      input {
        color: #597275;
        -webkit-text-fill-color: #597275;
      }
      svg {
          fill: #597275;
      }
  }
`;

const Icon = styled.span`
  width: 24px;
  height: 24px;
  color: ${(p) => p.theme.secondaryColor};
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background-color: ${(p) => p.theme.backgroundLight};
`;
