
export const writeoffMenuOptions = {
    yes: {
        title: 'Зі списанням',
    },
    no: {
        title: 'Без списання',
    },
    part: {
        title: 'Часткове списання',
    },
}
