import { isArray, isObject } from "lodash";

export const ItemCol = ({ itemRow, indexRow }) => {
    let keysObj = Object.keys(itemRow);

    return (
        keysObj.map((itemkey, indexKey) => {
            let itemData = itemRow[itemkey];

            if (isArray(itemData) && (itemData?.length > 0)) {
                return (
                    <div
                        key={`${itemkey}-${indexKey}`}
                        className="item-col-table item-col-table_list"
                        data-col={indexKey}
                    >
                        {
                            itemData.map((itCol, indexCOlList) => (
                                <div
                                    key={indexCOlList}
                                    className="item-col-list"
                                    title={itCol}
                                    data-id={`${indexRow}${indexKey}${indexCOlList}`}
                                    data-pop={`${indexRow}${indexKey - 1}${indexCOlList}`}
                                    data-item={`${indexRow}${indexCOlList}`}
                                >
                                    {
                                        !!(itCol?.length > 0 || itCol === 0 || itCol > 0 || itCol < 0) ? (
                                            <span>{itCol}</span>
                                        ) : (
                                            <span className="non-text-opas">opas</span>
                                        )
                                    }
                                </div>
                            ))
                        }
                    </div>
                )
            }

            if (isObject(itemData)) {
                let keysCol = Object.keys(itemData);

                return (
                    keysCol.map((elKey, indexK) => {
                        let itemValue = itemData[elKey];

                        if (isArray(itemValue) && (itemValue?.length > 0)) {
                            return (
                                <div
                                    key={indexKey}
                                    className="item-col-table item-col-table_list"
                                    data-col={indexKey + indexK}
                                >
                                    {
                                        itemValue.map((itemValCol, indexColVal) => (
                                            <div
                                                key={indexColVal}
                                                className="item-col-list"
                                                title={itemValCol}
                                                data-id={`${indexRow}${indexK}${indexColVal}`}
                                                data-pop={`${indexRow}${indexK - 1}${indexColVal}`}
                                                data-item={`${indexRow}${indexColVal}`}
                                            >
                                                {
                                                    !!(itemValCol?.length > 0 || itemValCol === 0 || itemValCol > 0 || itemValCol < 0) ? (
                                                        <span>{itemValCol}</span>
                                                    ) : (
                                                        <span className="non-text-opas">opas</span>
                                                    )
                                                }
                                            </div>
                                        ))
                                    }
                                </div>
                            )
                        }

                        if (!isArray(itemValue)) {
                            return (
                                <div
                                    key={indexKey}
                                    className="item-col-table item-col-table_list"
                                    data-col={indexKey}
                                >
                                    {
                                        !!(itemValue?.length > 0 || itemValue === 0 || itemValue > 0 || itemValue < 0) ? (
                                            <span>{itemValue}</span>
                                        ) : (
                                            <span className="non-text-opas">opas</span>
                                        )
                                    }
                                </div>
                            )
                        }
                    })
                )
            }

            if (!isObject(itemData) && !isArray(itemData)) {
                return (
                    <div
                        key={indexKey}
                        className="item-col-table"
                        title={itemData}
                        data-col={indexKey}
                    >
                        {
                            !!(itemData?.length > 0 || itemData === 0 || itemData > 0 || itemData < 0) ? (
                                <span>{itemData}</span>
                            ) : (
                                <span className="non-text-opas">opas</span>
                            )
                        }
                    </div>
                )
            }
        })
    )
}