import styled from "styled-components";
import Loader from 'react-loader-spinner';

export const ModalLoad = () => {
    return (
        <Wr>
            <Loader
                type='Oval'
                color={"#eda240"}
                width={'20px'}
                height={'20px'}
            />
        </Wr>
    )
}

const Wr = styled.div`
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    font-size: 20px;
    z-index: 100;
    box-shadow: 0px 0px 8px 2px rgb(0 0 0 / 50%);
    border-radius: 8px;
    & > div {
        font-size: 0;
    }
`;
