import { useMemo, useRef, Fragment } from "react";
import styled, { useTheme } from "styled-components";
import { useReactToPrint } from "react-to-print";

import { Typography } from "@mui/material";
import { MapContainer, TileLayer, Polyline } from "react-leaflet";
import { MapCenterObserver } from "components/mapes";
import { ModalLoad } from "components/modals/ModalLoad";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";

import Table from "components/ui-kit/Table";
import { deliveryRoute } from "constants/tables";

import { getLatLng } from "./utils";
import Waypoints from "./Waypoints";
import ModalLayout from "../ModalLayout";

const defaultStyle = {
  height: "100%",
  width: "100%",
  borderRadius: 10,
  minHeight: "350px",
};

const defaultOptions = {
  panControl: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: true,
  streetViewControl: false,
  rotateControl: false,
  clicableIcons: false,
  KeyboardShortcuts: false,
  scrollwheel: true,
  disablDoubleClickZoom: true,
  fullscreenControll: false,
}

const ModalDeliveryRoute = ({
  // state
  route: {
    id = null,
    courier = '',
    coordinates = [],
    waypoints = [],
  },
  isOpen = false,
  isLoading = false,
  // mapDispatch
  close = () => { },
}) => {
  const mapRef = useRef();
  const waypointsRef = useRef();
  const printRef = useRef();

  const theme = useTheme();
  const center = useMemo(() => {
    const [first] = waypoints;
    return first && getLatLng(first);
  }, [waypoints]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  return (
    <Fragment>
      <ModalLayout
        theme={theme}
        modalIsOpen={isOpen}
        closeModal={close}
        isfull>
        <Content ref={printRef}>
          <Title>
            <Typography variant="h5" component={Flexbox}>Маршрут доставки №{id} ({courier})</Typography>
            <Content $printHidden>
              <ButtonIcon
                colorBg="#3e73aa"
                onClick={handlePrint}
                icon={<LocalPrintshopIcon />}
              />
            </Content>
          </Title>
          {isOpen && (
            <Body>
              <MapContainer
                style={defaultStyle}
                center={center}
                zoom={14}
                options={defaultOptions}
                whenCreated={(m) => mapRef.current = m}
              >
                <TileLayer
                  attribution={false}
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />

                <MapCenterObserver center={center} />
                <Waypoints ref={waypointsRef} waypoints={waypoints} />
                <Polyline positions={coordinates} />

              </MapContainer>

              <Table
                data={waypoints}
                scheme={deliveryRoute}
                onClick={({ id, latitude, longitude }) => {
                  mapRef.current?.flyTo([latitude, longitude], 15);
                  waypointsRef.current?.show(id);
                }}
              />
            </Body>
          )}
        </Content>
      </ModalLayout>
      {isLoading && <ModalLoad />}
    </Fragment>
  );
};

const Title = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 18px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    font-weight: 700;
`;

const Flexbox = styled.div`
    display: flex;
    justify-content: center;
    flex: 1;
`;

const Body = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    flex: 1;
    margin-top: 40px;
    justify-content: center;
`;

const Content = styled.div`
    @media print {
      visibility: ${(p) => p.$printHidden ? 'hidden' : 'visible'};
    }
`;

export default ModalDeliveryRoute;
