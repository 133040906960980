import * as React from 'react';
import { 
    DataGridPremium,
    useGridApiRef,
    useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';

import { currencyFormatter } from 'utils/helpers/uahCurrencyFormatter';
import { Typography } from '@mui/material';

const ByPaymentTypeTable = ({ data, isCash }) => {
    const text = isCash ? 'Готівка' : 'Банківська карта';

    const apiRef = useGridApiRef();
    const initialState = useKeepGroupedColumnsHidden({
      apiRef,
      initialState: {
        columns: {
          // Other hidden columns
          columnVisibilityModel: { gross: false },
        },
        aggregation: {
            model: {
              sum: 'sum',
              to_pay: 'sum'
            },
        },
      },
    });

    const columns = [
        { 
            field: 'id', 
            headerName: 'Номер замовлення', 
            width: 150,
            valueFormatter: ({ value }) => {
                if (value === undefined) return null;
                return `№${value}`;
            } 
        },
        { field: 'brand', headerName: 'Бренд' },
        { field: 'closed_from', headerName: 'Час закриття замовлення', width: 200 },
        { 
            field: 'sum', 
            headerName: 'Сума за типом оплати',
            type: 'number',
            valueFormatter: ({ value }) => {
                if (value === undefined) return null;
                return currencyFormatter.format(value);
            },
        },
        { 
            field: 'to_pay', 
            headerName: 'До сплати за замовлення',
            type: 'number',
            valueFormatter: ({ value }) => {
                if (value === undefined) return null;
                return currencyFormatter.format(value);
            },
        },
        { 
            field: 'courier',
            headerName: 'Курʼєр',
            width: 150
        },
        { 
            field: 'operator', 
            headerName: 'Оператор',
            width: 150
        },
    ];

    return (
        <React.Fragment>
            <Typography variant='h5'>За типом оплати ({text})</Typography>
            <DataGridPremium
                sx={{
                    ".MuiDataGrid-aggregationColumnHeaderLabel": {
                    display: "none"
                    }
                }}

                apiRef={apiRef}
                initialState={initialState}

                density='compact'
                rows={data}
                columns={columns}
                hideFooter

                autoHeight
                disableColumnFilter
                disableColumnMenu
            />
        </React.Fragment>
    );
}

export default React.memo(ByPaymentTypeTable);
