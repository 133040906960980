import { isArray } from "lodash";

export const restaurantsMapped = (arr = []) => {
    return arr.map((item) => {
        return { title: item.name, value: item.code }
    });
};

export const restaurantsMappedLabel = (arr = []) => {
    let mewArray = [];

    arr.map((item) => {
        mewArray.push({ label: item.name, value: item.code });
    });

    return mewArray;
};

export const restaurantsMappedLabelId = (arr = []) => {
    let mewArray = [];

    arr.map((item) => {
        mewArray.push({ label: item.name, value: item.id });
    });

    return mewArray;
};

export const searchRestaurantByIdArr = (ids = [], arr = []) => {
    let obj = [];

    ids.map(itemId => {
        arr.map(itemKit => {
            if (itemId === itemKit.id) {
                obj.push({ label: itemKit.name, value: itemKit.id });
            }
        })
    });

    return obj;
}

export const searchRestaurantByCode = (code, arr = []) => {
    let obj = {};

    arr.map(el => {
        if (code === el.code) {
            obj = { label: el.name, value: el.code };
        }
    })

    return obj;
}

export const restaurantsMappedId = (arr = []) => {
    if (isArray(arr)) {
        arr = [...[{ name: "-", id: null }], ...arr,]

        return arr.map((item) => {
            return { title: item.name, value: item.id }
        });
    }

    return [];
};

export const saleMetodMappedSelectRes = (arr = []) => {
    let mewArray = [];

    arr?.map((item) => {
        mewArray.push({ value: item.code, label: item.name });
    });

    return mewArray;
}