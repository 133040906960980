import produce from 'immer';

import {
    GET_SETTINGS_PROVINGS,
    GET_CITY_POLYGON_BOUNDS,
    ADD_SETTINGS_PROVINGS_BLOCK_RESTOURANT,
    ADD_SETTINGS_PROVINGS_BLOCK_KITCHEN,
    DELETE_SETTINGS_PROVINGS_BLOCK_RESTOURANT,
    DELETE_SETTINGS_PROVINGS_BLOCK_KITCHEN,
    UPDATE_SETTINGS_PROVINGS_RESTOURAN,
    UPDATE_SETTINGS_PROVINGS_FIELD,
    UPDATE_SETTINGS_PROVING_SAVE_LOAD,
    UPDATE_SETTINGS_PROVINGS_FIELD_SECTION
} from "../constants/settingsProvings";

let dataKitchen = {
    kitchen_id: null,
    polygon_name: "",
    path: "",
    start_time: "",
    finish_time: "",
    min_price: "",
    delivery_time: "",
    closed_until: "",
};

let objData = {
    restaurants: [],
    polygons: [
        { ...dataKitchen }
    ],
    status: 1,
};

const initialState = {
    provingsAll: [],
    loadPageAll: false,
    loadSave: false,
    cityPolygonBounds: {},
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        case GET_SETTINGS_PROVINGS: {
            draft.provingsAll = payload;
            break;
        }

        case GET_CITY_POLYGON_BOUNDS: {
            const { city, bounds } = payload;
            draft.cityPolygonBounds[city] = bounds;
            break;
        }

        case UPDATE_SETTINGS_PROVINGS_FIELD_SECTION: {
            draft.provingsAll[payload.indexSection][payload.nameField] = payload.value;
            break;
        }

        case UPDATE_SETTINGS_PROVING_SAVE_LOAD: {
            draft.loadSave = payload;
            break;
        }

        case ADD_SETTINGS_PROVINGS_BLOCK_RESTOURANT: {
            draft.provingsAll.push(objData);
            break;
        }

        case ADD_SETTINGS_PROVINGS_BLOCK_KITCHEN: {
            draft.provingsAll[payload].polygons.push({ ...dataKitchen });
            break;
        }

        case DELETE_SETTINGS_PROVINGS_BLOCK_RESTOURANT: {
            draft.provingsAll = draft.provingsAll.filter((_, index) => index != payload)
            break;
        }

        case DELETE_SETTINGS_PROVINGS_BLOCK_KITCHEN: {
            let { indexRes, indexKitche } = payload;

            draft.provingsAll[indexRes].polygons = draft.provingsAll[indexRes].polygons.filter((el, index) => (index != indexKitche));
            break;
        }

        case UPDATE_SETTINGS_PROVINGS_RESTOURAN: {
            let { indexRes, value } = payload;
            draft.provingsAll[indexRes].restaurants = value;
            break;
        }

        case UPDATE_SETTINGS_PROVINGS_FIELD: {
            let { indexSection, indexKitchen, nameField, value } = payload;

            draft.provingsAll[indexSection].polygons[indexKitchen][nameField] = value;
            break;
        }

        default:
            break;
    }
}, initialState);
