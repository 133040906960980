import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as session from "store/actions/session";

import { default as ModalConfirmLogout } from "./ModalConfirmLogout";

function mapStateToProps(state) {
    return {
        isOpen: state.view.modalConfirmLogout.isOpen,
    };
}

const mapDispatchToProps = {
    ...viewActions,
    ...session,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmLogout);
