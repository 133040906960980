import { useState } from "react";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import Table from "components/ui-kit/Table";
import ModalDelete from "components/modals/ModalDelete";

import { tableBugReport } from "constants/tables";
import styles from "./../../scss/settings.module.scss";

const defModel = {
    modalDelete: {
        id: null,
        status: false,
    },
}

const BugList = ({
    // state
    isLoadPage,
    isLoadModal,
    selectedBugReport,
    list,
    // dispatch
    getBugReportAll,
    closeBugReport,
    selectBugReportToView,
}) => {
    const [modalDelete, setModalDelete] = useState(defModel.modalDelete);

    const handleCloseBugReport = async () => {
        const success = await closeBugReport(modalDelete.id);
        if (selectedBugReport?.id == modalDelete.id && success) {
            selectBugReportToView(null); // Якщо ми переглядали репорт який видалили, то скинемо його
        }

        handleCloseModalDelete();
        getBugReportAll();
    }

    const handleOpenModalDelete = ({ id }) => {
        setModalDelete({
            id,
            status: true
        });
    }

    const handleCloseModalDelete = async () => {
        setModalDelete(defModel.modalDelete)
    }

    return (
        <div>
            <div className={`${styles.title_row} ${styles.marg_bot}`}>Баг-репорти</div>
            <div className={styles.block}>
                {
                    !isLoadPage && (
                        <div className={styles.block}>
                            <Table
                                data={list}
                                scheme={tableBugReport}
                                onDelete={handleOpenModalDelete}
                                onClick={selectBugReportToView}
                            />
                        </div>
                    )
                }
                {
                    isLoadPage && (
                        <LoadingCircl />
                    )
                }
            </div>

            <ModalDelete
                title={'Баг-репорт'}
                text={`Ви підтверджуєте закриття багу ?`}
                isOpen={modalDelete.status}
                isLoad={isLoadModal}
                funDelete={handleCloseBugReport}
                onCloseModal={handleCloseModalDelete}
            />
        </div>
    )
}

export default BugList;