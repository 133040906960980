import { useRef, useMemo, useCallback } from "react";

// monaco
import Editor from "@monaco-editor/react";
import { conf, language } from "utils/highlight/twig";

// mui
import { Stack } from "@mui/material";

// chips
import TemplateChip from "./TemplateChip";

// prop types
import PropTypes from "prop-types";

const VisualEditor = ({
  title,
  value,
  templates = [],
  onChange = () => { },
  height = "150px",
}) => {
  const monacoRef = useRef(null);
  const options = useMemo(() => ({
    minimap: { enabled: false },
  }), []);

  const executeEdit = useCallback((text, range) => {
    monacoRef.current.focus();
    monacoRef.current.executeEdits("my-source", [
      {
        text,
        range,
        identifier: { major: 1, minor: 1 },
        forceMoveMarkers: true
      }
    ]);
  }, []);

  const handlePaste = useCallback((template) => {
    const selection = monacoRef.current.getSelection();
    executeEdit(template, selection);
  }, [executeEdit]);

  const handleWrap = useCallback((template) => {
    const selection = monacoRef.current.getSelection();
    const text = monacoRef.current.getModel().getValueInRange(selection) || 'текст';
    executeEdit(template + text + template, selection);
  }, [executeEdit]);

  const handleEditorWillMount = useCallback((monaco) => {
    monaco.languages.register({ id: "twig" });
    monaco.languages.setLanguageConfiguration("twig", conf);
    monaco.languages.setMonarchTokensProvider("twig", language);
  }, []);

  const handleEditorDidMount = useCallback((monaco) => {
    monacoRef.current = monaco;
  }, []);

  return (
    <div>
      <Stack spacing={1}>
        {!!title && <div>{title}</div>}
        <Editor
          value={value}
          height={height}
          theme="light"
          defaultLanguage="twig"
          beforeMount={handleEditorWillMount}
          onMount={handleEditorDidMount}
          onChange={onChange}
          options={options}
        />
      </Stack>
      {!!templates.length && (
        <Stack spacing={1}>
          <div>Змінні / логічні конструкції / стиль</div>
          <Stack direction='row' spacing={1}>
            {templates.map(({ title, template, isVisual, isLogical }, index) => (
              <TemplateChip
                key={`template-${index}`}
                title={title}
                template={template}
                handle={isVisual ? handleWrap : handlePaste}
                isVisual={isVisual}
                isLogical={isLogical}
              />
            ))}
          </Stack>
        </Stack>
      )}
    </div>
  );
}

const templateShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  template: PropTypes.string.isRequired,
  isVisual: PropTypes.bool,
  isLogical: PropTypes.bool,
});

VisualEditor.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  height: PropTypes.string,
  templates: PropTypes.arrayOf(templateShape),
}

export default VisualEditor;
