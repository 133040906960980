/*  no-nested-ternary */
/*  no-magic-numbers */
import queryString from "query-string";
import { UnauthorizedError, RequestAbortedError } from "./apiClientErrors";

import store from "../store";
import { LOGOUT_BUTTON } from "../store/constants/session";

export default class ApiClient {
  constructor({
    apiUrl = "",
    prefix = "",
    onError = () => { },
    onNetworkError = () => { },
  } = {}) {
    if (!apiUrl) throw new Error("[apiUrl] required");

    this.apiUrl = apiUrl;
    this.prefix = prefix;
    this.onError = onError;
    this.onNetworkError = onNetworkError;
  }

  get(url, params = {}, signal) {
    return this.request({
      url,
      params,
      signal,
      method: "GET",
    });
  }

  post(url, payload = {}, type = "json", signal) {
    return this.request({
      url,
      method: "POST",
      body: payload,
      signal,
      type,
    });
  }

  put(url, payload = {}, signal) {
    return this.request({
      url,
      method: "PUT",
      body: payload,
      signal,
    });
  }

  patch(url, payload = {}, signal) {
    return this.request({
      url,
      method: "PATCH",
      body: payload,
      signal,
    });
  }

  delete(url, payload = {}, signal) {
    return this.request({
      url,
      method: "DELETE",
      body: payload,
      signal,
    });
  }

  setApiUrl(apiUrl) {
    this.apiUrl = apiUrl;
  }

  setApiPrefix(prefix) {
    this.prefix = prefix;
  }

  setToken(token) {
    this.token = token;
  }

  getToken() {
    return this.token;
  }

  async fetch(url, options, attempts = 1) {
    for (let i = 0; i < attempts; i++) {
      try {
        return await fetch(url, options);
      } catch (error) {
        console.log("error fetch: ", error);
      }
    }
  }

  async request({ url, method, params = {}, body, type = "json", signal }) {
    const query = Object.keys(params).length
      ? `?${queryString.stringify(params, { arrayFormat: "comma" })}`
      : "";
    const requestUrl = `${this.apiUrl}/${this.prefix}/${url}${query}`;
    const headers = new Headers();
    const requestBody =
      method === "GET"
        ? undefined
        : type === "formData"
          ? body
          : JSON.stringify({ ...body });

    headers.append("Accept", "application/json");

    if (type === "json") {
      headers.append("Content-Type", "application/json");
    }

    if (this.token) {
      headers.append("Authorization", `Bearer ${this.token}`);
    }

    const options = {
      method,
      headers,
      signal, 
      redirect: "follow",
      withCredentials: true,
      crossDomain: false,
      body: requestBody,
    };

    let json = {};
    try {
      const res = await this.fetch(requestUrl, options);

      if (signal?.aborted) {
        throw new RequestAbortedError();
      }

      try {
        json = await res.json();
      } catch (error) {
        console.error("Wrong response error: ", error);
      }

      if (res.status === 401) {
        throw new UnauthorizedError();
      }

      if (json.status === 0) {
        throw json.error;
      }

      return json;
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        localStorage.removeItem("jwt"); // remove stale json web token
        store.dispatch({ type: LOGOUT_BUTTON }); // dispatch logout
      
        const message = json.message || 'Сессія застаріла, увійдіть знову';
        return { message, errors: { token: [message] }};
      }

      if (error instanceof RequestAbortedError) {
        return { errors: { user: ['Запит до серверу скасовано'] }};
      }

      console.error("Server error: ", error);
      return { errors: { server: ['Помилка серверу'] } };
    }
  }

  async delay(ms = 100) {
    return new Promise((res) => setTimeout(res, ms));
  }
}
