import Base from './Base.js';

export default class CookAPI extends Base {
    getOrdersStatusChef() {
        return this.apiClient.get('pages/cook/orders');
    }

    setCooksInTheKitchen(id, data) {
        return this.apiClient.post(`orders/${id}/update-cooks`, data);
    }
}