import PropTypes from "prop-types";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";

import styles from './../headers.module.scss';

export const HeaderProving = ({
    getPogigons = () => { },
    updateLink = () => { },
    handleSynchronization = () => { },
    linkValue = '',
    loadBtnGetMap = false,
    quantitykPolygons = 0,
    loadSynchronization = false,
    lengRecords = 0,
}) => {
    return (
        <div className={styles.wr}>
            <div className={styles.left}>
                <div className={styles.head_in_btn}>
                    <TextInput
                        title="Посилання на карту"
                        value={linkValue}
                        isStyle={true}
                        stylePadTop='0'
                        styleMarg='0'
                        onChange={(e) => updateLink(e.target.value)}
                    />
                    <ButtonIcon
                        onClick={getPogigons}
                        title='Отримати полігони'
                        positionIcon="left"
                        isLoad={loadBtnGetMap}
                    />
                    {
                        !!quantitykPolygons && (
                            <div className={styles.quantity_link}>
                                <b>Полігонів: </b>
                                {quantitykPolygons}
                            </div>
                        )
                    }

                </div>
            </div>
            <div className={styles.right}>
                {/* {
                    !!quantitykPolygons && !!lengRecords && (
                        <ButtonIcon
                            onClick={handleSynchronization}
                            title='Синхронізувати полігони'
                            positionIcon="left"
                            isLoad={loadSynchronization}
                            isModal={true}
                            colorBg="blue"
                        />
                    )
                } */}
            </div>
        </div>
    )
}

HeaderProving.propTypes = {
    handleCreate: PropTypes.func,
};

export default HeaderProving;
