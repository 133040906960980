import * as React from "react";

// lodash
import _ from "lodash";

// moment
import moment from "moment";

// components
import ButtonIcon from "components/ui-kit/ButtonIcon";

//icon
import KitchenDeliveryTimeIcon from "@mui/icons-material/AccessAlarm";

function getKitchenOpenTime(closedUntil) {
  if (!closedUntil || closedUntil.isBefore()) // not closed at all or past
    return;

  const difference = closedUntil.diff(moment(), "minutes");
  if (difference <= 0)
    return;

  return `${difference} хв.`;
}

const KitchenDeliveryTimeButton = ({ userKitchenCode, kitchens, handler }) => {
  const [opensIn, setOpensIn] = React.useState();
  const kitchenClosedUntil = React.useMemo(() => {
    const kitchen = _.find(kitchens, { code: userKitchenCode });
    if (!kitchen || !kitchen.closed_until)
      return;
    return moment(kitchen.closed_until);
  }, [kitchens, userKitchenCode]);

  React.useLayoutEffect(() => {
    const interval = setInterval(function checkInterval() {
      console.debug('Checking kitchen closed_until', kitchenClosedUntil);
      setOpensIn(getKitchenOpenTime(kitchenClosedUntil));
      return checkInterval;
    }(), 60000);

    // cleanup
    return () => clearInterval(interval);
  }, [kitchenClosedUntil]);

  return (
    <ButtonIcon
      title={opensIn}
      onClick={handler}
      icon={<KitchenDeliveryTimeIcon />}
      positionIcon="left"
      // disabled={!!opensIn}
      colorBg={opensIn && "#ff4900"}
    />
  );
};

export default React.memo(KitchenDeliveryTimeButton);
