import PropTypes from "prop-types";
import { isArray } from "lodash";
import {
    useRouteMatch,
    useHistory
} from "react-router-dom";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import TableCities from "components/ui-kit/Table/TableCities";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

import AddIcon from "@mui/icons-material/Add";
import styles from "./../scss/settings.module.scss";

import { tableCities } from "constants/tables";
import { useEffect } from "react";

const Restaurants = ({
    view = {},
    cities = [],
    //dispatch
    getSettingsCities,
    updateFormCity,
    cleaningNewForm,
}) => {
    const history = useHistory();
    const { path } = useRouteMatch();

    useEffect(() => {
        getSettingsCities();
    }, []);

    const handlerEdit = (data) => {
        history.push(`${path}/${data.sync_id}`);
    }

    const handlerCreate = (isSettlements = false) => {
        history.push(`${path}/new`);

        cleaningNewForm("editCity");

        if (isSettlements) {
            updateFormCity("type", 2);
            updateFormCity("parent_id", "");
        } else {
            updateFormCity("type", 1);
            updateFormCity("parent_id", 0);
        }
    }

    return (
        <div>
            <div className={styles.wr_head}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={() => handlerCreate()}
                        title='Додати місто'
                        icon={<AddIcon />}
                        positionIcon="left"
                    />
                </div>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={() => handlerCreate(true)}
                        title='Додати передмістя'
                        icon={<AddIcon />}
                        positionIcon="left"
                    />
                </div>
            </div>
            <div className={styles.wr_row}>
                {
                    !view?.isLoadPage ? (
                        isArray(cities) && (
                            <TableCities
                                onClick={handlerEdit}
                                data={cities}
                                scheme={tableCities}
                                isStictyhead={true}
                            />
                        )
                    ) : (
                        <LoadingCircl />
                    )
                }
            </div>
        </div>
    )
}

Restaurants.propTypes = {
    view: PropTypes.object,
    cities: PropTypes.array,
    getSettingsCities: PropTypes.func,
    updateFormCity: PropTypes.func,
    cleaningNewForm: PropTypes.func,
};

export default Restaurants;