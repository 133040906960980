import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { CSSTransition } from 'react-transition-group';
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";

import { TextInput } from "components/ui-kit/inputs/TextInput";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import Radio from "components/ui-kit/Radio";
import { IconButton } from "components/ui-kit/buttons/IconButton";
import { DatePicker } from "components/ui-kit/inputs/DatePicker";

import { saleMetodMappedDrop } from "utils/mappers/others";

import { getCashalotGetOrderById, postCashalotCreate } from "store/actions/salses";

import { RADIO_FORM_TYPE } from "constants/schemes";
import { isArray } from "lodash";

const dataStart = {
    formCheck: {
        id: null,
        data: moment(),
    },
    objForm: {
        type: "form",
        kitchen_code: "kulparkivska",
        payments: [
            {
                "name": "-",
                "value": 0,
            }
        ]
    }
}

export const ModalFormCheck = ({
    showModal,
    setShowModal,
    kitchens,
    metodPaymentsList,
}) => {
    const dispatch = useDispatch();
    const nodeRef = useRef(null);
    const [typeFrom, setTypeForm] = useState("one");
    const [isLoad, setIsLoad] = useState(false);
    const [isLoadGet, setIsLoadGet] = useState(false);
    const [dataRes, setDataRes] = useState(null);
    const [formCheck, setFormCheck] = useState({
        id: null,
        data: moment(),
    });

    const [objForm, setObkForm] = useState({
        type: "form",
        kitchen_code: null,
        payments: [
            {
                "name": "-",
                "value": 0,
            }
        ]
    });

    const handleUpdateFieldFormCheck = (name, value) => {
        setFormCheck(prev => ({
            ...prev,
            [name]: value,
        }));
    }

    const handleGetCheck = () => {
        dispatch(getCashalotGetOrderById({
            params: {
                order_id: formCheck.id,
                date: formCheck.data.format("YYYY-MM-DD"),
            },
            setIsLoad: setIsLoadGet,
            setDataRes,
        }));
    }

    const handlePostCheck = () => {
        dispatch(postCashalotCreate({
            data: {
                order_id: dataRes?.iiko_order?.id,
                type: "default",
            },
            setIsLoad,
            handleCleanForm,
        }));
    }

    const handleAddPayment = () => {
        setObkForm(prev => ({
            ...prev,
            payments: [
                ...prev.payments,
                ...[{
                    "name": "-",
                    "value": 0,
                }]
            ]
        }));
    }

    const handlerRemoveItem = (index) => {
        let copyArr = [...objForm.payments];
        copyArr.splice(index, 1);

        setObkForm(prev => ({
            ...prev,
            payments: copyArr,
        }));
    }

    const handleUpdateForm = (name, value) => {
        setObkForm(prev => ({
            ...prev,
            [name]: value,
        }));
    }

    const handleUpdateFormPayment = (index, name, value) => {
        let copyArr = [...objForm.payments];
        copyArr[index][name] = value;

        setObkForm(prev => ({
            ...prev,
            payments: copyArr,
        }));
    }

    const handleCleanForm = () => {
        setFormCheck(dataStart.formCheck);
        setObkForm(dataStart.objForm);
    }

    return (
        <CSSTransition
            in={showModal}
            nodeRef={nodeRef}
            timeout={300}
            classNames="alert"
            unmountOnExit
        >
            <div className="modal-form-check" ref={nodeRef}>
                <div className="opas" onClick={() => setShowModal(false)}></div>
                <div className="modal-form-check__wr">
                    <div className="head-form-modal">
                        <Radio
                            colors={"#000"}
                            items={RADIO_FORM_TYPE}
                            valueKey="status"
                            value={typeFrom}
                            onChange={(e) => setTypeForm(e.value.value)}
                        />
                    </div>
                    {
                        typeFrom == "one" && (
                            <div>
                                <div className="wore-modal-col">
                                    <div>
                                        <Dropdown
                                            title="Господарьска одиниця"
                                            type='text'
                                            isBlack={true}
                                            isLight={true}
                                            fontSize={"14px"}
                                            mode={'primary'}
                                            onChange={(e) => handleUpdateForm("kitchen_code", e.target.value)}
                                            value={objForm.kitchen_code}
                                            list={kitchens}
                                        />
                                    </div>
                                </div>
                                <div className="dish">
                                    <span>Страва: </span> Піца, суши та напої
                                </div>
                                {
                                    isArray(objForm.payments) && (
                                        objForm.payments.map((item, index) => (
                                            <div key={index} className={`modal-in-number ${(index > 1) ? "modal-in-number_two" : ""}`}>
                                                <div>
                                                    <Dropdown
                                                        title="Тип оплати"
                                                        type='text'
                                                        isBlack={true}
                                                        isLight={true}
                                                        fontSize={"14px"}
                                                        mode={'primary'}
                                                        onChange={(e) => handleUpdateFormPayment(index, "name", e.target.value)}
                                                        value={item.name}
                                                        list={saleMetodMappedDrop([...['-'], ...metodPaymentsList.map(item => item.code)])}
                                                    />
                                                </div>
                                                <div>
                                                    <TextInput
                                                        title="Грн"
                                                        type="number"
                                                        value={item.value}
                                                        isStyle={true}
                                                        onChange={(e) => handleUpdateFormPayment(index, "value", e.target.value)}
                                                    />
                                                </div>
                                                <IconButton
                                                    icon={<DeleteIcon />}
                                                    disabled={(objForm.payments.length == 1)}
                                                    onClick={() => handlerRemoveItem(index)}
                                                />
                                            </div>
                                        ))
                                    )
                                }
                                <div className="from-btn-add-price" onClick={handleAddPayment}><button>Додати оплату</button></div>
                                <div className="wore-modal-wr-btn">
                                    <ButtonIcon
                                        title='Відправити'
                                        colorBg="green"
                                        isLoad={isLoad}
                                        disabled={(!objForm.kitchen_code) || (objForm.kitchen_code == '-')}
                                        onClick={() => dispatch(postCashalotCreate({ data: objForm, setIsLoad, handleCleanForm }))}
                                    />
                                </div>
                            </div>
                        )
                    }
                    {
                        typeFrom == "two" && (
                            <div>
                                <div className="wore-modal-col wore-modal-col_two">
                                    <div>
                                        <TextInput
                                            title="№"
                                            type="number"
                                            value={formCheck.id}
                                            isStyle={true}
                                            onChange={(e) => handleUpdateFieldFormCheck("id", e.target.value)}
                                        />
                                    </div>
                                    <div>
                                        <DatePicker
                                            title="Дата"
                                            width="100%"
                                            max={moment()}
                                            white={true}
                                            value={formCheck.data}
                                            onChange={(e) => { handleUpdateFieldFormCheck("data", e) }}
                                        />
                                    </div>
                                    <div className="btn-min-row">
                                        <ButtonIcon
                                            title={`Отр.`}
                                            colorBg="green"
                                            isLoad={isLoadGet}
                                            onClick={handleGetCheck}
                                        />
                                    </div>
                                </div>
                                {
                                    !!dataRes && (
                                        <div className="content-check-wore">
                                            {
                                                dataRes.iiko_order.client && (
                                                    <>
                                                        <p className="title-table">Клієнт</p>
                                                        <table>
                                                            <tr>
                                                                <th>Ім'я</th>
                                                                <th>Телефон</th>
                                                            </tr>
                                                            <tr >
                                                                <td>{dataRes.iiko_order.client.name}</td>
                                                                <td>{dataRes.iiko_order.client.phone}</td>
                                                            </tr>
                                                        </table>
                                                    </>
                                                )
                                            }
                                            {
                                                isArray(dataRes.iiko_order.iiko_items) && (
                                                    <>
                                                        <br />
                                                        <p className="title-table">Методи оплати</p>
                                                        <table>
                                                            <tr>
                                                                <th>Назва</th>
                                                                <th>Ціна грн.</th>
                                                            </tr>
                                                            {
                                                                dataRes.iiko_order.iiko_items.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.sum}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </table>
                                                    </>
                                                )
                                            }
                                            {
                                                isArray(dataRes.iiko_order.iiko_payments) && (
                                                    <>
                                                        <br />
                                                        <p className="title-table">Методи оплати</p>
                                                        <table>
                                                            <tr>
                                                                <th>Назва</th>
                                                                <th>Ціна грн.</th>
                                                            </tr>
                                                            {
                                                                dataRes.iiko_order.iiko_payments.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>{item.name}</td>
                                                                        <td>{item.sum}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </table>
                                                    </>
                                                )
                                            }
                                            {
                                                isArray(dataRes.iiko_order.iiko_discounts) && (
                                                    <>
                                                        <br />
                                                        <p className="title-table">Знишки</p>
                                                        <table>
                                                            <tr>
                                                                <th>Назва</th>
                                                                <th>Ціна грн.</th>
                                                            </tr>
                                                            {
                                                                dataRes.iiko_order.iiko_discounts.map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td>-</td>
                                                                        <td>{item.sum}</td>
                                                                    </tr>
                                                                ))
                                                            }
                                                        </table>
                                                    </>
                                                )
                                            }
                                        </div>
                                    )
                                }
                                {
                                    !!dataRes?.iiko_order?.id && (
                                        <div className="wore-modal-wr-btn">
                                            <ButtonIcon
                                                title={`Відправити`}
                                                colorBg="green"
                                                isLoad={isLoad}
                                                onClick={handlePostCheck}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        )
                    }
                </div>
            </div>
        </CSSTransition>
    )
}
