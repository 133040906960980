import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as orderActions from "store/actions/order";
import * as ordersActions from "store/actions/orders";

import { default as OrdersList } from "./OrdersList";

function mapStateToProps(state) {
  return {
    activeOrder: state.orders.activeOrder,
    orderStatus: state.orders.orderStatus,
    fullUsers: state.users.fullUsers,
    extensive: state.clients.extensive,
  };
}

const mapDispatchToProps = {
  ...viewActions,
  ...orderActions,
  ...ordersActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);
