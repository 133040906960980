import PropTypes from "prop-types";

import { TextInput } from "components/ui-kit/inputs/TextInput";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import Radio from "components/ui-kit/Radio";
import Select from "components/ui-kit/Select";

import styles from "../scss/settings.module.scss";

import {
    RADIO_SCHEME,
    RADIO_SCHEME_ACTIONS
} from "constants/schemes";
import { TextArea } from "components/ui-kit/inputs/TextArea";

import { citiesMappedId } from "utils/mappers/cities";
import {
    rolesMappedSelectId,
    rolesSearchId,
    rolesArrayId
} from "utils/mappers/roles";
import {
    categoriesMappedSelect,
    searchCategoriesId,
    arrayIdCategoriesFromCategor
} from "utils/mappers/categories";

export const PromotionTypePickup = ({
    //props
    error,
    roles,
    cities,
    categories,
    promotionsEdit,
    updateFormPromotion = () => { },
}) => {
    return (
        <div>
            <div className={`${styles.row_form_two}`}>
                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                    <Dropdown
                        title='Місто'
                        isBlack={true}
                        width="100%"
                        onChange={(e) => updateFormPromotion("city_id", e.target.value)}
                        value={promotionsEdit.city_id}
                        list={citiesMappedId(cities)}
                        isLight={true}
                        error={error.city_id}
                        fontSize={'14px'}
                    />
                    <div className={styles.wr_drop}>
                        <Select
                            selectOptions={categoriesMappedSelect(categories)}
                            selectedOption={searchCategoriesId(promotionsEdit.product_categories, categories)}
                            valueKey='product_categories'
                            label='Категорії'
                            isMulti
                            mode={'primary'}
                            onChange={({ value }) => updateFormPromotion("product_categories", arrayIdCategoriesFromCategor(value))}
                        />
                    </div>
                    <TextInput
                        title="Назва"
                        type="text"
                        value={promotionsEdit.name}
                        isStyle={true}
                        error={error.name}
                        onChange={(e) => updateFormPromotion("name", e.target.value)}
                    />
                </div>
                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                    <TextInput
                        title="% знижки"
                        type="number"
                        max={100}
                        min={0}
                        value={promotionsEdit.sale}
                        isStyle={true}
                        error={error.sale}
                        onChange={(e) => updateFormPromotion("sale", e.target.value)}
                    />
                    <div className={styles.wr_drop}>
                        <Select
                            selectOptions={rolesMappedSelectId(roles)}
                            selectedOption={rolesSearchId(promotionsEdit.roles, roles)}
                            valueKey='roles'
                            label='Ролі'
                            isMulti
                            mode={'primary'}
                            onChange={({ value }) => updateFormPromotion("roles", rolesArrayId(value))}
                        />
                    </div>
                </div>
            </div>
            <TextArea
                isStyle={true}
                title='Опис акції'
                value={promotionsEdit.description}
                error={error.description}
                onChange={(e) => updateFormPromotion("description", e.target.value)}
            />
            <div className={styles.wr_drop}>
                <Radio
                    colors={"#000"}
                    items={RADIO_SCHEME_ACTIONS}
                    label="Працює з акційними товарами ?"
                    valueKey="status"
                    value={promotionsEdit.isActions}
                    onChange={(e) => updateFormPromotion("isActions", +e.value.value)}
                />
            </div>
            <div className={styles.wr_drop}>
                <Radio
                    colors={"#000"}
                    items={RADIO_SCHEME}
                    label="Активність акції"
                    valueKey="status"
                    value={promotionsEdit.status}
                    onChange={(e) => updateFormPromotion("status", +e.value.value)}
                />
            </div>
        </div>
    )
}

PromotionTypePickup.propTypes = {
    number: PropTypes.number,
    handleSubmit: PropTypes.func,
    handleSubmitClose: PropTypes.func,
    updateFormPromotionWeek: PropTypes.func,
};