import styled, { useTheme } from "styled-components";
import { isArray } from "lodash";

import { renderIcon } from "utils/helpers/typeKitchen";
import { getTimeFlag } from "utils/helpers/getTimeFlag";

import PersonIcon from '@mui/icons-material/Person';

const ItemProduct = ({ data }) => {
    return (
        <WrOrder>
            <OrderMain>
                {
                    data.quantity > 1 && (
                        <OrderImg>
                            {data.quantity}
                        </OrderImg>
                    )
                }
                <OrderInfo>
                    <OrderName>{data.product.title}</OrderName>
                    <ItemsComponents>
                        {
                            isArray(data.additions) && data.additions.map((itemDop, index) => {
                                return (
                                    <ItemComponent key={index}>{itemDop.addition.name} - {itemDop.quantity} шт. {((data.additions.length - 1) != index) && ','}</ItemComponent>
                                )
                            })
                        }
                    </ItemsComponents>
                </OrderInfo>
            </OrderMain>
            {
                data.comment && (
                    <>
                        <TitleText>Коментарь</TitleText>
                        <TextOrder>{data.comment ? data.comment : '-----'}</TextOrder></>
                )
            }
        </WrOrder>
    )
}

export const DetalCardCook = ({
    data,
}) => {
    const theme = useTheme();
    const timeCook = getTimeFlag(data, 'delivery_till');

    const typeIconkitchen = renderIcon(data?.restaurant);

    return (
        <Wrapper
            theme={theme}
        >
            <Head>
                <View>
                    {
                        typeIconkitchen && <ImgKit src={typeIconkitchen} />
                    }
                    <Order>№ <OrderItem>{data?.id},</OrderItem></Order>
                    {
                        data?.persons && (
                            <Pers>
                                <PersonIcon />
                                {data.persons}
                            </Pers>
                        )
                    }
                </View>
                <Time bg={timeCook.class}>{timeCook.time}</Time>
            </Head>

            {
                isArray(data?.items) && data.items.map((item) => (
                    <ItemProduct
                        key={item.id}
                        data={item}
                    />
                ))
            }

        </Wrapper>
    )
}

const Pers = styled.div`
    display: flex;
    align-items: center;
    margin-left: 10px;
`;

const Wrapper = styled.div`
    padding: 10px;
    border-radius: 8px;
    border: 1px solid ${(p) => p.theme.colorEC};
    background: #fff;
    transition: 0.4s;
    position: relative;
`;

const Head = styled.div`
    display: flex;
    align-items: center;
`;

const Order = styled.div`
    font-weight: 400;
    font-size: 14px;
`;

const OrderItem = styled.span`
    font-weight: 600;
`;

const View = styled.div`
    display: flex;
    align-items: center;
`;

const ImgKit = styled.img`
    display: flex;
    align-items: center;
    margin-right: 10px;
    max-width: 24px;
`;

const Time = styled.div`
    text-align: center;
    padding: 5px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    background: ${(p) => p.bg};
    color: #000;
    font-weight: 400;
    font-size: 14px;
    margin-left: auto;
`;

const WrOrder = styled.div`
    margin: 0 -10px 0 -10px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
`;

const OrderMain = styled.div`
    display: flex;
    align-items: flex-start;
`;

const OrderImg = styled.div`
    min-width: 40px; 
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 500;
    background: #483f3f;
`;

const OrderInfo = styled.div`
    margin-left: 10px;
    width: calc(100% - 40px);
`;

const OrderName = styled.div`
    color: ${(p) => p.theme.color33};
    font-weight: 500;
    font-size: 16px;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
`;

const ItemsComponents = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const ItemComponent = styled.span`
    padding: 0px 5px 0 0;
    color: ${(p) => p.theme.color5C};
    font-size: 14px;
`;

const TitleText = styled.div`
    color: ${(p) => p.theme.color82};
    font-weight: 500;
    margin: 10px 0 0 0;
    font-size: 14px;
`;

const TextOrder = styled.div`
    margin: 5px 0 0 0;
    font-weight: 500;
    font-size: 14px;
`;