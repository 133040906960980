import React from "react";
import styled, { useTheme } from "styled-components";

const ButtonIconLink = ({
  title = '',
  icon,
  disabled = false,
  colorBg = "",
  positionIcon = "right",
  width,
  label = "",
  isActive = false,
  href = "",
  target = "_self"
}) => {
  const theme = useTheme();
  colorBg = colorBg.length ? colorBg : theme.accentColor;

  return (
    <Wrapper
      theme={theme}
      disabled={disabled}
      colorBg={colorBg}
      width={width}
      title={label}
      isActive={isActive}
      href={href}
      target={target}
    >
      {
        positionIcon.includes('left') && <Icon>{icon}</Icon>
      }
      {
        title && <Title>{title}</Title>
      }
      {
        icon && positionIcon.includes('right') && <Icon>{icon}</Icon>
      }
    </Wrapper>
  );
};

const Wrapper = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border-radius: 8px;
  color: ${(p) => p.theme.secondaryColor};
  background-color: ${(p) => p.isActive ? p.theme.proceedColor : p.colorBg};
  pointer-events: ${(p) => p.isActive ? "none" : "auto"};
  transition: background-color 200ms ease-in-out;
  width: ${(p) => p.width};
  position: relative;
  &:disabled {
    opacity: 0.7;
    pointer-events: none;
  }
  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.proceedColor};
  }
  min-height: 42px;

  div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #00000036;
  }
`;

const Title = styled.span`
font-family: 'Barlow', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  margin: 0 5px;
`;

const Icon = styled.span`
  color: ${(p) => p.theme.secondaryColor};
  height: 18px;
  width: 18px;
  svg {
    height: inherit;
    width: inherit;
  }
  color: inherit;
`;

export default ButtonIconLink;
