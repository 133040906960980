import { useEffect, useState } from "react";

import HeaderProving from "components/headers/HeaderProving";
import ProvingGround from "./ProvingGround";

import { getPolion } from "utils/helpers/getPoligon";

const ProvingGrounds = ({
    poligones,
    setPoligones,
    settingsProvings
}) => {
    const [linlMap, setLinkMap] = useState('https://www.google.com/maps/d/view?mid=1q0jKc3RQ63hrjIR2peO4rO71v17JUKlk&key=AIzaSyBhOUWjNeq8iqMT71E8xM61WYNFN-Ol4jE');
    const [loadBtnGetMap, setLoadBtnGetMap] = useState(false);

    const getPogigons = () => {
        setLoadBtnGetMap(true);
        getPolion(linlMap).then((res) => {
            setLoadBtnGetMap(false);
            setPoligones(res);
        })
    }

    useEffect(() => {
        getPogigons();
    }, []);

    return (
        <div>
            <HeaderProving
                getPogigons={getPogigons}
                updateLink={(value) => setLinkMap(value)}
                linkValue={linlMap}
                loadBtnGetMap={loadBtnGetMap}
                quantitykPolygons={poligones.length}
                lengRecords={!!settingsProvings.provingsAll?.length}
            />
            <ProvingGround />
        </div>
    )
}

export default ProvingGrounds;
