import { isArray } from "lodash";

export const productMappedSelect = (arr = []) => {
    return arr?.map((item) => {
        const label = [item.article, item.title_ua]
            .filter(i => !!i)
            .join(" | ");
        return { value: item.id, label };
    }) ?? [];
};

export const productMappedSelectedItem = (arr = [], selectedProductId = null) => {
    const selectedItem = arr.find((item) => item.id == selectedProductId);
    return selectedItem ? { value: selectedItem.id, label: [selectedItem.article, selectedItem.title_ua].filter(i => !!i).join(" | ") } : [];
};

export const productMappedSearchSelectItem = (arr = [], products = []) => {
    let mewArray = [];

    arr?.map((item) => {
        products.map((id) => {
            if (id === item.id) {
                mewArray.push({ value: item.id, label: [item.article, item.title_ua].filter(i => !!i).join(" | ") });
            }
        });
    });

    return mewArray;
}

export const productMappedSelectItem = (arr = [], iskeyValid = null) => {
    let mewArray = [];

    if (isArray(arr)) {
        arr?.map((item) => {
            if (!!iskeyValid) {
                item.product.category[iskeyValid] == 1 && mewArray.push({ value: item.product.id, label: item.product.title_ua });
            } else {
                mewArray.push({ value: item.product.id, label: item.product.title_ua });
            }
        });
    }

    return mewArray;
};

export const productSearchSelectItem = (arr = [], products = []) => {
    let mewArray = [];

    arr?.map((item) => {
        products.map((el) => {
            if (el.product.id === item) {
                mewArray.push({ value: el.product.id, label: el.product.title_ua });
            }
        });
    });

    return mewArray;
}

export const productFilterId = (arr = []) => {
    return arr?.map((item) => item.value) ?? [];
}

export const productMappedDropdown = (arr = []) => {
    return arr?.map((item) => {
        const title = [item.article, item.title_ua]
            .filter(i => !!i)
            .join(" | ");
        return { value: item.id, title };
    }) ?? [];
};

export const searchProductId = (arr = [], products = []) => {
    let mewArray = [];

    arr?.map((item) => {
        products.map((el) => {
            const label = [el.article, el.title_ua]
                .filter(i => !!i)
                .join(" | ");

            if (el.id == item) {
                mewArray.push({ value: el.id, label });
            }
        });
    });

    return mewArray;
}



