import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import * as productsActions from 'store/actions/products';
import * as viewActions from "../../../store/actions/view";
import { default as PromotionsPage } from './Promotions';

function mapStateToProps(state) {

    return {
        cities: [...[{ name_ua: '-', sync_id: "-", status: 1 }], ...state.settings.cities],
        categories: [...[{ name: '-', sync_id: "-" }], ...state.settings.productCategories],
        restaurants: state.settings.restaurants,
        roles: state.settings.roles,
        products: state.products.list,
        promotionsEdit: state.settings.promotionsEdit.data,
        promotions: state.settings.promotions,
        isOpenEdit: state.settings.promotionsEdit.isOpen,
        isLoad: state.settings.promotionsEdit.isLoad,
        isLoadEdit: state.settings.promotionsEdit.isLoadEdit,
        isOpenModalDelete: state.view.modalDelete.isOpen,
        isLoadModalDelete: state.view.modalDelete.loadingsModal,
    };
}

const mapDispatchToProps = {
    ...settingsActions,
    ...viewActions,
    ...productsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromotionsPage);
