export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_ALL_MODALS = 'CLOSE_ALL_MODALS';
export const OPEN_EDITORCALLCENTER = 'OPEN_EDITORCALLCENTER';
export const CLOSE_EDITORCALLCENTER = 'CLOSE_EDITORCALLCENTER';
export const OPEN_MODAL_FILTER = 'OPEN_MODAL_FILTER';
export const CLOSE_MODAL_FILTER = 'CLOSE_MODAL_FILTER';
export const OPEN_MODAL_TOGGLE_KITCHEN_SHIFT = 'OPEN_MODAL_TOGGLE_KITCHEN_SHIFT';
export const CLOSE_MODAL_TOGGLE_KITCHEN_SHIFT = 'CLOSE_MODAL_TOGGLE_KITCHEN_SHIFT';
export const OPEN_MODAL_BUG_REPORT = 'OPEN_MODAL_BUG_REPORT';
export const CLOSE_MODAL_BUG_REPORT = 'CLOSE_MODAL_BUG_REPORT';
export const OPEN_MODAL_CONFIRM_LOGOUT = 'OPEN_MODAL_CONFIRM_LOGOUT';
export const CLOSE_MODAL_CONFIRM_LOGOUT = 'CLOSE_MODAL_CONFIRM_LOGOUT';
export const IS_REDACTION_ORDER = 'IS_REDACTION_ORDER';
export const IS_SET_ID_COOK = 'IS_SET_ID_COOK';
export const OPEN_MODAL_CLIENTS = 'OPEN_MODAL_CLIENTS';
export const CLOSE_MODAL_CLIENTS = 'CLOSE_MODAL_CLIENTS';
export const IS_LOAD_MODAL_CLIENTS = 'IS_LOAD_MODAL_CLIENTS';
export const UPDATE_LOAD_PAGE = 'UPDATE_LOAD_PAGE';
export const OPEN_MODAL_CLIENTS_ADD = 'OPEN_MODAL_CLIENTS_ADD';
export const CLOSE_MODAL_CLIENTS_ADD = 'CLOSE_MODAL_CLIENTS_ADD';
export const UPDATE_MODAL_COURIRERS = 'UPDATE_MODAL_COURIRERS';
export const UPDATE_MODAL_DELETE = 'UPDATE_MODAL_DELETE';
export const UPDATE_LOAD_MODAL_PRODUCTS = 'UPDATE_LOAD_MODAL_PRODUCTS';
export const UPDATE_MODAL_STREET = "UPDATE_MODAL_STREET";
export const UPDATE_LOAD_MODAL_USER = "UPDATE_LOAD_MODAL_USER";
export const UPDATE_LOAD_BTN_RESOURCE = "UPDATE_LOAD_BTN_RESOURCE";
export const UPDATE_LOAD_EDIT_RESOURCE = "UPDATE_LOAD_EDIT_RESOURCE";
export const UPDATE_LOAD_TABLE_RESOURCE = "UPDATE_LOAD_TABLE_RESOURCE";
export const UPDATE_LOAD_EDIT_PAYMENT = "UPDATE_LOAD_EDIT_PAYMENT";
export const UPDATE_ITEM_LOAD_BY_NAME = "UPDATE_ITEM_LOAD_BY_NAME";