import PropTypes from "prop-types";
import { useEffect } from "react";

import styles from "../StatisticPage.module.scss";
import { OrdersKitchen } from "./OrdersKitchen";
import { ControlKitchen } from "./controlKitchen";
import { default as KitchenDeliveryTimes } from "./KitchenDeliveryTimes";
import { ModalLoad } from "components/modals/ModalLoad";

import { isEditNone } from "utils/helpers/checks";


const Orders = ({
    //state
    orders,
    user,
    //dispatch
    getOrders,
    loadPage
}) => {
    const isNone = isEditNone(user);

    // useEffect(() => {
    //     if (!isNone) return;
    //     getOrders();

    //     const interval = setInterval(getOrders, 10000);
    //     return () => clearInterval(interval);
    // }, [isNone]);

    return (
        <div className={styles.rows_dashboard_4_2}>
            <div className={styles.col_2_h}>
                <div className={styles.title_col}>Замовлення на кухнях на теперішній час</div>
                <div className={styles.wr_item}>
                    {
                        isNone && (
                            <OrdersKitchen data={orders} />
                        )
                    }
                </div>

                {
                    loadPage && (
                        <ModalLoad />
                    )
                }
            </div>

            <div className={`${styles.col_2_h} ${styles.bor_l}`}>
                <ControlKitchen />
            </div>

            <div className={`${styles.col_2_h} ${styles.bor_l}`}>
                <div className={styles.title_col}>Час очікування</div>
                <div className={styles.wr_item}>
                    <KitchenDeliveryTimes />
                </div>
            </div>
        </div>
    )
}

Orders.propTypes = {
    orders: PropTypes.object,
    getOrders: PropTypes.func,
};

export default Orders;