import { connect } from 'react-redux';

import * as productsActions from '../../store/actions/products';
import * as settingsActions from '../../store/actions/settings';
import { productsWithSearchSelector } from '../../selectors/products';

import { default as ProductsPage } from './ProductsPage';

function mapStateToProps(state) {
    const productCategories = state.settings.productCategories.map(el => ({
        key: el.sync_id,
        title: el.name,
        obj: el
    }));

    const restoransCategories = state.settings.restaurants.map(el => ({
        key: el.code,
        title: el.name
    }));

    return {
        products: productsWithSearchSelector(state),
        productsMeta: state.products.meta,
        isFetching: state.products.isFetching,
        productCategories: [{ key: 'all', title: 'Всі' }, ...productCategories],
        restaurants: restoransCategories,
        cutlery: state.settings.cutlery
    };
}

const mapDispatchToProps = {
    ...productsActions,
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
