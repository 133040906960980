export const getPolion = (url = '') => {
    if (url.length === 0) return null;

    let arrPoligon = [];

    let urlParams = new URLSearchParams(new URL(url).search);
    let apiUrl = `https://www.google.com/maps/d/kml?mid=${urlParams.get('mid')}&forcekml=1&key=${urlParams.get('key')}`

    return fetch(apiUrl)
        .then(response => response.text())
        .then(data => {
            let parser = new DOMParser();
            let xmlDoc = parser.parseFromString(data, 'text/xml');
            let placemarks = xmlDoc.querySelectorAll('Placemark');

            placemarks.forEach(placemark => {
                let name = placemark.querySelector('name').textContent.trim();
                let coordinates = placemark.querySelector('coordinates').textContent.trim();

                arrPoligon.push({
                    title: name,
                    value: name,
                    polygon: coordinates
                })
            });

            return arrPoligon;
        })
        .catch(error => console.error('Error:', error));
}