import { useDispatch } from "react-redux";

import { TextInput } from "components/ui-kit/inputs/TextInput";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

import styled, { useTheme } from "styled-components";

import CloseIcon from "@mui/icons-material/Close";
import { isArray } from "lodash";

const Street = ({
    updateOrderAddress,
    address,
    isLoad = false,
    isOpenModal = false,
    setOpenModal = () => { },
    dataStreets = [],
    cleaning = () => { },
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    const handleOpenModal = () => {
        setOpenModal(true);
    }

    const handlerClickStreet = (street) => {
        dispatch(updateOrderAddress("street", street));
        dispatch(cleaning());
        handleCloseModal();
    }

    return (
        <WrInput theme={theme}>
            <TextInput
                title="Вулиця"
                type="text"
                value={address.street}
                onChange={(e) => {
                    dispatch(updateOrderAddress("street", e.target.value))
                }}
                onFocus={handleOpenModal}
            />

            <ContentStreet isOpen={isOpenModal}>
                <ContentStreetRow>
                    <CloseButton onClick={handleCloseModal}>
                        <CloseIcon style={{ fontSize: 20 }} />
                    </CloseButton>
                    {
                        isLoad ? (
                            <LoadingCircl mins={true} />
                        ) : (
                            isArray(dataStreets) && (
                                (dataStreets.length > 0) && (
                                    <List>
                                        {
                                            dataStreets.map((item) => (
                                                <Li key={item.id} onClick={() => handlerClickStreet(item.street)}>{item.street}</Li>
                                            ))
                                        }

                                    </List>
                                )
                            )
                        )
                    }
                </ContentStreetRow>
            </ContentStreet>
        </WrInput>
    )
}

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.accentColor};
  border-radius: 50%;
  background-color: transparent;
  margin-left: auto;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const WrInput = styled.div`
  position: relative;
`;

const ContentStreet = styled.div`
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    padding: 10px 0 0 0;
    display:  ${(p) => p.isOpen ? 'block' : 'none'};
    z-index: 10;
`;

const ContentStreetRow = styled.div`
    width: 100%;
    background-color: #2D303E;
    border: 1px solid #393C49;
    border-radius: 8px;
    font-size: 14px;
    padding: 5px 5px 15px 5px;
`;

const List = styled.ul`
    max-height: 200px;
    margin-top: 10px;
    overflow: auto;
`;

const Li = styled.li`
    margin-right: 10px;
    padding: 5px;
    color: ${(p) => p.theme.secondaryColor};
    transition: 0.4s;
    cursor: pointer;

    &:hover {
        color: ${(p) => p.theme.accentColor};
    }
`;

export default Street;