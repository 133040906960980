import React from "react";
import { Link } from "react-router-dom";
import { isString } from "lodash";

import DeleteIcon from '@mui/icons-material/Delete';
import styled, { useTheme } from "styled-components";

const Table = ({
  scheme,
  data,
  onClick = () => { },
  isLink = false,
  isKeyRed = '',
  link = "",
  onDelete = () => { },
  onChange = () => { },
  isHover = true,
  isBgActiveRow = "#ed572394",
  isStictyhead = false,
  isBtn = false,
  textBtn = "смс",
  keyBtn = "id",
  headerBtn = "",
  handleBtn = () => { },
  arrIds = [],
  actions = {},
  isKeyPressed = false,
}) => {
  const theme = useTheme();

  const renderHeader = () => {
    const HTMLelement = scheme.map((item, index) => {
      const { title, width } = item;
      return (
        <HeadCell key={index} style={{ width }}>
          {title}
        </HeadCell>
      );
    });

    return (
      <HeadRow>
        {HTMLelement}
        {!!headerBtn && (
          <HeadCell key="action-button-header">
            {headerBtn}
          </HeadCell>
        )}
      </HeadRow>
    );
  };

  const renderBody = () => {
    const renderCells = (item, indexRow) =>
      scheme.map((cell, index) => {
        if (cell.type === "list") {
          return (
            <Cell onClick={onClick ? () => onClick(item, indexRow) : null} key={index}>{isLink ? <Link to={`${link}${item['id']}`}>{renderList(item[cell.key], cell.items, cell.getStyle)}</Link> : renderList(item[cell.key], cell.items, cell.getStyle)}</Cell>
          );
        }
        else if (cell.type === "array") {
          let arr = item[cell.key];
          let str = ""

          arr.map((it, index) => {
            str += it[cell.keyArray];

            if ((arr.length - 1) > index) {
              str += ", ";
            }
          });

          return <Cell onClick={onClick ? () => onClick(item, indexRow) : null} key={index}>{isLink ? <Link to={`${link}${item['id']}`}>{str}</Link> : str}</Cell>;
        }
        else if (cell.type === "obj") {
          return <Cell onClick={onClick ? () => onClick(item, indexRow) : null} key={index}>{isLink ? <Link to={`${link}${item['id']}`}>{item[cell.key]?.[cell?.item]}</Link> : item[cell.key]?.[cell?.item]}</Cell>;
        }
        else if (cell.type === "img") {
          return <Cell onClick={onClick ? () => onClick(item, indexRow) : null} key={index}>
            {isLink ? <Link to={`${link}${item['id']}`}><IMG src={item[cell.key]} /></Link> : <IMG src={item[cell.key]} />}
          </Cell>;
        }
        else if (cell.key === "delete") {
          return <Cell key={index}>
            <Delete onClick={() => onDelete(item, indexRow)}>
              <DeleteIcon />
            </Delete>
          </Cell>;
        }
        else if (cell.key === "callback") {
          const { icon: Icon, actionKey } = cell;
          return <Cell key={index}>
            <Callback onClick={actions[actionKey]?.bind(this, item, indexRow)}>
              <Icon />
            </Callback>
          </Cell>;
        }
        else if (cell.type === "phone") {
          return <Cell key={index} onClick={isKeyPressed ? onClick ? () => onClick(item, indexRow) : null : () => { }}>
            {
              isKeyPressed ? (
                item[cell.key]
              ) : (
                <a title="телефон" href={`tel:${item[cell.key]}`}>{item[cell.key]}</a>
              )
            }

          </Cell>;
        }
        else if (item[cell.key] || item[cell.key] == 0) {
          let text = item[cell.key];

          if (cell.key == 'status') {
            if (text == "disabled" || text == "active") {
              if (text == "disabled") {
                text = "Не актив."
              } else {
                text = "Актив";
              }
            } else {
              text = text ? "Актив" : "Не актив.";
            }
          }

          if (cell.key == 'processed') {
            if (text == "disabled" || text == "active") {
              if (text == "disabled") {
                text = "Не опрац."
              } else {
                text = "Опрац.";
              }
            } else {
              text = text ? "Опрац." : "Не опрац.";
            }
          }

          if (cell.key == 'is_discount') {
            if (text == 0 || text == 1) {
              if (text == 0) {
                text = "Ні"
              } else {
                text = "Так";
              }
            } else {
              text = text ? "Так" : "Ні";
            }
          }

          return <Cell onClick={onClick ? () => onClick(item, indexRow) : null} key={index}>{isLink ? <Link to={`${link}${item['id']}`}>{text}</Link> : cell['isEdit'] ? <In onChange={(e) => (onChange(item, e.target.value))} value={text} /> : text}</Cell>;
        }
        else return <Cell onClick={onClick ? () => onClick(item, indexRow) : null} key={index}>{isLink ? <Link to={`${link}${item['id']}`}>{item['key']}</Link> : ""}</Cell>;
      });

    const HTMLelement = data.map((item, index) => {
      return (
        <Row
          key={index}
          isHover={isHover}
          colorBg={isBgActiveRow}
          isRedRow={((isString(item[isKeyRed]) && (item[isKeyRed].length > 0)) || !!item.processed) || (item?.["custom"] == 1)}
          isRowActive={(arrIds.length > 0) ? arrIds.indexOf(item?.["id"]) != -1 : false}
        >
          {renderCells(item, index)}
          {
            isBtn && item[keyBtn] && (
              <Cell>
                <Btn onClick={() => handleBtn(item[keyBtn], item)}>{textBtn}</Btn>
              </Cell>
            )
          }
        </Row>
      );
    });

    return HTMLelement;
  };

  const renderList = (list, items, getStyle) => {
    const HTMLelement = list.map((item, index) => {
      const style = getStyle?.(item);
      return (
        <Item key={index} style={style}>
          №{item[items[0]]} | {item[items[1]]} | {item[items[2]]}
        </Item>
      );
    });

    return <List>{HTMLelement}</List>;
  };

  return (
    <>
      {!!data && data.length ? (
        <Wrapper theme={theme}>
          <Thead isStictyhead={isStictyhead}>{renderHeader()}</Thead>
          <Tbody>{renderBody()}</Tbody>
        </Wrapper>
      ) : null}
    </>
  );
};

const In = styled.input`
  border: none;
  outline: none;
  &:focus {
    border: 1px solid #000;
  }
`;

const Wrapper = styled.table`
  display: table;
  width: 100%;
  border-collapse: collapse;
`;

const Thead = styled.thead`
    position: ${(p) => p.isStictyhead ? "sticky" : "inherit"};
    top: 49px;
`;

const Tbody = styled.tbody``;

const HeadRow = styled.tr``;

const Row = styled.tr`
  background-color: ${(p) => p.isRowActive ? ("#d5b7b7") : p.isRedRow ? p.colorBg : ''};

  &:hover {
    cursor: ${(p) => p.isHover ? "pointer" : "normal"};
    color: ${(p) => p.isHover ? p.theme.accentColor : ""};
    background-color: ${(p) => p.isHover ? p.theme.maskColor : ""};
  }
`;

const HeadCell = styled.th`
  padding: 3px;
  background-color: ${(p) => p.theme.lightAccentColor};
  border: 1px solid ${(p) => p.theme.lightAccentColor};
  color: ${(p) => p.theme.secondaryColor};
  font-weight: 400;
`;

const Cell = styled.td`
  padding: 5px;
  border: 1px solid ${(p) => p.theme.maskColor};
  position: relative;
  a {
    display: block;
  }
  a::after  {
    content: '',
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const List = styled.ul``;

const Item = styled.li``;

const Btn = styled.button`
  background: #EDA240;
  padding: 4px 10px;
  font-size: 16px;
  display: flex;
  margin: 0 auto;
  cursor: pointer;
  &:hover {
    background: #58bee1;
  }
`;

const IMG = styled.img`
  max-height: 40px;
`;

const Delete = styled.button`
  color: #fff;
  background: #eda240;
  border-radius: 8px;
  padding: 2px 2px;
  ustify-content: center;
  align-items: center;
  display: flex;
  font-size: 0;
  cursor: pointer;
  &:hover {
    background: red;
  }
`;

const Callback = styled.button`
  color: #fff;
  background: #eda240;
  border-radius: 8px;
  padding: 2px 2px;
  ustify-content: center;
  align-items: center;
  display: flex;
  font-size: 0;
  cursor: pointer;
  &:hover {
    background: #0B8457;
  }
`;

export default React.memo(Table);
