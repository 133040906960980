import 'moment/locale/ru';

import moment from 'moment';

import { capitalizeString } from './string';

export const fixedStartDate = "1988-07-05";

export function currentDate(format = "YYYY-MM-DD") {
    return moment().format(format);
}

export function getDate(format, isCapitalize = true) {
    const date = moment().format(format);

    if (isCapitalize) {
        return capitalizeString(date);
    }

    return date;
}

export const getRangeDate = (date) => {
    const { startDate, endDate } = date[0];
    const date_from = moment(startDate).format("Y-MM-DD");
    const date_to = moment(endDate).format("Y-MM-DD");

    let finalParams = { date_from, date_to };

    return finalParams;
}

export function getDateDifference(date) {
    let hours = moment().diff(date, 'hours');

    let minutes = (moment().diff(date, 'minutes') % 60).toFixed(0);

    if (`${minutes.replace('-', '')}`.length === 1) {
        minutes = `0${minutes}`;
    }

    if (`${hours}`.length === 1) {
        hours = `0${hours}`;
    }

    return { hours, minutes };
}

export function dataFormat(date, format = "YYYY-MM-DD") {
    return moment(new Date(date)).format(format);
}

export function maxDateBirthday(max = 5000) {
    return new Date(moment(new Date()).subtract(max, 'days').format());
}

export function dateNormal(date) {
    return date ? new Date(`${date}T00:00:00.000Z`) : new Date(date);
}

export function rangesDate(data) {
    let dataValid = [];

    if (data) {
        dataValid.push({
            startDate: new Date(data[0].startDate),
            endDate: new Date(data[0].endDate),
            key: "selection"
        });
    }

    return dataValid;
}

export function rangeConverDate(dateCal) {
    const [oneArr] = dateCal;

    const startDate = oneArr.startDate;
    const endDate = oneArr.endDate;

    // const today = new Date();

    const momentFrom = moment(new Date(startDate));
    const momentTo = moment(new Date(endDate));

    // if (momentFrom.isSame(today, "day") && momentTo.isSame(today, "day")) {
    //     return { date_from: undefined, date_to: undefined }; // if date_from and date_to are same, do not pass any parameters
    // }

    return { date_from: momentFrom.format("YYYY-MM-DD"), date_to: momentTo.format("YYYY-MM-DD") }
}

export function diffDateMonth(month) {
    let d = new Date();
    d.setMonth(d.getMonth() - month);
    return d;
}