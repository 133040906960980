import { connect } from "react-redux";

import * as statisticActions from "store/actions/statistic";
import { default as CouriersPage } from "./Orders";

function mapStateToProps(state) {
    return {
        orders: state.statistic.orders,
        user: state.user.user,
        loadPage: state.statistic.loadPageOrders,
    };
}

const mapDispatchToProps = {
    ...statisticActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CouriersPage);
