import React, { useEffect } from "react";
import { Marker, Polygon } from '@react-google-maps/api';

import { Icon, DivIcon } from "leaflet";
import {
  Marker as MarkerLeaflet,
  Polygon as PolygonLeaflet,
  useMap,
  useMapEvents,
} from "react-leaflet";

import icon from "assets/icons/marker.svg";

// import sass for maps
import "./Maps.module.scss";

export const MapMarker = ({
  onChange = () => { },
  position,
  icone = null,
  clickable = true,
  visible = true,
  label = ''
}) => {
  return visible && (<Marker
    position={position}
    icon={icone ? icone : icon}
    onClick={(e) => onChange(e.latLng.lat(), e.latLng.lng())}
    clickable={clickable}
    label={label}
  />);
};

export const MapCenterObserver = ({ center }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(center);
  }, [map, center]);
  return null;
}

export const MapMarkerLeaflet = ({
  onChange = () => { },
  position,
  icone = null,
  clickable = true,
  visible = true,
  iconProps = { iconSize: [45, 70], iconAnchor: [45 / 2, 70] },
  label = ''
}) => {
  useMapEvents({
    click(event) {
      clickable && onChange(event.latlng.lat, event.latlng.lng);
    }
  });

  const markerIcon = label ?
    new DivIcon({ html: label }) :
    new Icon({ iconUrl: icone ? icone : icon, ...iconProps });

  return visible && (<MarkerLeaflet
    position={position}
    icon={markerIcon}
  />);
};

export const MapPolygon = ({
  pathColor,
  positions,
  title,
  marker,
  time,
  startTime,
  finishTime,
  handleArea,
}) => {
  const options = {
    fillColor: pathColor,
    fillOpacity: 0.3,
    strokeColor: pathColor,
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
  }

  const onLoad = polygon => {
    // console.log("polygon: ", polygon);
  }

  return (
    <Polygon
      options={options}
      paths={positions}
      onClick={handleArea}
      onLoad={onLoad}
    >
    </Polygon>
  );
};


export const MapPolygonLeaflet = ({
  pathColor,
  positions,
  title,
  marker,
  time,
  startTime,
  finishTime,
  handleArea,
}) => {
  const options = {
    fillColor: pathColor,
    fillOpacity: 0.3,
    color: pathColor, // strokeColor
    opacity: 1,
    weight: 2, // strokeWeight
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
  }

  const onLoad = polygon => {
    // console.log("polygon: ", polygon);
  }

  return (
    <PolygonLeaflet
      pathOptions={options}
      positions={positions}
      onClick={handleArea}
      onLoad={onLoad}
    >
    </PolygonLeaflet>
  );
};
