import { uk } from "date-fns/locale";
import { DateRange, Calendar } from "react-date-range";
import React, { useState } from "react";
import moment from 'moment';

import { TextInput } from "components/ui-kit/inputs/TextInput";
import ButtonIcon from "components/ui-kit/ButtonIcon";

import { diffDateMonth } from "utils/helpers/date";

import CloseIcon from "@mui/icons-material/Close";
import styled from "./calendar.module.scss";

const CalendarModal = ({
    onChangeCal = () => { },
    valueIn = '',
    title,
    isStyle = false,
    isAbs = false,
    isBtn = false,
    isTitle = true,
    disabled=false,
    handlerSubmit = () => { },
}) => {
    const [isOpen, setIsOpen] = useState(false);

    const onOpen = () => {
        setIsOpen(true);
    }

    const onClose = () => {
        setIsOpen(false);
    }

    const onSub = () => {
        handlerSubmit();
        onClose();
    }

    return (
        <div className={styled.wr_calendar}>
            <div className={styled.wr_in} onClick={() => isOpen ? onClose() : onOpen()}>
                <TextInput
                    type="text"
                    // disabled={true}
                    value={moment(valueIn).format("YYYY/MM/DD")}
                    title={title}
                    isStyle={isStyle}
                    isTitle={isTitle}
                    disabled={disabled}
                />
                {
                    isOpen && (
                        <div className={styled.bnt_close_cal}>
                            <CloseIcon style={{ fontSize: 36 }} />
                        </div>
                    )
                }
            </div>
            {
                isAbs && isOpen && (
                    <div className={styled.main_cal_opas} onClick={onClose}></div>
                )
            }
            <div className={`${styled.main_cal} ${isOpen ? styled.main_cal_active : ''} ${isAbs ? styled.main_cal_abs : ''}`}>
                <div className={`${isAbs ? styled.main_cal_box : ''}`}>
                    <Calendar
                        className={`${isStyle ? "" : "calendar"}`}
                        onChange={(date) => onChangeCal(date)}
                        date={valueIn}
                        maxDate={new Date()}
                        locale={uk}
                    />
                </div>
                {
                    isBtn && (
                        <div className={styled.wr_btn}>
                            <ButtonIcon
                                onClick={onSub}
                                title='Підтвердити'
                                width="100%"
                            />
                        </div>
                    )
                }

            </div>
        </div>
    )
}

export default CalendarModal;