// primary colors declaration
const primaryColor = "#212121";
const secondaryColor = "#FFF";
const accentColor = "#EDA240";
const maskColor = "#E6E6E6";
const darkColor = "#393C49";
const errorColor = "red";
const lightBorderColor = "#DEDEDE";
const buttonColor = "#EF6C00";

export const theme = {
  settings: {
    sideMenuWidth: "100px",
    navigationHeight: "50px",
  },
  primaryColor: primaryColor,
  secondaryColor: secondaryColor,
  colorGreen: "green",
  colorRed: errorColor,
  accentColor: accentColor,
  color7a: "#7A7A9D",
  maskColor: maskColor,
  maskDarkColor: darkColor,
  lightAccentColor: "#9D9D9D",
  proceedColor: "#0B8457",
  background: "#1F1D2B",
  backgroundError: `linear-gradient(180deg, #cd1818 0%, #1F1D2B 20%)`,
  backgroundLight: "#2D303E",
  backgroundHover: "rgba(255, 255, 255, 0.2)",
  backgroundActive: "rgba(255, 255, 255, 0.1)",
  shadow: `0px 0px 5px ${maskColor}`,
  shadowBorder: `1px solid ${maskColor}`,
  inputBorder: `1px solid ${darkColor}`,
  checkboxBorder: `2px solid ${buttonColor}`,
  inputBorderDE: `1px solid ${lightBorderColor}`,
  inputBorderError: `1px solid ${errorColor}`,
  border39: `1px solid ${darkColor}`,
  colorED: "#EDF2F9",
  color25: "#252525",
  color5E: "#5E5E5E",
  colorDE: lightBorderColor,
  color42: "#425466",
  colorEC: "#ECECEC",
  color82: "#828282",
  color33: "#333333",
  color5C: "#5C5C5C",
};