import { isArray } from "lodash"

export const filterListNames = (list) => {
    let string = "";

    if (isArray(list)) {
        list.map((item, index) => {
            string += `${item.value}${((list.length - 1) > index) ? "," : ""}`;
        })
    }

    return string;
}