import { connect } from 'react-redux';

// import * as settingsActions from 'store/actions/settings';
import { default as HomePage } from './Home';

function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = {
    // ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
