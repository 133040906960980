import { isArray } from "lodash";
import React, { useEffect, useState, useRef } from "react";

import Orders from "../OrderList";
import ModalListCooks from "components/modals/ModalListCooks";
import { Voice } from "components/voice";

const Prepare = ({
    //actions
    cook,
    getOrderByStatusForChef,
    getCooksInTheKitchen,
    updateModalCooks,
    setCooksInTheKitchen,
    addNotification,
}) => {
    const controlPlayer = useRef(undefined);
    const [idOrder, setIdOrder] = useState(undefined);

    const [lengthorder, setLengthOrder] = useState(undefined);
    const [oneSetOrder, setOneSetOrder] = useState(false);

    const {
        cooking,
    } = cook.orders;

    useEffect(() => {
        getOrderByStatusForChef();
        getCooksInTheKitchen();

        const interval = setInterval(() => {
            getOrderByStatusForChef();
        }, 20000);

        return () => clearInterval(interval);
    }, []);

    const handlerPreparing = async (data) => {
        await updateModalCooks("isOpen", true);
        await setIdOrder(data.id);
    }

    const handleSubmit = async () => {
        await setCooksInTheKitchen(idOrder);
    }

    useEffect(() => {
        if (lengthorder === undefined) {
            if (isArray(cooking)) {
                setLengthOrder(cooking.length);
                setOneSetOrder(true);
            }
        } else {
            if (oneSetOrder) {
                if (lengthorder < cooking?.length) {
                    controlPlayer?.current && controlPlayer.current.play();
                    addNotification({ war: ['Нове замовлення'] });
                    setLengthOrder(cooking.length);
                } else if (lengthorder > cooking?.length) {
                    setLengthOrder(cooking.length);
                } else if (cooking?.length == 0) {
                    setLengthOrder(0);
                }
            }
        }
    }, [cooking]);

    return (
        <>
            <Orders
                handlerPreparing={handlerPreparing}
                data={cooking}
            />
            <ModalListCooks
                handleSubmit={handleSubmit}
                idOrder={idOrder}
            />

            <Voice controlPlayer={controlPlayer} />
        </>
    )
}

export default Prepare;