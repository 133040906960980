export const RADIO_FORM_TYPE = [
    { value: "one", label: "По сумі" },
    { value: "two", label: "По чеку" },
];

export const RADIO_FLOOR = [
    { value: "male", label: "Чоловіча" },
    { value: "female", label: "Жіноча" },
];

export const RADIO_SCHEME_CHANGES = [
    { value: "first", label: "Для першої зміни" },
    { value: "second", label: "Для другої зміни" },
];

export const RADIO_PARSE_TYPE = [
    { value: "1", label: "Перезаписати" },
    { value: "0", label: "Додати" },
];

export const BLACK_LIST = [
    { value: "1", label: "Так" },
    { value: "0", label: "Ні" },
];

export const MAIN_CATEGORY_TYPE_LIST = [
    { value: "pizza", label: "Піца" },
    { value: "sushi", label: "Суші" },
    { value: "other", label: "Інше" },
];

export const PAYMENT_TYPE = [
    { value: "", label: "За замовчуванням" },
    { value: "cash", label: "Готівка" },
    { value: "online", label: "Безготівка" },
    { value: "free", label: "Без оплати" },
];

export const RADIO_BUG_SEVERITY = [
    { value: "critical", label: "Критичний" },
    { value: "major", label: "Значний" },
    { value: "minor", label: "Не пріорітет" },
];

export const RADIO_SCHEME = [
    { value: "1", label: "Активна" },
    { value: "0", label: "Не активна" },
];

export const RADIO_SCHEME_FREE = [
    { value: "0", label: "Потрібна оплата" },
    { value: "1", label: "Безкоштовна" },
];

export const RADIO_SCHEME_PICKUP = [
    { value: "1", label: "Активна" },
    { value: "0", label: "Не активна" },
];

export const RADIO_SCHEME_PERIOD = [
    { value: "days_of_the_week", label: "День тижня" },
    { value: "period", label: "Період" },
];

export const RADIO_SCHEME_PERIOD_NO = [
    { value: "0", label: "Період" },
    { value: "1", label: "Без періода" },
];

export const RADIO_SCHEME_ACTIONS = [
    { value: "1", label: "Так" },
    { value: "0", label: "Ні" },
];

export const RADIO_SCHEME_ACTIONS_STATUS = [
    { value: "active", label: "Так" },
    { value: "disabled", label: "Ні" },
];

export const RADIO_SCHEME_USER = [
    { value: "active", label: "Активен" },
    { value: "disabled", label: "Неактивен" },
];

export const RADIO_SCHEME_USER_STATISTIC = [
    { value: "all", label: "Всі" },
    { value: "own", label: "Своя кухня" },
    { value: "certain", label: "Певні кухні" },
    { value: "none", label: "Жодної" },
];

export const RADIO_SCHEME_REASON = [
    { value: "Так", label: "Так" },
    { value: "Ні", label: "Ні" },
    { value: "Частково", label: "Частково" },
];

export const RADIO_SCHEME_FOR_WHOM = [
    { value: "Клієнт", label: "Клієнт" },
    { value: "Кухня", label: "Кухня" },
    { value: "Логістика", label: "Логістика" },
    { value: "Оператор", label: "Оператор" },
];

export const RADIO_SCHEME_USER_IN_THE_KITCHENS = [
    { value: "all", label: "По всім кухням" },
    { value: "my", label: "По моїм кухням " },
];

export const RADIO_SCHEME_AVAILABLE_USERS = [
    { value: "all", label: "Всі" },
    { value: "my", label: "Деякі" },
];

export const TYPE_CREATE_PROMOCODES = [
    { value: "number", label: "Генерувати по кількості" },
    { value: "list", label: "Додати самостійно" },
];

export const RADIO_SCHEME_SOON = [
    { value: "soon", label: "Як можна скоріше" },
    { value: "requested_time", label: "На час" },
];

export const RADIO_SCHEME_PICKUP_DELIVERY = [
    { value: "1", label: "Самовивіз" },
    { value: "0", label: "Доставка" },
];

export const RADIO_SCHEME_IGNORE_STOCK = [
    { value: "1", label: "Так" },
    { value: "0", label: "Ні" },
];

export const RADIO_SCHEME_PROMOCODE_OR_PROMOACTION = [
    { value: "promocode", label: "Промокод" },
    { value: "promoaction", label: "Промо-акцiя" },
];

export const RESTAURANTS = [
    { id: 0, label: 'Smaki Maki', value: 'smaki' },
    { id: 1, label: 'Sushi Go', value: 'go' }
];