import * as React from 'react';

// reports
const RegularCustomerAnalysis = React.lazy(() => import("./RegularCustomerAnalysis"));
const StaffOrders = React.lazy(() => import("./StaffOrders"));
const HourlySale = React.lazy(() => import("./HourlySale"));
const AverageCheck = React.lazy(() => import("./AverageCheck"));
const AverageTimeByStatus = React.lazy(() => import("./AverageTimeByStatus"));
const AverageTimeByStatusPickup = React.lazy(() => import("./AverageTimeByStatusPickup"));
const AverageTimeByStatusKPI = React.lazy(() => import("./AverageTimeByStatusKPI"));
const AverageTimeByStatusKPIByRestaurant = React.lazy(() => import("./AverageTimeByStatusKPIByRestaurant"));
const SpeedOfCouriers = React.lazy(() => import("./SpeedOfCouriers"));
const DeliveryByCouriers = React.lazy(() => import("./DeliveryByCouriers"));
const OrdersAndSumsByRestaurant = React.lazy(() => import("./OrdersAndSumsByRestaurant"));
const OrdersAndDishesByKitchen = React.lazy(() => import("./OrdersAndDishesByKitchen"));
const OrdersAndDishesByKitchenKPI = React.lazy(() => import("./OrdersAndDishesByKitchenKPI"));
const TechnicalOrders = React.lazy(() => import("./TechnicalOrders"));
const SalesDynamics = React.lazy(() => import("./SalesDynamics"));
const Dishes = React.lazy(() => import("./Dishes"));
const LateCouriers = React.lazy(() => import("./LateCouriers"));
const OblikovyiDen = React.lazy(() => import("./OblikovyiDen"));
const CouriersReport = React.lazy(() => import("./CouriersReport"));
const DeliveriesByOperator = React.lazy(() => import("./DeliveriesByOperator"));
const DeliveriesByAdministrator = React.lazy(() => import("./DeliveriesByAdministrator"));
const DailySales = React.lazy(() => import("./DailySales"));
const UsedStocks = React.lazy(() => import("./UsedStocks"));
const Orders = React.lazy(() => import("./Orders"));
const UnusedCourierLateBonus = React.lazy(() => import("./UnusedCourierLateBonus"));

export const reportsBySlug = {
    regular_customer_analysis: RegularCustomerAnalysis,
    orders_staff: StaffOrders,
    hourly_sale: HourlySale,

    average_check: AverageCheck,
    average_time_by_status: AverageTimeByStatus,
    average_time_by_status_no_pickup_and_middleman: AverageTimeByStatus,

    average_time_by_status_pickup: AverageTimeByStatusPickup,
    average_time_by_status_kpi: AverageTimeByStatusKPI,
    average_time_by_status_kpi_by_restaurant: AverageTimeByStatusKPIByRestaurant,
    speed_of_couriers: SpeedOfCouriers,
    delivery_by_couriers: DeliveryByCouriers,
    orders_and_sums_by_restaurant: OrdersAndSumsByRestaurant,
    orders_and_dishes_by_kitchen: OrdersAndDishesByKitchen,
    orders_and_dishes_by_kitchen_kpi: OrdersAndDishesByKitchenKPI,

    technical_orders: TechnicalOrders,
    sales_dynamics_reg: SalesDynamics,
    delivery_by_couriers_countryside: DeliveryByCouriers,

    sale_by_dishes: Dishes,
    couriers_are_late: LateCouriers,
    accounting_day: OblikovyiDen,
    courier_stats: CouriersReport,
    deliveries_by_operator: DeliveriesByOperator,
    deliveries_by_administrator: DeliveriesByAdministrator,
    daily_sales: DailySales,
    daily_sales_per_city_suburb: DailySales,
    used_stocks: UsedStocks,
    unused_courier_late_bonus: UnusedCourierLateBonus,
    orders: Orders,
}
