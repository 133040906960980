import { isArray, isObject, isString, first, find } from "lodash";
import { getAdditionPrice } from "utils/helpers/price";
import api from "../../apiSingleton";

import {
  UPDATE_ORDER,
  GET_UPDATE_ORDER,
  UPDATE_ORDER_DATA,
  UPDATE_ORDER_CLIENT,
  UPDATE_ORDER_CLIENT_GET,
  RESET_ORDER_ADDRESS,
  UPDATE_ORDER_ADDRESS,
  UPDATE_ORDER_ITEMS,
  ADD_ORDER_ITEM,
  REMOVE_ORDER_ITEM,
  SELECT_PIZZA_HALF,
  SELECT_SWAPPABLE_BONUS_PRODUCT,
  INCREASE_ORDER_ITEM_QUANTITY,
  DECREASE_ORDER_ITEM_QUANTITY,
  UPDATE_ORDER_ITEM_COMMENT,
  UPDATE_ORDER_PAYMENTS,
  ADD_ORDER_PAYMENTS,
  REMOVE_ORDER_PAYMENTS,
  RESET_ORDER,
  GET_KITCHEN_ADDRESS,
  GET_ACTIVE_KITCHENS_CITY,
  GET_KITCHEN_BY_ADDRESS,
  UPDATE_ITEM_DOP_ADD,
  UPDATE_DELETE_DOP,
  UPDATE_ITEM_DELETE,
  ADD_ADDITION_GROUP,
  GET_LIST_STREET,
  CLEANING_LIST_STREET,
  ADD_ONE_DOP,
  SUBTRACT_ONE_DOP,
  UPDATE_LOAD_CLIENT,
  LOAD_CLIENT,
  ORDER_UPDATE_FORM_REASON,
  ORDER_UPDATE_FORM_REASON_STATUS,
  SET_ORDER_ITEMS,
  GET_ORDER_PRINT,
  CLEAТ_FORM_CLIENT,
  GET_ORDERS_ALL_CLIETN_ID,
  CLEAN_FORM_REASON,
  SET_DISCOUNTS,
  REMOVE_PROMO_GIFT_DISCOUNT,
  REMOVE_ORDER_PROMOCODE,
  CLEAR_DATA_CLIENT_ORDER,
  ADD_CUTLERY_ITEM,
  UPDATE_CUTLERY_ITEM,
  REMOVE_CUTLERY_ALL,
  REMOVE_CUTLERY_ITEM,
  UPDATE_ORDER_PAYMENTS_ITEM,
  REASON_GET_INFORMATION,
  DELETE_BELONS_PRODUCT,
  UPDATE_ORDER_ITEM_QUANTITY,
  CLEAN_FORM_ORDER,
  SET_ORDER_DELIVERY_TIME,
  SET_ORDER_DELIVERY_TIME_LOADING,
} from "../constants/order";

import {
  updateLoadModalStreet
} from "./view";

import {
  addNotification
} from './notifications';

import {
  updateLoadModalEdition
} from "./loading";

import { fixedStartDate } from "utils/helpers/date";
import { getIdKitchenOnKitchenCode } from "utils/mappers/kitchens";
import { valueCulter } from "utils/helpers/cutlery";

let idTime = null;
export function editCuantityOrder(data, quantity, item) {
  return async (dispatch, state) => {
    const {
      order: {
        data: {
          cutlery_sets
        }
      }
    } = state();

    await dispatch({
      type: UPDATE_ORDER_ITEM_QUANTITY,
      payload: { data, quantity, item },
    });

    // if (isArray(item?.cutlery_sets) && item?.cutlery_sets.length > 0) {
    //   let cutleryId = item.cutlery_sets[0].id; // треба ще зробити на масив

    //   let quantityValue = valueCulter(cutleryId, cutlery_sets) + quantity;

    //   await dispatch(updateCutlery(cutleryId, "quantity", quantityValue));
    // }

    if (idTime)
      clearTimeout(idTime);

    idTime = setTimeout(() => {
      dispatch(auditStocks({}));
    }, 500);
  }
}

export function setOrderDeliveryTime(time) {
  return {
    type: SET_ORDER_DELIVERY_TIME,
    payload: time,
  }
}

function setOrderDeliveryTimeLoading(isLoading) {
  return {
    type: SET_ORDER_DELIVERY_TIME_LOADING,
    payload: isLoading,
  }
}

export function getOrderDeliveryTime() {
  return async (dispatch, state) => {
    const { order } = state();
    dispatch(setOrderDeliveryTimeLoading(true));

    const response = await api.orders.getOrderDeliveryTime(order);
    const deliveryTime = response?.deliveryTime;
    const kitchenClosedUntil = response?.kitchenClosedUntil;

    if (deliveryTime) {
      dispatch(setOrderDeliveryTime(`${deliveryTime} ${kitchenClosedUntil ? '' : 'хв.'}`));
    } else {
      dispatch(setOrderDeliveryTime('-'));
    }

    dispatch(setOrderDeliveryTimeLoading(false));

  }
}

export function cleanFormOrder() {
  return ({
    type: CLEAN_FORM_ORDER
  })
}

export function deleteBelonsProd({ prodBelons }) {
  return {
    type: DELETE_BELONS_PRODUCT,
    payload: prodBelons,
  };
}

export function clearFormClient() {
  return async (dispatch) => {
    await dispatch({
      type: CLEAR_DATA_CLIENT_ORDER,
    });
  };
}

export function updateOrder(isKitchen = false) {
  return (dispatch, state) => {
    const { user: { user: { city_sync_id } } } = state();
    const payload = {};
    
    if (isKitchen && city_sync_id) {
      payload.address = { city_sync_id };
    }

    dispatch({
      type: UPDATE_ORDER,
      payload,
    });
  }
}

export function getUpdateOrder(data) {
  return {
    type: GET_UPDATE_ORDER,
    payload: data,
  };
}

export function updateOrderData(item, data, isStock = false) {
  return async (dispatch, state) => {
    await dispatch({
      type: UPDATE_ORDER_DATA,
      payload: { item, data },
    });

    if (!!isStock)
      await dispatch(auditStocks({}));
  }
}

export function cleanFormClient() {
  return {
    type: CLEAТ_FORM_CLIENT,
  };
}

export function updateOrderClient(item, data) {
  return {
    type: UPDATE_ORDER_CLIENT,
    payload: { item, data },
  };
}

export function getUpdateOrderClient(id) {
  return async (dispatch) => {
    dispatch(loadClient(true));

    const { data } = await api.clients.getClientsExtensiveInformation(id);

    await dispatch({
      type: UPDATE_ORDER_CLIENT_GET,
      payload: data,
    });

    dispatch(loadClient(false));
  };
}

export function updateOrderPaymentsServer(stateSet = () => { }) {
  return async (dispatch, state) => {
    const {
      order: {
        data: dataorder
      }
    } = state();

    await stateSet(prev => ({
      ...prev,
      load: true,
    }));

    try {
      const res = await api.orders.updateOrderPauments(dataorder.id, { 'payments': dataorder.payments });

      if (res?.data?.id) {
        await dispatch(addNotification({ war: ['Оновленно'] }));
      }

      if (isObject(res?.errors)) {
        await dispatch(addNotification(res.errors, 'warn'));
      }
    } catch (error) {
      await dispatch(addNotification({ war: ['Помилка'] }, 'warn'));
    }

    await stateSet({
      status: false,
      load: false,
    });
  };
}

export function loadClient(status) {
  return async (dispatch) => {
    dispatch({
      type: LOAD_CLIENT,
      payload: status,
    }
    );
  }
}

export function updateOrderAddress(item, data, isStock = false) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_ORDER_ADDRESS,
      payload: { item, data },
    });

    if (!!isStock)
      await dispatch(auditStocks({}));
  }
}

export function resetOrderAddress() {
  return {
    type: RESET_ORDER_ADDRESS,
  };
}

export function updateOrderItems(item, data) {
  return {
    type: UPDATE_ORDER_ITEMS,
    payload: { item, data },
  };
}

export function addOrderItem(data, isStock = true) {
  return async (dispatch, state) => {
    await dispatch({
      type: ADD_ORDER_ITEM,
      payload: { data },
    });

    if (!!isStock)
      await dispatch(auditStocks({}));
  }
}

export function addSwappableBonusProduct(products) {
  return async (dispatch, state) => {
    const {
      order: {
        data: {
          items,
        }
      }
    } = state();

    // Let's remove the previous swappable bonus product as it
    // must exist only once

    const oldSwappableProduct = find(items, { status: 'swappable_bonus_product' });
    if (oldSwappableProduct) {
      await dispatch(removeOrderItem(oldSwappableProduct, false));
    }

    if (isArray(products) && products.length) {
      // Let's add first of bonus product to the order items
      // Later on, callcenter operator can choose which of products
      // The end user wants
      dispatch(addNotification({ war: ['Клієнт має подарунок за спізнення'] }));

      const bonusProduct = { ...first(products), status: 'swappable_bonus_product', is_gift: 1 };
      await dispatch(addOrderItem(bonusProduct, false));
    }
  }
}

export function selectPizzaHalf(index, half, product) {
  return (dispatch) => {
    dispatch({
      type: SELECT_PIZZA_HALF,
      payload: { index, half, product },
    });
    return dispatch(auditStocks({}));
  }
}

export function selectSwappableBonusProduct(id) {
  return async (dispatch, state) => {
    const {
      order: {
        data: {
          swappable_bonus_products,
        }
      }
    } = state();

    if (isArray(swappable_bonus_products) && swappable_bonus_products.length) {
      const productToSwap = find(swappable_bonus_products, { id: parseInt(id) });
      if (!productToSwap) return console.warn('Не знайдено продукту для заміни в доступних варіантах');

      await dispatch({
        type: SELECT_SWAPPABLE_BONUS_PRODUCT,
        payload: { data: productToSwap },
      });

      await dispatch(auditStocks({}));
    }
  }
}

export function addCutlery(data) {
  return async (dispatch, state) => {
    await dispatch({
      type: ADD_CUTLERY_ITEM,
      payload: { data },
    });
  }
}

export function updateCutlery(id, item, data) {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_CUTLERY_ITEM,
      payload: { id, item, data },
    });
  }
}

export function removeCutleryAll() {
  return async (dispatch) => {
    await dispatch({
      type: REMOVE_CUTLERY_ALL,
    });
  }
}

export function removeCutleryItem(data) {
  return async (dispatch) => {
    await dispatch({
      type: REMOVE_CUTLERY_ITEM,
      payload: { data },
    });
  }
}

export function removeOrderItem(data, isStock = true) {
  return async (dispatch, state) => {
    const {
      order: {
        data: {
          cutlery_sets
        }
      }
    } = state();


    await dispatch({
      type: REMOVE_ORDER_ITEM,
      payload: { data },
    });

    // let { quantity } = data;

    // if (isArray(data.cutlery_sets) && data.cutlery_sets.length > 0) {
    //   let cutleryId = data.cutlery_sets[0].id; // треба ще зробити на масив

    //   let quantityValue = valueCulter(cutleryId, cutlery_sets) - quantity;

    //   await dispatch(updateCutlery(cutleryId, "quantity", quantityValue));
    // }

    if (!!isStock)
      await dispatch(auditStocks({}));
  }
}

export function increaseOrderItem(data, item) {
  return async (dispatch, state) => {
    const {
      order: {
        data: {
          cutlery_sets
        }
      }
    } = state();

    await dispatch({
      type: INCREASE_ORDER_ITEM_QUANTITY,
      payload: { data },
    });

    // if (isArray(item.cutlery_sets) && item.cutlery_sets.length > 0) {
    //   let cutleryId = item.cutlery_sets[0].id; // треба ще зробити на масив

    //   let quantityValue = valueCulter(cutleryId, cutlery_sets) + 1;

    //   await dispatch(updateCutlery(cutleryId, "quantity", quantityValue));
    // }

    await dispatch(auditStocks({}));
  }
}

export function decreaseOrderItem(data, item) {
  return async (dispatch, state) => {
    const {
      order: {
        data: {
          cutlery_sets
        }
      }
    } = state();

    await dispatch({
      type: DECREASE_ORDER_ITEM_QUANTITY,
      payload: { data },
    });

    // if (isArray(item.cutlery_sets) && item.cutlery_sets.length > 0) {
    //   let cutleryId = item.cutlery_sets[0].id; // треба ще зробити на масив

    //   let quantityValue = valueCulter(cutleryId, cutlery_sets) - 1;

    //   await dispatch(updateCutlery(cutleryId, "quantity", quantityValue));
    // }

    await dispatch(auditStocks({}));
  }
}

export function updateOrderItemComment(item, data) {
  return {
    type: UPDATE_ORDER_ITEM_COMMENT,
    payload: { item, data },
  };
}

export function updateOrderPayments(item, data) {
  return async (dispatch, state) => {
    // if (item == "bonus" || item == "rucna-zniska") {
    //   dispatch(updateOrderPaymentsItemMin("cash", data));
    // }

    dispatch({
      type: UPDATE_ORDER_PAYMENTS,
      payload: { item, data },
    });
  }
}

export function updateOrderPaymentsItemMin(item, data) {
  return {
    type: UPDATE_ORDER_PAYMENTS_ITEM,
    payload: { item, data },
  };
}

export function addOrderPayments(item, data, free) {
  return async (dispatch, state) => {
    await dispatch({
      type: ADD_ORDER_PAYMENTS,
      payload: { item, data, free },
    });

    if (free) {
      dispatch(updateOrderData(
        "ignore_stock",
        "1",
        true
      ));
    }
  }
}

export function setDiscounts(data) {
  return {
    type: SET_DISCOUNTS,
    payload: data,
  };
}

export function removeOrderPayments(item) {
  return {
    type: REMOVE_ORDER_PAYMENTS,
    payload: { item },
  };
}

export function resetOrder() {
  return {
    type: RESET_ORDER,
  };
}

export function updateDopAdd(indexProduct, additionId, name, price, type, numberQuantity) {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_ITEM_DOP_ADD,
      payload: {
        indexProduct,
        additionId,
        name,
        price,
        type,
        numberQuantity
      }
    });
    await dispatch(auditStocks({}));
  }
}

export function removeDopItem(indexProduct, additionId, price) {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_ITEM_DELETE,
      payload: {
        indexProduct,
        additionId,
        price,
      }
    });
    await dispatch(auditStocks({}));
  }
}

export function removeDopAll(indexProduct) {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_DELETE_DOP,
      payload: {
        indexProduct
      }
    });
    await dispatch(auditStocks({}));
  }
}

export function searchDopCard(idCard) {
  return async (dispatch, state) => {
    const ordersProduct = state().products.list;

    // TODO: fix bug with orders product not having all products
    if (isArray(ordersProduct)) {
      let additions_group = ordersProduct.find((item) => item.id === idCard).addition_groups;

      dispatch({
        type: ADD_ADDITION_GROUP,
        payload: {
          id: idCard,
          data: additions_group,
        }
      })
    }
  }
}

export function getKitchenAddress(params = {}) {
  return async (dispatch) => {
    const { data } = await api.kitchen.getAddressKitchen(params);

    if (data) {
      dispatch({
        type: GET_KITCHEN_ADDRESS,
        payload: data.code
      });
    }
  };
}

export function getActiveKitchenCity(params = {}) {
  return async (dispatch) => {
    const { data } = await api.kitchen.getActiveKitchenCity(params);

    await dispatch({
      type: GET_ACTIVE_KITCHENS_CITY,
      payload: data,
    });
  };
}

export function getStreets(obj = {}) {
  return async (dispatch) => {
    const {
      city,
      settlelement,
      street,
    } = obj;

    if (street) {
      let params = {
        city: city,
      };

      params = settlelement ? { ...params, ...{ settlement: settlelement } } : params;
      params = { ...params, ...{ street: street } };

      dispatch(updateLoadModalStreet('loadingsModal', true));

      const res = await api.orders.getStreets(params);

      dispatch({
        type: GET_LIST_STREET,
        payload: res,
      });

      dispatch(updateLoadModalStreet('loadingsModal', false));
    }
  };
}

export function cleaning() {
  return {
    type: CLEANING_LIST_STREET,
  }
}

export function oneAddDop(indexProduct, indexDop, numberQuantity) {
  return async (dispatch) => {
    await dispatch({
      type: ADD_ONE_DOP,
      payload: { indexProduct, indexDop, numberQuantity }
    });
    await dispatch(auditStocks({}));
  }
}

export function oneSubtractDop(indexProduct, indexDop, numberQuantity) {
  return async (dispatch) => {
    dispatch({
      type: SUBTRACT_ONE_DOP,
      payload: { indexProduct, indexDop, numberQuantity }
    });
    await dispatch(auditStocks({}));
  }
}

export function loadEditClient(status) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_LOAD_CLIENT,
      payload: status,
    }
    );
  }
}

export function updateClient(setMode) {
  return async (dispatch, state) => {
    const {
      order: {
        data: {
          client: {
            id,
            name,
            phone,
            source,
            wallet,
            sex,
            birthday,
            blocking_reasons
          }
        }
      }
    } = state();

    let obj = {
      name,
      phone,
      source: source.code,
      wallet,
      blocking_reasons
    };

    sex && (obj = { ...{ sex }, ...obj });
    (birthday != fixedStartDate) && (obj = { ...{ birthday }, ...obj });

    await dispatch(loadEditClient(true));

    const res = await api.clients.updateClientServer(id, obj);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(addNotification({ war: ['Дані клієнта змінені'] }));
      await dispatch(updateOrderClient("blocking_reasons_text", res?.data?.blocking_reasons_text || ''));
    }

    await dispatch(loadEditClient(false));
    await setMode("preview");
  };
}

export function createClientOrder(setMode) {
  return async (dispatch, state) => {
    const {
      order: {
        data: {
          client: {
            name,
            phone,
            source,
            wallet,
            sex,
            birthday,
            blocking_reasons
          }
        }
      }
    } = state();

    let obj = {
      name,
      phone,
      source,
      wallet,
      blocking_reasons
    };

    sex && (obj = { ...{ sex }, ...obj });
    (birthday != fixedStartDate) && (obj = { ...{ birthday }, ...obj });

    await dispatch(loadEditClient(true));

    const res = await api.clients.addClient(obj);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch(addNotification({ war: ['Нового клієнта додано'] }));
    }

    if (res?.status == "Ok") {
      await dispatch({
        type: UPDATE_ORDER_CLIENT,
        payload: { item: null, data: res.data },
      });
      await setMode("preview");
    }

    await dispatch(loadEditClient(false));
  };
}

export function updateFormReason(item, data) {
  return {
    type: ORDER_UPDATE_FORM_REASON,
    payload: { item, data },
  };
}

export function updateFormReasonStatus(item, data) {
  return {
    type: ORDER_UPDATE_FORM_REASON_STATUS,
    payload: { item, data },
  };
}

export function cleanFormReason() {
  return {
    type: CLEAN_FORM_REASON,
  }
}

export function auditPropmocode() {
  return async (dispatch, state) => {
    const { order: { data: { items, promo_type, promocode, discounts, address: { city_sync_id }, client: { phone }, restaurant, kitchen_code } } } = state();

    await dispatch(updateLoadModalEdition(true));
    const res = await api.orders.auditPropmocode(promocode, promo_type, { 
      items, 
      city_sync_id, 
      discounts, 
      phone,
      restaurant,
      kitchen_code,
    });

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else if (res?.data?.error == 1) {
      await dispatch(addNotification({ war: [res?.data?.message] }, 'warn'));

      if ((items.length > 0) && res?.data.remove_gift) {
        await dispatch({
          type: REMOVE_PROMO_GIFT_DISCOUNT
        });
      }
    } else if (res?.data?.error == 0) {
      let mes = { war: [res?.data?.message] };

      if (isArray(res.data.discounts)) {
        await dispatch(setDiscounts(res.data.discounts));
        // await dispatch(updateOrderPaymentsItemMin("cash", res.data.discount)); // TODO: Чому від готівки чи іншого метода оплати в біса потрібно щось віднімати??
      }

      if (res?.data?.type == "gift_product") {
        const product = { additions: [] }; // available additions list
        const giftProduct = { ...res.data.gift_product, product };

        await dispatch(addOrderItem(giftProduct, false));
        mes = { war: [`${res?.data?.message} ${giftProduct.title_ua}`] };
      }

      await dispatch(addNotification(mes));
      await dispatch(auditStocks({}));
    }

    await dispatch(updateLoadModalEdition(false));
  };
}

export function removeOrderPromocode(index) {
  return async (dispatch) => {
    await dispatch({
      type: REMOVE_ORDER_PROMOCODE,
      payload: index
    });
    await dispatch(updateOrderData("promocode", ''));
    await dispatch(auditStocks({}));
  }
}

export function auditStocks({ dataItemProd = null }) {
  return async (dispatch, state) => {
    const {
      view: {
        modalCallCenter: { source }
      },
      order: {
        data: {
          restaurant,
          items: orderItems,
          status,
          kitchen_code,
          persons,
          pickup,
          ignore_stock,
          delivery_type_id,
          discounts,
          address,
          client: {
            id,
          },
        }
      }
    } = state();

    if (orderItems.length > 0 || dataItemProd !== null) {
      await dispatch(updateLoadModalEdition(true));

      const citySyncId = address.city_sync_id;
      const deliveryTypeId = delivery_type_id;

      const items = orderItems.map(function (item) {
        return {
          ...item,
          additions: item.additions?.map(function (itemAddition) {
            const addition = find(item.product.additions, { id: itemAddition.addition_id });
            return { 
              ...itemAddition, 
              price: getAdditionPrice(addition ?? itemAddition, citySyncId, deliveryTypeId),
            }
          }),
        }
      });

      const res = await api.orders.stocks({
        items: dataItemProd ? [...[dataItemProd], ...items] : items,
        discounts,
        kitchen_code,
        client_id: id,
        pickup,
        ignore_stock: +ignore_stock,
        delivery_type_id,
        address,
        status,
        persons: +persons,
        restaurant,
        source,
      });

      if (res?.errors) {
        await dispatch(addNotification(res.errors, 'error'));
        await dispatch(updateOrderData("stock_id", ''));
      } else if (isObject(res?.data)) {
        if (res?.data?.stock >= 0) {
          let mes = { successfull: [res.data.description] };

          await dispatch({
            type: SET_ORDER_ITEMS,
            payload: res.data.items,
          });

          await dispatch(updateOrderData("stock_id", `${res.data.stock}`));
          // if (res?.data?.stock > 1) {
          //   await dispatch(addNotification(mes));
          // }
        }

        if (isArray(res.data.discounts)) {
          await dispatch(setDiscounts(res.data.discounts));
        }
      }

      await dispatch(updateLoadModalEdition(false));
    }
  }
}

export function updateAdressServer(setOpen, setLoad, handleCloseWindow) {
  return async (dispatch, state) => {
    const { order: { data: { id, address } } } = state();
    const data = {
      address: {
        ...address,
        latitude: String(address.latitude),
        longitude: String(address.longitude),
      }
    }

    await setLoad(true);
    const res = await api.orders.updateAddress(id, data);
    await setLoad(false);
    // await handleCloseWindow();
    await dispatch(addNotification({ suc: ['Адресу змінено'] }));

    if (res?.status == 'Ok') {
      await setOpen(false);
    }
  }
}

export function getOrderPrint(idOFPage = null) {
  return async (dispatch, state) => {
    const { order: { data: { id } } } = state();

    if (idOFPage ? idOFPage : id) {
      const res = await api.orders.getOrderPrint(idOFPage ? idOFPage : id);

      if (res?.status?.includes('Ok')) {
        await dispatch({
          type: GET_ORDER_PRINT,
          payload: res.data,
        });
      }
    }
  }
}

export function getOrdersClient({
  setIsLoad = () => { },
  params = {}
}) {
  return async (dispatch, state) => {
    const { order: { data: { client: { id } } } } = state();

    setIsLoad(true);

    const res = await api.orders.getOrdersClient(id, { sort: 'desc', ...params });

    if (res?.data) {
      await dispatch({
        type: GET_ORDERS_ALL_CLIETN_ID,
        payload: res.data
      })
    }

    await setIsLoad(false);
  }
}

export function SendAnOrderByTelegram(setIsLoad) {
  return async (dispatch, state) => {
    const {
      order: {
        data,
      },
      settings: {
        kitchens
      }
    } = state();

    await setIsLoad(true);

    let idKitchen = getIdKitchenOnKitchenCode(kitchens, data.kitchen_code);

    if (idKitchen === undefined) {
      await setIsLoad(false);
      await dispatch(addNotification({ war: ['Кухня не знайдена'] }, 'warn'));
      return false;
    }

    const res = await api.orders.sendOrderTelegram(idKitchen, data);

    if (isObject(res?.data?.errors) && isString(res.data.errors.message)) {
      await dispatch(addNotification({ war: [res.data.errors.message] }, 'warn'));
    }

    if (res.data.message) {
      await dispatch(addNotification({ war: [res.data.message] }));
    }

    await setIsLoad(false);
  }
}

export function getReasonInformation() {
  return async (dispatch, state) => {
    const res = await api.orders.getReasonInformation();

    if (res?.guilty) {
      await dispatch({
        type: REASON_GET_INFORMATION,
        payload: res
      });
    }
  }
}

