import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as ordersActions from "store/actions/orders";
import * as clientsActions from "store/actions/clients";

import { default as ModalClientsAdd } from "./ModalClientAdd";

function mapStateToProps(state) {
    return {
        isOpen: state.view.modalClients.isOpenAdd,
        loadingsModal: state.view.modalClients.loadingsModal,
        restaurants: state.settings.restaurants,
        addClientsForm: state.clients.addClient,
        sourceList: state.settings.resource,
    };
}

const mapDispatchToProps = {
    ...viewActions,
    ...ordersActions,
    ...clientsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalClientsAdd);
