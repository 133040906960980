import * as React from "react";
import { useSelector } from "react-redux";

import styled from "styled-components";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { Key as KeyIcon } from "@mui/icons-material";

const ToggleKitchenShiftButton = ({
  // redux
  openModalToggleKitchenShift,
}) => {
    const { 
        kitchenShiftLoading, // Чи завантажується закриття/відкриття зміни (запит до веб-серверу)
        kitchenShiftStatus,
    } = useSelector(state => state.kitchens);

    const title = React.useMemo(() => {
        const { isShiftClosed, shiftId } = kitchenShiftStatus;
        const textArray = [
            isShiftClosed ? 'Відкрити' : 'Закрити',
            'зміну',
        ];

        if (shiftId !== null) {
            textArray.push(`№${shiftId}`);
        }
        
        return textArray.join(" ");
    }, [kitchenShiftStatus]);

    return (
        <Wrapper>
            <ButtonIcon
              title={title}
              onClick={openModalToggleKitchenShift}
              icon={<KeyIcon />}
              isLoad={kitchenShiftLoading}
              disabled={kitchenShiftLoading}
            />
        </Wrapper>
    )
}

// Обгортка для світчу робочої зміни кухні
const Wrapper = styled.div`
  margin-left: 1em;
`;

export default React.memo(ToggleKitchenShiftButton);
