import Base from "./Base.js";

export default class SettingsAPI extends Base {
  getSettingAllCashier() {
    return this.apiClient.get(`receipt-statistics/cashiers`);
  }

  getSettingsAllMetodPayment() {
    return this.apiClient.get(`receipt-statistics/payment-types`);
  }

  // get courier transport types
  getCourierTransportTypesAll() {
    return this.apiClient.get(`courier-transport-types`);
  }

  getCutlerySetsAll() {
    return this.apiClient.get(`cutlery-sets`);
  }

  // get courier transport types
  getCourierTransportTypesAll() {
    return this.apiClient.get(`courier-transport-types`);
  }

  // ---- complaints
  complaintCrate(data) {
    return this.apiClient.post(`complaint-types`, data);
  }

  getComplaintAll() {
    return this.apiClient.get(`complaint-types`);
  }

  getComplaintOne(id) {
    return this.apiClient.get(`complaint-types/${id}`);
  }

  updateComplaint(id, data) {
    return this.apiClient.patch(`complaint-types/${id}`, data);
  }

  deleteComplaint(id) {
    return this.apiClient.delete(`complaint-types/${id}`);
  }

  // ---- bug reports
  createBugReport(data) {
    return this.apiClient.post(`bug-report`, data);
  }

  getBugReports() {
    return this.apiClient.get(`bug-reports`, {
      is_closed: false,
    });
  }

  closeBugReport(id) {
    return this.apiClient.post(`bug-report/${id}/close`);
  }

  // sources

  complaintCrateSources(data) {
    return this.apiClient.post(`complaint-sources`, data);
  }

  getComplaintAllSources() {
    return this.apiClient.get(`complaint-sources`);
  }

  getComplaintOneSources(id) {
    return this.apiClient.get(`complaint-sources/${id}`);
  }

  updateComplaintSources(id, data) {
    return this.apiClient.patch(`complaint-sources/${id}`, data);
  }

  deleteComplaintSources(id) {
    return this.apiClient.delete(`complaint-sources/${id}`);
  }

  // ---- end complaints

  getCities() {
    return this.apiClient.get("cities");
  }

  getCitySyncId(syncId) {
    return this.apiClient.get(`cities/${syncId}`);
  }

  updateCity(id, data) {
    return this.apiClient.patch(`cities/${id}`, data);
  }

  createCity(data) {
    return this.apiClient.post("cities", data);
  }

  getKitchens(params) {
    return this.apiClient.get("kitchens", params);
  }

  getKitchen(code) {
    return this.apiClient.get(`kitchens/${code}/get`);
  }

  createKitchen(data) {
    return this.apiClient.post("kitchens", data);
  }

  updateKitchen(id, data) {
    return this.apiClient.patch(`kitchens/${id}`, data);
  }

  getLocations() {
    return this.apiClient.get("locations");
  }

  getLocationId(id) {
    return this.apiClient.get(`locations/${id}`);
  }

  createLocation(data) {
    return this.apiClient.post("locations", data);
  }

  updateLocation(id, data) {
    return this.apiClient.patch(`locations/${id}`, data);
  }

  // ---- payment

  getPayment() {
    return this.apiClient.get("payment");
  }

  createPayment(data) {
    return this.apiClient.post("payment", data);
  }

  getPaymentId(id) {
    return this.apiClient.get(`payment/${id}`);
  }

  updatePaymentId(id, data) {
    return this.apiClient.patch(`payment/${id}`, data);
  }

  deletePaymentId(id) {
    return this.apiClient.delete(`payment/${id}`);
  }

  // ---- end payment

  getOrderStatuses() {
    return this.apiClient.get("order-statuses");
  }

  getOrderTypes() {
    return this.apiClient.get("order-types");
  }

  getItemsTypes() {
    return this.apiClient.get("order-items-statuses");
  }

  getProductCategories(params) {
    return this.apiClient.get("product-categories", params);
  }

  oneSettingsProductCategories(id) {
    return this.apiClient.get(`product-categories/${id}`);
  }

  updatesettingsProductCategories(id, data) {
    return this.apiClient.patch(`product-categories/${id}`, data);
  }

  getProductTypes() {
    return this.apiClient.get("product-types");
  }

  getRoles() {
    return this.apiClient.get("rbac/roles");
  }

  getRoleId(id) {
    return this.apiClient.get(`rbac/roles/${id}`);
  }

  updataRole(id, data) {
    return this.apiClient.patch(`rbac/roles/${id}`, data);
  }

  createRole(data) {
    return this.apiClient.post(`rbac/roles`, data);
  }

  deleteRole(id) {
    return this.apiClient.delete(`rbac/roles/${id}`);
  }

  getActiveKitchensByCity(params) {
    return this.apiClient.get("kitchens/get-active-by-city", params);
  }

  getRestaurants() {
    return this.apiClient.get("restaurants");
  }

  getRestorant(code) {
    return this.apiClient.get(`restaurants/${code}`);
  }

  updateRestorant(id, data) {
    return this.apiClient.patch(`restaurants/${id}`, data);
  }

  createRestorant(data) {
    return this.apiClient.post(`restaurants`, data);
  }

  updateRestorantImage(image, id) {
    return this.apiClient.post(`restaurants/${id}/image`, image, 'formData');
  }

  uploadImage(image) {
    return this.apiClient.post(`image-upload`, image, 'formData');
  }

  getPromotion() {
    return this.apiClient.get(`stocks`);
  }

  getPromotionId(id) {
    return this.apiClient.get(`stocks/${id}`);
  }

  updatePromotion(id, data) {
    return this.apiClient.patch(`stocks/${id}`, data);
  }

  createPromotion(data) {
    return this.apiClient.post(`stocks`, data);
  }

  deletePromotion(id) {
    return this.apiClient.delete(`stocks/${id}`);
  }

  getDiscounts() {
    return this.apiClient.get(`discounts`);
  }

  getPromocode() {
    return this.apiClient.get(`promocodes`);
  }

  getPromocodeId(id) {
    return this.apiClient.get(`promocodes/${id}`);
  }

  createPromocode(data) {
    return this.apiClient.post(`promocodes`, data);
  }

  updatePromocode(id, data) {
    return this.apiClient.patch(`promocodes/${id}`, data);
  }

  deletePromocode(id) {
    return this.apiClient.delete(`promocodes/${id}`);
  }

  // -----

  getResource() {
    return this.apiClient.get(`source`);
  }

  getResourceId(id) {
    return this.apiClient.get(`source/${id}`);
  }

  createResource(data) {
    return this.apiClient.post(`source`, data);
  }

  updateResource(id, data) {
    return this.apiClient.patch(`source/${id}`, data);
  }

  deleteResource(id) {
    return this.apiClient.delete(`source/${id}`);
  }

  // pickup
  getPickup() {
    return this.apiClient.get(`pickup-settings`);
  }

  updatePickup(data) {
    return this.apiClient.patch(`pickup-settings/1`, data);
  }

  // types of delivery 
  getTypesOfDelivery() {
    return this.apiClient.get(`delivery-types`);
  }

  getDeliveryId(id) {
    return this.apiClient.get(`delivery-types/${id}`);
  }

  createDelivery(data) {
    return this.apiClient.post(`delivery-types`, data);
  }

  updateDeliveryId(id, data) {
    return this.apiClient.patch(`delivery-types/${id}`, data);
  }

  deleteItemDelivery(id) {
    return this.apiClient.delete(`delivery-types/${id}`);
  }

  // reason
  getReasonsList() {
    return this.apiClient.get(`client-blocking-reason`);
  }

  getReasonId(id) {
    return this.apiClient.get(`client-blocking-reason/${id}`);
  }

  updateReasonId(id, data) {
    return this.apiClient.patch(`client-blocking-reason/${id}`, data);
  }

  deleteItemReasonById(id) {
    return this.apiClient.delete(`client-blocking-reason/${id}`);
  }

  createReason(data) {
    return this.apiClient.post(`client-blocking-reason`, data);
  }

  getSwttingsTelestat(params) {
    return this.apiClient.get(`settings?group=telestat`, params);
  }

  updateTelestat(data) {
    return this.apiClient.patch(`settings/telestat`, data);
  }

  // maintenance status
  getMaintenanceStatus() {
    return this.apiClient.get(`technical-works`);
  }
}
