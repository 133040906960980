import React from 'react';
import { 
    DataGridPremium,
    useGridApiRef,
    useKeepGroupedColumnsHidden 
} from '@mui/x-data-grid-premium';

import { Typography } from '@mui/material';
import { currencyFormatter } from 'utils/helpers/uahCurrencyFormatter';

const RestaurantPaymentsTable = ({ data }) => {
  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['restaurant'],
      },
      aggregation: {
        model: {
          sum: 'sum'
        },
      },
      columns: {
        // Other hidden columns
        columnVisibilityModel: { gross: false },
      },
    },
  });
  
  const columns = [
    { field: 'restaurant', headerName: 'Бренд', groupable: true },
    { field: 'method', headerName: 'Метод оплати', groupable: true },
    { 
        field: 'sum', 
        headerName: 'Сума за типом оплати', 
        groupable: false,
        type: 'number', 
        valueFormatter: ({ value }) => {
            if (value === undefined) return null;
            return currencyFormatter.format(value);
        },
    }
  ];

  const rows = Object.entries(data?.restourant_payments.list ?? {}).reduce((accumulator, [restaurant, paymentMethods]) => {
    const arr = [];
    for (const [paymentMethodName, amounts] of Object.entries(paymentMethods))
    {
        const { sum } = amounts;
        arr.push({
            restaurant,
            method: paymentMethodName,
            sum
        });
    }

    return [...accumulator, ...arr];
  }, []);

  return (
    <React.Fragment>
        <Typography variant='h5'>За брендом</Typography>
        <DataGridPremium
            sx={{
              ".MuiDataGrid-aggregationColumnHeaderLabel": {
                display: "none"
              }
            }}

            density='compact'
            disableDensitySelector={true}

            disableColumnFilter // Disable filtering
            disableColumnMenu // Disable column menu (sorting)

            defaultGroupingExpansionDepth={1}
            rowGroupingColumnMode='single'

            apiRef={apiRef}
            initialState={initialState}

            getRowId={({ restaurant, method }) => `${restaurant}-${method}`}
            hideFooter

            rows={rows}
            columns={columns}
        />
    </React.Fragment>
  );
};

export default React.memo(RestaurantPaymentsTable);
