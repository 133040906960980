import * as React from "react";

// components
import Radio from "components/ui-kit/Radio";
import Select from "components/ui-kit/Select";
import TextArea from "components/ui-kit/TextArea";

// constants
import { RADIO_BUG_SEVERITY } from "constants/schemes";
import { selectPages } from "constants/selectPages";

// styled
import styled, { useTheme } from "styled-components";

const BugView = ({ selectedBugReport }) => {
    const theme = useTheme();
    const bugMap = {
        actual: { label: 'Як працює зараз', value: selectedBugReport.actual },
        expected: { label: 'Як має працювати', value: selectedBugReport.expected },
        steps: { label: 'Кроки для повторення', value: selectedBugReport.steps },
    };
    
    return (
        <Wrapper theme={theme}>
            <Radio
                colors={'#000'}
                items={RADIO_BUG_SEVERITY}
                label="Критичність багу"
                valueKey="severity"
                value={selectedBugReport.severity}
                onChange={() => {}}
            />
            <Select
                selectOptions={selectPages}
                selectedOption={selectedBugReport.pages}
                label='Впливає на сторінки'
                isMulti
                onChange={() => {}}
                valueKey='pages'
                mode='primary'
                disabled
            />
            {Object.entries(bugMap).map(([key, field]) => (
                <TextArea
                    key={key}
                    title={field.label}
                    value={field.value}
                    resize="vertical"
                    disabled
                />
            ))}
        </Wrapper>
    );
};

const Wrapper = styled.div``;

export default React.memo(BugView);
