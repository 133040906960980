import newOrder from "./newOrder.mp3";
import pendingConfirmedOrder from "./pendingConfirmedOrder.mp3";

export const sounds = {
  // orders
  new_order: newOrder,
  pending_confirmed_order: pendingConfirmedOrder,
  //
}

export const [defaultSound] = Object.keys(sounds);
