import React, { useEffect } from "react";
import { ReportFilter } from "./filter";
import { ReportsTable } from "./table";

import { LoadingModal } from "components/loadingOrder/loading";
import { itemListHeight } from "../../utils/helpers/reports";

const Reports = ({
    listSelect,
    activeSelect,
    listReports,
    filter,
    isLoadPage,
    getReportsList,
    clearReports,
    updateActiveSelect,
    updateFilterAction,
    updateFilterArr,
    getReportsOfType,
    kitchens,
    restaurants,
    cities,
    categories,
}) => {
    const reportTableRef = React.useRef(null);

    const handleSelect = (value) => {
        clearReports();
        updateActiveSelect(value);
    }

    const updateFilter = (name, value) => {
        updateFilterAction(name, value)
    }

    const handlerSubmitRange = () => {
        getReportsOfType();
    }

    const handleSaveExcel = () => {
        reportTableRef.current?.saveExcel();
    }

    useEffect(() => {
        getReportsList();
    }, []);

    useEffect(() => {
        getReportsOfType();
    }, [activeSelect]);

    useEffect(() => {
        itemListHeight();
    }, [listReports, activeSelect, isLoadPage]);

    return (
        <div>
            <ReportFilter
                listSelect={listSelect}
                activeSelect={activeSelect}
                filter={filter}
                kitchens={kitchens}
                restaurants={restaurants}
                cities={cities}
                categories={categories}

                handleSelect={handleSelect}
                updateFilter={updateFilter}
                updateFilterArr={updateFilterArr}
                handlerSubmitRange={handlerSubmitRange}
                saveExcel={handleSaveExcel}
            />
            <div className="row-me ">
                <div className="wr-table-me">
                    {isLoadPage ? (
                        <div style={{ height: '150px', minWidth: "700px", position: 'relative' }}>
                            <LoadingModal />
                        </div>
                    ) : (<ReportsTable
                        data={listReports}
                        activeSelect={activeSelect}
                        ref={reportTableRef}
                    />)}
                </div>
            </div>
        </div>
    )
}

export default Reports;