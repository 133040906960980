import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as kitchenActions from "store/actions/kitchen";

import { default as ModalToggleKitchenShift } from "./ModalToggleKitchenShift";

function mapStateToProps(state) {
    return {
        isOpen: state.view.modalToggleKitchenShift.isOpen,
        shiftStatus: state.kitchens.kitchenShiftStatus,
    };
}

const mapDispatchToProps = {
    ...viewActions,
    ...kitchenActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalToggleKitchenShift);
