import * as React from 'react';
import { 
    DataGridPremium,
    useGridApiRef,
    useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';

import { currencyFormatter } from 'utils/helpers/uahCurrencyFormatter';
import { Typography } from '@mui/material';

const CourierPaymentsTable = ({ data }) => {
    const apiRef = useGridApiRef();
    const initialState = useKeepGroupedColumnsHidden({
      apiRef,
      initialState: {
        rowGrouping: {
            model: ['courier', 'type', 'brand'],
        },
        aggregation: {
            model: {
              sum: 'sum',
              bonus: 'sum'
            },
        },
        columns: {
          // Other hidden columns
          columnVisibilityModel: { gross: false },
        },
      },
    });

    const columns = [
        { field: 'courier', headerName: 'Кур\'єр', groupable: true, width: 200 },
        { field: 'type', headerName: 'Тип оплати', groupable: true },
        { field: 'brand', headerName: 'Бренд', groupable: true },
        { field: 'id', headerName: 'Номер замовлення', width: 140 },
        { 
            field: 'sum', 
            headerName: 'Сума',
            type: 'number',
            valueFormatter: ({ value }) => {
                if (value === undefined) return null;
                return currencyFormatter.format(value);
            },
        },
        { 
            field: 'bonus', 
            headerName: 'Знижка',
            type: 'number',
            valueFormatter: ({ value }) => {
                if (value === undefined) return null;
                return currencyFormatter.format(value);
            },
        },
        { field: 'operator', headerName: 'Оператор', width: 200 },
        { field: 'closed_from', headerName: 'Закриття', width: 200 }
    ];

    return (
        <React.Fragment>
            <Typography variant='h5'>Платежі за кур'єром</Typography>
            <DataGridPremium
                sx={{
                    ".MuiDataGrid-aggregationColumnHeaderLabel": {
                    display: "none"
                    }
                }}

                apiRef={apiRef}
                initialState={initialState}

                defaultGroupingExpansionDepth={3}
                rowGroupingColumnMode='multiple'

                density='compact'
                rows={data}
                columns={columns}
                hideFooter

                autoHeight
                disableColumnFilter
                disableColumnMenu
            />
        </React.Fragment>
    )
}

export default React.memo(CourierPaymentsTable);
