import Base from "./Base.js";

export default class StatisticAPI extends Base {
    getReports() {
        return this.apiClient.get("reports");
    }
    getReportsOfType(params) {
        return this.apiClient.get("reports-get-by-code", params);
    }
    getReceiptStatisticsReport(params) {
        return this.apiClient.post("receipt-statistics/report", params);
    }
}