import React from "react";
import styled, { useTheme } from "styled-components";

import { TextInput } from "components/ui-kit/inputs/TextInput";
import { LoadingModal } from "../../loadingOrder/loading";
import InputPhone from "components/ui-kit/Modals/InputPhone";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import SaveIcon from "@mui/icons-material/Save";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import Radio from "components/ui-kit/Radio";
import { CalendarDate } from "components/ui-kit/Calendar/Calendar";
import { TextArea } from "components/ui-kit/inputs/TextArea";

import { sourceMapped } from "utils/mappers/source";
import { maxDateBirthday, dateNormal, dataFormat } from "utils/helpers/date";
import { currentDate } from 'utils/helpers/date';

import CloseIcon from "@mui/icons-material/Close";

import { RADIO_FLOOR } from "constants/schemes";

const ModalClientsAdd = ({
    //state
    isOpen,
    loadingsModal,
    addClientsForm,
    sourceList,
    //dispatch
    closeModalClientsAdd,
    updateFormAddClients,
    addClient,
    getClientsSea = () => { },
}) => {
    const theme = useTheme();

    const handleCloseWindow = async () => {
        await closeModalClientsAdd();
    }

    const onAddClients = () => {
        addClient(getClientsSea);
    }

    return (
        <>
            {
                isOpen && (
                    <BgModal onClick={handleCloseWindow} />
                )
            }

            <Wrapper
                theme={theme}
                isOpen={isOpen}
            >
                <Header>
                    <div style={{ color: "#fff" }}>Створення клієнта</div>

                    <CloseButton onClick={handleCloseWindow}>
                        <CloseIcon style={{ fontSize: 36 }} />
                    </CloseButton>
                </Header>

                <Block>
                    <TextInput
                        title="Ім'я"
                        type="text"
                        value={addClientsForm?.name ?? ''}
                        onChange={(e) => updateFormAddClients("name", e.target.value)}
                    />
                    <InputPhone
                        title='Номер телефону'
                        type='text'
                        value={addClientsForm?.phone ?? ''}
                        width={"100%"}
                        onChange={(value) => updateFormAddClients("phone", value)}
                    />
                    <Dropdown
                        title="Джерело"
                        type="text"
                        width={"auto"}
                        onChange={(e) => updateFormAddClients("source", e.target.value)}
                        list={sourceMapped(sourceList)}
                        value={addClientsForm.source}
                    />
                    <WrRange>
                        <CalendarDate
                            title="Дата народження"
                            valueIn={addClientsForm?.birthday || currentDate()}
                            date={dateNormal(addClientsForm?.birthday || currentDate())}
                            maxDate={maxDateBirthday()}
                            onChangeCal={(date) => updateFormAddClients("birthday", dataFormat(date))}
                        />
                    </WrRange>
                    <Radio
                        colors={"#fff"}
                        items={RADIO_FLOOR}
                        label="Стать"
                        valueKey="floor"
                        value={addClientsForm.sex}
                        onChange={(e) => updateFormAddClients("sex", e.value.value)}
                    />
                    <TextArea
                        title="Коментар"
                        onChange={(e) => updateClients("comment", e.target.value)}
                        value={addClientsForm?.comment || ""}
                    />
                </Block>

                <Menu>
                    <ButtonIcon
                        onClick={onAddClients}
                        title={"Cтворити"}
                        icon={<SaveIcon />}
                    />
                </Menu>

                {
                    loadingsModal && (
                        <LoadingModal />
                    )
                }
            </Wrapper>
        </>
    )
}

const Wrapper = styled.div`
    position: fixed;
    right: ${(p) => (p.isOpen ? "0" : "-1000px")};
    top: 0;
    z-index: 20000;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 460px;
    padding-bottom: 30px;
    background-color: ${(p) => p.theme.background};
    transition: 200ms right;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 10px 10px;
    border-bottom: ${(p) => p.theme.border39};
`;

const CloseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => p.theme.accentColor};
    border-radius: 50%;
    background-color: transparent;

    &:hover {
        cursor: pointer;
        background-color: ${(p) => p.theme.backgroundHover};
    }
`;

const BgModal = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.65);
`;

const Block = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px 16px;
    overflow-y: scroll;
    margin-bottom: 40px;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const WrRange = styled.div`
    margin-bottom: 20px;
    margin-top: 10px;
`;

const Menu = styled.ul`
    position: fixed;
    bottom: 20px;
    display: flex;
`;


export default React.memo(ModalClientsAdd);
