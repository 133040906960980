import PropTypes from "prop-types";
import { isArray } from "lodash";
import { useEffect, useState } from "react";
import {
    useRouteMatch,
    useHistory
} from "react-router-dom";

import Table from "components/ui-kit/Table";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import ModalDelete from "components/modals/ModalDelete";

import AddIcon from "@mui/icons-material/Add";
import styles from "./../scss/settings.module.scss";

import { tableRoles } from "constants/tables";

const Role = ({
    //state
    roles,
    view,
    isOpenModalDelete,
    isLoadModalDelete,
    //dispatch
    getSettingsRoles,
    updateModalDelete,
    deleteRole,
}) => {
    const [idRole, setIdRole] = useState(null);

    const history = useHistory();
    const { path } = useRouteMatch();

    useEffect(() => {
        getSettingsRoles();
    }, []);

    const handlerEdit = (data) => {
        history.push(`${path}/${data.id}`);
    }

    const handlerCreate = () => {
        history.push(`${path}/new`);
    }

    const handleDelete = (data) => {
        setIdRole(data.id);
        updateModalDelete('isOpen', true);
    }

    const handleDeleteRole = () => {
        deleteRole(idRole);
    }

    return (
        <div>
            <div className={styles.wr_head}>
                <ButtonIcon
                    onClick={handlerCreate}
                    title='Додати роль'
                    icon={<AddIcon />}
                    positionIcon="left"
                />
            </div>
            <div className={styles.wr_row}>
                {
                    !view?.isLoadPage ? (
                        isArray(roles) && (
                            <Table
                                onClick={handlerEdit}
                                data={roles}
                                scheme={tableRoles}
                                onDelete={handleDelete}
                            />
                        )
                    ) : (
                        <LoadingCircl />
                    )
                }
            </div>

            <ModalDelete
                title={'Видалити'}
                text={`Ви підтверджуєте видалення ?`}
                isOpen={isOpenModalDelete}
                isLoad={isLoadModalDelete}
                funDelete={() => handleDeleteRole()}
                updateModalDelete={updateModalDelete}
            />
        </div>
    )
}

Role.prototype = {
    roles: PropTypes.array.isRequired,
    view: PropTypes.object,
    getSettingsRoles: PropTypes.func.isRequired,
    createRole: PropTypes.func.isRequired,
    isOpenModalDelete: PropTypes.object,
    isLoadModalDelete: PropTypes.object,
    updateModalDelete: PropTypes.func.isRequired,
    deleteRole: PropTypes.func.isRequired,
}

export default Role;