import { useState } from "react";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import Table from "components/ui-kit/Table";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import ModalDelete from "components/modals/ModalDelete";
import ModalСreateComplaint from "components/modals/ModalСreateComplaint";

import { tableComplaint } from "constants/tables";

import AddIcon from "@mui/icons-material/Add";
import styles from "./../../scss/settings.module.scss";

const defModel = {
    modalDelete: {
        id: null,
        status: false,
    },
    modalAdd: {
        status: false,
        isNew: false,
    }
}

const Complaint = ({
    // state
    isLoadPage,
    isLoadModal,
    list,
    form,
    // dispatrch
    deleteComplaint,
    complaintCrate,
    getComplaintOne,
    updateComplaint,
    updateComplainForm,
    complainFormClean,
}) => {
    const [modalDelete, setModalDelete] = useState(defModel.modalDelete);
    const [modalAdd, setModalAdd] = useState(defModel.modalAdd);
    const [isLoadBtn, setIsLoadBtn] = useState(false);

    const handleCreateComplaint = () => {
        if (modalAdd.isNew) {
            complaintCrate(setIsLoadBtn, closeModalCreate);
            return;
        }

        updateComplaint(setIsLoadBtn, closeModalCreate);
    }

    const closeModalCreate = async () => {
        await setModalAdd(defModel.modalAdd);
        complainFormClean();
    }

    const handlerEdit = ({ id }) => {
        setModalAdd({ status: true, isNew: false });
        getComplaintOne(id);
    }

    const handleDeleteComplaint = () => {
        deleteComplaint(modalDelete.id, handleCloseModalDelete);
    }

    const handleOpenModalDelete = ({ id }) => {
        setModalDelete({
            id,
            status: true
        });
    }

    const handleCloseModalDelete = async () => {
        await setModalDelete(defModel.modalDelete)
    }

    return (
        <div>
            <div className={`${styles.title_row} ${styles.marg_bot}`}>Типи скарг</div>
            <div className={styles.block}>
                {
                    !isLoadPage && (
                        <>
                            <div className={styles.wr_head}>
                                <ButtonIcon
                                    onClick={() => setModalAdd({ status: true, isNew: true })}
                                    title='Додати'
                                    icon={<AddIcon />}
                                    positionIcon="left"
                                />
                            </div>
                            <div className={styles.block}>
                                <Table
                                    onClick={handlerEdit}
                                    data={list}
                                    scheme={tableComplaint}
                                    onDelete={handleOpenModalDelete}
                                />
                            </div>
                        </>
                    )
                }

                {
                    isLoadPage && (
                        <LoadingCircl />
                    )
                }
            </div>

            <ModalСreateComplaint
                isOpen={modalAdd.status}
                isLoad={isLoadModal}
                isLoadBtn={isLoadBtn}
                data={form}
                isNew={modalAdd.isNew}
                onCloseModal={closeModalCreate}
                handleCreateClick={handleCreateComplaint}
                updateComplainForm={updateComplainForm}
            />

            <ModalDelete
                title={'Видалити'}
                text={`Ви підтверджуєте видалення ?`}
                isOpen={modalDelete.status}
                isLoad={isLoadModal}
                funDelete={handleDeleteComplaint}
                onCloseModal={handleCloseModalDelete}
            />
        </div>
    )
}

export default Complaint;