import React, { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";

import Radio from "components/ui-kit/Radio";

import { saveData, removeItem, getData } from "utils/helpers/localStorage";
import { removeEmpty } from "utils/helpers/removeEmptyFromObj";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";

import { BLACK_LIST } from "constants/schemes";

const ModalFilterUsers = ({
    // state
    isOpen,
    keyNameLocal,
    // mapDispatch
    closeModalFilter,
    getClients = () => { },
}) => {
    const theme = useTheme();

    const [disabledBtnClear, setDisabledBtnClear] = useState(false);
    const [disabledBtnSubmit, setDisabledBtnSubmit] = useState(false);
    const [update, setUpdate] = useState();
    const [type, setType] = useState(0);

    const clearState = () => {
        setType(0);

        getClients(1);
        closeModalFilter();
    }

    const handleSubmit = () => {
        let params = {
            black_list: type,
        };

        params = removeEmpty(params);

        if (Object.keys(params).length) {
            saveData(keyNameLocal, params);
        } else {
            handleSubmitRemove();
        }

        getClients(1, params);

        setUpdate(prev => !prev);
        closeModalFilter();
    }

    const handleSubmitRemove = () => {
        removeItem(keyNameLocal);
        clearState();
    }

    useEffect(() => {
        let dataFilterParamsLocalStorage = getData(keyNameLocal);

        if (dataFilterParamsLocalStorage?.black_list) {
            setType(dataFilterParamsLocalStorage?.black_list);
        }
    }, []);

    useEffect(() => {
        let dataFilterLocalStorage = getData(keyNameLocal);
        let isDisabledBtnSubmit = ((type.length == 0) && !dataFilterLocalStorage);
        let isDisabledBtnClear = ((type.length == 0) && !dataFilterLocalStorage);

        setDisabledBtnSubmit(isDisabledBtnSubmit);
        setDisabledBtnClear(isDisabledBtnClear);
    }, [type, update]);

    return (
        <>
            {
                isOpen && (
                    <BgModal onClick={() => closeModalFilter()} />
                )
            }

            <Wrapper theme={theme} isOpen={isOpen}>
                <Header>
                    <div style={{ color: "#fff" }}>Фільтр</div>
                    <CloseButton onClick={() => closeModalFilter()}>
                        <CloseIcon style={{ fontSize: 36 }} />
                    </CloseButton>
                </Header>
                <Block>
                    <WrIn>
                        <Radio
                            colors={"#ffffff"}
                            items={BLACK_LIST}
                            label="Шукати тільки по чорному списку?"
                            valueKey="status"
                            value={type}
                            onChange={(e) => setType(e.value.value)}
                        />
                    </WrIn>
                </Block>
                <Menu>
                    <Item>
                        <ButtonIcon
                            onClick={handleSubmit}
                            title='Застосувати'
                            icon={<SaveIcon />}
                            disabled={disabledBtnSubmit}
                        />
                    </Item>
                    <Item>
                        <ButtonIcon
                            onClick={handleSubmitRemove}
                            title='Скинути'
                            icon={<CloseIcon />}
                            disabled={disabledBtnClear}
                        />
                    </Item>
                </Menu>
            </Wrapper>
        </>

    );
};

const Wrapper = styled.div`
  position: fixed;
  right: ${(p) => (p.isOpen ? "0" : "-1000px")};
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 460px;
  padding-bottom: 30px;
  background-color: ${(p) => p.theme.background};
  transition: 200ms right;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 10px 20px;
  border-bottom: 1px solid ${(p) => p.theme.secondaryColor};
  margin-bottom: 15px;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.accentColor};
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 15px;
  margin-bottom: 40px;
  
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WrIn = styled.div`
  &:not(:first-child) {
      margin-top: 15px;
  }
`;

const Menu = styled.ul`
  position: fixed;
  bottom: 0;
  display: flex;
`;

const Item = styled.li`
  margin: 0 20px 15px 0;
  &:last-child {
    margin-right: 0;
  }
`;

const BgModal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.65);
`;

export default ModalFilterUsers;
