import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as bugList } from './BugList';

function mapStateToProps(state) {
    const {
        settings: {
            bugReports: {
                isLoadPage,
                isLoadModal,
                selectedBugReport,
                list,
            }
        }
    } = state;

    return {
        isLoadPage,
        isLoadModal,
        selectedBugReport,
        list,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(bugList);
