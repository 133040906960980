import { connect } from "react-redux";
import { default as KitchenDeliveryTimeButton } from "./KitchenDeliveryTimeButton";

const mapStateToProps = ({
  user: { 
    user: { kitchen_code: userKitchenCode }
  },
  settings: { kitchens },
}) => ({
  userKitchenCode,
  kitchens,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(KitchenDeliveryTimeButton);
