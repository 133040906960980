import { connect } from "react-redux";

import * as productsActions from "../../../store/actions/products";
import { default as ModalProductSpecification } from "./ModalProductSpecification";

function mapStateToProps(state) {
  const { 
    specification, 
    isOpen, 
    isLoad: isLoading 
  } = state.products.modalProductSpecification;

  return {
    specification,
    isOpen,
    isLoading,
  };
}

const mapDispatchToProps = {
  ...productsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalProductSpecification);
