import { default as ModalKitchenDeliveryTime } from './ModalKitchenDeliveryTime';

// redux
import { connect } from 'react-redux';

// actions
import * as settingsActions from 'store/actions/settings';

const mapStateToProps = (state) => ({
    restaurants: state.settings.restaurants,
    editKitchens: state.settings.editKitchens,
    kitchenCode: state.user.user.kitchen_code,
});

const mapDispatchToProps = {
    ...settingsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalKitchenDeliveryTime);
