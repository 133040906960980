import { combineReducers } from "redux";

import clients from "./clients";
import orders from "./orders";
import products from "./products";
import session from "./session";
import statistic from "./statistic";
import user from "./user";
import users from "./users";
import view from "./view";
import order from "./order";
import listings from "./listings";
import settings from "./settings";
import kitchens from "./kitchens";
import loadings from "./loading";
import cook from './cook';
import couriers from './couriers';
import notifications from './notifications';
import settingsMagazine from './settingsMagazine';
import reports from "./reports";
import sales from "./sales";
import settingsProvings from "./settingsProvings";
import promos from "./promos";

export default combineReducers({
  session,
  user,
  users,
  view,
  orders,
  statistic,
  products,
  clients,
  order,
  listings,
  settings,
  kitchens,
  loadings,
  cook,
  couriers,
  notifications,
  settingsMagazine,
  settingsProvings,
  reports,
  sales,
  promos,
});
