import { statusClosed, statusrejected } from "constants/statuses";

const getTimeFlagColor = (order, color) => {
  if ([statusClosed, statusrejected].includes(order.status)) 
    return "";
  else if (order.technical)
    return "darkgray";
  return color;
}

export const getTimeFlag = (order, flag) => {
  const timeFlags = order.time_flags || {};
  const color = getTimeFlagColor(order, timeFlags.color || "green");

  if (flag === "color")
    throw new Error("Неможливо отримати timeFlag color");

  return { time: timeFlags[flag], class: color };
}
