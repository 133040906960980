const condition = `
{% if ... %}
    Текст...
{% endif %}
`;

const iteration = `
{% for item, quantity in items %}
    {{ item }}: {{ quantity }}
{% endfor %}
`;

const styleTemplates = [
    { title: 'Виділити жирним', template: '*', isVisual: true },
    { title: 'Виділити курсивом', template: '_', isVisual: true },
    { title: 'Закреслити', template: '~', isVisual: true },
];

export const cartNotifyMessageTemplates = [
    { title: 'Телефон', template: '{{ phone }}' },
    { title: 'Ресторан', template: '{{ restaurant }}' },
    //
    { title: 'Якщо', template: condition, isLogical: true },
    { title: 'Усі товари', template: iteration, isLogical: true },
    //
    ...styleTemplates,
];

export const followOrderMessageTemplates = [
    { title: 'Ресторан', template: '{{ restaurant }}' },
    { title: 'Посилання', template: '{{ link }}' },
    // ...styleTemplates,
];
