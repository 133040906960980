import { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch } from "react-redux";

import classNames from "classnames";
import { getSettingsProductCategories } from "store/actions/settings";

import Checkbox from "components/ui-kit/Checkbox";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { isArray } from "lodash";

export const RestorantAndKitchen = ({
    kitchens,
    restaurants,
    cities,
    categories,
    filter: {
        filterKitchen,
        filterRestorant,
        filterCity,
        filterCategory,
        filterRestorantAll,
        filterKitchenAll,
        filterCityAll,
        filterCategoryAll,
    },
    update,
    handlerSubmit,
    selectedReport,
}) => {
    const dispatch = useDispatch();
    const refWr = useRef(undefined);
    const [show, setShow] = useState(false);

    useEffect(() => {
        dispatch(getSettingsProductCategories({ status: 1 }));
    }, []);

    const categoryPickerAvailable = ["orders_and_dishes_by_kitchen", "orders_and_dishes_by_kitchen_kpi"].includes(selectedReport?.params.code);
    const cityPickerAvailable = selectedReport?.params.code === "daily_sales";
    const kitchenPickerAvailable = selectedReport?.params.code !== "unused_courier_late_bonus";

    const categoriesByRestaurant = useMemo(() => {
        return categories.reduce((prev, curr) => ({
            ...prev,
            [curr.restaurant]: [
                ...(prev[curr.restaurant] ?? []),
                curr,
            ]
        }), {});
    }, [categories]);

    const filteredRestaurantCodes = useMemo(() => {
        if (filterRestorantAll === 'all') return restaurants.map(r => r.code);
        return filterRestorant;
    }, [filterRestorant, filterRestorantAll, restaurants]);

    const handleOpen = () => {
        setShow(true);
    }

    const handleBtn = () => {
        handlerSubmit();
        setShow(false);
    }

    useEffect(() => {
        const handleClockWr = (e) => {
            if (show) {
                const withinBoundaries = e.composedPath().includes(refWr.current);

                if (!withinBoundaries) {
                    setShow(false);
                }
            }
        }

        !!document.body && document.body.addEventListener('mousedown', handleClockWr);

        return () => {
            !!document.body && document.body.addEventListener('mousedown', handleClockWr);
        }
    }, [show]);

    return (
        <div className="res-kit-wr" ref={refWr}>
            <div className="res-kit-wr__in" onClick={handleOpen}>рухня, кухняб кухня3, кухня4</div>
            {
                !!show && (
                    <div className={classNames("res-kit-modal", {"category-picker-available": categoryPickerAvailable})}>
                        <div className="res-kit-modal__main">
                            <div className="res-kit-modal__main_flex">
                                <div className="res-kit-modal__l">
                                    <p>Ресторан</p>
                                    <div className="res-kit-modal__w-lir">
                                        <ul className="list-r-k">
                                            <li className="list-r-k__item item_all" >
                                                <Checkbox isStyle={true} isMin={true} title="Всі" checked={filterRestorantAll == 'all'} onChecked={() => update("filterRestorantAll", 'all')} />
                                            </li>
                                            {
                                                isArray(restaurants) && restaurants.map(item => (
                                                    !!item.status && (
                                                        <li className="list-r-k__item" key={item.id}>
                                                            <Checkbox isStyle={true} isMin={true} title={item.name} checked={filterRestorant.includes(item.code)} onChecked={() => update("filterRestorant", item.code, true)} />
                                                        </li>
                                                    )
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                {kitchenPickerAvailable && (<div className="res-kit-modal__m">
                                    <p>Кухня</p>
                                    <div className="res-kit-modal__w-lir">
                                        <ul className="list-r-k">
                                            <li className="list-r-k__item item_all">
                                                <Checkbox isStyle={true} isMin={true} title="Всі" checked={filterKitchenAll == 'all'} onChecked={() => update("filterKitchenAll", 'all')} />
                                            </li>
                                            {
                                                isArray(kitchens) && kitchens.map(item => (
                                                    !!item.status && (
                                                        <li className="list-r-k__item" key={item.id}>
                                                            <Checkbox isStyle={true} isMin={true} title={item.title} checked={filterKitchen.includes(item.code)} onChecked={() => update("filterKitchen", item.code, true)} />
                                                        </li>
                                                    )
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>)}
                                {cityPickerAvailable && (<div className="res-kit-modal__m">
                                    <p>Місто</p>
                                    <div className="res-kit-modal__w-lir">
                                        <ul className="list-r-k">
                                            <li className="list-r-k__item item_all">
                                                <Checkbox isStyle={true} isMin={true} title="Всі" checked={filterCityAll == 'all'} onChecked={() => update("filterCityAll", 'all')} />
                                            </li>
                                            {
                                                isArray(cities) && cities.map(item => (
                                                    !!item.status && (
                                                        <li className="list-r-k__item" key={item.id}>
                                                            <Checkbox isStyle={true} isMin={true} title={item.name_ua} checked={filterCity.includes(item.id)} onChecked={() => update("filterCity", item.id, true)} />
                                                        </li>
                                                    )
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>)}
                                {categoryPickerAvailable && (
                                    <div className="res-kit-modal__r">
                                        <p>Категорія страв</p>
                                        <div className="res-kit-modal__w-lir">
                                            <ul className="list-r-k">
                                                <li className="list-r-k__item item_all">
                                                    <Checkbox isStyle={true} isMin={true} title="Всі" checked={filterCategoryAll == 'all'} onChecked={() => update("filterCategoryAll", 'all')} />
                                                </li>
                                                {Object.entries(categoriesByRestaurant).map(([restaurantCode, categories]) => (
                                                    filteredRestaurantCodes.includes(restaurantCode) && (<div className="category-list" key={`product-categories-${restaurantCode}`}>
                                                        <p>{restaurants.find(({ code }) => code === restaurantCode)?.name}</p>
                                                        {
                                                            isArray(categories) && categories.map(item => (
                                                                <li className="list-r-k__item" key={item.id}>
                                                                    <Checkbox isStyle={true} isMin={true} title={item.name} checked={filterCategory.includes(item.id)} onChecked={() => update("filterCategory", item.id, true)} />
                                                                </li>
                                                            ))
                                                        }
                                                    </div>)
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="res-kit-modal__main_bot">
                                <ButtonIcon
                                    onClick={handleBtn}
                                    title="Ок"
                                    isMin={true}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    )
}