import { cloneDeep } from "lodash";
import PropTypes from "prop-types";
import styled from "styled-components";

import { TextInput } from "components/ui-kit/inputs/TextInput";
import Checkbox from "components/ui-kit/Checkbox";
import Select from "components/ui-kit/Select";
import styles from "../scss/settings.module.scss";

import { TextArea } from "components/ui-kit/inputs/TextArea";
import { MenuItem } from "components/menuPageMulti/menuItem";
import { LoadingModal } from "components/loadingOrder/loading";

import { productMappedSelect, productMappedSearchSelectItem } from "utils/mappers/products";
import { useMemo, useState, useEffect, useCallback } from "react";


const MenuItemList = styled.ul`
    margin-top: 10px;
    display: flex;
`;

export const PromotionTypeLateCourierExcuse = ({
    //props
    error,
    restaurants,
    products,
    promotionsEdit,
    updateFormPromotion = () => { },
    getProducts = () => { },
}) => {

    const [restaurant, setRestaurant] = useState(restaurants[0]?.code);
    const [productsLoading, setProductsLoading] = useState(false);

    useEffect(() => {
        console.debug('Оновлюємо статичну назву акції');
        updateFormPromotion("name", "Запізнення кур'єра");

        return () => updateFormPromotion("name", "");
    }, [updateFormPromotion]);

    useEffect(() => {
        setProductsLoading(true);
        getProducts(
            {
                short_projection: true,
                restaurant: restaurant,
            },
            setProductsLoading,
            () => { },
            true
        );
    }, [getProducts, restaurant]);

    const selectedRestaurant = useMemo(() => promotionsEdit.restaurants[restaurant], [promotionsEdit.restaurants, restaurant]);
    const updateRestaurantsNestedModel = useCallback((field, value) => {
        const copy = cloneDeep(promotionsEdit.restaurants);
        copy[restaurant][field] = value;

        updateFormPromotion("restaurants", copy);

    }, [restaurant, updateFormPromotion, promotionsEdit]);

    if (!Object.keys(promotionsEdit.restaurants).length) return <LoadingModal />;
    return (
        <div>
            <div className={`${styles.row_form_two}`}>
                <div className={`${styles.wr_drop} ${styles.item_two}`}>
                    <MenuItemList>
                        {restaurants.map(({ code, name }) => (
                            <MenuItem
                                key={code}
                                inKey={code}
                                title={name}
                                onHandle={setRestaurant}
                                isActive={restaurant === code}
                            />
                        ))}
                    </MenuItemList>

                    {!!restaurant && (
                        <div className={styles.wr_drop}>

                            <Select
                                isLoad={productsLoading}
                                selectOptions={productMappedSelect(products)}
                                selectedOption={productMappedSearchSelectItem(products, selectedRestaurant.free_products)}
                                valueKey='free_products'
                                label='Оберіть акційні позиції'
                                mode={'primary'}
                                onChange={(option) => {
                                    const { value } = option;
                                    updateRestaurantsNestedModel("free_products", value.map(i => i.value));
                                }}
                                isMulti
                                isSearchable
                            />

                            <TextInput
                                title="Скільки днів діє акція"
                                type="number"
                                value={selectedRestaurant.bonus_time}
                                isStyle={true}
                                onChange={(e) => updateRestaurantsNestedModel("bonus_time", +e.target.value)}
                            />

                            <TextInput
                                title="Нагадування за n днів до кінця"
                                type="number"
                                value={selectedRestaurant.remind_days}
                                isStyle={true}
                                onChange={(e) => updateRestaurantsNestedModel("remind_days", +e.target.value)}
                            />

                            <TextArea
                                title="Текст СМС запізнення"
                                height="10em"
                                value={selectedRestaurant.sms_courier_late_text}
                                isStyle={true}
                                onChange={(e) => updateRestaurantsNestedModel("sms_courier_late_text", e.target.value)}
                            />

                            <TextArea
                                title="Текст СМС нагадування"
                                height="10em"
                                value={selectedRestaurant.sms_notify_text}
                                isStyle={true}
                                onChange={(e) => updateRestaurantsNestedModel("sms_notify_text", e.target.value)}
                            />

                            <Checkbox
                                isStyle={true}
                                // isMin={true} 
                                title={`Активна`}
                                checked={!!selectedRestaurant.status}
                                onChecked={() => updateRestaurantsNestedModel("status", !promotionsEdit.restaurants[restaurant]?.status)}
                            />

                        </div>
                    )}
                </div>

            </div>
        </div>
    )
}

PromotionTypeLateCourierExcuse.propTypes = {
    number: PropTypes.number,
    handleSubmit: PropTypes.func,
    handleSubmitClose: PropTypes.func,
    updateFormPromotionWeek: PropTypes.func,
};