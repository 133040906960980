import HomeIcon from '@mui/icons-material/Home';

export const dataItemsMenu = {
    cooking: {
        title: 'Готуються',
        icon: <HomeIcon />,
    },
    preparing: {
        title: 'Пакуються',
    },
    for_delivery: {
        title: 'Очікують доставки',
    },
    on_way: {
        title: 'Доставляються',
    },
    pending_confirmed: {
        title: 'Попередні (оброблені)',
    },
    rejected: {
        title: 'Скасовані',
    },
    delivered: {
        title: 'Доставлені',
    },
    closed: {
        title: 'Завершені',
    },
}

export const dataItemsMenuCallCenter = {
    new: {
        title: 'Нові',
        icon: <HomeIcon />,
    },
    cooking: {
        title: 'Готуються',
    },
    preparing: {
        title: 'Пакуються',
    },
    for_delivery: {
        title: 'Очікують доставки',
    },
    on_way: {
        title: 'Доставляються',
    },
    pending: {
        title: 'Попередні',
    },
    pending_confirmed: {
        title: 'Попередні (оброблені)',
    },
    delivered: {
        title: 'Доставлені',
    },
}
