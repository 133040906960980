import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";

import ModalEditor from "components/modals/ModalEditor";
import MenuPageMulti from "components/menuPageMulti";
import OrdersList from "./OrdersList/index";
import OrdersListObj from "./OrdersListObj";
import ModalFilter from "components/modals/ModalFilter";
import { Header } from "components/orders/Header";
import { HeadOrder } from "components/orders/HeadOrderCallcenter";
// import ButtonIcon from "components/ui-kit/ButtonIcon";
import { ModalLoad } from "components/modals/ModalLoad";
import { dataItemsMenuCallCenter } from "constants/menuPageKitchens";

import { getFiltet } from "utils/processingRequester/getLocalFilterOrders";
import { getData } from "utils/helpers/localStorage";
import { activeInForceKitchenTabs } from "utils/helpers/activeInForce";

// import CloseIcon from "@mui/icons-material/Close";

const CallcenterPage = ({
  orders,
  ordersCount,
  updateOrder,
  openEditorCallcenter,
  getAllActiveOrders,
  updateFormSearchOrders,
  handleІSybmitSearchOrders,
  openModalFilter,
  isRedactionOrder,
  redactionOrder,
  closeEditorCallcenter,
  searchValue,
  statusSearch,
  loadPage,
  isOpenModal,
  cleanFormOrder,
}) => {
  let keysMenu = Object.keys(dataItemsMenuCallCenter);
  const [activeTabs, setActiveTabs] = useState([keysMenu[0]]);

  useEffect(() => {
    // , callcenter: 1
    if (!isOpenModal) {
      getAllActiveOrders({...getFiltet(), active_tabs: activeTabs});

      const interval = setInterval(() => {
        getAllActiveOrders({...getFiltet(), active_tabs: activeTabs});
      }, 20000);

      return () => clearInterval(interval);
    }
  }, [statusSearch, activeTabs, isOpenModal]);

  const handleCreateOrder = () => {
    openEditorCallcenter();
    updateOrder();
  };

  const handleShowFilter = () => {
    openModalFilter();
  }

  // const handleCloseProhibition = () => {
  //   redactionOrder(true);
  //   closeEditorCallcenter();
  // }

  useEffect(() => {
    if (!isOpenModal)
      cleanFormOrder();
  }, [isOpenModal]);

  const menuItemsWithOrderCount = useMemo(() => {
    const entries = Object.entries(dataItemsMenuCallCenter)
      .map(([key, value]) => (
        [key, {
          ...value,
          title: `${value.title} (${ordersCount[key] ?? 0})`
        }]
      ));
    return Object.fromEntries(entries);
  }, [ordersCount]);

  const renderOrders = () => {
    return (
      <>
        <View>
          <MenuPageMulti
            items={menuItemsWithOrderCount}
            activeTabs={activeTabs}
            setActiveTabs={setActiveTabs}
            // isBtnAdd={isBtnAdd}
            handleCreateOrder={handleCreateOrder}
            disabledOnClosedShift={false}
            borderless
            // isBtnPrint={true}
            // isBtnKitchenCounter={profile.role_name == 'administrator'}
            // isBtnCourierReport={profile.role_name == 'administrator'}
            // handlePrintAll={handlePrintAll}
            // handleBtnKitchenCounter={() => setModalCounter(true)}
            // handleBtnCourierReport={() => setModalCourierReport(true)}
          />
          <HeadOrder />
        </View>
        <>
          {activeInForceKitchenTabs("new", activeTabs) && <OrdersList key="new" data={orders.new || {}} isHidePrice={true} isKitchen={true} title='Нові' /> }
          {activeInForceKitchenTabs("cooking", activeTabs) && <OrdersListObj key="cooking" data={orders.cooking || {}} isKitchen={true} title='Готуються' /> }
          {activeInForceKitchenTabs("preparing", activeTabs) && <OrdersListObj key="preparing" data={orders.preparing || {}} isKitchen={true} title='Пакуються' /> }
          {activeInForceKitchenTabs("for_delivery", activeTabs) && <OrdersListObj key="for_delivery" data={orders.for_delivery || {}} isKitchen={true} title='Очікують доставки' /> }
          {activeInForceKitchenTabs("on_way", activeTabs) && <OrdersListObj key="on_way" data={orders.on_way || {}} isKitchen={true} title='Доставляються' /> }
          {activeInForceKitchenTabs("pending", activeTabs) && <OrdersListObj key="pending" data={orders.pending || {}} isKitchen={true} title='Попереднi замовлення' /> }
          {activeInForceKitchenTabs("pending_confirmed", activeTabs) && <OrdersListObj key="pending_confirmed" data={orders.pending_confirmed || {}} isKitchen={true} title='Попереднi замовлення (оброблені)' /> }
          {activeInForceKitchenTabs("delivered", activeTabs) && <OrdersListObj key="delivered" data={orders.delivered || {}} isKitchen={true} title='Доставлені' /> }
        </>
      </>
    );
  };

  return (
    <Wrapper>
      <Header
        handleCreateOrder={handleCreateOrder}
        handleShowFilter={handleShowFilter}
        updateFormSearchOrders={updateFormSearchOrders}
        handleІSybmitSearchOrders={handleІSybmitSearchOrders}
        closedOrders={false}
        closeReject={false}
        searchValue={searchValue}
        statusSearch={statusSearch}
        isAtiveFilterBtn={!!getData('dataFilterCallCenter')}
      />
      {
        orders && (
          <WrOrders>
            {renderOrders()}
          </WrOrders>
        )
      }
      {
        isOpenModal && (
          <ModalEditor isKitchen={false} />
        )
      }
      <ModalFilter />
      {/* {
        !isRedactionOrder && (
          <ModalProhibition
            onClick={handleCloseProhibition}
          >
            <ModalView>
              <TextModal>Це замовлення вже принято іншим оператором</TextModal>
              <ButtonIcon
                onClick={handleCloseProhibition}
                title='Закрить'
                icon={<CloseIcon />}
              />
            </ModalView>
          </ModalProhibition>
        )
      } */}
      {
        loadPage && (
          <ModalLoad />
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 30px;
`;

const WrOrders = styled.div`
  margin-top: 24px;
`;

const View = styled.div`
  margin-top: 20px;
  margin-bottom: 24px;
  background: #FAFAFA;
  position: sticky;
  top: 104px;
  z-index: 100;
`;

const ModalProhibition = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 2022000;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
`;

const ModalView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TextModal = styled.div`
  text-align: center;
  color: #fff;
  font-size: 18px;
  margin: 20px 0;
`;

export default React.memo(CallcenterPage);
