import * as React from 'react';

import {
  DataGridPremium,
  useKeepGroupedColumnsHidden,
  useGridApiRef,
} from '@mui/x-data-grid-premium';

import { currencyFormatter } from 'utils/helpers/uahCurrencyFormatter';

function Report({ data }, ref) {
  const apiRef = useGridApiRef();
  const [pModel, setPModel] = React.useState({
    pageSize: 1,
    page: 0,
  });

  React.useImperativeHandle(ref, () => {
    return {
      saveExcel: () => {
        apiRef.current.exportDataAsExcel();
      }
    };
}, []);

  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['accounting_day', 'kitchen'],
      },
      pagination: pModel,
      columns: {
        // Other hidden columns
        columnVisibilityModel: { gross: false },
      },
    },
  });

  const columns = [
    {
      headerName: "Обліковий день",
      field: "accounting_day",
      width: 150,
    },
    {
      headerName: "Кухня",
      field: "kitchen",
      width: 200,
    },
    {
      headerName: "Ресторан",
      field: "restaurant",
      width: 200,
    },
    {
      headerName: "Час замовлення",
      field: "delivered_till",
      width: 150,
    },
    {
      headerName: "№ чек",
      field: "id",
      width: 150,
    },

    {
      headerName: "Номер тел. клієнта",
      field: "client_phone",
      width: 100,
    },
    {
      headerName: "№ ПРРО",
      field: "num_fiscal",
      width: 150,
    },
    {
      headerName: "Фіскальний номер",
      field: "check_id",
      width: 150,
    },
    {
      headerName: "Тип оплати",
      field: "payment_types",
      width: 150,
    },
    {
      headerName: "Сума до сплати",
      field: "to_pay",
      width: 150,
      type: "number",
      valueFormatter: ({ value }) => {
        if (value === undefined) return null;
        return currencyFormatter.format(value);
      },
    },
    {
      headerName: "Причина",
      field: "reject_reason",
      width: 200,
    },
  ];
  return (
    <DataGridPremium
      sx={{
        ".MuiDataGrid-aggregationColumnHeaderLabel": {
          display: "none"
        }
      }}

      slotProps={{
        pagination: {
          labelRowsPerPage: 'Рядків на сторінці',
          labelDisplayedRows: ({ from, to, count }) => `${from}-${to} з ${count} елементів`,
        },
      }}

      density='compact'
      disableDensitySelector={true}

      pagination
      paginationModel={pModel}
      onPaginationModelChange={setPModel}
      pageSizeOptions={[1, 3, 7]}

      rows={data}
      columns={columns}
      apiRef={apiRef}
      rowGroupingColumnMode="multiple"
      groupingColDef={({ fields }) => {
        return { leafField: fields[0] }
      }}

      initialState={initialState}
      defaultGroupingExpansionDepth={4}
    />
  );
}

export default React.forwardRef(Report);
