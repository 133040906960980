import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as ordersActions from "store/actions/orders";
import * as clientsActions from "store/actions/clients";
import * as orderActions from "store/actions/order";

import { default as ClientOrders } from "./ClientOrders";

function mapStateToProps(state) {
    return {
        isOpen: state.view.modalClients.isOpen,
        loadingsModal: state.view.modalClients.loadingsModal,
        extensive: state.clients.extensive,
        orders: state.orders.ordersClient,
        restaurants: state.settings.restaurants,
        view: state.view
    };
}

const mapDispatchToProps = {
    ...viewActions,
    ...ordersActions,
    ...clientsActions,
    ...orderActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientOrders);
