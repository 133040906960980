import api from "../../apiSingleton";

import {
  CHECK_SESSION_SUCCESS,
  LOGOUT,
  UPDATE_JWT,
  UPDATE_SESSION_FAILURE,
  UPDATE_SESSION_SUCCESS,
  LOGOUT_BUTTON
} from "../constants/session";
import * as ROUTES from "../../constants/routes";
import history from "constants/history";
// import { getProducts } from "./products";
import { getSettings } from "./settings";
import { getUser } from "./user";
import { getKitchenShiftStatus } from "./kitchen";

import {
  addNotification
} from './notifications';

const PUBLIC_ROUTES = [ROUTES.LOGIN];

export function login({ phone, password, onSuccess, onError }) {
  return async (dispatch) => {
    const res = await api.session.create({ phone, password });
    const isResponseValid = !!res.access_token;

    if (isResponseValid) {
      localStorage.setItem("jwt", res.access_token);
      api.apiClient.setToken(res.access_token);
      onSuccess();
    } else {
      await dispatch(addNotification({ login: [res.message] }, 'error'));
      onError();
    }
  };
}

export function generateAuthLink({ id, first_name: firstName, last_name: lastName }) {
  return async (dispatch) => {
    const res = await api.session.generateAuthLink(id);
    const isResponseValid = !!res.user_id;

    if (isResponseValid) {
      const sudoToken = btoa(JSON.stringify(res));
      const link = new URL(window.location.origin);

      link.searchParams.set('sudo_token', sudoToken);
      await navigator.clipboard.writeText(link);

      dispatch(addNotification({
        auth: [
          `Посилання для входу в аккаунт користувача ${firstName} ${lastName} скопійоване`
        ]
      }, 'success'));

    } else {
      dispatch(addNotification({ auth: [res.message] }, 'error'));
    }
  }
}

export function temporaryLogin({ token }) {
  return async (dispatch) => {
    const { user_id: id, ...rest } = JSON.parse(atob(token));

    const res = await api.session.temporaryLogin(id, rest);
    const isResponseValid = !!res.token;

    if (isResponseValid) {

      dispatch(addNotification({ auth: ['Успішний тимчасовий вхід!'] }, 'success'));
      return res.token;

    } else {
      dispatch(addNotification({ auth: ['Помилка входу за тимчасовим токеном'] }, 'error'));
    }
  }
}

export function logout() {
  return async (dispatch) => {
    api.apiClient.setToken("");

    dispatch({ type: LOGOUT });

    const pathname = history?.location?.pathname;

    if (!PUBLIC_ROUTES || !PUBLIC_ROUTES?.includes(pathname)) {
      if (history) {
        history.replace(ROUTES.LOGIN);
      }
    }
  };
}

export function logoutButton() {
  return async (dispatch) => {
    localStorage.removeItem("jwt");

    dispatch({ type: LOGOUT_BUTTON });
  };
}

export function checkSession() {
  return async (dispatch) => {

    const params = new URLSearchParams(document.location.search);
    const token = params.get("sudo_token");

    let jwt = localStorage.getItem("jwt");
    if (token)
      jwt = await dispatch(temporaryLogin({ token }));

    if (jwt) {
      api.apiClient.setToken(jwt);

      await dispatch({ type: UPDATE_SESSION_SUCCESS });
      const isAuthorized = await dispatch(getUser());

      if (!isAuthorized)
        throw new Error("Unauthorized.");

      await dispatch(getKitchenShiftStatus());
      await dispatch(getSettings());
      // await dispatch(getProducts());

      await dispatch({
        type: UPDATE_JWT,
        payload: { jwt },
      });
    } else {
      dispatch({ type: UPDATE_SESSION_FAILURE });
    }

    dispatch({ type: CHECK_SESSION_SUCCESS });
  };
}
