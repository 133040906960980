import { isArray, isObject } from "lodash";
import React, { useEffect, useState } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import Select from "components/ui-kit/Select";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import CalendarRange from "components/ui-kit/CalendarRange";
import TableReviewsRatings from "components/ui-kit/Table/TableReviewsRatings";
import TableAnswer from "components/ui-kit/Table/TableAnswer";
import ModalDetalClient from "components/modals/ModalDetalClient";

import { restaurantsMappedLabel } from "utils/mappers/restaurants";

import { tableAnswer } from "constants/tables";
import { ratingBy, rating, review, reviewKeys } from "constants/reviews";

import AddIcon from "@mui/icons-material/Add";
import GradeIcon from '@mui/icons-material/Grade';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from "@mui/icons-material/Close";
import styles from "./../StatisticPage.module.scss";
import { Pagination } from "components/pagination";

const Reviews = ({
    // state
    filter,
    typePage,
    restaurants,
    ratings,
    responses,
    view,
    restoranName,
    extensive,
    // dispatch
    updateReviews,
    updateFilterReviews,
    getRivews,
    downloadExcelReviews,
    getClientExtensiveAndOrder
}) => {
    const [isLoadDownload, setIsLoadDownload] = useState(false);
    const [isOpenModalClien, setIsOpenModalClient] = useState(false);
    const [isFilter, setIsFilter] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const handlerSubmit = (isBtn = false) => {
        updateReviews('restoranName', filter.restaurants.label);

        if (isBtn) {
            getRivews({ page: 1 });
            setCurrentPage(1);
            closeFilter();
        } else {
            getRivews({ page: currentPage });
        }
    }

    const handlePagin = (number) => {
        setCurrentPage(number);
    }

    const handlerDownload = () => {
        downloadExcelReviews(setIsLoadDownload);
    }

    const handlerClick = ({ client_id }) => {
        setIsOpenModalClient(true);
        getClientExtensiveAndOrder(client_id);
    }

    const onCloseModalClient = () => {
        setIsOpenModalClient(false);
    }

    const closeFilter = () => {
        setIsFilter(false);
    }

    useEffect(() => {
        setCurrentPage(1);
    }, [typePage]);

    useEffect(() => {
        handlerSubmit();
    }, [currentPage]);

    return (
        <div className={styles.statisticpage}>
            <div className={styles.contain}>
                <div className={styles.hed}>
                    <div className={styles.title_p}>Ресторан: <span>{restoranName}</span></div>
                    <div className={styles.hed_r}>
                        <ButtonIcon
                            title='Фільтр'
                            onClick={() => setIsFilter(true)}
                        />
                    </div>
                </div>

                {
                    !view?.isLoadPage && (
                        <div className={styles.contain_main}>
                            {
                                typePage == rating && (
                                    isArray(ratings.reviews) && ratings.reviews.map((item, index) => {
                                        return (
                                            <div key={index}>
                                                <div className={`${styles.title_p} ${styles.title_p_n}`}><span>{item.title}</span></div>
                                                <br />
                                                <TableReviewsRatings
                                                    data={item.items}
                                                />
                                                <br />
                                            </div>
                                        )
                                    })
                                )
                            }

                            {
                                typePage == review && (
                                    <TableAnswer
                                        data={responses.reviews}
                                        scheme={tableAnswer}
                                        onClient={handlerClick}
                                    />
                                )
                            }
                        </div>
                    )
                }

                {
                    view?.isLoadPage && (
                        <div className={styles.modal_wr}>
                            <LoadingCircl mins={true} />
                        </div>
                    )
                }

                <Pagination
                    colPage={(typePage == review) ? responses.last_page : ratings.last_page}
                    active={currentPage}
                    onClick={handlePagin}
                />
            </div>

            {
                isFilter && (
                    <div className={`${styles.statisticpage__select}`}>
                        <div className={`${styles.opas} bg_opas`} onClick={closeFilter}></div>
                        <div className={`${styles.statisticpage__select_main}`}>
                            <div className={styles.headers}>
                                <div className={styles.title_white}>Фільтр</div>
                                <div className={styles.btn_close} onClick={closeFilter}>
                                    <CloseIcon style={{ fontSize: 36 }} />
                                </div>
                            </div>
                            <div className={`${styles.slick}`}>
                                <div className={styles.row_two_btn}>
                                    <ButtonIcon
                                        onClick={() => updateReviews('typePage', review)}
                                        title='Відповіді'
                                        icon={<QuestionAnswerIcon />}
                                        positionIcon="left"
                                        isActive={typePage == review}
                                    />
                                    <ButtonIcon
                                        onClick={() => updateReviews('typePage', rating)}
                                        title='Оцінки'
                                        icon={<GradeIcon />}
                                        positionIcon="left"
                                        isActive={typePage == rating}
                                    />
                                </div>

                                <Select
                                    selectOptions={restaurantsMappedLabel(restaurants)}
                                    selectedOption={filter.restaurants}
                                    valueKey='restaurants'
                                    label='Ресторан'
                                    mode={'secondary'}
                                    onChange={({ value }) => updateFilterReviews("restaurants", value)}
                                />

                                {
                                    typePage == rating && (
                                        <>
                                            <Select
                                                selectOptions={ratingBy}
                                                selectedOption={filter.rating}
                                                valueKey='rating'
                                                label='Оцінки по:'
                                                isMulti
                                                mode={'secondary'}
                                                onChange={({ value }) => updateFilterReviews("rating", value)}
                                            />
                                        </>
                                    )
                                }

                                {
                                    typePage == review && (
                                        <>
                                            <Select
                                                selectOptions={reviewKeys}
                                                selectedOption={filter.reviewKeys}
                                                valueKey='reviewKeys'
                                                label='Відповіді:'
                                                isMulti
                                                mode={'secondary'}
                                                onChange={({ value }) => updateFilterReviews("reviewKeys", value)}
                                            />
                                        </>
                                    )
                                }

                                <CalendarRange
                                    title="Період з - по"
                                    valueIn={filter.date}
                                    ranges={filter.date}
                                    isAbs={true}
                                    onChangeCal={(date) => updateFilterReviews("date", [date.selection])}
                                />

                                <div className={`${styles.row_two_btn} ${styles.row_two_btn_marg_top}`}>
                                    <ButtonIcon
                                        onClick={() => handlerSubmit(true)}
                                        title='Задіяти'
                                        icon={<AddIcon />}
                                        positionIcon="left"
                                    />
                                    {
                                        (typePage == rating) && isObject(ratings.reviews) && (Object.keys(ratings.reviews).length > 0) && (
                                            <ButtonIcon
                                                onClick={handlerDownload}
                                                title='Excel'
                                                icon={<FileDownloadIcon />}
                                                positionIcon="left"
                                                colorBg="#3d91ff"
                                                isLoad={isLoadDownload}
                                            />
                                        )
                                    }
                                    {
                                        (typePage == review) && isArray(responses.reviews) && (responses.reviews.length > 0) && (
                                            <ButtonIcon
                                                onClick={handlerDownload}
                                                title='Excel'
                                                icon={<FileDownloadIcon />}
                                                positionIcon="left"
                                                colorBg="#3d91ff"
                                                isLoad={isLoadDownload}
                                            />
                                        )
                                    }
                                </div>

                                {
                                    isLoadDownload && (
                                        <div className="info-text-zvit">Звіт ще формується</div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                )
            }

            <ModalDetalClient
                isOpen={isOpenModalClien}
                isLoad={view.modalClients.loadingsModal}
                data={extensive}
                onCloseModal={onCloseModalClient}
            />
        </div>
    )
}

export default Reviews;