// react
import * as React from "react";

import ModalLayout from "../ModalLayout";
import { useTheme } from "styled-components";
import { ModalContent } from "./modalContent";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

// helpers
import { withLoading } from "utils/helpers/withLoading";

const ModalKitchenDeliveryTime = ({
  // props
  title = "",
  isOpen = false,
  initiatedByUser = false,
  onCloseModal = () => { },
  // redux
  editKitchens = {},
  kitchenCode = "",
  getKitchen = () => { },
  updateKitchen = () => { },
  updateKitchenForm = () => { },
  restaurants = [],
}) => {
  const theme = useTheme();
  const [isLoad, setIsLoad] = React.useState(false);

  React.useEffect(() => {
    if (kitchenCode) {
      withLoading(setIsLoad, getKitchen.bind(this, kitchenCode));
    }
  }, [kitchenCode]);

  return (
    <ModalLayout
      theme={theme}
      modalIsOpen={isOpen}
      closeModal={onCloseModal}
      dopClass="m-kitchen-delivery-time"
    >
      <div className="head-modal-f">
        <div className="head-modal-f__title" style={{ marginTop: "5px", marginBottom: "5px" }}>{title}</div>
      </div>

      <div className="cont-modal-sc" style={{ marginTop: 0 }}>
        {
          !isLoad && (
            <ModalContent
              kitchenCode={kitchenCode}
              editKitchens={editKitchens}
              updateKitchen={updateKitchen}
              updateKitchenForm={updateKitchenForm}
              restaurants={restaurants}
              closeModal={onCloseModal}
              initiatedByUser={initiatedByUser}
            />
          )
        }
        {
          isLoad && (
            <LoadingCircl mins={true} />
          )
        }
      </div>
    </ModalLayout>
  )
}

export default ModalKitchenDeliveryTime;
