import * as React from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Typography } from '@mui/material';

const OverallTable = ({ data }) => {
  const columns = [
    { field: 'label', headerName: 'Attribute', flex: 1, renderHeader: () => <></>, sortable: false },
    { field: 'value', headerName: 'Value', flex: 1, renderHeader: () => <></>, sortable: false },
  ];

  const rows = [
    { id: 1, label: 'Місто:', value: data?.overall?.city },
    { id: 2, label: 'Термінал:', value: data?.overall?.terminal },
    { id: 3, label: 'Касова зміна:', value: data?.overall?.cash_register_shift },
    { id: 4, label: 'Зміна відкрита:', value: data?.overall?.opened_from },
    { id: 5, label: 'By manager (Адмін):', value: data?.overall?.admin },
  ];

  return (
    <React.Fragment>
      <Typography variant='h5'>Загальна інформація</Typography>
      <DataGridPremium
        rows={rows}
        columns={columns}
        autoHeight
        disableSelectionOnClick
        disableColumnFilter // Disable filtering
        disableColumnMenu // Disable column menu (sorting)
        disableRowSelectionOnClick
        disableMultipleRowSelection

        disableChildrenSorting
        disableMultipleColumnsSorting
        columnHeaderHeight={0}

        disableColumnReorder
        disableColumnResize
        disableAggregation
        disableChildrenFiltering

        density='compact'
        hideFooter
      />
    </React.Fragment>
  );
};

export default React.memo(OverallTable);
