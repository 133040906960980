import { connect } from "react-redux";

import { default as StatisticPage } from "./StatisticPage";

function mapStateToProps(state) {
  return {
    pages: state.user.user.pages || [],
    statisticsPages: state.user.user.statistics_pages || [],
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(StatisticPage);
