// react
import { useState, useEffect } from "react";

// lodash get
import { get } from "lodash";

// icons
import KitchenIcon from "@mui/icons-material/Kitchen";
import {
  SetMeal as Sushi,
  LocalPizza as Pizza,
  LocalDining as Other,
} from "@mui/icons-material";

// utils
import { withLoading } from "utils/helpers/withLoading";

const products = [
  { type: 'sushi', name: 'Суші', icon: <Sushi /> },
  { type: 'pizza', name: 'Піца', icon: <Pizza /> },
  { type: 'other', name: 'Інше', icon: <Other /> },
];

// mui
import { Typography, Divider, Stack, Box } from "@mui/material";

// components
import { LoadingModal } from "components/loadingOrder/loading";

// classes & classnames
import classnames from "classnames";
import styles from "../../../../components/menuGeneral/scss/menuPage.module.scss";
import classes from "./KitchenDeliveryTimes.module.scss";

const Shipment = ({ time, type: shipmentType, restaurant, label }) => (
  <Stack direction='column' spacing={1}>
    <Typography variant='subtitle2'>
      {label} хв.
    </Typography>
    {products.map(({ name, type: productType, icon }) => (
      <Stack key={productType} direction='row' display='flex' justifyContent='space-between'>
        <Box display='flex'>
          {icon}
          <Typography>{name}</Typography>
        </Box>
        <Typography>{get(time, `${restaurant}.${shipmentType}.${productType}`) ?? '-'}</Typography>
      </Stack>
    ))}
  </Stack>
);

export const KitchenDeliveryTimes = ({
  kitchens,
  restaurants,
  deliveryTime = {},
  getKitchen = () => { },
}) => {
  const [isLoad, setIsLoad] = useState(false);
  const [selectedKitchen, setSelectedKitchen] = useState(() => {
    const [first] = kitchens;
    return first;
  });

  useEffect(() => {
    if (selectedKitchen) {
      withLoading(setIsLoad, getKitchen.bind(this, selectedKitchen.code));
    }
  }, [selectedKitchen]);

  return (
    <div className={classes.container}>
      <div className={classnames(styles.wr_menu, classes.kitchens)}>
        <ul className={styles.ul}>
          {
            kitchens.map((kitchen) => {
              const active = kitchen.code === selectedKitchen?.code;
              return (
                <li key={kitchen.code} className={styles.li}>
                  <a onClick={setSelectedKitchen.bind(this, kitchen)} className={classnames(styles.a, { [styles.active]: active })}>
                    <span className={styles.icon}>
                      <KitchenIcon />
                    </span>
                    {kitchen.title}
                  </a>
                </li>
              )
            })
          }
        </ul>
      </div>

      <div className={classes.information}>
        {isLoad && <LoadingModal />}
        {restaurants.map((restaurant) => (
          <div key={restaurant.code} className={classes.item}>
            <Typography>
              {restaurant.name}
            </Typography>

            <Shipment
              restaurant={restaurant.code}
              time={deliveryTime}
              type='delivery'
              label='Доставка'
            />
            <Divider orientation='vertical' />
            <Shipment
              restaurant={restaurant.code}
              time={deliveryTime}
              type='pickup'
              label='Самовивіз'
            />
          </div>
        ))}
      </div>

    </div>
  )
}

