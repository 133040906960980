import { isArray } from "lodash"

export const convertObjMenu = (array) => {
    let newArray = [];

    if (isArray(array)) {
        array.map((el, index) => {
            let value = '';

            if (index != 0)
                value = el.value;

            newArray.push({ title: el.label, path: value });
        });
    }

    return newArray;
}