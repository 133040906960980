import { useEffect, useCallback, useState, useRef } from "react";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import ModalLayout from "../ModalLayout";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import CalendarModal from "components/ui-kit/CalendarModal";
import { ModalContent } from "./modalContent";

import { dataFormat } from "utils/helpers/date";
import { getDailyReportsForKitchen } from "store/actions/kitchen";

import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';

const ModalKitchenCounter = ({
    // props
    title = '',
    isOpen = false,
    onCloseModal = () => { },
}) => {
    const [filterDate, setFilterDate] = useState(new Date());
    const [data, setData] = useState(null);
    const [isLoad, setIsLoad] = useState(false);
    const { user: { user } } = useSelector(state => state);
    const componentRef = useRef(undefined);
    const theme = useTheme();
    const handlerCloseModal = () => onCloseModal();
    const handlePring = useReactToPrint({ content: () => componentRef.current });
    
    const handlerSubmitDate = useCallback(async () => {
        let data = await getDailyReportsForKitchen({ kitchen_code: user.kitchen_code, date: dataFormat(filterDate) }, setIsLoad);
        setData(data);
    }, [setData, setIsLoad, user, filterDate]);

    useEffect(() => {
        handlerSubmitDate();
    }, []);

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={handlerCloseModal}
            dopClass='m-kitchen-counter'
        >
            <div className="head-modal-f">
                <div className="head-modal-f__title">{title}</div>
                <div className="filter-model">
                    <CalendarModal
                        title="Дата"
                        isStyle={true}
                        valueIn={filterDate}
                        onChangeCal={(d) => setFilterDate(d)}
                        isAbs={true}
                        isBtn={true}
                        isTitle={false}
                        handlerSubmit={handlerSubmitDate}
                        disabled={isLoad}
                    />
                    <ButtonIcon
                        onClick={handlePring}
                        icon={<LocalPrintshopIcon />}
                        colorBg="blue"
                        disabled={isLoad}
                    />
                </div>
            </div>

            <div className="cont-modal-sc">
                {
                    !isLoad && (
                        <ModalContent data={data} ref={componentRef} />
                    )
                }
                {
                    isLoad && (
                        <LoadingCircl mins={true} />
                    )
                }
            </div>
        </ModalLayout>
    )
}

export default ModalKitchenCounter;