import Base from "./Base.js";

export default class PromosAPI extends Base {
    getPromoactions() {
        return this.apiClient.get(`promoactions`);
    }

    getSwappableBonusProducts(restaurant, clientId, citySyncId) {
        return this.apiClient.get(`stocks/get-swappable-bonus-products`, {
            restaurant,
            client_id: clientId,
            city_sync_id: citySyncId,
        });
    }
}
