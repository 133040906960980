/*  camelcase */
import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import { default as ToggleKitchenShiftButton } from "./ToggleKitchenShiftButton";

const mapDispatchToProps = { 
    ...viewActions, // Тут знаходиться дія відкриття модалки для відкриття/закриття робочої зміни
};

export default connect(null, mapDispatchToProps)(ToggleKitchenShiftButton);
