export const menuCook = {
    common: {
        title: "Готується",
        path: "",
    },
};

export const menuCookOther = {
    prepared: {
        title: "Приготовлено",
        path: "/prepared",
    },
    pending: {
        title: 'Попередні',
        path: "/pending",
    },
    other: {
        title: 'Інші',
        path: "/other",
    },
};
