import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import * as settingsProvingsActions from 'store/actions/settingsProvings';
import { default as ProvingGroundPage } from './ProvingGround';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        view,
        settingsProvings: state.settingsProvings,
        restaurants: settings.restaurants,
        kitchens: [...[{ title: '-', code: "-", status: 1 }], ...settings.kitchens],
        poligones: [...[{ title: '-', value: "-", status: 1 }], ...settings.poligones],
    };
}

const mapDispatchToProps = {
    ...settingsActions,
    ...settingsProvingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvingGroundPage);
