import { isArray } from "lodash"

export const searchPage = ({ pages = null, page = null }) => {
    if (isArray(pages) && !!page)
        for (let i = 0; i < pages.length; i++) {
            if (pages[i].value.includes(page))
                return true;
        }

    return false;
}

export const deleteMatchingPagesStatistic = ({ pagesAll = null, pages = null, typePage = null }) => {
    if (isArray(pagesAll) && isArray(pages)) {
        let copyArrayPagesAll = [...pagesAll];
        let pageStatistic = [];

        for (let i = 0; i < pages.length; i++) {
            if (pages[i].value.includes(typePage))
                pageStatistic = [...pages[i].pages];
        }

        if (copyArrayPagesAll.length > 0) {
            for (let k = 0; k < pageStatistic.length; k++) {
                for (let i = 0; i < copyArrayPagesAll.length; i++) {
                    if (copyArrayPagesAll[i].value == pageStatistic[k].value) {
                        copyArrayPagesAll.splice(i, 1);
                    }
                }
            }

            return copyArrayPagesAll;
        }
    }

    return [];
}