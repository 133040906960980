import { connect } from "react-redux";

import * as actionCooks from "store/actions/cook";
import * as actionNotifications from "store/actions/notifications";
import * as actionOrders from "store/actions/orders";

import { default as PreparedPage } from "./Other";

function mapStateToProps(state) {
    const { cook } = state;

    return {
        cook
    };
}

const mapDispatchToProps = {
    ...actionCooks,
    ...actionOrders,
    ...actionNotifications,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreparedPage);
