import { isUndefined, omitBy } from "lodash";

const API_URL_GOOGLE = "https://maps.google.com/maps/api/geocode/json";
const API_URL_VISICOM = "https://api.visicom.ua/data-api/5.0/uk/geocode.json";
// const API_URL_GEO = "https://geo.smaki.com.ua/search";

// const API_KEY_GOOGLE = process.env.GOOGLE_API_KEY || "AIzaSyBhOUWjNeq8iqMT71E8xM61WYNFN-Ol4jE";
const API_KEY_VISICOM = process.env.API_KEY_VISICOM || "05ff6c14eed208ebeeea540f2866d4a2";

// получения адресса по кординатам
export async function getGeodataAddress(lat, lng, bounds) {
  const params = "?" + new URLSearchParams(omitBy({
    key: API_KEY_VISICOM,
    categories: "adr_address",
    near: `${lng},${lat}`,
    l: 1,
    contains: bounds,
  }, isUndefined)).toString();

  return await fetch(API_URL_VISICOM + params)
    .then((res) => res.json())
    .then((data) => {
      if (data?.type === "Feature") {
        const {
          street,
          settlement: city,
          name: house_number,
        } = data.properties;
        return { house_number, street, city }
      }
      return null;
    })
    .catch((e) => console.error(e));
}

// получения кординат по адрессу
export async function getGeodataCoordinates(city, settlement, street, house, bounds) {
  const getVisicomFeatureLatLng = (feature) => {
    const { geo_centroid: geoCentroid } = feature;
    const [lng, lat] = geoCentroid.coordinates;
    return { lng, lat };
  }

  const params = "?" + new URLSearchParams(omitBy({
    key: API_KEY_VISICOM,
    text: `${settlement?.name_ua || city?.name_ua || ''} ${street} ${house}`,
    l: 1,
    contains: bounds,
  }, isUndefined)).toString();

  return await fetch(API_URL_VISICOM + params)
    .then((res) => res.json())
    .then((data) => {
      switch (data.type) {
        case "Feature":
          return getVisicomFeatureLatLng(data);

        case "FeatureCollection":
          const [bestMatch] = data.features;
          return getVisicomFeatureLatLng(bestMatch);

        default:
          console.log("Unknown data type", data.type);
          return null;
      }
    })
    .catch((e) => console.error(e));
}
