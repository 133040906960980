import api from "../../apiSingleton";
import { UPDATE_USER } from "../constants/user";

export function getUser() {
  return async (dispatch) => {
    const res = await api.user.get();
    const authorized = !res.errors;
    if (authorized) {
      await dispatch({
        type: UPDATE_USER,
        payload: { user: { ...res.data } },
      });
    }
    return authorized;
  };
}
