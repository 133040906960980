import { MapContainer, TileLayer } from "react-leaflet";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import Radio from "components/ui-kit/Radio";
import {
    MapMarkerLeaflet,
    MapPolygonLeaflet,
    MapCenterObserver,
} from "components/mapes";
import ButtonIcon from "components/ui-kit/ButtonIcon";

import { citySearch } from "utils/mappers/cities";
import { getActiveKitchenCityReturnPromis, saveActivePolygonKitchen } from "store/actions/kitchen"

import { RADIO_SCHEME_IGNORE_STOCK } from "constants/schemes";

import styles from "../StatisticPage.module.scss";
import iconHome from "assets/icons/marker-home.svg";
import SaveIcon from "@mui/icons-material/Save";

const defaultStyle = {
    height: "100%",
    width: "100%",
    borderRadius: 10,
};

const defaultOptions = {
    panControl: true,
    zoomControl: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: false,
    clicableIcons: false,
    KeyboardShortcuts: false,
    scrollwheel: true,
    disablDoubleClickZoom: true,
    fullscreenControll: false,
    styles: [
        {
            featureType: 'all',
            elementType: 'geometry',
            stylers: [{ color: '#f0f0f0' }]
        }
    ]
}

const parseCoordinates = (cities) => {
    let cityDefault = null;

    cityDefault = citySearch('lviv', cities);

    if (!!cityDefault.latitude && !!cityDefault.longitude) {

        return {
            lat: +cityDefault.latitude,
            lng: +cityDefault.longitude,
        };
    }

    return {
        lat: 49.84287298912712,
        lng: 24.027133941126525,
    };
};

export const ControlKitchen = () => {
    const [poligons, setPoligons] = useState([]);
    const [dataKitchen, setDataKitchen] = useState([]);
    const [loadBtn, setLoadBtn] = useState(false);
    const dispatch = useDispatch();

    const {
        settings: { cities },
    } = useSelector((store) => store);

    const updateStatus = (index, data, value) => {
        let newArr = JSON.parse(JSON.stringify(dataKitchen))

        newArr.splice(index, 1, {
            kitchen_id: data.kitchen_id,
            accepts_orders: +value,
            code: data.code
        });

        setDataKitchen(newArr)
    }

    const handleSubmit = () => {
        setLoadBtn(true);
        dispatch(saveActivePolygonKitchen(
            dataKitchen.map(item => ({ kitchen_id: item.kitchen_id, accepts_orders: +item.accepts_orders })),
            dataKitchen.map(item => ({ kitchen_code: item.code, status: item.accepts_orders })))
        ).then(() => {
            setLoadBtn(false)
        });
    }

    useEffect(async () => {
        let data = await dispatch(getActiveKitchenCityReturnPromis({
            city_sync_id: 'lviv',
            datetime: '',
            restaurants_code: 'smaki'
        }));

        setDataKitchen(data.map(item => ({ kitchen_id: item.id, accepts_orders: item.accepts_orders, code: item.code })));
        setPoligons(data);
    }, []);

    const mapCenter = parseCoordinates(cities);
    return (
        <>
            <div className={styles.title_col}>Контроль полігона</div>
            <div className={styles.row_map_and}>
                <div className={styles.items_radio}>
                    {
                        dataKitchen.map((item, index) => (
                            <Radio
                                colors={"#000"}
                                items={RADIO_SCHEME_IGNORE_STOCK}
                                label={item.code}
                                valueKey="status"
                                value={item.accepts_orders}
                                onChange={(e) => updateStatus(index, item, e.value.value)}
                                key={item.code}
                            />
                        ))
                    }
                </div>
                <div className={`${styles.bor_l}`}>
                    <div className={`${styles.mape} `}>
                        {/* <GoogleMap
                            mapContainerStyle={defaultStyle}
                            center={parseCoordinates(cities)}
                            zoom={10.4}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                            options={defaultOptions}
                        > */}
                        <MapContainer
                            style={defaultStyle}
                            center={mapCenter}
                            zoom={10.4}
                            options={defaultOptions}
                        >
                            <TileLayer
                                attribution={false}
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />

                            <MapCenterObserver center={mapCenter} />
                            <MapMarkerLeaflet
                                key={'markerOne'}
                                position={parseCoordinates(cities)}
                            />

                            {
                                poligons.map((itemKitchen, indexKitchen) => {
                                    let statusPilugon = !!Number(dataKitchen.find(el => (el.kitchen_id == itemKitchen.id)).accepts_orders)

                                    return (
                                        <div key={`wr_${indexKitchen}`}>
                                            <MapMarkerLeaflet
                                                key={`marger_${indexKitchen}`}
                                                position={{
                                                    lat: +itemKitchen.latitude,
                                                    lng: +itemKitchen.longitude,
                                                }}
                                                icone={iconHome}
                                                clickable={false}
                                                label={itemKitchen.title}
                                            />

                                            {
                                                itemKitchen?.polygons?.map((itemPolygons, index) => (
                                                    <MapPolygonLeaflet
                                                        key={`mapPolygon_${index}`}
                                                        pathColor={statusPilugon ? itemPolygons?.paths_color : "#818181"}
                                                        positions={itemPolygons?.paths}
                                                        title={itemKitchen?.title}
                                                        time={itemPolygons?.delivery_time}
                                                        startTime={itemPolygons?.start_time}
                                                        finishTime={itemPolygons?.finish_time}
                                                        marker={parseCoordinates(cities)}
                                                    />
                                                ))
                                            }
                                        </div>
                                    )
                                })
                            }
                        </MapContainer>
                    </div>
                    <div className={styles.btn_bot_mape}>
                        <ButtonIcon
                            onClick={handleSubmit}
                            title='Оновити'
                            colorBg="green"
                            icon={<SaveIcon />}
                            isLoad={loadBtn}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}