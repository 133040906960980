import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";
import { debounce, isArray, isObject } from "lodash";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import { TextArea } from "components/ui-kit/inputs/TextArea";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import { TimeInput } from "components/ui-kit/inputs/TimeInput";
import { DatePicker } from "components/ui-kit/inputs/DatePicker";
import Radio from "components/ui-kit/Radio";
import Street from "../elements/Street";
import { ClientSearch } from "../elements/ClientSearch";
import TabMap, { searchForCoorinates } from "./TabMap";

import { citiesMapped, citySearch } from "utils/mappers/cities";
import { kitchensMapped, kitchenSearchByCodeBoolean } from "utils/mappers/kitchens";
import { restaurantsMapped } from "utils/mappers/restaurants";

import { statuses } from "constants/statuses";
import api from "../../../../apiSingleton";

import {
  updateOrderData,
  resetOrderAddress,
  updateOrderAddress,
  getKitchenAddress,
  getActiveKitchenCity,
  getStreets,
  cleaning,
  auditStocks,
  addOrderItem,
  removeOrderItem,
  addOrderPayments,
  removeOrderPayments,
  getOrderDeliveryTime,
  setOrderDeliveryTime,
} from "store/actions/order";

import { getCityPolygonBounds } from "store/actions/settingsProvings";

import SaveIcon from "@mui/icons-material/Save";

import {
  RADIO_SCHEME_SOON,
  RADIO_SCHEME_PICKUP_DELIVERY,
  BLACK_LIST,
} from "constants/schemes";

export const TabDelivery = ({
  isEdit,
  isEditDeliver,
  isOpenOrder,
  openAndCloseModalSaveAddress = () => { },
  openOrderAll,
  isMap = true,
  isKitchen,
  isSelfPickup,
  isDeliveryOnlyPicup,
}) => {
  const dispatch = useDispatch();
  const [startOne, setStartOne] = useState(false);
  const [isOpenModalSteet, setIsOpenModalStreet] = useState(false);
  const order = useSelector((store) => store.order.data);

  const {
    user: {
      user
    },
    order: {
      data: {
        address,
        kitchen_code,
        status,
        // id,
      },
      searchStreet,
    },
    settings: {
      cities,
      kitchens,
      restaurants
    },
    kitchens: { kitchensListCity },
    view: {
      modalStreet
    }
  } = useSelector((store) => store);

  const isAdmin = user.role_name == 'system_administrator';
  const cityPolygonBounds = useSelector(({ settingsProvings }) => {
    return settingsProvings.cityPolygonBounds[order.address.city_sync_id];
  });

  const kitchensFilter = (isOne = true) => {
    let kitchensFilterCcity = [];
    let news = [];

    // если есть кухни получены по запросу /kitchens/get-active-by-city если нету то выбираем со списка всех кухонь
    if (isArray(kitchensListCity) && (kitchensListCity?.length > 0)) {
      kitchensFilterCcity = kitchensListCity;
    } else {
      kitchensFilterCcity = kitchens?.filter((item) => {
        item?.city_sync_id === address?.city_sync_id;
      });
    }

    // эту проверку потом можно будет убрать она на тот случай если у списка кухонь нету поля с названием города
    if (kitchensFilterCcity.length == 0) {
      kitchensFilterCcity = kitchens;
    }

    if (isOne) {
      news = [
        ...[{ title: "-", value: "" }]
      ]
    }

    news = [
      ...news, ...kitchensMapped([...kitchensFilterCcity], { isPickup: true, isKitchenuser: !isEditDeliver, kitchensUser: user.kitchens, isOnlyPicup: isDeliveryOnlyPicup }),
    ];

    return news;
  };

  const settlementList = () => {
    let list = citySearch(address?.city_sync_id, cities)?.settlements;

    list = isArray(list) ? list : [];

    return citiesMapped([...[{ name_ua: "-", sync_id: "", status: 1 }], ...list]);
  };

  const debouncedGetAddressKitchen = useCallback(debounce((address, status) => {
    if (status === "draft") {
      if (
        address.city_sync_id ||
        (address.city_sync_id && address.settlement_id) ||
        (address.city_sync_id && address.street && address.house_number) ||
        (address.city_sync_id && address.settlement_id && address.street && address.house_number)
      ) {
        if (address.city_sync_id && address.house_number && address.street) {
          dispatch(getKitchenAddress({
            city_sync_id: address.city_sync_id,
            settlement_sync_id: address.settlement_id,
            street: address.street,
            street_type: 1, //тип улицы(улица, пров, ...)
            house_number: address.house_number
          }));
        }
      }
    }
  }, 450), []);

  const debouncedGetStreets = useCallback(debounce((address, cities, startOne) => {
    const currentCity = citySearch(address.city_sync_id, cities);
    const nameCity = currentCity?.name_ua || '';
    const settlelements = currentCity?.settlements
    const currentSettlelements = citySearch(address.settlement_id, settlelements);
    const currentSettlelementsName = isObject(currentSettlelements) ? currentSettlelements.name_ua : '';

    if (startOne && (address.street.length > 2)) {
      dispatch(getStreets({
        city: nameCity,
        settlelement: currentSettlelementsName,
        street: address.street,
      }));
    }
  }, 250), []);

  const debouncedCoordinateSearch = useCallback(debounce(searchForCoorinates, 450), []);
  const debouncedOrderDeliveryTime = useCallback(debounce(() => dispatch(getOrderDeliveryTime()), 450), []);

  useEffect(() => {
    debouncedGetStreets(order.address, cities, startOne);
  }, [
    cities,
    startOne,
    order.address.street,
    order.address.city_sync_id,
    order.address.settlement_id,
  ]);

  useEffect(() => {
    if (!startOne) {
      setStartOne(true);
    } else {
      debouncedGetAddressKitchen(order.address, order.status);
      debouncedCoordinateSearch(order.address, cities, cityPolygonBounds, dispatch);
    }
  }, [
    cities,
    debouncedCoordinateSearch,
    debouncedGetAddressKitchen,
    cityPolygonBounds,
    order.status,
    order.address.city_sync_id,
    order.address.settlement_id,
    order.address.street,
    order.address.house_number,
  ]);

  useEffect(() => {
    if (!!order.restaurant?.length && order.restaurant != '-')
      dispatch(
        getActiveKitchenCity({
          city_sync_id: order.address.city_sync_id,
          datetime: order.delivered_till ? order.delivered_till : '',
          restaurants_code: order?.restaurant ? order.restaurant : ''
        })
      );
  }, [order.address.city_sync_id, order.delivered_till, order.restaurant]);

  useEffect(() => { // get city polygon bounds
    dispatch(getCityPolygonBounds(order.address.city_sync_id));
  }, [order.address.city_sync_id]);

  useEffect(() => {
    const shouldGetDeliveryTime = order.items.length &&
      order.client.phone &&
      order.kitchen_code &&
      order.restaurant != '-';

    if (shouldGetDeliveryTime) {
      debouncedOrderDeliveryTime();
    }
  }, [order]);

  return (
    <Wrapper>
      <DivWr>
        <Dropdown
          title="Ресторан"
          type="text"
          onChange={(e) => {
            dispatch(updateOrderData("restaurant", e.target.value));
            dispatch(updateOrderData("items", []));
            dispatch(updateOrderData("payments", []));
            dispatch(setOrderDeliveryTime("-"));
          }}
          value={order.restaurant}
          list={restaurantsMapped([...[{ name: '-', code: null }], ...restaurants])}
          disabled={!isEdit}
        />

        <ClientSearch
          isOpenOrder={isOpenOrder}
          openOrderAll={openOrderAll}
          status={status}
          isEdit={isEdit}
        />

        {
          !isSelfPickup && (
            <>
              <Row>
                <Dropdown
                  title="Місто"
                  width="49.5%"
                  onChange={(e) => {
                    dispatch(updateOrderAddress("city_sync_id", e.target.value, true));
                    dispatch(updateOrderAddress("street", ""));
                    dispatch(updateOrderAddress("settlement_id", ""));
                    dispatch(updateOrderAddress("house_number", ""))
                  }}
                  value={address.city_sync_id}
                  list={citiesMapped(cities)}
                />
                <Dropdown
                  title="Передмістя"
                  width="49%"
                  onChange={(e) => {
                    dispatch(updateOrderAddress("settlement_id", e.target.value, true));
                    dispatch(updateOrderAddress("street", ""));
                  }}
                  value={address.settlement_id}
                  list={settlementList()}
                />
              </Row>
              <Street
                address={address}
                updateOrderAddress={updateOrderAddress}
                isOpenModal={isOpenModalSteet}
                setOpenModal={setIsOpenModalStreet}
                dataStreets={searchStreet}
                cleaning={cleaning}
                isLoad={modalStreet.loadingsModal}
              />
              <Row>
                <TextInput
                  width="15%"
                  title="Будинок"
                  type="text"
                  onChange={(e) => {
                    dispatch(updateOrderAddress("house_number", e.target.value));
                  }}
                  value={address.house_number}
                />
                <TextInput
                  width="15%"
                  title="Під'їзд"
                  type="text"
                  onChange={(e) =>
                    dispatch(updateOrderAddress("entrance", e.target.value))
                  }
                  value={address.entrance}
                />
                <TextInput
                  width="15%"
                  title="Поверх"
                  type="text"
                  onChange={(e) =>
                    dispatch(updateOrderAddress("floor", e.target.value))
                  }
                  value={address.floor}
                />
                <TextInput
                  width="15%"
                  title="Корпус"
                  type="text"
                  onChange={(e) =>
                    dispatch(updateOrderAddress("corps", e.target.value))
                  }
                  value={address.corps}
                />
                <TextInput
                  width="15%"
                  title="Квартира"
                  type="text"
                  onChange={(e) => {
                    dispatch(updateOrderAddress("apartment", e.target.value));
                  }}
                  value={address.apartment}
                />
                <TextInput
                  width="15%"
                  title="Домофон"
                  type="text"
                  onChange={(e) =>
                    dispatch(updateOrderAddress("intercom", e.target.value))
                  }
                  value={address.intercom}
                />
              </Row>
            </>
          )
        }
        <Subtext>
          lat: {address.latitude} lng: {address.longitude}
        </Subtext>
        {
          (statuses.indexOf(status) == -1) && (
            <RowBtnSave>
              <ButtonIcon
                onClick={openAndCloseModalSaveAddress}
                title={'Змінити адрес доставки'}
                colorBg="green"
                icon={<SaveIcon />}
              />
            </RowBtnSave>
          )
        }
        {
          !isSelfPickup && (
            <TextArea
              title="Коментар кур'єру"
              onChange={(e) =>
                dispatch(updateOrderAddress("comment_to_courier", e.target.value))
              }
              value={order?.address?.comment_to_courier || ''}
              disabled={!isEdit}
              autoSize
            />
          )
        }

        <TextArea
          title="Коментар адміністратору"
          onChange={(e) =>
            dispatch(
              updateOrderAddress("comment_to_administrator", e.target.value)
            )
          }
          value={order?.address?.comment_to_administrator || ''}
          disabled={!isAdmin}
          autoSize
        />

        <Dropdown
          title="Кухня"
          onChange={(e) =>
            dispatch(updateOrderData("kitchen_code", e.target.value))
          }
          value={order.kitchen_code}
          list={kitchensFilter()}
          disabled={!isEdit}
        />
        {
          kitchenSearchByCodeBoolean(kitchen_code, kitchens) && (
            <Wr>
              <Radio
                colors={"#ffffff"}
                items={RADIO_SCHEME_PICKUP_DELIVERY}
                label="Забрати / доставити"
                valueKey="status"
                value={order?.pickup}
                disabled={!isEdit}
                onChange={(e) => {
                  dispatch(updateOrderData("pickup", e.value.value));
                  dispatch(resetOrderAddress());
                  dispatch(auditStocks({}));
                }}
              />
            </Wr>
          )
        }
        <Wr>
          <Radio
            colors={"#ffffff"}
            items={RADIO_SCHEME_SOON}
            label="Час доставки"
            valueKey="status"
            value={order?.type}
            disabled={!isEdit}
            onChange={(e) => {
              dispatch(updateOrderData("type", e.value.value));

              if (e.value.value == "soon") {
                dispatch(updateOrderData("delivered_till", null));
              } else if (e.value.value == "requested_time") {
                dispatch(
                  updateOrderData(
                    "delivered_till",
                    moment().add(2, "hours").format("YYYY-MM-DD HH:mm:ss")
                  )
                );
              }
            }}
          />
        </Wr>
        {order?.type.includes(RADIO_SCHEME_SOON[1].value) && (
          <Row>
            <DatePicker
              title="День"
              width="49%"
              min={moment()}
              value={moment(new Date(order.delivered_till.split(' ')[0]))}
              onChange={(e) => {
                let curr = e.format("YYYY-MM-DD");

                if (order?.delivered_till && order?.delivered_till !== undefined && order?.delivered_till !== null) {
                  let h = order.delivered_till.split(' ')[1];
                  let d = `${curr} ${h}`;

                  dispatch(
                    updateOrderData(
                      "delivered_till",
                      d
                    )
                  );
                }
              }}
            />
            <TimeInput
              width="49%"
              title="Час"
              value={moment(order?.delivered_till)}
              onChange={(e) => {
                let selectedTime = e.format("HH:mm:ss");

                if (order?.delivered_till && order?.delivered_till !== undefined && order?.delivered_till !== null) {
                  let da = order.delivered_till.split(' ')[0];
                  let d = `${da} ${selectedTime}`;

                  dispatch(
                    updateOrderData(
                      "delivered_till",
                      d
                    )
                  );
                }
              }}
            />
          </Row>
        )}
        <br />
        <Radio
          colors={"#ffffff"}
          items={BLACK_LIST}
          label="Технічне замовлення"
          valueKey="technical"
          value={order?.technical}
          disabled={!order?.restaurant || order.restaurant === "-"}
          onChange={async (e) => {
            const isTechnical = Boolean(+e.value.value);
            console.log('Технічне замовлення', isTechnical);

            if (isTechnical) {
              api.products.getList({ search: 'Технічне Замовлення', restaurant: order.restaurant }).then(res => {
                const [techProduct] = res.data;
                if (!techProduct) return;

                dispatch(addOrderPayments('tex-zamovlenya', 0.1, 0)); // add cash payment type
                dispatch(addOrderItem({
                  id: techProduct.id,
                  article: techProduct.article,
                  description: techProduct.description_ua,
                  title: techProduct.title_ua,
                  weight: techProduct.weight,
                  weight_type: techProduct.weight_type,
                  image: techProduct.image,
                  prices: techProduct.prices,
                  price: 0.1,
                  sum: 0.1,
                  // addition_groups: techProduct.addition_groups,
                  // cutlery_sets: techProduct.category.cutlery_sets,
                  product: { additions: [] },
                  __TECHNICAL: true,
                }, false));
              });
            } else {
              // remove all technical items
              const technicalItems = order?.items.filter(i => i.__TECHNICAL) ?? [];
              for (const item of technicalItems) {
                dispatch(removeOrderItem(item, false));
              }
              dispatch(removeOrderPayments('tex-zamovlenya'));
            }

            dispatch(updateOrderData("technical", e.value.value));
          }}
        />
      </DivWr>
      {
        !!isMap && (
          <TabMap />
        )
      }
    </Wrapper>
  );
};

const RowBtnSave = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #393C49;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 10px;
  z-index: 10;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Subtext = styled.div`
  margin-top: 10px;
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  border-bottom: ${(p) => p.theme.inputBorder};
  color: ${(p) => p.theme.lightAccentColor};
`;

const Wr = styled.div`
  color: #fff;
  margin-top: 10px;
`;

const DivWr = styled.div`
  // pointer-events: ${(p) => p.isEdit ? 'auto' : 'none'};
`;


