import { getCurrentHoursAndMinutes } from "./dataTiemAdminKitchen";

export const isCloseKitchen = (kitchenCode = '', kitchenList = [], setState = () => { }) => {
    if (!(kitchenCode?.length > 0) || !(kitchenList?.length > 0))
        setState(undefined);

    let kithcen = kitchenList.find(el => (el.code == kitchenCode));

    if (!kithcen?.code)
        setState(undefined);

    let { time_close } = kithcen;
    let arrTiems = time_close.split(":");
    let hoursClose = arrTiems[0];
    let minutesClose = arrTiems[1];

    return setInterval(() => {
        function start() {
            let { hours, minutes } = getCurrentHoursAndMinutes();

            if (Number(hoursClose) > Number(hours)) {
                setState(undefined);
            }

            if (Number(hoursClose) == Number(hours)) {
                console.log("hoursClose = hours: ", Number(hoursClose) == Number(hours));

                console.log("Number(minutesClose) > Number(minutes): ", Number(minutesClose), Number(minutes));

                if (Number(minutesClose) > Number(minutes)) {
                    setState(undefined);
                }

                if (Number(minutesClose) == Number(minutes)) {
                    setState({ isBtnPrint: true, isAutoPrint: true });
                }

                if (Number(minutesClose) < Number(minutes)) {
                    setState({ isBtnPrint: true });
                }
            }

            if (Number(hoursClose) < Number(hours)) {
                setState({ isBtnPrint: true });
            }
        }

        start();
    }, 50000);
}