import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import React, { useEffect, useState } from 'react';
import styled, { useTheme } from "styled-components";

import ModalLayout from "../ModalLayout";
import ModalDelete from "../ModalDelete";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import Radio from "components/ui-kit/Radio";
import Select from "components/ui-kit/Select";
import { TextArea } from "components/ui-kit/inputs/TextArea";

import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from '@mui/icons-material/Delete';

import { validateReason } from 'utils/validation';
import { kitchensMapped } from "utils/mappers/kitchens";
import { productMappedSelectItem, productSearchSelectItem, productFilterId } from "utils/mappers/products";

import { mapperCoveInValue, mapperCoveInlabel } from "utils/mappers/other";

const ModalReason = ({
    // props
    data,
    dataObj = {},
    order,
    isOpen = false,
    isLoad = false,
    updateFormReason,
    handleReject,
    handleCloseModal,
    kitchens,
}) => {
    const theme = useTheme();

    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const toggleConfirmationModalOpen = () => {
        setConfirmationModalOpen(open => !open);
    }

    const [errors, setErrors] = useState({
        reason: '',
        comment_reason: '',
        write_off_products: '',
        guilty: '',
    });

    const {
        reason,
        comment_reason,
        write_off_products,
        scrapped_products,
        guilty,
        comment,
        kitchen_code,
        kitchen_transferring,
    } = data;

    const handlerCloseModal = () => {
        handleCloseModal();
    }

    const handleSubmit = () => {
        validateReason({
            data: data,
            onSuccess: () => setConfirmationModalOpen(true),
            onError: validationErrors => setErrors(validationErrors),
        });
    }

    const rejectOrder = () => {
        handleReject();
        setErrors({
            reason: '',
            comment_reason: '',
            write_off_products: '',
            guilty: '',
        });

        setConfirmationModalOpen(false);
    }

    useEffect(() => {
        updateFormReason("kitchen_code", order.kitchen_code);
    }, [isOpen]);

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={handlerCloseModal}
        >
            <ModalDelete
                title={'Ви ДIЙСНО хочете скасувати замовлення?'}
                isOpen={confirmationModalOpen}
                funDelete={rejectOrder}
                updateModalDelete={toggleConfirmationModalOpen}
            />
            <Title>Скасування</Title>
            <WR_DROP>
                <Dropdown
                    title={'Причина'}
                    isBlack={true}
                    width="100%"
                    onChange={(e) => updateFormReason("reason", e.target.value)}
                    value={reason}
                    list={mapperCoveInValue([...[{ title: "-", value: "" }], ...dataObj?.reasons || []])}
                    isLight={true}
                    fontSize={'14px'}
                    error={errors.reason}
                />
            </WR_DROP>
            {
                ('transferring_to_another_kitchen' == reason) && (
                    <WR_DROP>
                        <Dropdown
                            title="Кухня на яку перемістити"
                            onChange={(e) => updateFormReason("kitchen_transferring", e.target.value)}
                            value={kitchen_transferring}
                            isLight={true}
                            isBlack={true}
                            fontSize={'14px'}
                            list={kitchensMapped(kitchens)}
                        />
                    </WR_DROP>
                )
            }
            <WR_DROP>
                <TextInput
                    title={`Коментар "Не обов'язкове"`}
                    type="Коментарій до приини"
                    value={comment_reason}
                    isStyle={true}
                    error={errors.comment_reason}
                    onChange={(e) => updateFormReason("comment_reason", e.target.value)}
                />
            </WR_DROP>
            <WR_DROP>
                <Radio
                    colors={"#000"}
                    items={mapperCoveInlabel(dataObj?.write_off_products || [])}
                    label='Списання продуктів ?'
                    valueKey='write_off_products'
                    value={write_off_products}
                    onChange={(e) => updateFormReason("write_off_products", e.value.value)}
                />
            </WR_DROP>
            {
                (write_off_products == "part") && (
                    <WR_DROP>
                        <Select
                            selectOptions={productMappedSelectItem(order.items, 'write_off_products')}
                            selectedOption={productSearchSelectItem(scrapped_products, order.items)}
                            valueKey='dish'
                            label='Страва на списання'
                            isMulti
                            mode={'primary'}
                            onChange={({ value }) => updateFormReason("scrapped_products", productFilterId(value))}
                        />
                    </WR_DROP>
                )
            }

            {
                (write_off_products !== 'Ні') && (
                    <WR_DROP>
                        <Radio
                            colors={"#000"}
                            items={mapperCoveInlabel(dataObj?.guilty || [])}
                            label='За кого списання ?'
                            valueKey='write_off_products'
                            value={guilty}
                            onChange={(e) => updateFormReason("guilty", e.value.value)}
                        />
                    </WR_DROP>
                )
            }

            <WR_DROP>
                <TextArea
                    isStyle={true}
                    title='Коментар'
                    value={comment}
                    onChange={(e) => updateFormReason("comment", e.target.value)}
                />
            </WR_DROP>

            <RowBtn>
                {
                    !isLoad && (
                        <>
                            <Item>
                                <ButtonIcon
                                    onClick={handleSubmit}
                                    title={'Підтвердити'}
                                    colorBg="green"
                                    icon={<DeleteIcon />}
                                />
                            </Item>
                            <Item>
                                <ButtonIcon
                                    onClick={handlerCloseModal}
                                    title='Відміна'
                                    colorBg="red"
                                    icon={<CancelIcon />}
                                />
                            </Item>
                        </>
                    )
                }
            </RowBtn>

            {
                isLoad && (
                    <LoadingCircl mins={true} />
                )
            }
        </ModalLayout>
    )
}

const WR_DROP = styled.div`
    margin: 3px 0;
    padding-top: 4px;
`;

const Title = styled.div`
    text-align: center;
    font-size: 18px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    font-weight: 700;
`;

const Text = styled.div`
    text-align: center;
    font-size: 16px;
    margin-top: 15px;
    padding: 0 20px;
`;

const RowBtn = styled.div`
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
`;

const Item = styled.div`
    padding: 0 10px;
`;


export default ModalReason;