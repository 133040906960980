import { isArray } from "lodash";

import {
    removeCutleryAll,
    removeCutleryItem
} from "store/actions/order";

export const valueCulter = (cutleryId, сutlery) => {
    let quantity = 0;

    if (isArray(сutlery) && сutlery.length > 0) {
        for (let i = 0; i < сutlery.length; i++) {
            if (сutlery[i].id == cutleryId) {
                quantity = сutlery[i].quantity;
            }
        }
    }

    return quantity;
}

export const removeCutleryCard = async (dataOrder, dispatch) => {
    const { items, cutlery_sets } = dataOrder;

    if ((isArray(cutlery_sets) && cutlery_sets?.length > 0) && (isArray(items) && items.length > 0)) {
        for (let i = 0; i < cutlery_sets?.length; i++) {
            try {
                const bloSt = async () => {
                    let itemMyCutlery = cutlery_sets[i];
                    let index = i;

                    block_one: {
                        for (let k = 0; k < items.length; k++) {
                            let itemCutlerys = items[k].cutlery_sets;

                            for (let p = 0; p < itemCutlerys.length; p++) {
                                let itemCutlery = itemCutlerys[p];

                                if (itemMyCutlery.cutlery_set_id == itemCutlery.id) {
                                    index = null;
                                    break block_one;
                                }
                            }
                        }
                    }

                    if (index !== null) {
                        await dispatch(removeCutleryItem(index));
                    }
                };

                await bloSt();
            } catch (error) {
                console.log("removeCutleryCard: ", error);
            }
        }
    } else {
        await dispatch(removeCutleryAll());
    }
}