import React from "react";
import styled, { useTheme } from "styled-components";

// data = {title: 'TITLE', value: 'VALUE'}

export const Dropdown = ({
  title = '',
  value,
  onChange,
  width,
  height,
  list = [],
  isLight = false,
  fontSize = '',
  isBlack = false,
  error = "",
  disabled = false,
}) => {
  const theme = useTheme();

  const renderOptions = () => {
    return list?.map((item, index) => {
      return (
        <option key={index} value={item?.value} disabled={item?.disabled}>
          {item?.title}
        </option>
      );
    });
  };

  return (
    <Wrapper theme={theme} width={width} height={height} disabled={disabled}>
      {
        !!title && <Title isBlack={isBlack}>{title}</Title>
      }
      <Field
        height={height}
        error={error.length > 0}
        value={value || ''}
        isLight={isLight}
        onChange={onChange}
        fontSize={fontSize}
        disabled={disabled}
      >
        {renderOptions()}
      </Field>
      {
        error.length > 0 && (
          <Error>{error}</Error>
        )
      }
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-radius: 3px;
  color: ${(p) => p.theme.secondaryColor};
  font-size: 14px;
  width: ${(p) => p.width ?? "100%"};
  pointer-events: ${(p) => p.disabled ? "none" : "auto"};
  opacity: ${(p) => p.disabled ? "0.6" : "1"};
`;

const Error = styled.p`
  color: red;
  padding-top: 4px;
`;

const Title = styled.p`
  border-radius: 3px;
  color: ${(p) => p.isBlack ? "#000" : p.theme.secondaryColor};
  font-size: 14px;
`;

const Field = styled.select`
  width: 100%;
  height: ${(p) => p.height};
  margin-top: 4px;
  padding: 10px;
  background-color: ${(p) => p.isLight ? p.theme.secondaryColor : p.theme.backgroundLight};
  border: ${(p) => p.error ? p.theme.inputBorderError : (p.isLight ? p.theme.inputBorderDE : p.theme.inputBorder)};
  border-radius: 8px;
  color: ${(p) => p.isLight ? p.theme.color25 : p.theme.secondaryColor};
  font-size: ${(p) => p.fontSize ? p.fontSize : '18px'};
  font-family: Rubik;
  outline: none;
  &:active,
  &:focus {
    border: 1px solid ${(p) => p.isLight ? p.theme.accentColor : p.theme.accentColor};
  }
`;
