import Base from './Base.js';

export default class SessionAPI extends Base {
    create(payload) {
        return this.apiClient.post('login', payload);
    }

    generateAuthLink(id) {
        return this.apiClient.get(`generate-auth-link/${id}`);
    }

    temporaryLogin(id, payload) {
        return this.apiClient.get(`temporary-login/${id}`, payload);
    }
}
