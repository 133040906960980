import { getData } from "utils/helpers/localStorage";

import { rangeConverDate, currentDate } from "utils/helpers/date";

export const getFiltet = () => {
    let params = {};
    let dataFilterParamsLocalStorage = getData('dataFilterCallCenter');

    if (dataFilterParamsLocalStorage?.restaurant) {
        params = { ...params, restaurant: dataFilterParamsLocalStorage?.restaurant };
    }

    if (dataFilterParamsLocalStorage?.city_sync_id) {
        params = { ...params, city_sync_id: dataFilterParamsLocalStorage?.city_sync_id }
    }

    if (dataFilterParamsLocalStorage?.kitchen_code) {
        params = { ...params, kitchen_code: dataFilterParamsLocalStorage.kitchen_code }
    }

    if (dataFilterParamsLocalStorage?.dateCal) {
        let { date_from, date_to } = rangeConverDate(dataFilterParamsLocalStorage.dateCal);

        params = { ...params, date_from, date_to };
    
    }
    // } else {
    //     let date_from = currentDate();
    //     let date_to = currentDate();
    //     params = { ...params, date_from, date_to };
    // }

    return params;
}

export const getFiltetUsers = (external = {}) => {
    let params = {};

    if (Object.keys(external).length > 0) {
        params = { ...external };
    } else {
        let dataFilterParamsLocalStorage = getData('dataFilterUsers');

        if (dataFilterParamsLocalStorage) {
            params = dataFilterParamsLocalStorage;
        }
    }

    return params;
}

export const getFiltetUsersSearch = (searchForm = {}, external = {}) => {
    let params = { ...searchForm };

    if (Object.keys(external).length > 0) {
        params = external;
    }

    return params;
}


export const getFilQueryParams = (query, noPageNoStatus = false) => {
    let params = {};

    if (Object.keys(query).length > 0) {
        Object.keys(query).map((item) => {
            if (!noPageNoStatus) {
                params[item] = query[item];
            }

            if (!noPageNoStatus) {
                if (item != "page")
                    params[item] = query[item];
            }
        })
    }

    return params;
}