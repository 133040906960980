import moment from "moment";
import { isArray } from "lodash";

export const mappedDistances = (arr) => {
  if (isArray(arr)) {
    return arr.map((item) => {
      const distance =
        ((item.sum_delivery_distance + item.sum_return_distance)) / 1000;

      return {
        id: item.user_id,
        name: `${item.first_name} ${item.last_name}`,
        orders_within_city: item.orders_within_city,
        orders_outside_city: item.orders_outside_city,
        medium_distance: (distance / (item.orders_within_city + item.orders_outside_city)).toFixed(2),
        distance: distance.toFixed(2),
        deliveries: item?.deliveries || [],
        kitchen_title: item?.kitchen_title,
      };
    });
  } else {
    return {};
  }
}

export const mappedDeliveries = (arr) =>
  arr.map((item) => {
    // const distance =
    //   ((item.return_distance + item.delivery_distance) * 1.2) / 1000;
    return {
      id: item.id,
      delivered: moment(item.updated_at).format("Y-MM-DD HH:mm"),
      delivery_orders: item.delivery_orders,
      distance: item.distance.toFixed(2),
      kitchen: item.kitchen
    };
  });

export const mappedOrder = (obj) =>
  Object.keys(obj).map((key) => {
    let item = obj[key];

    return ({
      kitchen: item.title,
      new: item.new,
      cooking: item.cooking,
      preparing: item.preparing,
      delivered: item.for_delivery,
    })
  });

export const mappedSelf = (arr) => {
  if (isArray(arr)) {
    return arr.map((item) => {

      return {
        id: item.id,
        name: `${item.user.first_name} ${item.user.last_name}`,
        position: item?.role_title || "",
        orders: item.order_count,
        sum: item.sum,
      };
    });
  } else {
    return {};
  }
}

