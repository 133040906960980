import { isArray } from "lodash";
import { useEffect, useMemo, useState } from "react";

import Select from "components/ui-kit/Select";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import Radio from "components/ui-kit/Radio";

import { kitchensMapped } from "utils/mappers/kitchens";
import { restaurantsMappedLabelId, searchRestaurantByIdArr } from "utils/mappers/restaurants";
import { hour, getMinute, getHour, minute } from "utils/helpers/time";

import { hours, minutes, deliveryTime } from "constants/watch";
import { RADIO_SCHEME } from "constants/schemes";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from "@mui/icons-material/Save";
import styles from "./../../scss/settings.module.scss";

const ProvingGround = ({
    restaurants,
    kitchens,
    poligones,
    settingsProvings,
    addBlockRestorant,
    addBlockKitchen,
    deleteSectionRestorant,
    deleteSectionKitnech,
    updateRestorans,
    updateFieldKitchens,
    createProvings,
    getProvings,
    updateFieldSection
}) => {
    // const [activePolygonList, setActivePolygonList] = useState([]);
    const [isSaved, setIsSaved] = useState(false);

    const {
        provingsAll,
        loadPageAll,
        loadSave
    } = settingsProvings;

    const activePolygonList = useMemo(() => {
        /*
        provingsAll.forEach(element => {
            setActivePolygonList(element.polygons.map((itemKit) => {
                return itemKit.polygon_name;
            }));
        });
        */

        // console.log('poligones', poligones);
        // console.log('provingsAll', provingsAll);

        return provingsAll.map(({ polygons }) => {
            const polygonNames = polygons?.map(p => p.polygon_name) || []; // selected polygon names inside section
            return poligones.filter(function ({ title }) {
                return !polygonNames.includes(title); // if selected, do not show the polygon in A SECTION where it is selected
            });
        });
    }, [provingsAll, poligones]);

    const updateRestorant = (indexSection, value) => {
        let ids = value.map(item => item.value);
        updateRestorans(indexSection, ids);
    }

    const updateFieldKitchen = ({ indexSection, indexKitchen, nameField, value }) => {
        updateFieldKitchens({ indexSection, indexKitchen, nameField, value });
    }

    const updateFieldPolygon = ({ indexSection, indexKitchen, value }) => {
        updateFieldKitchens({ indexSection, indexKitchen, nameField: 'polygon_name', value });
    }

    const handleSubmit = () => {
        createProvings(setIsSaved)
    };

    useEffect(() => {
        getProvings();
    }, []);

    return (
        <div className={`${styles.d_max}`}>
            <br />
            {
                (!loadPageAll || !loadSave) ? (
                    (isArray(provingsAll) && provingsAll.length === 0) ? (
                        <div className={styles.text_info}>Пусто... </div>
                    ) : (
                        <div>
                            {
                                provingsAll.map((itemRow, index) => (
                                    <div className={styles.roving_restorant} key={index}>
                                        <div className={styles.d_fle_spase_and_cent}>
                                            <div className={styles.rowe_secr}>
                                                <div className={`${styles.title_in}`}>Секція {index + 1}</div>
                                                <div>|</div>
                                                <div className={`${styles.row_radio}`}>
                                                    <Radio
                                                        colors={"#000"}
                                                        items={RADIO_SCHEME}
                                                        label="Активність секції ?"
                                                        valueKey="status"
                                                        value={!!itemRow?.status ? 1 : 0}
                                                        onChange={(e) => updateFieldSection(index, 'status', +e.value.value)}
                                                    />
                                                </div>
                                            </div>
                                            <ButtonIcon
                                                title="Видалити"
                                                onClick={() => deleteSectionRestorant(index)}
                                                icon={<CloseIcon />}
                                                positionIcon="left"
                                                colorBg="red"
                                                isMin={true}
                                                isModal={true}
                                            />
                                        </div>
                                        <br />
                                        <div className={`${styles.wr_drop} ${styles.wr_drop_bot_bor}`}>
                                            <Select
                                                selectOptions={restaurantsMappedLabelId(restaurants)}
                                                selectedOption={searchRestaurantByIdArr(itemRow.restaurants, restaurants)}
                                                valueKey='restaurants'
                                                label='Ресторан'
                                                isMulti
                                                mode={'primary'}
                                                onChange={({ value }) => updateRestorant(index, value)}
                                            />
                                        </div>
                                        <div className={`${styles.wr_settings_proving}`}>
                                            <div className={`${styles.grid_row_proving}`}>
                                                <div>№/ID</div>
                                                <div>Кухня</div>
                                                <div>Полігон</div>
                                                <div>Год.роб(від) - хв.роб(від)</div>
                                                <div>Год.роб(закр) - хв.роб(закр)</div>
                                                <div>Мін.зам(грн)</div>
                                                <div>Час доставки</div>
                                                <div></div>
                                            </div>
                                            <div>
                                                {
                                                    itemRow.polygons.map((itemPolig, indexPolig) => (
                                                        <div className={`${styles.grid_row_proving} ${styles.grid_row_proving_border}`} key={indexPolig}>
                                                            <div className={`${styles.title_item_table_proving} ${styles.item_table_proving_border_right}`}>{indexPolig + 1}</div>
                                                            <div>
                                                                <Dropdown
                                                                    isBlack={true}
                                                                    width="100%"
                                                                    onChange={(e) => updateFieldKitchen({ indexSection: index, indexKitchen: indexPolig, nameField: 'kitchen_id', value: e.target.value })}
                                                                    value={itemPolig.kitchen_id}
                                                                    list={kitchensMapped(kitchens, { key: 'id' })}
                                                                    isLight={true}
                                                                    fontSize={'14px'}
                                                                />
                                                            </div>
                                                            <div className={styles.col_itemSelect}>
                                                                <TextInput
                                                                    isTitle={false}
                                                                    value={itemPolig.polygon_name}
                                                                    isStyle={true}
                                                                    stylePadTop='0'
                                                                    styleMarg='0'
                                                                    disabled={true}
                                                                    error={isSaved && !itemPolig.path?.length ? 'Відсутній полігон' : ""}
                                                                />
                                                                <Dropdown
                                                                    isBlack={true}
                                                                    width="100%"
                                                                    onChange={(e) => updateFieldPolygon({ indexSection: index, indexKitchen: indexPolig, value: e.target.value })}
                                                                    value={itemPolig.polygon_name}
                                                                    list={activePolygonList[index]}
                                                                    isLight={true}
                                                                    fontSize={'14px'}
                                                                />
                                                            </div>
                                                            <div>
                                                                <div className={`${styles.row_form_gride}`}>
                                                                    <div>
                                                                        <Dropdown
                                                                            isBlack={true}
                                                                            width="100%"
                                                                            onChange={(e) => updateFieldKitchen({ indexSection: index, indexKitchen: indexPolig, nameField: 'start_time', value: hour(e.target.value, itemPolig.start_time) })}
                                                                            value={getHour(itemPolig.start_time)}
                                                                            list={hours}
                                                                            isLight={true}
                                                                            fontSize={'14px'}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <Dropdown
                                                                            isBlack={true}
                                                                            width="100%"
                                                                            onChange={(e) => updateFieldKitchen({ indexSection: index, indexKitchen: indexPolig, nameField: 'start_time', value: minute(e.target.value, itemPolig.start_time) })}
                                                                            value={getMinute(itemPolig.start_time)}
                                                                            list={minutes}
                                                                            isLight={true}
                                                                            fontSize={'14px'}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <div className={`${styles.row_form_gride}`}>
                                                                    <div>
                                                                        <Dropdown
                                                                            isBlack={true}
                                                                            width="100%"
                                                                            onChange={(e) => updateFieldKitchen({ indexSection: index, indexKitchen: indexPolig, nameField: 'finish_time', value: hour(e.target.value, itemPolig.finish_time) })}
                                                                            value={getHour(itemPolig.finish_time)}
                                                                            list={hours}
                                                                            isLight={true}
                                                                            fontSize={'14px'}
                                                                        />
                                                                    </div>
                                                                    <div>
                                                                        <Dropdown
                                                                            isBlack={true}
                                                                            width="100%"
                                                                            onChange={(e) => updateFieldKitchen({ indexSection: index, indexKitchen: indexPolig, nameField: 'finish_time', value: minute(e.target.value, itemPolig.finish_time) })}
                                                                            value={getMinute(itemPolig.finish_time)}
                                                                            list={minutes}
                                                                            isLight={true}
                                                                            fontSize={'14px'}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <TextInput
                                                                    type="number"
                                                                    isTitle={false}
                                                                    value={itemPolig.min_price}
                                                                    isStyle={true}
                                                                    stylePadTop='0'
                                                                    styleMarg='0'
                                                                    onChange={(e) => updateFieldKitchen({ indexSection: index, indexKitchen: indexPolig, nameField: 'min_price', value: e.target.value })}
                                                                />
                                                            </div>
                                                            <div>
                                                                <Dropdown
                                                                    isBlack={true}
                                                                    width="100%"
                                                                    onChange={(e) => updateFieldKitchen({ indexSection: index, indexKitchen: indexPolig, nameField: 'delivery_time', value: e.target.value })}
                                                                    value={itemPolig.delivery_time}
                                                                    list={deliveryTime}
                                                                    isLight={true}
                                                                    fontSize={'14px'}
                                                                />
                                                            </div>
                                                            <div className={styles.delete_btn_row}>
                                                                {
                                                                    itemRow.polygons.length > 1 && (
                                                                        <ButtonIcon
                                                                            onClick={() => deleteSectionKitnech(index, indexPolig)}
                                                                            icon={<CloseIcon />}
                                                                            positionIcon="left"
                                                                            colorBg="red"
                                                                            isMin={true}
                                                                            isModal={true}
                                                                        />
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    ))
                                                }

                                            </div>
                                        </div>
                                        <div className={styles.row_btn_pol}>
                                            <ButtonIcon
                                                onClick={() => addBlockKitchen(index)}
                                                title='Додати'
                                                icon={<AddIcon />}
                                                positionIcon="left"
                                                isMin={true}
                                            />
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    )
                ) : (
                    <LoadingCircl />
                )
            }

            <div className={styles.row_btn_pol_left}>
                <ButtonIcon
                    onClick={addBlockRestorant}
                    title='Додати'
                    icon={<AddIcon />}
                    positionIcon="left"
                    isMin={true}
                />
            </div>

            <div className={styles.row_btn}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmit}
                        title={'Зберегти'}
                        colorBg="green"
                        icon={<SaveIcon />}
                        isLoad={loadSave}
                    />
                </div>
            </div>
        </div>
    )
}

export default ProvingGround;