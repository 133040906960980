import PropTypes from "prop-types";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";

import AddIcon from "@mui/icons-material/Add";
import styles from './../headers.module.scss';

import { citiesMapped } from "utils/mappers/cities";

export const HeaderKitchen = ({
    title = '',
    cities = [],
    activeCity = "",
    handleCreate = () => { },
    handlerCities = () => { },
}) => {
    return (
        <div className={styles.wr}>
            <div className={styles.left}>
                {
                    (title.length > 0) && (
                        <div className={styles.title}>{title}</div>
                    )
                }
            </div>
            <div className={styles.right}>
                <div className={styles.wr_item}>
                    <Dropdown
                        width="200px"
                        onChange={(e) => handlerCities(e.target.value)}
                        value={activeCity}
                        list={citiesMapped(cities)}
                        isLight={true}
                        fontSize={'14px'}
                    />
                </div>
                <ButtonIcon
                    onClick={handleCreate}
                    title='Додати кухню'
                    icon={<AddIcon />}
                    positionIcon="left"
                />
            </div>
        </div>
    )
}

HeaderKitchen.propTypes = {
    title: PropTypes.string,
    handleCreate: PropTypes.func,
    cities: PropTypes.array,
    handlerCities: PropTypes.func,
    activeCity: PropTypes.string,
};

export default HeaderKitchen;
