import * as React from "react";

import {
  DataGridPremium,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';

import { currencyFormatter } from "utils/helpers/uahCurrencyFormatter";

export default function CouriersReport({ data, apiRef, isReportsPage = false }) {
    const [pModel, setPModel] = React.useState({
      pageSize: 1,
      page: 0,
    });

    const groupingModel = ['accounting_day', 'courier', 'payment_type'];
    if (isReportsPage) {
        groupingModel.splice(2, 0, 'restaurant', 'kitchen');
    }
  
    const initialState = useKeepGroupedColumnsHidden({
      apiRef,
      initialState: {
        rowGrouping: {
          model: groupingModel,
        },
        aggregation: {
            model: {
              payment_sum: 'sum',
            },
        },
        pagination: pModel,
        columns: {
          // Other hidden columns
          columnVisibilityModel: { gross: false },
        },
      },
    });

    const columns = [
        { 
          headerName: "Обліковий день", 
          field: "accounting_day",
          width: 200,
        },
        { 
          headerName: "Кур'єр", 
          field: "courier",
          width: 200,
        },
        {
          headerName: "Тип оплати",
          field: "payment_type",
          width: 100,
        },
        {
          headerName: "Замовлення №",
          field: "order_id",
          width: 100,
        },
        { 
          headerName: "Сума", 
          field: "payment_sum",
          type: "number",
          width: 150,
            valueFormatter: ({ value }) => {
              if (value === undefined) return null;
              return currencyFormatter.format(value);
          },
        },
    ];

    if (isReportsPage) {
        columns.splice(2, 0,
            { 
                headerName: "Ресторан", 
                field: "restaurant",
                width: 200,
            },
            { 
                headerName: "Кухня", 
                field: "kitchen",
                width: 200,
            },
        );
    }

    return (
      <DataGridPremium
        sx={{
          ".MuiDataGrid-aggregationColumnHeaderLabel": {
          display: "none"
          }
        }}
  
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Рядків на сторінці',
            labelDisplayedRows: ({ from, to, count }) => `${from}-${to} з ${count} елементів`,
          },
        }}
    
        density='compact'
        disableDensitySelector={true}
  
        pagination
        paginationModel={pModel}
        onPaginationModelChange={setPModel}
        pageSizeOptions={[1, 3, 7]}
  
        rows={data}
        columns={columns}
        apiRef={apiRef}
        rowGroupingColumnMode="multiple"
        initialState={initialState}
        defaultGroupingExpansionDepth={2}
      />
    );
}