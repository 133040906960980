export const UPDATE_STATISTIC = 'UPDATE_STATISTIC';
export const UPDATE_DELIVERIES = 'UPDATE_DELIVERIES';
export const UPDATE_DELIVERY_ROUTE = 'UPDATE_DELIVERY_ROUTE';
export const SET_DELIVERY_ROUTE_LOADING = 'SET_DELIVERY_ROUTE_LOADING';
export const CLEAR_DELIVERY_ROUTE = 'CLEAR_DELIVERY_ROUTE';
export const GET_STATIC_KITCHENS = 'GET_STATIC_KITCHENS';
export const UPDATE_FILTER = 'UPDATE_FILTER';
export const UPDATE_DELIVERIES_CLEAR = "UPDATE_DELIVERIES_CLEAR";
export const GET_STATISTIC_ORDERS = "GET_STATISTIC_ORDERS";
export const UPDATE_FILTER_SELF = "UPDATE_FILTER_SELF";
export const GET_STATISTIC_SELF = "GET_STATISTIC_SELF";
export const UPDATE_LOAD_GET_ORDERS_STATISTIC = "UPDATE_LOAD_GET_ORDERS_STATISTIC";
export const UPDATE_STAT_REVIEWS = "UPDATE_STAT_REVIEWS";
export const UPDATE_STAT_REVIEWS_FILTER = "UPDATE_STAT_REVIEWS_FILTER";
export const GET_STATE_REVIEWS_LIST = "GET_STATE_REVIEWS_LIST";
export const GET_STATE_RATING_LIST = "GET_STATE_RATING_LIST";
export const UPDATE_FILTER_COMPLAINTS = "UPDATE_FILTER_COMPLAINTS";
export const GET_STATISTIC_COMPLAINTS = "GET_STATISTIC_COMPLAINTS";