import api from "../../apiSingleton";

import { isArray } from 'lodash';
import { addNotification } from './notifications';

import {
  GET_SETTINGS_PROVINGS,
  GET_CITY_POLYGON_BOUNDS,
  ADD_SETTINGS_PROVINGS_BLOCK_RESTOURANT,
  ADD_SETTINGS_PROVINGS_BLOCK_KITCHEN,
  DELETE_SETTINGS_PROVINGS_BLOCK_RESTOURANT,
  DELETE_SETTINGS_PROVINGS_BLOCK_KITCHEN,
  UPDATE_SETTINGS_PROVINGS_RESTOURAN,
  UPDATE_SETTINGS_PROVINGS_FIELD,
  UPDATE_SETTINGS_PROVING_SAVE_LOAD,
  UPDATE_SETTINGS_PROVING_GET_LOAD,
  UPDATE_SETTINGS_PROVINGS_FIELD_SECTION
} from "../constants/settingsProvings";

export function addBlockRestorant() {
  return {
    type: ADD_SETTINGS_PROVINGS_BLOCK_RESTOURANT,
  };
}

export function addBlockKitchen(index) {
  return {
    type: ADD_SETTINGS_PROVINGS_BLOCK_KITCHEN,
    payload: index
  };
}

export function deleteSectionRestorant(index) {
  return {
    type: DELETE_SETTINGS_PROVINGS_BLOCK_RESTOURANT,
    payload: index
  };
}

export function deleteSectionKitnech(indexRes, indexKitche) {
  return {
    type: DELETE_SETTINGS_PROVINGS_BLOCK_KITCHEN,
    payload: { indexRes, indexKitche }
  };
}

export function updateRestorans(indexRes, value) {
  return {
    type: UPDATE_SETTINGS_PROVINGS_RESTOURAN,
    payload: { indexRes, value }
  };
}

export function updateFieldSection(indexSection, nameField, value) {
  return {
    type: UPDATE_SETTINGS_PROVINGS_FIELD_SECTION,
    payload: { indexSection, nameField, value }
  };
}

export function updateLoadSave(status = true) {
  return {
    type: UPDATE_SETTINGS_PROVING_SAVE_LOAD,
    payload: status
  };
}

export function updateLoadGET(status = true) {
  return {
    type: UPDATE_SETTINGS_PROVING_GET_LOAD,
    payload: status
  };
}

export function createProvings(setIsSaved = () => { }) {
  return async (dispatch, state) => {
    let { settings: { poligones }, settingsProvings: { provingsAll } } = state();

    await dispatch(updateLoadSave(true));

    new Promise((resolve, reject) => {
      let isError = false;

      if (!poligones?.length) {
        return reject();
      }

      if (!!provingsAll?.length) {
        provingsAll.forEach((elSection, index) => {
          elSection.polygons.forEach((elKit, indexKit) => {
            if (elKit.polygon_name.length > 0 && poligones.length > 0) {
              let objPol = poligones.find(el => el.title == elKit.polygon_name);

              if (!objPol) {
                isError = true;
                setIsSaved(true);

                dispatch(updateFieldKitchens({ indexSection: index, indexKitchen: indexKit, nameField: 'polygon_name', value: '' }))
              }

              dispatch(updateFieldKitchens({ indexSection: index, indexKitchen: indexKit, nameField: 'path', value: objPol?.polygon || '' }));

            }
          })
        });
      }

      if (isError) return reject();

      resolve();
    }).then(async () => {
      let { settingsProvings: { provingsAll } } = state();

      const res = await api.provings.createProvings(provingsAll);

      if (res?.errors) {
        dispatch(addNotification(res.errors, 'error'));
      } else {
        dispatch(addNotification({ suc: ["Успішно"] }));
        setIsSaved(false);
        dispatch(getProvings());
      }
    })
      .catch((error) => {
        console.error(`Помилка полігонів`, error);
        dispatch(addNotification({ suc: [`Помилка полігонів`] }, 'error'));
      })

    await dispatch(updateLoadSave(false));
  };
}

export function getProvings() {
  return async (dispatch, state) => {
    await dispatch(updateLoadGET(true));
    const res = await api.provings.getProvings();

    dispatch({
      type: GET_SETTINGS_PROVINGS,
      payload: res.data,
    });

    await dispatch(updateLoadGET(false));
  };
}

export function updateFieldKitchens({ indexSection, indexKitchen, nameField, value }) {
  return {
    type: UPDATE_SETTINGS_PROVINGS_FIELD,
    payload: { indexSection, indexKitchen, nameField, value }
  };
}

export function getCityPolygonBounds(city) {
  return async (dispatch, getState) => {
    const {
      settingsProvings: {
        cityPolygonBounds: {
          [city]: boundsForCity,
        },
      },
    } = getState();

    if (!!boundsForCity)
      return;

    const { status: success, polygons: bounds } = await api.provings.getCityPolygons(city);
    if (success) {
      const payload = { city, bounds };
      if (payload.bounds && !isArray(payload.bounds))
        dispatch({ type: GET_CITY_POLYGON_BOUNDS, payload });

    } else {
      dispatch(addNotification({ error: [`Помилка отримання кордона полігонів для міста`] }, 'error'));
    }
  }
}
