import Base from "./Base.js";

export default class KitchenApi extends Base {
  getAddressKitchen(params) {
    return this.apiClient.get("kitchens/find-one-closest-by-customer-address", params);
  }
  getActiveKitchenCity(params) {
    return this.apiClient.get("kitchens/get-active-by-city", params);
  }
  apiGetDataPrintOrderAllKitchen(code) {
    return this.apiClient.get(`kitchens/${code}/z-rep`);
  }
  getDailyReportsForKitchen(params) {
    return this.apiClient.get("daily-reports-for-kitchen", params);
  }
  getShiftStatus() {
    return this.apiClient.get("kitchens/shift-status");
  }
  toggleShiftStatus() {
    return this.apiClient.post("kitchens/toggle-shift-status");
  }
  executeKitchenCashalotCommand(kitchenCode, command) {
    return this.apiClient.get(`kitchens/${kitchenCode}/cashalot/${command}`);
  }
  saveActivePolygonKitchen(data) {
    return this.apiClient.post("kitchens/update-accepts-orders-status", data);
  }
}
