import styled from "styled-components";
import Loader from 'react-loader-spinner';

export const LoadingModal = ({
    isFixed = false,
    text = 'Опрацювання',
}) => {
    return (
        <Wr isFixed={isFixed}>
            <Text>{text}</Text>
            <WrLoad>
                <Loader
                    type='Oval'
                    color={"#fff"}
                    width={'20px'}
                    height={'20px'}
                />
            </WrLoad>
        </Wr>
    )
}

const Wr = styled.div`
    position: ${(p) => p.isFixed ? "fixed" : "absolute"} ;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: rgba(0, 0, 0, 0.7);
    font-size: 20px;
    z-index: 99999;
`;

const Text = styled.div`
`;

const WrLoad = styled.div`
    margin-left: 15px;
    div {
        height: 20px;
        width: 20px;
    }
`;