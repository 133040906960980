import { isArray } from "lodash";
import React from "react";

import { LoadingModal } from "components/loadingOrder/loading";
import { ItemRow } from "./itemRow";
import { TableHead } from "./TableHead";

// mui grid
import { useGridApiRef } from "@mui/x-data-grid-premium";

// report collection by slug
import { reportsBySlug } from "./Collection";

export const ReportsTable = React.forwardRef(({
    data = null,
    activeSelect = null,
}, ref) => {
    const apiRef = useGridApiRef();
    React.useImperativeHandle(ref, () => {
        return {
          saveExcel: () => {
            apiRef.current.exportDataAsExcel();
          }
        };
    }, []);

    const selectedReportKey = activeSelect?.params.code;
    console.debug('Report selected', selectedReportKey);

    const ReportTable = reportsBySlug[activeSelect?.params.code];
    return (
        <React.Suspense fallback={<LoadingModal />}>
            <div className="tables-report">
                {!!ReportTable ? <ReportTable 
                    apiRef={apiRef} 
                    data={data} 
                    isReportsPage 
                /> :
                
                (<><TableHead
                    activeSelect={activeSelect}
                />
                <div className="report-wr-row">
                    <div className="report-table">
                        <ItemRow
                            data={data}
                        />
                        {
                            isArray(data) && data.length == 0 && (
                                <div className="empty-list">Пусто</div>
                            )
                        }
                    </div>
                </div></>)
                }
            </div>
        </React.Suspense>
    )
});
