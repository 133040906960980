import { useRef, useImperativeHandle } from "react";
import { sounds, defaultSound } from "./sounds";

export const Voice = ({ controlPlayer }) => {
  const audioRef = useRef(null);

  useImperativeHandle(controlPlayer, () => ({
    play(sound = defaultSound) {
      audioRef.current.src = sounds[sound] || sounds[defaultSound];
      audioRef.current.play();
    }
  }), []);

  return <audio ref={audioRef} />;
}
