import React, { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import { useLocation } from 'react-router-dom';

import { Header } from "./Header";
import ModalEditor from "components/modals/ModalEditor";
import { LoadingModal } from "components/loadingOrder/loading";
import Pagination from "components/ui-kit/Pagination";
import OrdersList from "./OrdersList";

const ClientOrders = ({
    //state
    extensive,
    view,
    //redux
    openEditorCallcenter,
    updateOrder,
    getClientExtensiveAndOrder,
    getOrdersAllClient,
    orders
}) => {
    const theme = useTheme();
    const location = useLocation();
    const [page, setPage] = useState(1);

    const handleCreateOrder = () => {
        updateOrder();
        openEditorCallcenter();
    };

    useEffect(() => {
        let id = location.pathname.split('/')[2];
        if (id) {
            getClientExtensiveAndOrder(id);
            getOrdersAllClient(id, {
                page,
                per_page: 10,
            });
        }
    }, [page]);

    return (
        <Wrapper theme={theme}>
            <Header
                name={extensive?.name}
                handlerOpenModalAdd={handleCreateOrder}
            />

            <ViewRow>
                {orders.data.length ? (
                    <OrdersList data={orders.data} />
                ) : (<TextNone>Пусто</TextNone>)}

                {view?.isLoadPage && <LoadingModal />}
            </ViewRow>
             
            <Pagination
                onClick={setPage}
                currentPage={page}
                lastPage={orders.meta.last_page}
            />  
            
            <ModalEditor />
        </Wrapper>
    )
}

const Wrapper = styled.div`
`;

const ViewRow = styled.div`
    margin-top: 24px;
`;

const TextNone = styled.div`
    text-align: center;
    color: #9D9D9D;
`;

export default React.memo(ClientOrders);