import { connect } from 'react-redux';
import { default as bugReportsPage } from './BugReports';

import * as settingsActions from "store/actions/settings";

function mapStateToProps(state) {
    const {
        bugReports: {
            selectedBugReport
        }
    } = state.settings;

    return { selectedBugReport };
}

const mapDispatchToProps = {
    ...settingsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(bugReportsPage);
