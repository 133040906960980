import Base from './Base.js';

export default class UsersAPI extends Base {
    get(params) {
        return this.apiClient.get('users', params);
    }

    getUser(id, params) {
        return this.apiClient.get(`users/${id}`, params);
    }

    create(user) {
        return this.apiClient.post('users', user);
    }

    edit(user, id) {
        return this.apiClient.patch(`users/${id}`, user);
    }

    delete(id) {
        return this.apiClient.delete(`users/${id}`);
    }

    resetTheApp(id) {
        return this.apiClient.post(`courier/${id}/reset-status-delivery`);
    }

    deleteSelectUsers(data) {
        return this.apiClient.post('users/multi-delete', data);
    }

    deactivateSelectUsers(data) {
        return this.apiClient.post('users/multi-deactivate', data);
    }
}
