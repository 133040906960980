import { isArray } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import Select from "components/ui-kit/Select";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import ModalDeliveryRoute from "components/modals/ModalDeliveryRoute";

import TableCouriers from "components/ui-kit/Table/TableCouriers";
import { mappedDistances } from "utils/mappers/statistic";
import { statisticKitchen } from "constants/tables";
import CalendarRange from "components/ui-kit/CalendarRange";

import { citiesMapped } from "utils/mappers/cities";
import { kitchenSearchSelect, kitchensMappedSelect } from "utils/mappers/kitchens";
import { isEditCity, isEditOwn, isEditCertain, isEditNone } from "utils/helpers/checks";

import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from "@mui/icons-material/Close";
import styles from "./../StatisticPage.module.scss";

function Couriers({
    //state
    getStatistic,
    statistic,
    kitchens,
    filter,
    cities,
    view,
    user,
    //dispatch
    getStatisticKitchens,
    getDeliveryRoute,
    updateFilter,
    getDeliveries,
    downloadExcel
}) {
    const [isLoadDownload, setIsLoadDownload] = useState(false);
    const [isOneLoad, setIsOneLoad] = useState(false);
    const [isFilter, setIsFilter] = useState(false);

    useEffect(async () => {
        getStatisticKitchens();
    }, [filter.city]);

    useEffect(async () => {
        if (isOneLoad) {
            await startFilter();
        } else {
            setIsOneLoad(true);
        }
    }, [kitchens]);

    useEffect(async () => {
        if (!isEditOwn(user) && !isEditCertain(user)) {
            await handlerSubmit();
        }
    }, []);

    const handleDetail = (item, setLoad, isOpen) => {
        getDeliveries(item.id, setLoad, isOpen);
    }

    const handlerDownload = () => {
        downloadExcel(setIsLoadDownload);
    }

    const startFilter = async () => {
        if (isEditOwn(user)) {
            await updateFilter("kitchens", kitchenSearchSelect(user?.kitchens, kitchens));
        } else if (isEditCertain(user)) {
            await updateFilter("kitchens", kitchenSearchSelect(user?.kitchens_statistic, kitchens));
            await handlerSubmit();
        }
    }

    const arrayIdKitchens = () => {
        if (isEditOwn(user)) {
            return kitchenSearchSelect(user?.kitchens, kitchens);
        } else if (isEditCertain(user)) {
            return kitchenSearchSelect(user?.kitchens_statistic, kitchens);
        }

        return kitchensMappedSelect(kitchens);
    }

    const handlerSubmit = async (isBtn = false) => {
        if (isEditNone(user)) {
            await getStatistic();
        }

        if (isBtn)
            closeFilter();
    }

    const closeFilter = () => {
        setIsFilter(false);
    }

    return (
        <div className={styles.statisticpage}>
            {
                isEditNone(user) && (
                    <>
                        <div className={styles.contain}>
                            <div className={styles.hed}>
                                <div className={styles.title_p}></div>
                                <div className={styles.hed_r}>
                                    <ButtonIcon
                                        title='Фільтр'
                                        onClick={() => setIsFilter(true)}
                                    />
                                </div>
                            </div>
                            {
                                !view?.isLoadPage ? (
                                    isArray(statistic) && (statistic.length > 0) ? (
                                        <div className={styles.contain_main}>
                                            <TableCouriers
                                                onClick={handleDetail}
                                                onClickDelivery={getDeliveryRoute}
                                                data={mappedDistances(statistic)}
                                                scheme={statisticKitchen}
                                                isStictyhead={true}
                                            />
                                        </div>
                                    ) : (
                                        <div className={styles.text_info}>Пусто... оберіть потрібні параметри і натисніть кнопку "Задіяти"</div>
                                    )
                                ) : (
                                    <LoadingCircl />
                                )
                            }
                        </div>

                        <ModalDeliveryRoute />

                        {
                            isFilter && (
                                <div className={`${styles.statisticpage__select}`}>
                                    <div className={`${styles.opas} bg_opas`} onClick={closeFilter}></div>
                                    <div className={`${styles.statisticpage__select_main}`}>
                                        <div className={styles.headers}>
                                            <div className={styles.title_white}>Фільтр</div>
                                            <div className={styles.btn_close} onClick={closeFilter}>
                                                <CloseIcon style={{ fontSize: 36 }} />
                                            </div>
                                        </div>
                                        <div className={`${styles.slick}`}>
                                            {
                                                !isEditCity(user) && (
                                                    <div className={styles.wr_head}>
                                                        <Dropdown
                                                            title="Місто"
                                                            isLight={true}
                                                            width="100%"
                                                            onChange={(e) => updateFilter("city", e.target.value)}
                                                            value={filter.city}
                                                            list={citiesMapped(cities)}
                                                            fontSize={'14px'}
                                                        />
                                                    </div>
                                                )
                                            }

                                            <Select
                                                selectOptions={arrayIdKitchens()}
                                                selectedOption={filter.kitchens || []}
                                                valueKey='kitchens'
                                                label='Кухні'
                                                isMulti
                                                mode={'secondary'}
                                                onChange={({ value }) => updateFilter("kitchens", value)}
                                            />

                                            <CalendarRange
                                                title="Період з - по"
                                                valueIn={filter.date}
                                                ranges={filter.date}
                                                isAbs={true}
                                                onChangeCal={(date) => updateFilter("date", [date.selection])}
                                            />

                                            <div className={`${styles.row_two_btn} ${styles.row_two_btn_marg_top}`}>
                                                <ButtonIcon
                                                    onClick={() => handlerSubmit(true)}
                                                    title='Задіяти'
                                                    icon={<AddIcon />}
                                                    positionIcon="left"
                                                />
                                                {
                                                    isArray(statistic) && statistic.length > 0 && (
                                                        <ButtonIcon
                                                            onClick={handlerDownload}
                                                            title='Excel'
                                                            icon={<FileDownloadIcon />}
                                                            positionIcon="left"
                                                            colorBg="#3d91ff"
                                                            isLoad={isLoadDownload}
                                                        />
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </>
                )
            }
        </div>
    );
}

Couriers.propTypes = {
    getStatistic: PropTypes.func.isRequired,
    statistic: PropTypes.array,
    filter: PropTypes.object,
    view: PropTypes.object,
    kitchens: PropTypes.array,
    getStatisticKitchens: PropTypes.func.isRequired,
    getDeliveries: PropTypes.func.isRequired,
    updateFilter: PropTypes.func.isRequired,
    downloadExcel: PropTypes.func.isRequired,
};

Couriers.defaultProps = {
    statistic: {},
    kitchens: [],
};

export default React.memo(Couriers);
