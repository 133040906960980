import api from "../../apiSingleton";

import { filterStatisc } from "utils/helpers/filterApiParams";

import {
  UPDATE_STATISTIC,
  UPDATE_DELIVERIES,
  UPDATE_DELIVERY_ROUTE,
  CLEAR_DELIVERY_ROUTE,
  GET_STATIC_KITCHENS,
  UPDATE_FILTER,
  UPDATE_DELIVERIES_CLEAR,
  GET_STATISTIC_ORDERS,
  UPDATE_FILTER_SELF,
  GET_STATISTIC_SELF,
  UPDATE_LOAD_GET_ORDERS_STATISTIC,
  UPDATE_STAT_REVIEWS,
  UPDATE_STAT_REVIEWS_FILTER,
  GET_STATE_REVIEWS_LIST,
  GET_STATE_RATING_LIST,
  UPDATE_FILTER_COMPLAINTS,
  GET_STATISTIC_COMPLAINTS,
  SET_DELIVERY_ROUTE_LOADING
} from "../constants/statistic";

import {
  updadeLoadPageClients,
} from './view';

import {
  addNotification
} from './notifications';

import { isEditOwn, isEditCertain } from "utils/helpers/checks";
import { filterKitchenCodeOnId } from "utils/helpers/filterApiParams";
import { getRangeDate } from "utils/helpers/date";

import { rating, review } from "constants/reviews";

// --- complaints
export const updateFilterComplaints = (item, data) => {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_FILTER_COMPLAINTS,
      payload: { item, data }
    });
  }
}

export const getComplaints = () => {
  return async (dispatch, state) => {
    const {
      statistic: {
        complaints: {
          filter
        }
      }
    } = state();

    let params = { ...getRangeDate(filter.date) };

    if (filter.processed.value != "all") {
      params.processed = filter.processed.value;
    }

    dispatch(updadeLoadPageClients(true));

    let res = await api.statistic.ComplaintsAll(params);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch({
        type: GET_STATISTIC_COMPLAINTS,
        payload: res.data
      });
    }

    dispatch(updadeLoadPageClients(false));
  };
}

let statusRepeatComplaints = false;
export function downloadExcelComplaints(stateLoda = () => { }) {
  return async (dispatch, state) => {
    const {
      statistic: {
        complaints: {
          filter
        }
      }
    } = state();

    let params = { ...getRangeDate(filter.date) };

    if (filter.processed.value != "all") {
      params.processed = filter.processed.value;
    }

    await stateLoda(true);

    const res = await api.statistic.downloadExcelComplaints(params);

    if (res.status == "Ok") {
      const link = document.createElement('a');

      link.href = res.data.file;
      link.setAttribute(
        'download',
        `complains.xlsx`,
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      await stateLoda(false);
    } else {
      if (!statusRepeatComplaints) {
        setTimeout(() => {
          dispatch(downloadExcelSelf());
          statusRepeatComplaints = true;
        }, 2000);
      } else {
        await stateLoda(false);
      }
    }
  }
}

export function setFromComplaints(obj, setIsLoadBnt, closeModal) {
  return async (dispatch) => {

    await setIsLoadBnt(true);

    const res = await api.statistic.setFromComplaints(obj);

    if (!!res?.data?.id) {
      await dispatch(addNotification({ suc: ["Додано"] }));
      await dispatch(getComplaints());
      closeModal();
    } else {
      await dispatch(addNotification(res?.errors || {}, 'error'));
    }

    await setIsLoadBnt(false);
  };
}

export function setFromComplaintsProcessing(obj, setIsLoadBnt, closeModal) {
  return async (dispatch) => {

    await setIsLoadBnt(true);

    const res = await api.statistic.setFromComplaintsProcessing(obj);

    console.log("setFromComplaintsProcessing: ", res);

    if (!!res?.data?.id) {
      await dispatch(addNotification({ suc: ["Обробленно"] }));
      await dispatch(getComplaints());
      closeModal();
    } else {
      await dispatch(addNotification(res?.errors || {}, 'error'));
    }

    await setIsLoadBnt(false);
  };
}
// end --- complaints


export function updateFilterSelf(item, data) {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_FILTER_SELF,
      payload: { item, data }
    });
  }
}

export function getOrders() {
  return async (dispatch, state) => {
    const { user: { user }, settings: { kitchens } } = state();

    let params = {};

    if (isEditOwn(user)) {
      params = filterKitchenCodeOnId(user.kitchens, kitchens);
    } else if (isEditCertain(user)) {
      params = filterKitchenCodeOnId(user.kitchens_statistic, kitchens);
    }

    await dispatch(updateLoadGetOrdersStatistic(true));

    const { data } = await api.statistic.getOrders(params);

    await dispatch(updateLoadGetOrdersStatistic(false));

    await dispatch({
      type: GET_STATISTIC_ORDERS,
      payload: data || {},
    });

  };
}

export function getStatistic() {
  return async (dispatch, state) => {
    const { statistic: {
      filter
    }
    } = state();

    let finalParams = await filterStatisc(filter);

    dispatch(updadeLoadPageClients(true));

    let res = await api.statistic.getDistances(finalParams);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch({
        type: UPDATE_STATISTIC,
        payload: { statistic: res.data },
      });
    }

    dispatch(updadeLoadPageClients(false));
  };
}

export function getDeliveryRoute({ id }) {
  return async (dispatch, state) => {
    const { statistic: { loadDeliveryRoute } } = state();
    if (loadDeliveryRoute)
      return;

    dispatch(setDeliveryRouteLoading(true));
    const res = await api.statistic.getDeliveryRoute(id);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else if (res.waypoints.length) {
      await dispatch({
        type: UPDATE_DELIVERY_ROUTE,
        payload: { id, ...res },
      });
    } else {
      await dispatch(addNotification({
        error: ['В цьому маршруті ще немає точок']
      }, 'warn'));
    }

    dispatch(setDeliveryRouteLoading(false));
  }
}

export function clearDeliveryRoute() {
  return {
    type: CLEAR_DELIVERY_ROUTE,
    payload: {},
  }
}

/* export */ function setDeliveryRouteLoading(loading) {
  return {
    type: SET_DELIVERY_ROUTE_LOADING,
    payload: loading,
  }
}

export function getSelf() {
  return async (dispatch, state) => {
    const {
      statistic: {
        self: {
          filter
        }
      }
    } = state();

    let finalParams = await filterStatisc(filter);

    dispatch(updadeLoadPageClients(true));

    let res = await api.statistic.getSelf(finalParams);

    if (res?.errors) {
      await dispatch(addNotification(res.errors, 'error'));
    } else {
      await dispatch({
        type: GET_STATISTIC_SELF,
        payload: res.data
      });
    }

    dispatch(updadeLoadPageClients(false));
  };
}

export function getStatisticKitchens() {
  return async (dispatch, state) => {
    const { statistic: {
      filter
    }
    } = state();

    let params = {};

    if (filter.city.length > 3)
      params = { city_sync_id: filter.city };

    const { data: kitchens } = await api.settings.getKitchens(params);

    await dispatch({
      type: GET_STATIC_KITCHENS,
      payload: kitchens,
    });

  };
}

export function updateFilter(item, data) {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_FILTER,
      payload: { item, data }
    });
  }
}

export function updateLoadGetOrdersStatistic(value) {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_LOAD_GET_ORDERS_STATISTIC,
      payload: value,
    });
  }
}

export function getDeliveries(userId, setLoad, isOpen) {
  return async (dispatch, state) => {
    const { statistic: {
      filter
    }
    } = state();

    if (isOpen) {
      await dispatch({
        type: UPDATE_DELIVERIES_CLEAR,
        payload: { userId },
      });
    } else {
      let finalParams = await filterStatisc(filter);

      const res = await api.statistic.getDeliveries({ ...finalParams, ...{ user_id: userId } });

      if (res?.errors) {
        await dispatch(addNotification(res.errors, 'error'));
      } else {
        await dispatch({
          type: UPDATE_DELIVERIES,
          payload: { deliveries: res.data, userId },
        });
      }

      setLoad(false);
    }
  };
}

let statusRepeat = false;
export function downloadExcel(stateLoda = () => { }) {
  return async (dispatch, state) => {
    const {
      statistic: {
        filter
      }
    } = state();

    let finalParams = await filterStatisc(filter);

    await stateLoda(true);

    const res = await api.statistic.downloadExcel(finalParams);

    if (res.status == "Ok") {
      const link = document.createElement('a');

      link.href = res.data.file;
      link.setAttribute(
        'download',
        `statistics.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      await stateLoda(false);
    } else {
      if (!statusRepeat) {
        setTimeout(() => {
          dispatch(downloadExcel());
          statusRepeat = true;
        }, 2000);
      } else {
        await stateLoda(false);
      }
    }
  }
}

let statusRepeatSelf = false;
export function downloadExcelSelf(stateLoda = () => { }) {
  return async (dispatch, state) => {
    const {
      statistic: {
        self: {
          filter
        }
      }
    } = state();

    let finalParams = await filterStatisc(filter);

    await stateLoda(true);

    const res = await api.statistic.statusRepeatSelf(finalParams);

    if (res.status == "Ok") {
      const link = document.createElement('a');

      link.href = res.data.file;
      link.setAttribute(
        'download',
        `statistics.xlsx`,
      );

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      await stateLoda(false);
    } else {
      if (!statusRepeatSelf) {
        setTimeout(() => {
          dispatch(downloadExcelSelf());
          statusRepeatSelf = true;
        }, 2000);
      } else {
        await stateLoda(false);
      }
    }
  }
}

// rewiews
export function updateReviews(item, value) {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_STAT_REVIEWS,
      payload: { item, value },
    });
  }
}

export function updateFilterReviews(item, value) {
  return async (dispatch) => {
    await dispatch({
      type: UPDATE_STAT_REVIEWS_FILTER,
      payload: { item, value },
    });
  }
}

export function getRivews({ page = 1 }) {
  return async (dispatch, state) => {
    const {
      statistic: {
        reviews: {
          typePage,
          filter,
        }
      }
    } = state();

    let params = {
      filter_type: typePage,
      page: page,
      per_page: 100,
      restaurant: filter.restaurants.value,
      ...getRangeDate(filter.date),
    };

    switch (typePage) {
      case rating: {
        let ratingsBy = "";

        for (let i = 0; i < filter.rating.length; i++) {
          ratingsBy += filter.rating[i].value;

          if ((filter.rating.length - 1) !== i)
            ratingsBy += ',';
        }

        params.ratings_by = ratingsBy;
        break;
      }
      case review: {
        let ratings = '';

        for (let i = 0; i < filter.reviewKeys.length; i++) {
          ratings += filter.reviewKeys[i].value;

          if ((filter.reviewKeys.length - 1) !== i)
            ratings += ',';
        }

        params.ratings = ratings;
        break;
      }
    }

    dispatch(updadeLoadPageClients(true));

    const { data } = await api.statistic.getRivews(params);

    if (typePage == rating) {
      await dispatch({
        type: GET_STATE_RATING_LIST,
        payload: data
      });
    }

    if (typePage == review) {
      await dispatch({
        type: GET_STATE_REVIEWS_LIST,
        payload: data
      });
    }

    dispatch(updadeLoadPageClients(false));
  };
}

let idInterval = null;
export function downloadExcelReviews(stateLoda = () => { }) {
  return async (dispatch, state) => {
    const {
      statistic: {
        reviews: {
          typePage,
          filter,
        }
      }
    } = state();

    let params = {
      filter_type: typePage,
      restaurant: filter.restaurants.value,
      ...getRangeDate(filter.date),
    };

    switch (typePage) {
      case rating: {
        let ratingsBy = "";

        for (let i = 0; i < filter.rating.length; i++) {
          ratingsBy += filter.rating[i].value;

          if ((filter.rating.length - 1) !== i)
            ratingsBy += ',';
        }

        params.ratings_by = ratingsBy;
        break;
      }
      case review: {
        let ratings = '';

        for (let i = 0; i < filter.reviewKeys.length; i++) {
          ratings += filter.reviewKeys[i].value;

          if ((filter.reviewKeys.length - 1) !== i)
            ratings += ',';
        }

        params.ratings = ratings;
        break;
      }
    }

    await stateLoda(true);

    console.log("ііііі: start");

    const res = await api.statistic.downloadExcelReviews(params);

    console.log("res: ", res);

    if (res.status == "Ok") {
      if (res.data?.job_id) {
        idInterval = setInterval(async () => {
          const resJob = await api.statistic.downloadExcelReviews({ ...params, job_id: res.data.job_id });

          console.log("resJob: ", resJob);

          if (resJob?.status == "Ok") {
            if (!!resJob.data?.file) {
              clearInterval(idInterval);

              const link = document.createElement('a');

              link.href = resJob.data.file;
              link.setAttribute(
                'download',
                `reviews.xlsx`,
              );

              document.body.appendChild(link);
              link.click();
              link.parentNode.removeChild(link);

              await stateLoda(false);
            }
          } else {
            await dispatch(addNotification({ suc: ["Помилка"] }, 'error'));
            await stateLoda(false);
            clearInterval(idInterval);
          }
        }, 10e3);
      }
    } else {
      await dispatch(addNotification({ suc: ["Помилка"] }, 'error'));
      await stateLoda(false);
    }
  }
}
