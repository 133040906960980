import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as listingsActions from "store/actions/listings";

import { default as ModalFilter } from "./ModalFilter";

function mapStateToProps(state) {
    return {
        isOpen: state.view.modalfiilter.isOpen,
        kitchens: state.settings.kitchens,
        cities: state.settings.cities,
        restaurants: state.settings.restaurants
    };
}

const mapDispatchToProps = {
    ...viewActions,
    ...listingsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalFilter);
