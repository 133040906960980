import styled, { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import ModalLayout from "../ModalLayout";
import { TextArea } from "components/ui-kit/inputs/TextArea";
import Select from "components/ui-kit/Select";
import InputPhone from "components/ui-kit/Modals/InputPhone";
import ButtonIcon from "components/ui-kit/ButtonIcon";

import { getOrdersClientOfPhone } from "store/actions/clients";
import { setFromComplaints } from "store/actions/statistic";

import { complaintsMapped } from "utils/mappers/complaint";
import { ordersClientOfComplaint } from "utils/mappers/orders";
import { validateComplaintForm } from "utils/validation";
import { isArray } from "lodash";

const startDate = {
    complaint: null,
    phone: "",
    comment: "",
    resource: null,
    idOrder: null
}

const ModalFormAddComplaint = ({
    // props
    isOpen = false,
    complaint,
    resource,
    onCloseModal = () => { },
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [dataObj, setDataObj] = useState({ ...startDate });
    const [orders, setOrders] = useState({
        list: [],
        isLoad: false,
    });
    const [error, setErrors] = useState({
        complaint: "",
        comment: "",
        resource: "",
        idOrder: "",
    });
    const [isEnterPgone, setIsEnterPhone] = useState(false);
    const [isLoadBnt, setIsLoadBnt] = useState(false);

    const isDisabled = !!!dataObj.idOrder;
    const isValidBtn = !!dataObj.complaint && !!dataObj.comment && dataObj.resource;

    const setDate = (item, data) => {
        setDataObj((prev) => ({
            ...prev,
            [item]: data,
        }));
    }

    const handleSubmit = () => {
        validateComplaintForm({
            data: dataObj,

            onSuccess: async () => {
                let objSet = {
                    type_id: dataObj?.complaint?.value,
                    source_id: dataObj?.resource?.value,
                    client_phone: dataObj?.phone,
                    comment: dataObj?.comment,
                    order_id: dataObj?.idOrder?.value
                }
                dispatch(setFromComplaints(objSet, setIsLoadBnt, onCloseModalClick));

                setErrors({
                    complaint: "",
                    comment: "",
                    resource: "",
                    idOrder: "",
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    const getorder = () => {
        if (!isEnterPgone)
            if (dataObj.phone.length == 12) {
                setIsEnterPhone(true);
                dispatch(getOrdersClientOfPhone(dataObj.phone, setOrders));
            }
    }

    const onCloseModalClick = () => {
        onCloseModal();
        setDataObj({ ...startDate });
        setOrders({
            list: [],
            isLoad: false,
        });
    }

    useEffect(() => {
        getorder();

        if (dataObj.phone.length != 12) {
            setIsEnterPhone(false);
        }
    }, [dataObj.phone]);

    useEffect(() => {
        if (!isArray(orders.list)) return;

        if (orders.list.length > 0)
            setDate("idOrder", ordersClientOfComplaint(orders.list)[0]);
    }, [orders.list]);

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={onCloseModalClick}
        >
            <Title>Оформлення скарги</Title>

            <RowIn>
                <InputPhone
                    title='Номер телефону клієнта'
                    type='text'
                    isStyle={true}
                    value={dataObj.phone}
                    onChange={(value) => setDate("phone", value)}
                    width="100%"
                    onKeyDown={getorder}
                />
            </RowIn>

            <RowIn>
                <Select
                    selectOptions={ordersClientOfComplaint(orders.list)}
                    selectedOption={dataObj.idOrder}
                    valueKey='idOrder'
                    label='Замовлення клієнта'
                    mode={'primary'}
                    isShowError={!!error.idOrder}
                    error={error.idOrder}
                    isLoad={orders.isLoad}
                    onChange={({ value }) => setDate("idOrder", value)}
                />
            </RowIn>

            <RowIn>
                <Select
                    selectOptions={complaintsMapped(complaint.list)}
                    selectedOption={dataObj.complaint}
                    valueKey='complaint'
                    label='Вид скарги'
                    mode={'primary'}
                    isShowError={!!error.complaint}
                    error={error.complaint}
                    onChange={({ value }) => setDate("complaint", value)}
                    disabled={isDisabled}
                />
            </RowIn>

            <RowIn>
                <TextArea
                    title="Причина та коментар до скарги"
                    isStyle={true}
                    value={dataObj.comment}
                    error={error.comment}
                    onChange={(e) => setDate("comment", e.target.value)}
                    disabled={isDisabled}
                />
            </RowIn>

            <RowIn>
                <Select
                    selectOptions={complaintsMapped(resource.list)}
                    selectedOption={dataObj.resource}
                    valueKey='resource'
                    label='Ресурс'
                    isShowError={!!error.resource}
                    error={error.resource}
                    mode={'primary'}
                    onChange={({ value }) => setDate("resource", value)}
                    disabled={isDisabled}
                />
            </RowIn>

            <Wrcenter>
                <ButtonIcon
                    title="Додати"
                    onClick={handleSubmit}
                    isLoad={isLoadBnt}
                    disabled={!isValidBtn}
                />
            </Wrcenter>
        </ModalLayout>
    )
}

const Title = styled.div`
    text-align: center;
    font-size: 16px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    font-weight: 700;
`;

const RowIn = styled.div`
    margin-bottom: 15px;
`;

const Wrcenter = styled.div`
    display: flex;
    justify-content: center;
`;

export default ModalFormAddComplaint;