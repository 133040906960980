import React, { useRef, useMemo } from "react";
import styled, { useTheme } from "styled-components";

import { LoadingModal } from "../../loadingOrder/loading";
import { isArray } from "lodash";
import { Stack, Divider } from "@mui/material";
import { renderIcon } from "utils/helpers/typeKitchen";

import CloseIcon from "@mui/icons-material/Close";
import { CSSTransition } from 'react-transition-group';

const ModalProductSpecification = ({
  // state
  isOpen = false,
  isLoading = false,
  specification = {},
  // dispatch
  closeModalProductSpecification: handleClose = () => { },
}) => {
  const ref = useRef(null);
  const theme = useTheme();
  const icon = useMemo(() => renderIcon(specification.restaurant), [specification.restaurant]);
  return (
    <>
      {isOpen && <BgModal onClick={handleClose} />}
      <CSSTransition
        in={isOpen}
        nodeRef={ref}
        timeout={300}
        classNames="right-to-left"
        unmountOnExit
      >
        <Wrapper theme={theme} ref={ref}>
          <Header>
            {icon && <RestaurantIcon src={icon} />}
            <div>Специфікація продукту {specification.name}</div>
            <CloseButton onClick={handleClose}>
              <CloseIcon style={{ fontSize: 36 }} />
            </CloseButton>
          </Header>
          <Stack divider={<Divider />} spacing={1} width='100%' sx={{ px: 1.5, py: 1.5 }}>
            {isArray(specification.items) && specification.items.map((item) => item.artikul && (
              <Item key={item.artikul}>
                {item.name} ({item.quantity} {item.unit}.)
              </Item>
            ))}
          </Stack>
          {isLoading && <LoadingModal />}
        </Wrapper>
      </CSSTransition>
    </>
  );
}

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 10px 10px;
    border-bottom: ${(p) => p.theme.accentColor};
    height: 56px;
`;

const Wrapper = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 460px;
  background-color: #ffffff;
`;

const BgModal = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.65);
`;

const CloseButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(p) => p.theme.accentColor};
    border-radius: 50%;
    background-color: transparent;

    &:hover {
        cursor: pointer;
        background-color: ${(p) => p.theme.backgroundHover};
    }
`;

const Item = styled.div``;
const RestaurantIcon = styled.img`
  display: flex;
  align-items: center;
  margin-right: 10px;
  max-width: 24px;
`;

export default ModalProductSpecification;
