import produce from "immer";

import { LOGOUT } from "../constants/session";
import {
  UPDATE_STATISTIC,
  UPDATE_DELIVERIES,
  GET_STATIC_KITCHENS,
  UPDATE_FILTER,
  UPDATE_DELIVERIES_CLEAR,
  UPDATE_DELIVERY_ROUTE,
  CLEAR_DELIVERY_ROUTE,
  SET_DELIVERY_ROUTE_LOADING,
  GET_STATISTIC_ORDERS,
  UPDATE_FILTER_SELF,
  GET_STATISTIC_SELF,
  UPDATE_LOAD_GET_ORDERS_STATISTIC,
  UPDATE_STAT_REVIEWS,
  UPDATE_STAT_REVIEWS_FILTER,
  GET_STATE_REVIEWS_LIST,
  GET_STATE_RATING_LIST,
  UPDATE_FILTER_COMPLAINTS,
  GET_STATISTIC_COMPLAINTS
} from "../constants/statistic";

import { ratingBy, review, reviewKeys } from "constants/reviews";
import { listenTypeProcessing } from "constants/complaints";

const initialState = {
  statistic: [],
  kitchens: [],
  deliveries: [],
  deliveryRoute: {},
  orders: {},
  filter: {
    city: '',
    kitchens: [],
    date: [
      {
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
      }
    ]
  },
  self: {
    filter: {
      kitchens: [],
      date: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        }
      ]
    },
    orders: [],
  },
  reviews: {
    restoranName: "Smaki Maki",
    typePage: review,
    filter: {
      rating: [...ratingBy],
      restaurants: {
        label: "Smaki Maki",
        value: "smaki",
      },
      reviewKeys: [...reviewKeys],
      date: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        }
      ],
    },
    ratings: {
      last_page: 1,
      reviews: [],
    },
    responses: {
      last_page: 1,
      reviews: [],
    },
  },
  complaints: {
    filter: {
      processed: listenTypeProcessing[0],
      date: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        }
      ]
    },
    list: [],
  },
  loadPageOrders: false,
  loadDeliveryRoute: false,
};

export default produce((draft, action) => {
  const { payload } = action;

  switch (action.type) {
    // complaints
    case UPDATE_FILTER_COMPLAINTS: {
      draft.complaints.filter[payload.item] = payload.data;
      break;
    }
    case GET_STATISTIC_COMPLAINTS: {
      draft.complaints.list = payload;
      break;
    }
    // end complaints

    case GET_STATE_RATING_LIST: {
      draft.reviews.ratings = payload;
      break;
    }
    case GET_STATE_REVIEWS_LIST: {
      draft.reviews.responses = payload;
      break;
    }
    case UPDATE_STAT_REVIEWS_FILTER: {
      draft.reviews.filter[payload.item] = payload.value;
      break;
    }
    case UPDATE_STAT_REVIEWS: {
      draft.reviews[payload.item] = payload.value;
      break;
    }

    case UPDATE_LOAD_GET_ORDERS_STATISTIC: {
      draft.loadPageOrders = payload;
      break;
    }
    case GET_STATISTIC_SELF: {
      draft.self.orders = payload;
      break;
    }

    case UPDATE_FILTER_SELF: {
      draft.self.filter[payload.item] = payload.data;
      break;
    }

    case UPDATE_DELIVERIES_CLEAR: {
      let index = draft.statistic.findIndex((el) => el.user_id == payload.userId);

      draft.statistic[index].deliveries = [];
      break;
    }

    case GET_STATISTIC_ORDERS: {
      draft.orders = payload;
      break;
    }

    case UPDATE_STATISTIC: {
      draft.statistic = payload.statistic;
      break;
    }

    case UPDATE_FILTER: {
      draft.filter[payload.item] = payload.data;
      break;
    }

    case GET_STATIC_KITCHENS: {
      draft.kitchens = payload;
      break;
    }

    case UPDATE_DELIVERY_ROUTE: {
      draft.deliveryRoute = payload;
      break;
    }

    case CLEAR_DELIVERY_ROUTE: {
      draft.deliveryRoute = {};
      break;
    }

    case SET_DELIVERY_ROUTE_LOADING: {
      draft.loadDeliveryRoute = payload;
      break;
    }

    case UPDATE_DELIVERIES: {
      let index = draft.statistic.findIndex((el) => el.user_id == payload.userId);

      draft.statistic[index].deliveries = payload.deliveries;
      break;
    }

    case LOGOUT: {
      draft.statistic = null;
      break;
    }

    default:
      break;
  }
}, initialState);
