import { connect } from "react-redux";

import * as statisticActions from "store/actions/statistic";
import * as viewActions from "store/actions/view";
import { default as CouriersPage } from "./Couriers";

function mapStateToProps(state) {
    return {
        statistic: state.statistic.statistic,
        kitchens: state.statistic.kitchens,
        filter: state.statistic.filter,
        cities: [...[{ name_ua: '-', sync_id: "-", status: 1 }], ...state.settings.cities],
        view: state.view,
        deliveries: state.statistic.deliveries,
        user: state.user.user,
    };
}

const mapDispatchToProps = {
    ...viewActions,
    ...statisticActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(CouriersPage);
