import { useDropzone } from 'react-dropzone'

import UploadFileIcon from '@mui/icons-material/UploadFile';
import styles from "./dropZone.module.scss";

const DropZone = ({ onDrop }) => {
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple: false,
    });

    return (
        <div className={`${styles.add_file_zone} ${isDragActive ? styles.dragActive : ""}`}>
            <div
                {...getRootProps()}
            >
                <input
                    type="file"
                    className="none"
                    {...getInputProps()}
                />
                <div className={styles.bnt_zone}>
                    <UploadFileIcon />
                    <span>Виберіть або перетягніть файл</span>
                </div>
            </div>
        </div>
    )
}

export default DropZone;