import produce from 'immer';

import {
    GET_PRODUCTS_SUCCESS,
    UPDATE_PRODUCTS_FETCHING,
    UPDATE_PRODUCTS_SEARCH,
    GET_PRODUCT_BY_ID,
    UPDATE_FORM_PRODUCT,
    UPDATE_FORM_PRODUCT_TYPE_PRICE,
    UPDATE_MODAL_PRODUCT_SPECIFICATION,
} from '../constants/products';
import { LOGOUT } from '../constants/session';

const initialState = {
    list: [],
    meta: {
        current_page: 1,
        from: 1,
        last_page: 1
    },
    isFetching: true,
    searchQuery: '',
    modalProductSpecification: {
        isOpen: false,
        isLoad: false,
        specification: {},
    },
    formProduct: {
        id: null,
        restaurant: "",
        article: "",
        title_ua: "",
        weight: null,
        weight_type: "",
        category_sync_id: "",
        type_sync_id: "",
        description_ua: "",
        image: "",
        prices: [],
        type: {},
        category: {},
        addition_groups: [],
        media: [],
    }
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        case UPDATE_FORM_PRODUCT_TYPE_PRICE: {
            let indexDelivery = draft.formProduct.prices[payload.index].delivery_type_prices.findIndex(el => el.delivery_type_id == payload.id);

            if (indexDelivery == -1) {
                draft.formProduct.prices[payload.index].delivery_type_prices.push({
                    delivery_type_id: payload.id,
                    price: +payload.value,
                });
            } else {
                draft.formProduct.prices[payload.index].delivery_type_prices[indexDelivery].price = +payload.value;
            }

            break;
        }

        case UPDATE_FORM_PRODUCT: {
            draft.formProduct[payload.item] = payload.data;
            break;
        }

        case GET_PRODUCT_BY_ID: {
            draft.formProduct = payload;
            break;
        }

        case GET_PRODUCTS_SUCCESS: {
            if (payload.isNewStatus || payload.isRecord) {
                draft.list = payload.data;
            } else {
                draft.list = [...draft.list, ...payload.data];
            }

            draft.meta = payload.meta
            break;
        }

        case UPDATE_PRODUCTS_FETCHING: {
            draft.isFetching = payload.isFetching;
            break;
        }

        case UPDATE_PRODUCTS_SEARCH: {
            draft.searchQuery = payload.searchQuery;
            break;
        }

        case LOGOUT: {
            draft.list = [];
            draft.isFetching = true;
            break;
        }

        case UPDATE_MODAL_PRODUCT_SPECIFICATION: {
            Object.assign(draft.modalProductSpecification, payload);
            break;
        }        

        default:
            break;
    }
}, initialState);
