import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import * as viewActions from "../../../store/actions/view";

import { default as HomePage } from './sourseOfCustomers';

function mapStateToProps(state) {
    return {
        isOpenModalDelete: state.view.modalDelete.isOpen,
        isLoadModalDelete: state.view.modalDelete.loadingsModal,
    };
}

const mapDispatchToProps = {
    ...settingsActions,
    ...viewActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(HomePage);
