import Base from "./Base.js";

export default class SettingsAPI extends Base {
    createProvings(data) {
        return this.apiClient.post(`polygon`, data);
    }
    getProvings() {
        return this.apiClient.get(`polygon`);
    }
    getCityPolygons(city) {
        return this.apiClient.get(`get-city-polygons/${city}`);
    }
}