import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import styled, { useTheme } from "styled-components";

import { ROOT } from "../../constants/routes";
import history from "constants/history";

import { parsePhone } from "utils/helpers/parsers";
import { decodeErrorObject, validateCreateSession } from "utils/validation";

import { Keyboard } from "@mui/icons-material";

import Button from "components/ui-kit/Button/Button";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { PhoneInput } from "components/ui-kit/inputs/PhoneInput";
import { PasswordInput } from "components/ui-kit/inputs/PasswordInput";
import Notifications from "components/notifications";
import VirtualKeyboard from "components/VirtualKeyboard";
function Login({ login }) {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const keyboardRef = useRef(null);
  const [virtualKeyboard, setVirtualKeyboard] = useState(false);
  const [activeInput, setActiveInput] = useState("phone");

  const [errors, setErrors] = useState({
    phone: "",
    password: "",
  });
  const [inputs, setInputs] = useState({
    phone: "",
    password: "",
  });

  useEffect(() => {
    // Synchronise input with virtual keyboard
    if (!virtualKeyboard) return;
  
    const inputValue = inputs[activeInput];
    keyboardRef.current?.setInput(inputValue);
  }, [virtualKeyboard, activeInput, inputs]);

  const handleInputChange = ({ valueKey, value }) => {
    setInputs((prev) => ({ ...prev, [valueKey]: value }));
    setErrors((prev) => ({ ...prev, [valueKey]: "" }));
    setIsFormValid(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const { phone, password } = inputs;
    const parsedPhone = parsePhone(`380${phone}`);

    validateCreateSession({
      data: {
        phone: parsedPhone,
        password,
      },
      onSuccess: async (validData) => {
        setIsLoading(true);

        await login({
          ...validData,
          onSuccess: () => {
            setIsLoading(false);
            history.replace(ROOT);
          },
          onError: () => {
            const error = decodeErrorObject({ phone: "INVALID_LOGIN_DATA" });

            setErrors(error);
            setIsFormValid(false);
            setIsLoading(false);
          },
        });
      },
      onError: (validationErrors) => {
        setErrors(validationErrors);
        setIsFormValid(false);
      },
    });
  };

  return (
    <Wrapper theme={theme}>
      <FormWrapper>
        <Title>Авторизація</Title>

        <Form onSubmit={handleSubmit}>
          <PhoneInput
            valueKey='phone'
            value={inputs.phone}
            onChange={handleInputChange}
            isError={errors.phone}
            isFetching={isLoading}
            onFocus={() => setActiveInput('phone')}
          />

          <PasswordInput
            valueKey='password'
            value={inputs.password}
            onChange={handleInputChange}
            isError={errors.password}
            isFetching={isLoading}
            onFocus={() => setActiveInput('password')}
          />

          <Row>
            <Button
              label='Увійти'
              type='submit'
              isLoading={isLoading}
              isDisabled={isLoading || !isFormValid}
            />
            <ButtonIcon
              icon={<Keyboard />}
              onClick={() => setVirtualKeyboard(visible => !visible)}
              type='button'
            />
          </Row>

        </Form>
      </FormWrapper>
      <Notifications />
      <VirtualKeyboard 
        ref={keyboardRef} 
        isOpen={virtualKeyboard}
        onChange={(value) => handleInputChange({ valueKey: activeInput, value })}
        isNumeric={activeInput === "phone"}
        maxLength={activeInput === "phone" ? 9 : 1024}
      />
    </Wrapper>
  );
}

const Wrapper = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: ${(p) => p.theme.background};
`;

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h1`
  margin-bottom: 25px;
  text-align: center;
  color: ${(p) => p.theme.secondaryColor};
  font-size: 24px;
  letter-spacing: 0.03em;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
`

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

export default React.memo(Login);
