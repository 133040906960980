import { isArray } from "lodash";

export const cutleryMappedLabel = (arr = []) => {
    let mewArray = [];

    arr.map((item) => {
        mewArray.push({ label: item.name, value: item.id });
    });

    return mewArray;
};

export const filterCutleryId = (arr = []) => {
    let mewArray = [];

    arr.map((item) => {
        mewArray.push(item.value);
    });

    return mewArray;
}

export const searchCutleryToid = (arrValue = [], arrCutlery = []) => {
    let mewArray = [];

    if (isArray(arrValue))
        arrValue.map((item) => {
            arrCutlery.map((itemCutlery) => {
                if (item == itemCutlery.id)
                    mewArray.push({ label: itemCutlery.name, value: itemCutlery.id });
            });
        });

    return mewArray;
}