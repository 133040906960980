import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { EditResource } from "./EditResource";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import Table from "components/ui-kit/Table";
import { LoadingModal } from "components/loadingOrder/loading";

import AddIcon from "@mui/icons-material/Add";
import styles from "../../scss/settings.module.scss";

import { tableresource } from "constants/tables";

const resource = ({
    // props
    onDeleteResource,
    //state
    resourceEdit,
    view,
    resource,
    // dispatch
    updateFormResource,
    getResource,
    updateResource,
    createResource,
    getResourceId,
    cleaningNewForm,
}) => {
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        getResource();
    }, []);

    const handlerCreateresource = () => {
        cleaningNewForm("resourceEdit");
        setIsEdit((prev) => !prev);
    }

    const handlerEdit = ({ id }) => {
        setIsEdit(true);
        getResourceId(id);
    }

    const handleSubmitUpdate = () => {
        updateResource(setIsEdit);
    }

    const handleSubmitСreate = () => {
        createResource(setIsEdit);
    }

    return (
        <div className={`${styles.grid_col}`}>
            {
                !isEdit && (
                    <div className={styles.wr_head}>
                        <ButtonIcon
                            onClick={handlerCreateresource}
                            icon={<AddIcon />}
                            positionIcon="left"
                        />
                    </div>
                )
            }

            {
                isEdit && (
                    <EditResource
                        resourceEdit={resourceEdit}
                        handleSubmitClose={handlerCreateresource}
                        updateFormResource={updateFormResource}
                        handleSubmitСreate={handleSubmitСreate}
                        handleSubmitUpdate={handleSubmitUpdate}
                        view={view}
                    />
                )
            }

            <div className={`${styles.wr_row}`}>
                {
                    resource.length > 0 ? (
                        <div className={styles.max_he_300}>
                            <Table
                                onClick={handlerEdit}
                                onDelete={(data) => onDeleteResource(data, 'resource')}
                                data={resource}
                                scheme={tableresource}
                            />
                        </div>
                    ) : (
                        <div className={styles.text_info}>Пусто... Немає джерел</div>
                    )
                }
                {
                    view.isLoadTableResource && (
                        <LoadingModal text='' />
                    )
                }
            </div>

        </div>
    )
}

resource.propTypes = {
    resource: PropTypes.array,
    resourceEdit: PropTypes.object,
    updateFormResource: PropTypes.func,
    getResource: PropTypes.func,
    view: PropTypes.object,
    updateResource: PropTypes.func,
    createResource: PropTypes.func,
    getResourceId: PropTypes.func,
};

export default resource;