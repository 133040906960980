import { connect } from 'react-redux';

import { default as bugView } from './BugView';

function mapStateToProps(state) {
    const {
        settings: {
            bugReports: {
                selectedBugReport,
            }
        }
    } = state;

    return { selectedBugReport };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(bugView);
