import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as ReasonsPage } from './Reasons';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        view,
        blackList: settings.blackList,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(ReasonsPage);
