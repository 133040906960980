import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import * as viewActions from "../../../store/actions/view";
import { default as TelestatPage } from './Telestat';

function mapStateToProps(state) {
    return {
        isLoadPage: state.view.isLoadPage,
        telestatData: state.settings.telestat,
    };
}

const mapDispatchToProps = {
    ...settingsActions,
    ...viewActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(TelestatPage);
