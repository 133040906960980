import * as React from 'react';
import { 
  DataGridPremium,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium';
import { Typography } from '@mui/material';

import { currencyFormatter } from 'utils/helpers/uahCurrencyFormatter';

const PaymentBrandsTable = ({ data }) => {
  const apiRef = useGridApiRef();
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      aggregation: {
        model: {
          sum: 'sum',
          bonus: 'sum',
          to_pay: 'sum',
          orders_count: 'sum'
        },
      },
      columns: {
        // Other hidden columns
        columnVisibilityModel: { gross: false },
      },
    },
  });

  const columns = [
    { field: 'brand', headerName: 'Тип оплати' },
    { 
      field: 'to_pay', 
      headerName: 'Сума до сплати', 
      type: 'number',
      valueFormatter: ({ value }) => {
        if (value === undefined) return null;
        return currencyFormatter.format(value);
      },
    },
    { 
      field: 'bonus', 
      headerName: 'Знижка', 
      type: 'number',
      valueFormatter: ({ value }) => {
        if (value === undefined) return null;
        return currencyFormatter.format(value);
      },
    },
    { 
      field: 'orders_count', 
      headerName: 'Кількість замовлень', 
      type: 'number',
      valueFormatter: ({ value }) => {
        return value ?? null;
      },
    }
  ];

  const rows = Object.entries(data?.payment_type.list ?? []).reduce((accumulator, [paymentType, amounts]) => {
    const { sum, bonus, to_pay, orders_count } = amounts;
    return [...accumulator, {
      brand: paymentType,
      sum,
      bonus,
      to_pay,
      orders_count
    }];
  }, []);

  return (
    <React.Fragment>
      <Typography variant='h5'>Для всіх брендів</Typography>
      <DataGridPremium
        sx={{
          ".MuiDataGrid-aggregationColumnHeaderLabel": {
            display: "none"
          }
        }}

        apiRef={apiRef}
        initialState={initialState}

        density='compact'
        rows={rows}
        columns={columns}

        getRowId={({ brand }) => brand}
        hideFooter

        autoHeight
        disableColumnFilter
        disableColumnMenu
      />
    </React.Fragment>
  );
};

export default React.memo(PaymentBrandsTable);
