export const GET_SETTINGS_PROVINGS = 'GET_SETTINGS_PROVINGS';
export const GET_CITY_POLYGON_BOUNDS = 'GET_CITY_POLYGON_BOUNDS';
export const ADD_SETTINGS_PROVINGS_BLOCK_RESTOURANT = 'ADD_SETTINGS_PROVINGS_BLOCK_RESTOURANT';
export const ADD_SETTINGS_PROVINGS_BLOCK_KITCHEN = 'ADD_SETTINGS_PROVINGS_BLOCK_KITCHEN';
export const DELETE_SETTINGS_PROVINGS_BLOCK_RESTOURANT = 'DELETE_SETTINGS_PROVINGS_BLOCK_RESTOURANT';
export const DELETE_SETTINGS_PROVINGS_BLOCK_KITCHEN = 'DELETE_SETTINGS_PROVINGS_BLOCK_KITCHEN';
export const UPDATE_SETTINGS_PROVINGS_RESTOURAN = 'UPDATE_SETTINGS_PROVINGS_RESTOURAN';
export const UPDATE_SETTINGS_PROVINGS_FIELD = 'UPDATE_SETTINGS_PROVINGS_FIELD';
export const UPDATE_SETTINGS_PROVING_SAVE_LOAD = 'UPDATE_SETTINGS_PROVING_SAVE_LOAD'
export const UPDATE_SETTINGS_PROVING_GET_LOAD = 'UPDATE_SETTINGS_PROVING_GET_LOAD'
export const UPDATE_SETTINGS_PROVINGS_FIELD_SECTION = 'UPDATE_SETTINGS_PROVINGS_FIELD_SECTION'
