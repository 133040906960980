import { connect } from 'react-redux';

// import * as settingsActions from 'store/actions/settings';
import { default as GeneralPage } from './General';

function mapStateToProps(state) {
    return {
    };
}

const mapDispatchToProps = {
    // ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralPage);
