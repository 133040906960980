import { useEffect, useState } from "react";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import Table from "components/ui-kit/Table";
import ModalUpdateCategories from "components/modals/ModalUpdateCategories";

import { tableCategories } from "constants/tables";

import styles from "./../scss/settings.module.scss";

const defOnj = {
    modal: {
        id: null,
        isOpen: false,
        isLoad: false,
    }
};

const Complaint = ({
    // state
    productCategories,
    productCategoriesForm,
    cutlery,
    // dispatrch
    getSettingsProductCategories,
    updateFormCategories,
    oneSettingsProductCategories,
    updateCategorieOfid,
}) => {
    const [isLoadPagem, setIsLoadPage] = useState(false);
    const [statModal, setStateModal] = useState({ ...defOnj.modal });
    const [isLoadBtn, setIsLoadBtn] = useState(false);

    const loadModal = async (status) => {
        await setStateModal((prev) => ({
            ...prev,
            isLoad: status,
        }));
    }

    const openModal = ({ id }) => {
        setStateModal((prev) => ({
            ...prev,
            id,
            isOpen: true,
        }));

        oneSettingsProductCategories(id, loadModal);
    }

    const closeModal = () => {
        setStateModal(defOnj.modal);
        setIsLoadBtn(false);
    }

    const handleCreateClick = async () => {
        updateCategorieOfid(setIsLoadBtn, closeModal);
    }

    useEffect(() => {
        getSettingsProductCategories({}, setIsLoadPage);
    }, []);

    return (
        <div>
            <div className={styles.block}>
                {
                    !isLoadPagem && (
                        <>
                            <div className={styles.block}>
                                <Table
                                    onClick={openModal}
                                    data={productCategories}
                                    scheme={tableCategories}
                                />
                            </div>
                        </>
                    )
                }

                {
                    isLoadPagem && (
                        <LoadingCircl />
                    )
                }
            </div>

            <ModalUpdateCategories
                isOpen={statModal.isOpen}
                isLoad={statModal.isLoad}
                isLoadBtn={isLoadBtn}
                data={productCategoriesForm}
                cutlery={cutlery}
                onCloseModal={closeModal}
                updateCategoriesForm={updateFormCategories}
                handleCreateClick={handleCreateClick}
            />
        </div>
    )
}

export default Complaint;