import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as complaintsPage } from './Complaints';

function mapStateToProps(state) {
    // const { } = state;

    return {
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(complaintsPage);
