import * as React from "react";
import styled, { useTheme } from "styled-components";

import ModalLayout from "../ModalLayout";
import { Typography } from "@mui/material";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import LogoutIcon from "@mui/icons-material/Logout";

const ModalConfirmLogout = ({
    // state
    isOpen,
    // mapDispatch
    closeModalConfirmLogout,
    logoutButton,
}) => {
    const theme = useTheme();
    const logout = React.useCallback(() => {
        closeModalConfirmLogout(); // close modal first
        logoutButton(); // then logout
    }, [logoutButton, closeModalConfirmLogout]);

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={closeModalConfirmLogout}>
            <Title>
                <Typography variant='h5'>Ви дійсно хочете вийти?</Typography>
            </Title>
            <RowBtn>
                <Item>
                    <ButtonIcon
                        onClick={logout}
                        title="Так"
                        icon={<LogoutIcon />}
                    />
                </Item>
            </RowBtn>
        </ModalLayout>
    );
};

const Title = styled.div`
    text-align: center;
    font-size: 18px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    font-weight: 700;
`;

const RowBtn = styled.div`
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
`;

const Item = styled.div`
    padding: 0 5px;
`;

export default ModalConfirmLogout;
