import { isArray, find } from "lodash";
import { getAdditionPrice } from "./price";

export const searchPaymentCodeAndReturn = (idPayment, list, sum = 0) => {
  let pay = list.find(el => (el.id == idPayment));

  if (pay?.code) {
    return {
      payment_type: (pay.code == 'kurjerom') ? 'cash' : pay.code, // cash - якщо обрано кур'єр
      sum: sum,
      free: 0,
    }
  }
}

// проверка на наличия оплаты наличкой или бонусми
export const sumBonusAndOnline = (array, arrayDiscoumt) => {
  let sum = 0;

  array?.find((item) => {
    if ((item.payment_type) == "bonus" || (item.payment_type == "rucna-zniska")) {
      sum += +item?.sum;
    }
  });

  // TODO: discount
  if (isArray(arrayDiscoumt)) {
    arrayDiscoumt?.find((item) => {
      // if ((item.ignore != 1) && (item?.sum != 1))
        sum += +item?.sum;
    });
  }

  return sum;
}

export const revealBonuses = (balances, restaurant, allSum) => {
  let kitchen = balances.find((itemBonuses) => itemBonuses.restaurant === restaurant);

  if (kitchen) {
    let valueBodusOfKitchen = kitchen.value;

    if (valueBodusOfKitchen > allSum) {
      return allSum;
    } else {
      return valueBodusOfKitchen;
    }
  }

  return 0;
}

export const sumCutlery = (cutlerus) => {
  let sum = 0;

  if (isArray(cutlerus)) {
    for (let i = 0; i < cutlerus.length; i++) {
      if (cutlerus[i].status == 1) {
        if (!!cutlerus[i].price) {
          sum += (+cutlerus[i].price * cutlerus[i].quantity);
        }
      }
    }
  }

  return sum;
}

export const returnBonus = (payments) => {
  let bonus = undefined;

  if (isArray(payments)) {
    for (let i = 0; i < payments.length; i++) {
      if ((payments[i].payment_type === "bonus") || (payments[i].payment_type === "rucna-zniska")) {
        bonus = payments[i];
      }
    }
  }

  return bonus;
}

// Сплачено
export const calculateSumPayments = (payments) => {
  return payments.reduce((a, b) => {
    if ((b.payment_type != "bonus") && (b.payment_type != "rucna-zniska")) {
      let bSum = b.sum ? +b.sum : 0;
      return +a + bSum;
    }

    return a
  }, 0);
};

export const countDops = ({ item, city, deliveryType }) => {
  const quantity = +item.quantity;
  const additions = item.additions || [];
  const availableAdditions = item.product.additions || [];

  return additions.reduce((accumulator, { addition_id: id, quantity: additionQuantity, ...itemAddition }) => {
    // find addition among all available additions
    const addition = find(availableAdditions, { id });
    if (!addition)
      console.warn('countDops: addition not found in available additions list.', id, itemAddition);

    const price = getAdditionPrice(addition ?? itemAddition, city, deliveryType);

    // calculate
    return accumulator + (price * additionQuantity) * quantity;
  }, 0);
}

// до оплати рахується всі додатки, всі позиції
export const calculateSumItems = ({
  payments,
  items,
  data,
  data: {
    address: {
      city_sync_id: city,
    },
    delivery_type_id: deliveryType,
  },
  isBonus = true
}) => {
  // we subtract bonuses and manual discount
  let bonusAndOnline = sumBonusAndOnline(payments, data.discounts);
  let priceCutlery = sumCutlery(data?.cutlery_sets);
  let priceAndBonus = 0;

  items.map(item => {
    let sumItem = +(item.sum ?? 0.1); // TODO
    let subDop = countDops({ item, city, deliveryType: +deliveryType });

    priceAndBonus += (sumItem + subDop);
  });

  priceAndBonus += priceCutlery;

  // bonuses no longer need to be withdrawn
  if (isBonus)
    return priceAndBonus - bonusAndOnline;

  return priceAndBonus
};

export const paymentAvailablFunList = (paymentAvailable = [], paymentsOrder = []) => {
  let newArr = [...paymentAvailable];

  if (isArray(paymentAvailable) && isArray(paymentsOrder)) {
    if (paymentsOrder.length == 0) {
      return paymentAvailable;
    } else {
      paymentsOrder.map((el) => {
        newArr.map((itemPay, index) => {
          if (el.payment_type == itemPay.code) {
            newArr.splice(index, 1);
          }
        })
      });
    }
  }

  return newArr;
}