import styled, { useTheme } from "styled-components";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isArray } from "lodash";

import ModalLayout from "../ModalLayout";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import Select from "components/ui-kit/Select";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import CalendarRange from "components/ui-kit/CalendarRange";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

import { restaurantsMappedLabelId } from "utils/mappers/restaurants";
import { validateComplaintFormProcessing } from "utils/validation";
import { diffDateMonth } from "utils/helpers/date";

import { setFromComplaintsProcessing } from 'store/actions/statistic';
import { updateFilterCalls, getClientCallByPhoneNumber } from "store/actions/clients";

import { complaitnsTypeProcessing } from "constants/complaints";

const ModalFormComplaintProcessing = ({
    // props
    isOpen = false,
    data = {},
    onCloseModal = () => { },
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const {
        settings: {
            restaurants,
            telestat: {
                audio_link
            }
        },
        clients: {
            calls
        },
        user: {
            user
        },

    } = useSelector(data => data);

    const startDate = {
        type: complaitnsTypeProcessing[0],
        restaurant: null,
        value: 0,
        operator_id: user.id,
        client_id: data.client_id,
        complaint_id: data.complaint_id,
    }
    const [dataObj, setDataObj] = useState({
        ...startDate,
    });
    const [error, setErrors] = useState({
        type: "",
        restaurant: "",
        value: "",
    });
    const [isLoadBnt, setIsLoadBnt] = useState(false);

    const isValidBtn = !!dataObj.restaurant;

    const setDate = (item, data) => {
        setDataObj((prev) => ({
            ...prev,
            [item]: data,
        }));
    }

    const handleSubmit = () => {
        validateComplaintFormProcessing({
            data: {
                type: +dataObj.type.value,
                restaurant: +dataObj.restaurant.value,
                value: +dataObj.value,
            },

            onSuccess: async (valid) => {
                let objSet = {
                    type: valid.type,
                    restaurant_id: valid.restaurant,
                    value: valid.value,
                    operator_id: dataObj.operator_id,
                    client_id: data.client_id,
                    complaint_id: data.complaint_id,
                }

                dispatch(setFromComplaintsProcessing(objSet, setIsLoadBnt, onCloseModalClick));

                setErrors({
                    type: "",
                    restaurant: "",
                    value: "",
                });
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    const onCloseModalClick = () => {
        onCloseModal();
        setDataObj(startDate);
    }

    useEffect(() => {
        if (!!data.complaint_id)
            dispatch(getClientCallByPhoneNumber(data.client_phone))
    }, [data.complaint_id]);

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={onCloseModalClick}
            isfull={true}
            fullWidth="940px"
        >
            <Title>Опрацювання скарги №{data.complaint_id}</Title>
            <InfoClient>{data.client_full_name} <a href={`tel:${data.client_phone}`}>{data.client_phone}</a></InfoClient>

            <RowWr>
                <ColCall>
                    <WrCalendar>
                        <CalendarRange
                            title="Період з - по"
                            valueIn={calls.filter.date}
                            ranges={calls.filter.date}
                            isAbs={true}
                            isBtn={true}
                            isStyle={true}
                            minDate={diffDateMonth(1)}
                            onChangeCal={(date) => dispatch(updateFilterCalls("date", [date.selection]))}
                            handlerSubmit={() => dispatch(getClientCallByPhoneNumber(data.client_phone))}
                        />
                    </WrCalendar>
                    <BodyCalls>
                        {
                            calls.isLoad ? (
                                <LoadingCircl mins={true} />
                            ) : (
                                isArray(calls?.list) && (calls.list.length > 0) ? (
                                    <UlList>
                                        {
                                            calls.list.map((itemCall, index) => {
                                                let dateTel = itemCall.calldate.split(' ')[0].replaceAll('-', '/');

                                                return (
                                                    <UlLi key={index}>
                                                        <TalkTime>Час розмови: {itemCall.calldate}</TalkTime>
                                                        <audio controls="controls">
                                                            <source src={`${audio_link}/${dateTel}/${itemCall.record}`} />
                                                        </audio>
                                                    </UlLi>
                                                )
                                            })
                                        }
                                    </UlList>
                                ) : (
                                    <Empty>Пусто</Empty>
                                )
                            )
                        }
                    </BodyCalls>
                </ColCall>
                <ColForm>
                    <RowIn>
                        <Select
                            selectOptions={complaitnsTypeProcessing}
                            selectedOption={dataObj.type}
                            valueKey='type'
                            label='Дія'
                            mode={'primary'}
                            isShowError={!!error.idOrder}
                            error={error.type}
                            disabled={true}
                            onChange={({ value }) => setDate("type", value)}
                        />
                    </RowIn>

                    <RowIn>
                        <TextInput
                            title="Бонуси"
                            type="number"
                            value={dataObj.value}
                            isStyle={true}
                            min={0}
                            max={5000}
                            error={error.value}
                            onChange={(e) => setDate("value", e.target.value)}
                        />
                    </RowIn>

                    <RowIn>
                        <Select
                            selectOptions={restaurantsMappedLabelId(restaurants)}
                            selectedOption={dataObj.restaurant}
                            valueKey='restaurant'
                            label='Ресторан'
                            mode={'primary'}
                            isShowError={!!error.restaurant}
                            error={error.restaurant}
                            onChange={({ value }) => setDate("restaurant", value)}
                        />
                    </RowIn>
                </ColForm>
            </RowWr>

            <Wrcenter>
                <ButtonIcon
                    title="Додати"
                    onClick={handleSubmit}
                    isLoad={isLoadBnt}
                    disabled={!isValidBtn}
                />
            </Wrcenter>
        </ModalLayout>
    )
}

const Empty = styled.div`
    text-align: center;
    color: #000;
`;

const TalkTime = styled.div`
    padding: 0 0 5px 20px;
    color: #000;
    font-size: 13px;
`;

const BodyCalls = styled.div`
    max-height: 300px;
    overflow: auto;
    padding: 0px 10px 0px 0px;
    margin-top: 20px;
`;

const UlList = styled.ul``;

const UlLi = styled.li`
    &:not(first-child) {
        margin-top: 10px;
    }
    audio {
        width: 100%;
    }
`;

const WrCalendar = styled.div`
    margin: -7px 0 -3px 0;
`;

const RowWr = styled.div`
    display: flex;
    margin-top: 20px;
`;

const ColForm = styled.div`
    width: 50%;
    padding-left: 10px;
    border-left: 1px solid ${(p) => p.theme.accentColor};
`;

const ColCall = styled.div`
    width: 50%;
    padding-right: 10px;
`;

const InfoClient = styled.div`
    text-align: center;
    font-size: 14px;
    margin-top: 5px;
    border-bottom: 1px solid ${(p) => p.theme.accentColor};
    padding-bottom: 15px;
    margin-bottom: 10px;
    a {
        color: blue;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const Title = styled.div`
    text-align: center;
    font-size: 16px;
    font-weight: 700;
`;

const RowIn = styled.div`
    margin-bottom: 15px;
`;

const Wrcenter = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 30px;
`;

export default ModalFormComplaintProcessing;