import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as PaymentPage } from './Payment';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        view,
        paymentData: settings.payment,
        paymentDataEdit: settings.paymentEditForm,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentPage);
