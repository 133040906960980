import { useEffect, useCallback, useState, useRef } from "react";
import { useTheme } from "styled-components";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";

import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import ModalLayout from "components/modals/ModalLayout";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import CalendarRange from "components/ui-kit/CalendarRange";
import { ModalContent } from "./modalContent";

import { rangeConverDate } from "utils/helpers/date";
import { getReceiptStatisticsReport } from "store/actions/reports";

import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

export const ModalReport = ({
    // props
    title = '',
    isOpen = false,
    onCloseModal = () => { },
}) => {
    const [filterDate, setFilterDate] = useState({
        startDate: new Date(),
        endDate: new Date(),
        key: "selection",
    });
    const [data, setData] = useState(null);
    const [isLoad, setIsLoad] = useState(false);
    const { user: { user } } = useSelector(state => state);
    const componentRef = useRef(undefined);
    const reportRef = useRef(undefined);
    const theme = useTheme();
    const handlerCloseModal = () => onCloseModal();

    const handlePring = useReactToPrint({ content: () => componentRef.current });
    const handleSaveExcel = () => {
        reportRef.current?.saveExcel();
    }

    const handlerSubmitDate = useCallback(async () => {
        const { date_from, date_to } = rangeConverDate([filterDate]);
        let data = await getReceiptStatisticsReport({ date_from, date_to }, setIsLoad);
        setData(data);
    }, [setData, setIsLoad, user, filterDate]);

    useEffect(() => {
        handlerSubmitDate();
    }, []);

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={handlerCloseModal}
            dopClass='m-kitchen-counter'
        >
            <div className="head-modal-f">
                <div className="head-modal-f__title">{title}</div>
                <div className="filter-model">
                    <CalendarRange
                        isAbs={true}
                        isStyle={true}
                        isTitle={false}
                        isBtn={true}
                        valueIn={[filterDate]}
                        ranges={[filterDate]}
                        // minDate={diffDateMonth(1)}
                        onChangeCal={(date) => setFilterDate(date.selection)}
                        handlerSubmit={handlerSubmitDate}
                    />
                    <ButtonIcon
                        onClick={handlePring}
                        icon={<LocalPrintshopIcon />}
                        colorBg="blue"
                        disabled={isLoad}
                    />

                    <ButtonIcon
                        title="Завантажити Excel звіт"
                        isMin={true}
                        width="100%"
                        icon={<FileDownloadIcon />}
                        colorBg="blue"
                        onClick={handleSaveExcel}
                    />
                </div>
            </div>

            <div className="cont-modal-sc">
                {
                    !isLoad && (
                        <ModalContent
                            data={data} 
                            printRef={componentRef} 
                            reportRef={reportRef}
                        />
                    )
                }
                {
                    isLoad && (
                        <LoadingCircl mins={true} />
                    )
                }
            </div>
        </ModalLayout>
    )
}
