import React, { useState, useEffect, useCallback } from "react";
import styled, { useTheme } from "styled-components";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import BugReportIcon from "@mui/icons-material/BugReport";
import CloseIcon from "@mui/icons-material/Close";

// validation
import { validateBugReportForm } from "utils/validation";

// utils
import { withLoading } from "utils/helpers/withLoading";

// components
import Radio from "components/ui-kit/Radio";
import Select from "components/ui-kit/Select";
import { TextArea } from "components/ui-kit/inputs/TextArea";

// styles
import styles from "./ModalBugReport.module.scss";

// constants
import { RADIO_BUG_SEVERITY } from "constants/schemes";
import { selectPages } from "constants/selectPages";

const errorsInitialState = {
    actual: '',
    expected: '',
    steps: '',
}

const ModalBugReport = ({
    // state
    isOpen,
    // mapDispatch
    closeModalBugReport,
    createBugReport,
}) => {
    const theme = useTheme();
    
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(errorsInitialState);

    const [severity, setSeverity] = useState('minor');
    const [pages, setPages] = useState([]);

    const [actual, setActual] = useState('');
    const [expected, setExpected] = useState('');
    const [steps, setSteps] = useState('');

    const resetForm = React.useCallback(() => {
        const callables = [setActual, setExpected, setSteps];
        for (const setFieldValue of callables) {
            setFieldValue('');
        }
        // reset severity & pages & errors
        setSeverity('minor');
        setPages([]);
        setErrors(errorsInitialState);
        //

    }, [setSeverity, setPages, setActual, setExpected, setSteps]);

    const handleSubmit = React.useCallback(async () => {
        console.debug('submit bug report');
        const dataObj = {
            pages,
            severity,
            actual,
            expected,
            steps,
        }

        validateBugReportForm({
            data: dataObj,
            onSuccess: async () => {
                await withLoading(setLoading, () => {
                    setErrors(errorsInitialState);
                    return createBugReport(dataObj);
                });
                
                closeModalBugReport();
                resetForm();
            },
            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });


    }, [
        closeModalBugReport, createBugReport, resetForm, setLoading,
        pages, severity, actual, expected, steps
    ]);

    const handleCancel = () => {
        console.debug('cancel bug report');

        closeModalBugReport();
        resetForm();
    }

    return (
        <>
            {
                isOpen && (
                    <BgModal onClick={() => closeModalBugReport()} />
                )
            }

            <Wrapper theme={theme} isOpen={isOpen}>
                <Header>
                    <div className={styles.header}>
                        <p className={`${styles.header__name} font-600`}>
                            Повідомити про помилку
                        </p>
                        <CloseButton onClick={() => closeModalBugReport()}>
                            <CloseIcon style={{ fontSize: 36 }} />
                        </CloseButton>
                    </div>
                </Header>
                <Block>
                    <WrIn>
                        <Radio
                            colors={"#ffffff"}
                            items={RADIO_BUG_SEVERITY}
                            label="Виберіть критичність багу"
                            valueKey="severity"
                            value={severity}
                            onChange={(e) => setSeverity(e.value.value)}
                        />
                    </WrIn>
                    <WrIn>
                        <Select
                            selectOptions={selectPages}
                            selectedOption={pages}
                            label='На яких сторінках баг?'
                            isMulti
                            onChange={({ value }) => setPages(value)}
                            valueKey='pages'
                        />
                    </WrIn>
                    <WrIn>
                        <TextArea
                            title="Як працює зараз"
                            value={actual}
                            onChange={(e) => setActual(e.target.value)}
                            resize="vertical"
                            error={errors.actual}
                            autoSize
                        />
                    </WrIn>
                    <WrIn>
                        <TextArea
                            title="Як має працювати"
                            value={expected}
                            onChange={(e) => setExpected(e.target.value)}
                            resize="vertical"
                            error={errors.expected}
                            autoSize
                        />
                    </WrIn>
                    <WrIn>
                        <TextArea
                            title="Кроки для повторення"
                            value={steps}
                            onChange={(e) => setSteps(e.target.value)}
                            resize="vertical"
                            error={errors.steps}
                            autoSize
                        />
                    </WrIn>
                </Block>
                <Menu>
                    <Item>
                        <ButtonIcon
                            onClick={handleSubmit}
                            title='Відправити'
                            icon={<BugReportIcon />}
                            isLoad={loading}
                            disabled={loading}
                        />
                    </Item>
                    <Item>
                        <ButtonIcon
                            onClick={handleCancel}
                            title='Скинути'
                            icon={<CloseIcon />}
                            disabled={loading}
                        />
                    </Item>
                </Menu>
            </Wrapper>
        </>

    );
};

const Wrapper = styled.div`
  position: fixed;
  right: ${(p) => (p.isOpen ? "0" : "-1000px")};
  top: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 460px;
  padding-bottom: 30px;
  background-color: ${(p) => p.theme.background};
  transition: 200ms right;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 10px 20px;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.accentColor};
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px 15px;
  margin-bottom: 40px;
  
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const WrIn = styled.div`
  &:not(:first-child) {
      margin-top: 15px;
  }
`;

const Menu = styled.ul`
  position: fixed;
  bottom: 0;
  display: flex;
`;

const Item = styled.li`
  margin: 0 20px 15px 0;
  &:last-child {
    margin-right: 0;
  }
`;

const BgModal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.65);
`;

export default ModalBugReport;
