import api from "../../apiSingleton";

import {
    GET_REPORTS_LIST,
    UPDATE_REPORTS_ACTIVE,
    UPDATE_FILTER_REPORTS,
    GET_REPORTS_OF_CODE,
    UPDATE_FILTER_REPORTS_ARR
} from "../constants/reports";

import {
    updadeLoadPageClients,
} from './view';

import { rangeConverDate } from "utils/helpers/date";

import {
    addNotification
} from './notifications';
import { reportFilterRestKit } from "utils/helpers/reports";

export function getReportsList() {
    return async (dispatch, state) => {
        const { user: { user } } = state();
        let res = await api.reports.getReports();
        let allKitchensAvailable = user.kitchens_statistic_status === "all";

        if (res?.data) {
            await dispatch({
                type: GET_REPORTS_LIST,
                payload: allKitchensAvailable ? 
                    res.data : 
                    res.data.filter(report => report.params.code !== 'unused_courier_late_bonus'),
            });
        }
    };
}

export const updateActiveSelect = (value) => {
    return {
        type: UPDATE_REPORTS_ACTIVE,
        payload: value,
    }
}

export const updateFilterAction = (name, value) => {
    return {
        type: UPDATE_FILTER_REPORTS,
        payload: { name, value },
    }
}

export const updateFilterArr = (name, value, isArr = false) => {
    return {
        type: UPDATE_FILTER_REPORTS_ARR,
        payload: { name, value, isArr },
    }
}

export function getReportsOfType() {
    return async (dispatch, state) => {
        const { user: { user }, reports: { filter, activeSelect }, settings: { kitchens, restaurants, cities, productCategories } } = state();
        const code = activeSelect?.params?.code;

        const allKitchensAvailable = user.kitchens_statistic_status === "all";
        const availableKitchens = allKitchensAvailable ?
            kitchens : kitchens.filter((kitchen) => user.kitchens?.includes(kitchen.id));

        if (code) {
            let params = {
                code,
                restaurants: reportFilterRestKit(filter.filterRestorantAll == "all", filter.filterRestorant, restaurants),
                kitchen_codes: reportFilterRestKit(filter.filterKitchenAll == "all", filter.filterKitchen, availableKitchens),
                cities: reportFilterRestKit(filter.filterCityAll == "all", filter.filterCity, cities),
                categories: reportFilterRestKit(filter.filterCategoryAll == "all", filter.filterCategory, productCategories),
                ...rangeConverDate(filter.date),
            }

            dispatch(updadeLoadPageClients(true));
            // if (code === 'average_time_by_status_pickup') params.kitchen_codes = 'sadybna';

            let res = await api.reports.getReportsOfType(params);

            if (res?.errors) {
                await dispatch(addNotification(res.errors, 'error'));
            } else {
                await dispatch({
                    type: GET_REPORTS_OF_CODE,
                    payload: res.data,
                });
            }

            dispatch(updadeLoadPageClients(false));
        }
    };
}

export async function getReceiptStatisticsReport(params, setLoad) {
    setLoad(true);
    const res = await api.reports.getReceiptStatisticsReport(params);
    setLoad(false);

    if (res?.status === "Ok")
        return res.data;

    return null;
}

export function clearReports() {
    return async (dispatch, state) => {
        return dispatch({
            type: GET_REPORTS_OF_CODE,
            payload: [],
        })
    }
}