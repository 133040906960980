import { isArray } from "lodash";
import React from "react";

import { ItemCol } from "./itemCol";

export const ItemRow = ({ data }) => {
    return (
        isArray(data) && data.map((itemRow, indexRow) => {
            return (
                <div className="report-row-table" data-row={indexRow} key={indexRow}>
                    <ItemCol indexRow={indexRow} itemRow={itemRow} />
                </div>
            )
        })
    )
}