// redux
import { connect } from "react-redux";

// component
import { KitchenDeliveryTimes } from "./KitchenDeliveryTimes";

// actions
import { getKitchen } from "store/actions/settings";

const mapStateToProps = ({ settings }) => ({
    kitchens: settings.kitchens,
    restaurants: settings.restaurants,
    deliveryTime: settings.editKitchens?.delivery_time,
});

const mapDispatchToProps = { getKitchen };

export default connect(mapStateToProps, mapDispatchToProps)(KitchenDeliveryTimes);
