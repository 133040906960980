import * as React from "react";

// virtual keyboard
import Keyboard from "react-simple-keyboard";

// virtual keyboard styles
import styles from "./VirtualKeyboard.module.scss";
import "react-simple-keyboard/build/css/index.css";

import classNames from "classnames";

// layouts
import { numbers, en, list } from "./layouts";

const VirtualKeyboardComponent = ({ onChange, isOpen, isNumeric, ...props }, ref) => {
  const [layout, setLayout] = React.useState(en);
  const [layoutName, setLayoutName] = React.useState("default");

  React.useLayoutEffect(() => {
    const overflow = document.body.style.overflowY;
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = overflow;
    }
  }, []);

  const onKeyPress = (button) => {
    switch (button) {
      case "{shift}":
        return handleShift();
      case "{locale}":
        return handleLayoutChange();
      default:
        return;
    }
  }

  const handleShift = () => {
    setLayoutName((layoutName) => {
      return layoutName === "default" ? "shift" : "default";
    });
  }

  const handleLayoutChange = () => {
    setLayout((prevLayout) => {
      const nextLayoutIndex = list.indexOf(prevLayout) + 1;
      const maxLayoutIndex = list.length - 1;

      if (nextLayoutIndex > maxLayoutIndex) return list[0];
      return list[nextLayoutIndex];
    });
  }

  return isOpen && (
    <div className={styles.wrapper}>
      <Keyboard
        keyboardRef={(r) => ref.current = r}
        layoutName={layoutName}
        onKeyPress={onKeyPress}
        onChange={onChange}
        layout={isNumeric ? numbers : layout}
        theme={classNames("hg-theme-default", styles.smaki)}
        display={{ "{locale}": layout.name }}
        mergeDisplay
        {...props}
      />
    </div>
  );
}

export const VirtualKeyboard = React.forwardRef(VirtualKeyboardComponent);
