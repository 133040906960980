import React from 'react';
import { DataGridPremium } from '@mui/x-data-grid-premium';
import { Typography } from '@mui/material';

const AllSalesTable = ({ data }) => {
  const allSales = data?.all_sales ?? { list: {}, total: {
    sum: 0,
    bonus: 0,
  }};

  const columns = [
    { field: 'label', headerName: 'Заклад', width: 150 },
    { field: 'sum', headerName: 'Сума', width: 150, renderCell: (params) => <span>{params.row.sum} ₴</span> },
    { field: 'bonus', headerName: 'Знижка', width: 150, renderCell: (params) => <span>{params.row.bonus} ₴</span> },
  ];

  const rows = Object.keys(allSales.list).map((item) => ({
    id: item,
    label: item,
    sum: allSales.list[item].sum,
    bonus: allSales.list[item].bonus,
  }));

  // Add a total row
  rows.push({
    id: 'total',
    label: 'Всього',
    sum: allSales.total.sum,
    bonus: allSales.total.bonus,
  });

  return (
    <React.Fragment>
      <Typography variant='h5'>За закладом</Typography>
      <DataGridPremium
        rows={rows}
        columns={columns}
        autoHeight
        disableColumnFilter
        disableColumnMenu
        density='compact'
        hideFooter
      />
    </React.Fragment>
  );
};

export default React.memo(AllSalesTable);
