import { isObject } from "lodash";
import React from "react";
import styled, { useTheme } from "styled-components";

const TableReviewsRatings = ({
    data,
}) => {
    const theme = useTheme();

    const renderBody = () => {
        const renderCells = (itemObj) => {
            return Object.keys(itemObj).map((itemKey, itemIndex) => {
                if (itemKey == "title") {
                    return <Cell key={itemIndex}>{itemObj[itemKey]}</Cell>
                } else {
                    return Object.keys(itemObj.ratings).map((key, index) => {
                        switch (key) {
                            case "all": {
                                return <Cell key={index}>{itemObj.ratings[key]}</Cell>
                            }
                            default: {
                                return <Cell key={index}>{itemObj.ratings[key].count}% ({itemObj.ratings[key].percent})</Cell>
                            }
                        }
                    })
                }
            })
        }

        const HTMLelement = Object.keys(data).map((key, index) => {
            return (
                <Row key={index}>
                    {renderCells(data[key], index)}
                </Row>
            );
        });

        return HTMLelement;
    };

    return (
        <>
            <Wrapper theme={theme}>
                {
                    isObject(data) && (Object.keys(data).length > 0) && (
                        <Tbody>{renderBody()}</Tbody>
                    )
                }
            </Wrapper>
        </>
    );
};


const Wrapper = styled.table`
  display: table;
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
`;

const Tbody = styled.tbody``;

const Row = styled.tr``;

const Cell = styled.td`
  padding: 5px;
  border: 1px solid ${(p) => p.theme.maskColor};
  position: relative;
  a {
    display: block;
  }
  a::after  {
    content: '',
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export default React.memo(TableReviewsRatings);
