import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
} from "react-router-dom";
import { isArray } from "lodash";

import MenuPage from "components/menuPage";

import styles from "./StatisticPage.module.scss";

import { convertObjMenu } from "utils/helpers/menus";
import { searchIndex } from "utils/helpers/searchIndexs";

import { pageStatistic } from "constants/selectPages";

import PageCouriers from "./Couriers";
import PageOrders from "./Orders";
import PageSelfPickup from "./SelfPickup";
import PageReviews from "./PageReviews";
import PageComplaints from "./Complaints";

const selectPagesStatistic = [
  {
    value: '/couriers',
    page: PageCouriers,
  },
  {
    value: '/orders',
    page: PageOrders,
  },
  {
    value: '/self_pickup',
    page: PageSelfPickup,
  },
  {
    value: '/reviews',
    page: PageReviews,
  },
  {
    value: '/complaints',
    page: PageComplaints,
  },
];

function StatisticPage({
  // state
  pages,
  statisticsPages
}) {
  const { path } = useRouteMatch();
  let pagesStatistic = [...pages?.[searchIndex(pages, pageStatistic)]?.pages || [], ...statisticsPages];

  return (
    <div>
      <MenuPage items={convertObjMenu(pagesStatistic)} />

      <div className={styles.block}>
        <Switch>
          {
            isArray(pagesStatistic) && pagesStatistic.map((el, index) => {
              let urlPage = '';
              let page = selectPagesStatistic.find((elPage) => elPage.value === el.value).page;

              if (index != 0)
                urlPage = el.value;

              return (
                <Route key={index} component={page} exact path={`${path}${urlPage}`} />
              )
            })
          }
        </Switch>
      </div>
    </div>
  );
}

export default React.memo(StatisticPage);
