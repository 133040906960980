import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as settingsActions from "store/actions/settings";

import { default as ModalBugReport } from "./ModalBugReport";

function mapStateToProps(state) {
    return {
        isOpen: state.view.modalBugReport.isOpen,
    };
}

const mapDispatchToProps = {
    ...viewActions,
    ...settingsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalBugReport);
