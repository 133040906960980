export const kitchensName = (valueArray = [], kitchens = []) => {
    let strKitchens = '';

    valueArray.map((item, index) => {
        kitchens.map((el) => {
            if (item === el.id) {
                strKitchens += el.title;

                if ((valueArray.length - 1) > index) {
                    strKitchens += ', ';
                }
            }
        });
    });

    return strKitchens;
}