import * as React from "react";

import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import classNames from "classnames";

import { MenuItem } from "./menuItem";

import ButtonIcon from "components/ui-kit/ButtonIcon";

// Kitchen Delivery time button
import KitchenDeliveryTimeButton from "pages/Kitchen/KitchenDeliveryTimeButton";

import AddIcon from "@mui/icons-material/Add";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CourierReportIcon from '@mui/icons-material/DeliveryDining';

import styles from "./scss/menuPage.module.scss";

const MenuPage = ({
    // props
    items = {},
    borderless = false,
    marginless = false,
    activeTabs,
    setActiveTabs,
    isBtnAdd = false,
    isBtnPrint = false,
    isBtnKitchenCounter = false,
    isBtnCourierReport = false,
    isBtnKitchenDeliveryTime = false,
    disabledOnClosedShift = true,
    handleCreateOrder = () => { },
    handlePrintAll = () => { },
    handleBtnKitchenCounter = () => { },
    handleBtnCourierReport = () => { },
    handleBtnKitchenDeliveryTime = () => { },
}) => {
    const { kitchenShiftStatus: { isShiftClosed }, kitchenShiftLoading } = useSelector(state => state.kitchens);
    const onHandle = (key) => {
        setActiveTabs((prev) => {
            const selected = prev.includes(key);
            if (selected && prev.length > 1) {
                return prev.filter(k => k !== key);
            } else if (!selected) {
                return [...prev, key];
            }
            return prev;
        })
    }

    const wrStyles = classNames(styles.wr, { [styles.borderless]: borderless });
    const ulStyles = classNames(styles.ul, { [styles.marginless]: marginless });

    return (
        <div className={wrStyles}>
            <ul className={ulStyles}>
                {
                    Object.keys(items).map((key) => {
                        let active = activeTabs.indexOf(key) != -1;

                        return (
                            <MenuItem
                                key={key}
                                inKey={key}
                                isActive={active}
                                isDisabled={disabledOnClosedShift && (isShiftClosed || kitchenShiftLoading)}
                                icon={items[key]?.icon}
                                title={items[key].title}
                                onHandle={onHandle}
                            />
                        )
                    })
                }
            </ul>

            <div className={styles.btns_r}>
                {
                    !!isBtnKitchenDeliveryTime && (
                        <div>
                            <KitchenDeliveryTimeButton
                                handler={handleBtnKitchenDeliveryTime}
                            />
                        </div>
                    )
                }
                {
                    !!isBtnKitchenCounter && (
                        <div>
                            <ButtonIcon
                                onClick={handleBtnKitchenCounter}
                                icon={<CurrencyExchangeIcon />}
                                positionIcon="left"
                            />
                        </div>
                    )
                }
                {
                    !!isBtnCourierReport && (
                        <div>
                            <ButtonIcon
                                onClick={handleBtnCourierReport}
                                icon={<CourierReportIcon />}
                                positionIcon="left"
                            />
                        </div>
                    )
                }
                {
                    !!isBtnPrint && (
                        <div>
                            <ButtonIcon
                                onClick={handlePrintAll}
                                icon={<LocalPrintshopIcon />}
                                positionIcon="left"
                            />
                        </div>
                    )
                }
                {
                    !!isBtnAdd && (
                        <div>
                            <ButtonIcon
                                onClick={handleCreateOrder}
                                icon={<AddIcon />}
                                positionIcon="left"
                                disabled={isShiftClosed}
                            />
                        </div>
                    )
                }
            </div>
        </div>
    )
}

MenuPage.prototype = {
    items: PropTypes.object,
    borderless: PropTypes.bool,
    disabledOnClosedShift: PropTypes.bool,
};

export default React.memo(MenuPage);