import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";

import MenuGeneral from "components/menuGeneral";

import styles from "./../scss/settings.module.scss";

import { generalDataItemsMenuMagazine } from 'constants/menuPageSettings';

import PageMagazineComplaints from '../PageMagazineComplaints';

const Home = () => {
    let { path } = useRouteMatch();

    return (
        <div className={styles.ge_wr}>
            <div className={styles.ge_menu}>
                <MenuGeneral items={generalDataItemsMenuMagazine} />
            </div>
            <div className={styles.ge_main}>
                <Switch>
                    <Route component={PageMagazineComplaints} exact path={`${path}${generalDataItemsMenuMagazine['common'].path}`} />
                </Switch>
            </div>
        </div>
    )
}

export default Home;