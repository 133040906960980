import styled, { useTheme } from "styled-components";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { InputSearch } from "components/ui-kit/inputs/InputSearch";

import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import SearchIcon from "@mui/icons-material/Search";


export const Header = ({
    handleCreateOrder = () => { },
    handleShowFilter = () => { },
    updateFormSearchOrders = () => { },
    handleІSybmitSearchOrders = () => { },
    kitchenName = '',
    closedOrders = false,
    closeReject = false,
    isBtnFilter = true,
    statusSearch = 0,
    isCreat = true,
    isAtiveFilterBtn = false,
    searchValue = "",
}) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { rejected, closed } = useSelector(state => state.listings.ordersCount || { rejected: 0, closed: 0 });

    const onEnter = (e) => {
        if (e.keyCode === 13) {
            handleІSybmitSearchOrders(statusSearch += 1);
        }
    }

    useEffect(() => {
        if (searchValue.length == 0) {
            handleІSybmitSearchOrders(0);
            updateFormSearchOrders("");
        }
    }, [searchValue]);

    return (
        <>
            {
                (kitchenName.length > 0) && (
                    <>
                        <TitleCenter><span>Кухня:</span> {kitchenName}</TitleCenter>
                    </>
                )
            }
            <Wr theme={theme}>
                <Left>
                    <Label>Замовлення</Label>
                </Left>
                <Right>
                    <WrBtn>
                        <InputSearch
                            placeholder="Пошук замовлення"
                            value={searchValue}
                            onChange={(e) => dispatch(updateFormSearchOrders(e.target.value))}
                            onKeyDown={(e) => onEnter(e)}
                        />
                        {
                            statusSearch > 0 && (
                                <Ml>
                                    <ButtonIcon
                                        icon={<CleaningServicesIcon />}
                                        colorBg="red"
                                        onClick={() => {
                                            handleІSybmitSearchOrders(0);
                                            updateFormSearchOrders("");
                                        }}
                                    />
                                </Ml>
                            )
                        }
                        <Ml>
                            <ButtonIcon
                                icon={<SearchIcon />}
                                disabled={searchValue.length == 0}
                                onClick={() => { handleІSybmitSearchOrders(statusSearch += 1) }}
                            />
                        </Ml>
                    </WrBtn>

                    <WrBtn>
                        <NavLink
                            to={`${!closedOrders ? `/callcenter/closed` : '/callcenter'}`}
                        >
                            <NavLinks>
                                <span>{!closedOrders ? `Завершені (${closed})` : "Активні"}</span>
                            </NavLinks>
                        </NavLink>
                    </WrBtn>
                    <WrBtn>
                        <NavLink
                            to={`${!closeReject ? `/callcenter/rejected` : '/callcenter'}`}
                        >
                            <NavLinks>
                                <span>{!closeReject ? `Скасовані (${rejected})` : "Активні"}</span>
                            </NavLinks>
                        </NavLink>
                    </WrBtn>
                    {
                        isBtnFilter && (
                            <WrBtn>
                                <ButtonIcon
                                    onClick={handleShowFilter}
                                    title='Фільтр'
                                    icon={<FilterAltIcon />}
                                    colorBg={isAtiveFilterBtn ? "green" : "#9D9D9D"}
                                />
                            </WrBtn>
                        )
                    }
                    {
                        isCreat && (
                            <ButtonIcon
                                onClick={handleCreateOrder}
                                icon={<AddIcon />}
                                positionIcon="left"
                            />
                        )
                    }
                </Right>
            </Wr>
        </>
    )
}

const Ml = styled.div`
    margin-left: 10px;
`;

const NavLinks = styled.span`
        border: 1px solid #EDA240;
                        padding: 5px 16px;
                        border-radius: 8px;
                        cursor: pointer;
                        transition: all 0.3s ease;
                        font-size: 14px;
                        font-weight: 500;
                        color: #EDA240;
                        white-space: nowrap;
                        text-align: center;
                        display: flex;
                        align-items: center;
                        background: transparent;
                        min-height: 42px;
                        &:hover,
                        &.active {
                            background: #EDA240;
                        color: #FFF;
                        user-select: none;
        }
                        `;

const Wr = styled.div`
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        background: #FAFAFA;
                        position: sticky;
                        top: 50px;
                        z-index: 100;
                        padding: 6px 0;
                        border-bottom: 1px solid #EDF2F9;
                        `;

const Left = styled.div`
                        display: flex;
                        align-items: center;
                        `;

const Label = styled.div`
                        font-weight: 600;
                        font-size: 20px;
                        color: ${(p) => p.theme.color25};
                        `;

const WrBtn = styled.div`
                        margin-right: 24px;
                        display: flex;
                        align-items: center;
                        `;

const Right = styled.div`
                        display: flex;
                        align-items: center;
                        `;

const TitleCenter = styled.div`
                        font-size: 16px;
                        font-weight: 600;
                        margin: 0 0 24px 0;
                        text-align: center;
                        border-bottom: 1px solid #EDF2F9;
                        padding-bottom: 15px;
                        span {
                            font - weight: 400;
    }
                        `;