import React, { useEffect, useState } from "react";
import { concat } from "lodash";
import {
    useRouteMatch,
} from "react-router-dom";

import Orders from "../OrderList";
import ModalCooksBtn from "components/modals/ModalCooksBtn";
import { menuCookOther } from "constants/menuCook";

const selectobj = (cookAll, path) => {
    switch (path) {
        case `/cook${menuCookOther.prepared.path}`: {
            return cookAll['preparing'];
        }
        case `/cook${menuCookOther.pending.path}`: {
            return cookAll['pending_confirmed'];
        }
        case `/cook${menuCookOther.other.path}`: {
            const { on_way: onWay, for_delivery: forDelivery } = cookAll;
            return concat(onWay || [], forDelivery || []);
        }
    }
}

const Other = ({
    //actions
    cook,
    getOrderByStatusForChef,
    getCooksInTheKitchen,
    returnToTheKitchen,
}) => {
    const [idOrder, setIdOrder] = useState(undefined);
    const [isModal, setIsModal] = useState(false);
    const [isLoadModal, setIsLoadModal] = useState(false);
    const { path } = useRouteMatch();

    const isPending = (path === `/cook${menuCookOther.pending.path}`);

    useEffect(() => {
        getOrderByStatusForChef();
        getCooksInTheKitchen();

        const interval = setInterval(() => {
            getOrderByStatusForChef();
        }, 20000);

        return () => clearInterval(interval);
    }, []);

    const handlerPreparing = async (data) => {
        if (path == `/cook${menuCookOther.prepared.path}`) {
            await setIdOrder(data.id);
            await setIsModal(true);
        }
    }

    const handleSubmit = async () => {
        await returnToTheKitchen(idOrder, setIsLoadModal, handlerCloseModal);
    }

    const handlerCloseModal = async () => {
        await setIdOrder(undefined);
        await setIsModal(false);
    }

    return (
        <>
            <Orders
                isPrepared={!isPending}
                data={selectobj(cook.orders, path)}
                handlerPreparing={handlerPreparing}
            />

            <ModalCooksBtn
                handleSubmit={handleSubmit}
                handlerCloseModal={handlerCloseModal}
                idOrder={idOrder}
                title={`№ ${idOrder}`}
                text={`Повернути замовлення на статус готується ?`}
                isOpen={isModal}
                isLoad={isLoadModal}
            />
        </>
    )
}

export default Other;