import React from "react";
import styled from "styled-components";

const InputPhone = ({
  value = "",
  width = "170px",
  onChange = () => { },
  isTitle = true,
  isStyle = false,
  disabled = false,
  onKeyDown
}) => {
  const handleChange = (e) => {
    const { value } = e.target;
    let va = value.trim().replace(/[^\d\+]/g, '');

    if (va.length < 14) onChange(va);
  };

  return (
    <Wrapper
      width={width}
      isStyle={isStyle}
    >
      {
        isTitle && <Title isStyle={isStyle}>Телефон</Title>
      }
      <Field
        type='text'
        value={value}
        placeholder='xxxxxxxxxxxx'
        onChange={handleChange}
        isStyle={isStyle}
        disabled={disabled}
        onKeyDown={onKeyDown}
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  width: ${(p) => p.width};
  margin: ${(p) => !p.isStyle ? '5px 0' : 0};
`;

const Title = styled.p`
  position: relative;
  margin-bottom: 5px;
  padding: 0px 3px;
  border-radius: 3px;
  color: ${(p) => p.isStyle ? "#000" : p.theme.secondaryColor};
  font-size: 14px;
`;

const Field = styled.input`
  width: 100%;
  margin-top: ${(p) => !p.isStyle ? '5px' : 0};
  padding: ${(p) => !p.isStyle ? "10px 8px 8px 10px" : "10px 8px 8px 10px"};
  background-color:  ${(p) => !p.isStyle ? p.theme.backgroundLight : "#FFFFFF"};
  border: ${(p) => !p.isStyle ? p.theme.inputBorder : p.theme.inputBorderDE};
  border-radius: 8px;
  color: ${(p) => !p.isStyle ? p.theme.secondaryColor : "#000"};
  height: ${(p) => !p.isStyle ? "auto" : "42px"};
  font-size: ${(p) => !p.isStyle ? "18px" : "14px"};
  font-family: Rubik;
  outline: none;
  &:active,
  &:focus {
    border: 1px solid ${(p) => p.theme.accentColor};
  }
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.7;
    background: rgba(0, 0, 0, 0.2);
  }
`;

export default InputPhone;
