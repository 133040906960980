import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import { isArray } from "lodash";
import api from "apiSingleton";

import { resetOrder } from "store/actions/order";
import { TabDelivery } from "./Tabs/TabDelivery";
import { TabOrder } from "./Tabs/TabOrder";
import { TabPayments } from "./Tabs/TabPayments";
import { TabHistory } from "./Tabs/TabHistory";
import { TabReject } from "./Tabs/TabReject";
import { LoadingModal } from "../../loadingOrder/loading";
import ModalReason from "../ModalReason/ModalReason";
import ModalDelete from "../ModalDelete";
import OrderAll from "./orderAll";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import ModalListOfCouriers from "components/modals/ModalListOfCouriers";

import { calculateSumItems, calculateSumPayments, sumBonusAndOnline } from "utils/helpers/payment";
import { isActiveTabPosition, isActiveTabPayment } from "utils/helpers/isActivesTablesOrder";

import SaveIcon from "@mui/icons-material/Save";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import TelegramIcon from '@mui/icons-material/Telegram';
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { belonsToTheRestorantsProducts } from "utils/helpers/products";

const EditorCallcenter = ({
  isKitchen = false,
  isOpen,
  order,
  reason,
  closeEditorCallcenter,
  loadingsModal,
  getClientsExtensive,
  updateModalCourirers,
  getCouriers,
  isEditingOrder = false,
  promotions,
  isOpenModalReason,
  isLoadModalReason,
  kitchens,
  payment,
  updateOrderData,
  addSwappableBonusProduct,
  deleteBelonsProd,
  typeListDeliver,
  handlePrint = () => { },
  updateOrderStatus = () => { },
  updateFormReason = () => { },
  updateFormReasonStatus = () => { },
  createReason = () => { },
  updateAdressServer = () => { },
  getCallcenterOrderItem = () => { },
  SendAnOrderByTelegram = () => { },
  cleanFormReason = () => { },
  getReasonInformation = () => { },
  orderCreate,
  orderUpdate,
  getOrderPrint,
  isEditDeliver = true,
  isMap = true,
  isDeliveryOnlyPicup = false,
  reasonData,
}) => {
  if (!isOpen) {
    return <></>
  }

  const dispatch = useDispatch();
  const theme = useTheme();

  const [tab, setTab] = useState(1);
  const [isOpenModalSaveEditAddress, setIsOpenModalSaveEditAddress] = useState(false);
  const [isLoadModalSaveEditAddress, setIsLoadModalSaveEditAddress] = useState(false);
  const [isOpenOrderAll, setIsOpenOrderAll] = useState(false);
  const [isLoadBtnSendTelegram, setIsLoadBtnTelegram] = useState(false);
  const [activeCourier, setActiveCourier] = useState(null);
  const [modalWarnignBelongs, setModalWarnignBelongs] = useState({ open: false, data: [] })

  const isSelfPickup = order?.pickup == 1;
  const textBtnSubmit = "Зберегти";

  let isPaymentOk = false;
  let isLatLngOk = isSelfPickup || !!(order.address.latitude && order.address.longitude); // latlng not required for pickup
  let calAllSum = calculateSumItems({ payments: order.payments, items: order.items, data: order, isBonus: false });

  if (order.payments.find(el => el.free == 1)) {
    isPaymentOk = true;
  } else {
    let calPayments = calculateSumPayments(order.payments, payment);
    let bonusAndDiscount = sumBonusAndOnline(order.payments, order.discounts);
    let paymsAndBonusAndDis = calPayments + bonusAndDiscount;

    isPaymentOk = (paymsAndBonusAndDis || (calPayments > 0)) && ((calAllSum <= calPayments) || (paymsAndBonusAndDis >= calAllSum));
  }

  const handleSubmit = () => {
    let arrBelo = belonsToTheRestorantsProducts({ order: order });

    if (arrBelo.length > 0) {
      setModalWarnignBelongs({ open: true, data: arrBelo })
      return;
    }

    if (order.status === "draft" || order.id === "") {
      orderCreate(order, isKitchen, false, handlePrint);
    } else if (["for_delivery", "on_way", "preparing"].includes(order.status)) {
      handleSubmitEditSave();
    } else {
      // orderUpdate(order, "cooking", isKitchen);
      let newStatus = "pending_confirmed";
      if (order.type === "soon") {
        newStatus = "cooking";
      }
      orderUpdate(order, newStatus, isKitchen);
    }
  };

  const handleCloseModalBelon = () => setModalWarnignBelongs({ open: false, data: [] });

  const handleBelonDelete = () => {
    deleteBelonsProd({ prodBelons: modalWarnignBelongs.data });
    handleCloseModalBelon();
  }

  const handleSubmitEditSave = () => {
    orderUpdate(order, order.status, isKitchen);
  };

  const handleSendCooking = () => {
    orderUpdate(order, "cooking", isKitchen);
  };

  const handleCloseOrder = () => {
    if (!!order?.id) {
      updateFormReasonStatus("isOpenModalReason", true);
      getReasonInformation();
    } else {
      handleCloseWindow();
    }
  };

  const handleCloseModal = () => {
    updateFormReasonStatus("isOpenModalReason", false);
  };

  const handleReject = () => {
    createReason(isKitchen);
  }

  const handleCloseWindow = (isOrder = true) => {
    if (isOrder)
      dispatch(resetOrder());
    closeEditorCallcenter();
    closeOrderAll();
  };

  const handleSubmitOpenModalCouriers = () => {
    getCouriers();
    updateModalCourirers('isOpen', true);
  }

  const handleSubmitPreparing = () => {
    orderUpdate(order, "preparing", isKitchen);
  }

  const handleSubmitDelivered = () => {
    orderUpdate(order, "delivered", isKitchen);
  }

  const handleSubmitCompleted = () => {
    orderUpdate(order, "closed", isKitchen);
  }

  const openAndCloseModalSaveAddress = () => {
    setIsOpenModalSaveEditAddress(prev => !prev);
  }

  const handlerupdateAdressServer = () => {
    updateAdressServer(setIsOpenModalSaveEditAddress, setIsLoadModalSaveEditAddress, handleCloseWindow);
  }

  const handlePrints = async () => {
    await getOrderPrint();
    await handlePrint();
  }

  useEffect(() => {
    const newTab = (order.status === "rejected") ?
      5 : (isKitchen ? 1 : 1);

    setTab(newTab);

  }, [order.status]);

  useEffect(() => {
    // swappable bonus products are fetched when both resturant and client
    // are selected

    // also, do not fetch swappable bonus product when editing order
    // -- order id exists

    if (order.id || !order.client?.id || !order.restaurant || order.restaurant === '-') return;
    api.promos.getSwappableBonusProducts(order.restaurant, order.client.id, order.address.city_sync_id)
      .then((response) => {
        updateOrderData('swappable_bonus_products', response.data);
        addSwappableBonusProduct(response.data);
      })
      .catch(console.error);

  }, [order.client?.id, order.restaurant, order.address.city_sync_id]);

  useEffect(() => {
    if (tab === 3) {
      order.client.id && getClientsExtensive(order.client.id);
    }
  }, [tab]);

  useEffect(() => {
    cleanFormReason();
  }, [order.id]);

  const renderTabList = () => {
    return (
      <TabList>
        {
          order.status == "rejected" && (
            <TabItem>
              <TabButton isActive={tab === 5} onClick={() => setTab(5)}>
                Відміна
              </TabButton>
            </TabItem>
          )
        }
        <TabItem>
          <TabButton isActive={tab === 1} onClick={() => setTab(1)}>
            Доставка
          </TabButton>
        </TabItem>
        <TabItem>
          <TabButton disabled={isActiveTabPosition(order, isSelfPickup)} isActive={tab === 2} onClick={() => setTab(2)}>
            Позиції
          </TabButton>
        </TabItem>
        <TabItem>
          <TabButton disabled={isActiveTabPayment(order)} isActive={tab === 3} onClick={() => setTab(3)}>
            Оплати
          </TabButton>
        </TabItem>
        {(order.id > 0) && (
          <TabItem>
            <TabButton isActive={tab === 4} onClick={() => setTab(4)}>
              Статус
            </TabButton>
          </TabItem>
        )
        }
      </TabList>
    );
  };

  const openOrderAll = () => {
    setIsOpenOrderAll(true);
  }

  const closeOrderAll = () => {
    setIsOpenOrderAll(false);
  }

  const handlerGetOneOrder = async (id) => {
    getCallcenterOrderItem(id, { isNew: true });
  }

  // useEffect(() => {
  //   updateOrderData("restaurant", '-');
  // }, []);

  useEffect(() => {
    const courier = order?.courier ?? null;
    setActiveCourier(courier);
  }, [order?.courier]);

  return (
    <>
      {
        isOpen && (
          <BgModal />
        )
      }

      <Wrapper theme={theme} isOpen={isOpen}>
        <Header>
          <div style={{ color: "#fff" }}>Замовлення №{order?.id}</div>

          <CloseButton onClick={() => handleCloseWindow(false)}>
            <CloseIcon style={{ fontSize: 36 }} />
          </CloseButton>
        </Header>

        {
          isArray(promotions) && (tab === 2) && promotions.length > 0 && (
            <ActionsFixed>
              <span>* Доступні акції:</span>
              {
                promotions.map((item, index) => {
                  let name = item.name;

                  if ((promotions.length - 1) !== index) {
                    name += ", ";
                  }

                  return name;
                })
              }
            </ActionsFixed>
          )
        }

        {renderTabList()}

        <Block>
          {
            (tab === 2) && <TabOrder calAllSum={calAllSum} key={'2'} isEdit />
          }

          <div style={{ display: `${(tab === 3) ? "block" : "none"}` }}>
            <TabPayments key={'3'} isPaymentOk={isPaymentOk} isEdit />
          </div>

          {
            (tab === 4) && <TabHistory key={'4'} isKitchen={isKitchen} updateOrderStatus={updateOrderStatus} />
          }

          {
            (tab === 5) && <TabReject key={'5'} rejected={order.reason_rejected} />
          }

          {
            (tab === 1) && <TabDelivery
              isEdit
              key={'1'}
              isKitchen={isKitchen}
              isEditDeliver={isEditDeliver}
              openAndCloseModalSaveAddress={openAndCloseModalSaveAddress}
              openOrderAll={openOrderAll}
              isOpenOrder={isOpenOrderAll}
              isMap={isMap}
              isSelfPickup={isSelfPickup}
              isDeliveryOnlyPicup={isDeliveryOnlyPicup}
            />
          }
        </Block>

        {
          (order.status !== "rejected") && (
            <Menu>
              {
                (!["delivered", "closed"].includes(order.status)) && isPaymentOk && isLatLngOk && (
                  <Item>
                    <ButtonIcon
                      onClick={handleSubmit}
                      title={textBtnSubmit}
                      colorBg="green"
                      icon={order.status === "new" ? <ScheduleSendIcon /> : <SaveIcon />}
                    />
                  </Item>
                )
              }
              {
                isKitchen ? (
                  <>
                    {
                      (order.status === "pending_confirmed") && (
                        <Item>
                          <ButtonIcon
                            onClick={handleSendCooking}
                            title="Готувати"
                            colorBg="green"
                            icon={<SaveIcon />}
                          />
                        </Item>
                      )
                    }
                    {
                      (["preparing", "for_delivery", "on_way"].includes(order.status) && !order.pickup) && (
                        <Item>
                          <ButtonIcon
                            onClick={handleSubmitOpenModalCouriers}
                            title={activeCourier ? "Змінити кур'єра" : "Обрати кур'єра"}
                            icon={<DeliveryDiningIcon />}
                          />
                        </Item>
                      )
                    }
                    {
                      (order.status === "cooking") && isPaymentOk && (
                        <Item>
                          <ButtonIcon
                            colorBg="green"
                            onClick={handleSubmitPreparing}
                            title="Пакувати"
                            icon={<ScheduleSendIcon />}
                          />
                        </Item>
                      )
                    }
                    {/* {
                      // (order.status === "preparing") && (
                      (order.status === "preparing" && order.pickup) && (
                        <Item>
                          <ButtonIcon
                            colorBg="green"
                            onClick={handleSubmitDelivered}
                            title="Доставити"
                            icon={<ScheduleSendIcon />}
                          />
                        </Item>
                      )
                    } */}
                    {
                      isEditingOrder && (
                        <Item>
                          <ButtonIcon
                            colorBg="#3e73aa"
                            onClick={handlePrints}
                            icon={<LocalPrintshopIcon />}
                          />
                        </Item>
                      )
                    }
                  </>
                ) : (
                  <>
                    {
                      (order.status !== "draft") && (
                        <Item>
                          <ButtonIcon
                            onClick={() => SendAnOrderByTelegram(setIsLoadBtnTelegram)}
                            colorBg="blue"
                            title='Telegram кухня'
                            label="Відправити в телеграм на кухню"
                            isLoad={isLoadBtnSendTelegram}
                            icon={<TelegramIcon />}
                          />
                        </Item>
                      )
                    }
                  </>
                )
              }

              {
                (
                  isEditingOrder && ["for_delivery", "on_way", "delivered"].includes(order.status) ||
                  !!(order.pickup && order.status == 'preparing')
                ) && (
                  <Item>
                    <ButtonIcon
                      colorBg="green"
                      onClick={handleSubmitCompleted}
                      title="Завершити"
                      icon={<ScheduleSendIcon />}
                    />
                  </Item>
                )
              }

              {(!isKitchen || order.status !== "on_way") && (<Item>
                <ButtonIcon
                  onClick={handleCloseOrder}
                  title='Скасувати'
                  colorBg="red"
                  icon={<CancelIcon />}
                />
              </Item>)}
              
            </Menu>
          )
        }
        {
          loadingsModal && (
            <LoadingModal />
          )
        }
        {
          isOpenOrderAll && (
            <OrderAll
              isThereMap={tab > 2}
              closeOrderAll={closeOrderAll}
              handlerGetOneOrder={handlerGetOneOrder}
            />
          )
        }
      </Wrapper>

      <ModalReason
        data={reason}
        dataObj={reasonData}
        isOpen={isOpenModalReason}
        isLoad={isLoadModalReason}
        updateFormReason={updateFormReason}
        handleReject={handleReject}
        handleCloseModal={handleCloseModal}
        order={order}
        kitchens={[...[{ title: '-', code: null, status: 1 }], ...kitchens]}
      />

      <ModalListOfCouriers
        isKitchen={true}
        activeCourier={activeCourier}
        setActiveCourier={setActiveCourier}
      />

      <ModalDelete
        title={'Попередження'}
        text={`Ви впевнені, що хочете змінити адрес ?`}
        isOpen={isOpenModalSaveEditAddress}
        isLoad={isLoadModalSaveEditAddress}
        funDelete={handlerupdateAdressServer}
        updateModalDelete={openAndCloseModalSaveAddress}
      />

      <ModalDelete
        title={'Попередження'}
        text={`Позиції: ${modalWarnignBelongs.data.map(it => `${it.title}/${it.article}`).join(', ')}. НЕ ВІДНОСЯТЬСЯ ДО РЕСТОРАНУ "${order.restaurant}" ?`}
        isOpen={modalWarnignBelongs.open}
        funDelete={handleBelonDelete}
        updateModalDelete={handleCloseModalBelon}
        textBtnSend={"Видалити"}
        textBtnClose='Повернутись'
      />
    </>
  );
};

const ActionsFixed = styled.div`
  color: #fff;
  width: 100%;
  padding: 0 10px 20px 10px;
  span {
    color: ${(p) => p.theme.accentColor};
    padding-right: 10px;
  }
`;

const Wrapper = styled.div`
  position: fixed;
  right: ${(p) => (p.isOpen ? "0" : "-1000px")};
  top: 0;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 460px;
  padding-bottom: 30px;
  background-color: ${(p) => p.theme.background};
  transition: 200ms right;
  
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 10px 20px;
`;

const CloseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(p) => p.theme.accentColor};
  border-radius: 50%;
  background-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
  }
`;

const TabList = styled.ul`
  display: flex;
  width: 100%;
  padding-right: 5px;
  border-bottom: 1px solid ${(p) => p.theme.lightAccentColor};
`;

const TabItem = styled.li`
  margin: 0;
`;

const TabButton = styled.button`
  padding: 8px 15px;
  background-color: ${(p) =>
    p.isActive ? p.theme.backgroundActive : "transparent"};
  border-bottom: 4px solid
    ${(p) => (p.isActive ? p.theme.accentColor : "transparent")};
  border-radius: 10px 10px 0 0;
  color: ${(p) => (p.isActive ? p.theme.accentColor : p.theme.secondaryColor)};
  font-family: Rubik, sans-serif;
  font-size: 16px;
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out;  
  &:disabled {
    opacity: 0.6;
  }

  &:hover {
    cursor: pointer;
    background-color: ${(p) => p.theme.backgroundHover};
    color: ${(p) => p.theme.accentColor};
  }
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 5px;
  margin-bottom: 46px;
  flex: 1 1 auto;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Menu = styled.ul`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  button {
    padding: 0px 5px;
    min-height: 31px;
  }
`;

const Item = styled.li`
  margin: 0 10px 10px 0;
  &:last-child {
    margin-right: 0;
  }
`;

const BgModal = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.65);
  z-index: 1000;
`;

export default React.memo(EditorCallcenter);
