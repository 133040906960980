import React, { useEffect, useState } from "react";
import styled from "styled-components";

import ModalEditor from "components/modals/ModalEditor";
import OrdersListObj from "../OrdersListObj";
import ModalFilter from "components/modals/ModalFilter";
import { getFiltet } from "utils/processingRequester/getLocalFilterOrders";
import { Header } from "components/orders/Header";
import { HeadOrder } from "components/orders/HeadOrder";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { ModalLoad } from "components/modals/ModalLoad";

import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const CallcenterPageClosed = ({
    orders,
    updateOrder,
    openEditorCallcenter,
    getAllActiveOrders,
    updateFormSearchOrders,
    handleІSybmitSearchOrders,
    openModalFilter,
    isRedactionOrder,
    redactionOrder,
    closeEditorCallcenter,
    searchValue,
    statusSearch,
    loadPage,
    downloadExcelOrder,
}) => {
    const [isLoadDownload, setIsLoadDownload] = useState(false);

    useEffect(() => {
        getAllActiveOrders(getFiltet(), false, "closed");

        const interval = setInterval(() => {
            getAllActiveOrders(getFiltet(), false, "closed");
        }, 20000);

        return () => clearInterval(interval);
    }, [statusSearch]);

    const handleCreateOrder = () => {
        updateOrder();
        openEditorCallcenter();
    };

    const handleShowFilter = () => {
        openModalFilter();
    }

    const handleCloseProhibition = () => {
        redactionOrder(true);
        closeEditorCallcenter();
    }

    const handlerDownload = () => {
        downloadExcelOrder(getFiltet(), "closed", setIsLoadDownload);
    }

    const renderOrders = () => {
        return (
            <>
                <View>
                    <HeadOrder />
                </View>
                <OrdersListObj showSum={true} key="closed" status="closed" data={orders?.closed || {}} isKitchen={true} title='Завершені' />
            </>
        );
    };

    return (
        <Wrapper>
            <Header
                handleCreateOrder={handleCreateOrder}
                handleShowFilter={handleShowFilter}
                updateFormSearchOrders={updateFormSearchOrders}
                handleІSybmitSearchOrders={handleІSybmitSearchOrders}
                closedOrders={true}
                closeReject={false}
                searchValue={searchValue}
                statusSearch={statusSearch}
            />
            {orders && (
                <WrOrders>
                    {renderOrders()}
                </WrOrders>
            )}
            <ModalEditor />
            <ModalFilter />

            {/* {
                !isRedactionOrder && (
                    <ModalProhibition
                        onClick={handleCloseProhibition}
                    >
                        <ModalView>
                            <TextModal>Це замовлення вже принято іншим оператором</TextModal>
                            <ButtonIcon
                                onClick={handleCloseProhibition}
                                title='Закрить'
                                icon={<CloseIcon />}
                            />
                        </ModalView>
                    </ModalProhibition>
                )
            } */}
            {
                loadPage && (
                    <ModalLoad />
                )
            }

            <WrBtnFixed>
                <ButtonIcon
                    onClick={handlerDownload}
                    title='Cкачать в Excel'
                    icon={<FileDownloadIcon />}
                    positionIcon="left"
                    width="100%"
                    colorBg="#3d91ff"
                    isLoad={isLoadDownload}
                />
            </WrBtnFixed>
        </Wrapper>
    );
};

const WrBtnFixed = styled.div`
    position: fixed;
    right: 30px;
    bottom: 40px;
    z-index: 10;
`;

const Wrapper = styled.div`
  padding-bottom: 30px;
`;

const WrOrders = styled.div`
  margin-top: 24px;
`;

const View = styled.div`
  margin-top: 40px;
  margin-bottom: 24px;
`;

const ModalProhibition = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  z-index: 20000;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  padding: 20px;
`;

const ModalView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TextModal = styled.div`
  text-align: center;
  color: #fff;
  font-size: 18px;
  margin: 20px 0;
`;

export default React.memo(CallcenterPageClosed);
