import produce from 'immer';

import {
  GET_PROMOACTIONS,
  SET_PROMOS_FILTER,
} from '../constants/promos';

const initialState = {
  promoactions: [],
  filter: { status: 1 }, // display only active discounts by default
};

export default produce((draft, action) => {
    const { payload } = action;
    switch (action.type) {
      case GET_PROMOACTIONS: {
        draft.promoactions = payload;
        break;
      }
      case SET_PROMOS_FILTER: {
        const { option, filter } = payload;
        if (filter !== undefined) {
          draft.filter[option] = filter; 
        } else {
          delete draft.filter[option];
        }
        break;
      }
      default:
          break;
    }
}, initialState);
