/*  camelcase */
import produce from "immer";

import {
    GET_COURIERS
} from "../constants/couriers";

const initialState = {
    list: [],
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        case GET_COURIERS: {
            draft.list = payload;
            break;
        }

        default:
            break;
    }
}, initialState);
