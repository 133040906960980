import { connect } from "react-redux";

import * as viewActions from "store/actions/view";
import * as ordersActions from "store/actions/orders";
import * as clientsActions from "store/actions/clients";

import { default as ModalClients } from "./ModalClients";

function mapStateToProps(state) {
    return {
        user: state.user.user,
        isOpen: state.view.modalClients.isOpen,
        loadingsModal: state.view.modalClients.loadingsModal,
        extensive: state.clients.extensive,
        restaurants: state.settings.restaurants,
        bonusClient: state.clients.bonusClient,
        reasonList: state.settings.blackList.reasonsList,
        calls: state.clients.calls,
        telestatSettings: state.settings.telestat,
    };
}

const mapDispatchToProps = {
    ...viewActions,
    ...ordersActions,
    ...clientsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalClients);
