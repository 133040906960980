import { isArray, isString } from "lodash";
 
export const kitchensMapped = (arr = [], params = {}) => {
  const {
    isPickup,
    isKitchenuser,
    kitchensUser,
    isOnlyPicup = false,
    key = 'code'
  } = params;
  let mewArray = [];

  if (isArray(arr))
    if (isKitchenuser) {
      kitchensUser?.map((item) => {
        arr.map((el) => {
          if (el.id === item) {
            let name = el.title

            if (isPickup && !!el.pickup) {
              name += " -> Самовивіз";
            }

            mewArray.push({ value: el.code, title: name });
          }
        });
      });
    } else {
      arr?.map((item) => {
        if (item.status) {
          let name = item.title

          if (isPickup && !!item.pickup) {
            name += " -> Самовивіз";
          }

          if (isOnlyPicup) {
            if (!!item.pickup)
              mewArray.push({ title: name, value: item[key] });
          } else {
            mewArray.push({ title: name, value: item[key] });
          }
        }
      });
    }
  return mewArray;
};

export const kitchenSearchDropdown = (arr = [], kitchens = []) => {
  let mewArray = [];

  arr?.map((item) => {
    kitchens.map((el) => {
      if (el.id === item) {
        mewArray.push({ value: el.code, title: el.title });
      }
    });
  });

  return mewArray;
}

export const kitchenSearchByCodeBoolean = (code, kitchens) => {
  if (isArray(kitchens)) {
    return kitchens.findIndex(el => (el.code === code) && (el.pickup === 1)) != -1;
  }

  return false;
}

export const kitchensMappedSelect = (arr = []) => {
  let mewArray = [];

  arr?.map((item) => {
    if (!!item.status)
      mewArray.push({ value: item.code, label: item.title });
  });

  return mewArray;
};

export const kitchensMappedSelectId = (arr = [], city_sync_id = '') => {
  let mewArray = [];

  arr?.map((item) => {
    if (item.status)
      if (isString(city_sync_id) && city_sync_id.length > 0) {
        if (city_sync_id === item.city_sync_id)
          mewArray.push({ value: item.id, label: item.title });
      } else {
        mewArray.push({ value: item.id, label: item.title });
      }
  });

  return mewArray;
};

export const kitchenSearchDrob = (id, kitchens) => {
  let kit = kitchens.find((item) => item.id == id);

  if (kit) {
    return kit.title
  }

  return '';
}

export const kitchenSearch = (id, kitchens) => {
  if (isArray(kitchens)) {
    return kitchens.find((item) => item.code === id);
  } else {
    return id;
  }
}

export const kitchenSearchSelect = (arr = [], kitchens = []) => {
  let mewArray = [];

  arr?.map((item) => {
    kitchens.map((el) => {
      if (el.id === item) {
        mewArray.push({ value: el.code, label: el.title });
      }
    });
  });

  return mewArray;
}

export const kitchenSearchSelectCode = (arr = [], kitchens = []) => {
  let mewArray = [];

  arr?.map((item) => {
    kitchens.map((el) => {
      if (el.code === item) {
        mewArray.push({ value: el.code, label: el.title });
      }
    });
  });

  return mewArray;
}

export const kitchenSearchSelectId = (arr = [], kitchens = []) => {
  let mewArray = [];

  arr?.map((item) => {
    kitchens.map((el) => {
      if (el.id === item) {
        mewArray.push({ value: el.id, label: el.title });
      }
    });
  });

  return mewArray;
}

export const kitchenSearchSelectFilterId = (kitchens = []) => {
  let mewArray = [];

  kitchens.map((el) => {
    mewArray.push(el.value);
  });

  return mewArray;
}

export const searchActiveKitchenCityPolygons = (cityId, listKitchens) => {
  if (isArray(listKitchens)) {
    return listKitchens.filter((item) => item.city_sync_id === cityId);
  }

  return [];
}

export const getIdKitchenOnKitchenCode = (kitchens = [], code) => {
  if (isArray(kitchens)) {
    return kitchens.find(el => el.code === code)?.id || undefined;
  }
}