import React from "react";
import styled, { useTheme } from "styled-components";

export const TextArea = ({
  title="",
  value="",
  onChange = () => { },
  width,
  isStyle = false,
  height = "auto",
  resize = "none",
  error = "",
  disabled = false,
  autoSize = false,
}) => {
  const theme = useTheme();
  const ref = React.useRef(null);

  React.useLayoutEffect(() => {
    if (!autoSize) return;
    const area = ref.current;

    area.style.height = "auto";
    area.style.height = `${area.scrollHeight}px`;

  }, [autoSize, value]);

  return (
    <Wrapper theme={theme} width={width}>
      <Title isStyle={isStyle}>{title}</Title>
      <Field
        ref={ref}
        isStyle={isStyle}
        value={value || ""}
        placeholder={title}
        onChange={onChange}
        height={height}
        disabled={disabled}
        $resize={resize}
        $autoSize={autoSize}
      />
      {
        error.length > 0 && (
          <Error>{error}</Error>
        )
      }
    </Wrapper>
  );
};

const Error = styled.p`
  color: red;
  padding-top: 4px;
`;

const Wrapper = styled.div`
  width: ${(p) => p.width};
  margin: 3px 0;
  padding-top: 4px;
`;

const Title = styled.span`
  margin-left: 4px;
  border-radius: 3px;
  color: ${(p) => p.isStyle ? "#000" : p.theme.secondaryColor};
  font-size: 14px;
`;

const Field = styled.textarea`
  height: ${(p) => p.height};
  width: 100%;
  margin-top: 4px;
  padding: 8px;
  background-color: ${(p) => p.isStyle ? "#FFFFFF" : p.theme.backgroundLight};
  border: ${(p) => p.isStyle ? p.theme.inputBorderDE : p.theme.inputBorder};
  border-radius: 8px;
  color: ${(p) => !p.isStyle ? p.theme.secondaryColor : "#000"};
  font-size: 14px;
  font-family: Rubik;
  outline: none;
  resize: ${(p) => p.$resize};
  overflow: ${(p) => p.$autoSize ? 'hidden' : 'auto'};

  &:active,
  &:focus {
    border: 1px solid ${(p) => p.theme.accentColor};
  }
`;
