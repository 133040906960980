export const ratingBy = [
    {
        id: 0,
        value: 'kitchen',
        label: 'Кухня',
    },
    {
        id: 1,
        value: 'courier',
        label: "Кур'єр",
    },
    {
        id: 2,
        value: 'operator',
        label: "Оператор",
    }
];


export const reviewKeys = [
    {
        id: 1,
        value: '1',
        label: '1',
    },
    {
        id: 2,
        value: '2',
        label: "2",
    },
    {
        id: 3,
        value: '3',
        label: "3",
    },
    {
        id: 4,
        value: '4',
        label: "4",
    },
    {
        id: 5,
        value: '5',
        label: "5",
    }
];

export const rating = "rating";
export const review = "review";