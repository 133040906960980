import { useState } from "react";
import { isArray } from "lodash";
import { useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import moment from 'moment';

import { assignableOrderStatuses } from "constants/assignableOrderStatuses";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";

const TIME_DEFAULT = {
  time: '00:00',
  format: 'HH:mm'
};

export const TabHistory = ({ 
  isKitchen = false, 
  updateOrderStatus = () => { },
}) => {
  const theme = useTheme();
  const [status, setStatus] = useState(() => {
    const [{ value }] = assignableOrderStatuses; // first
    return value;
  });

  const { id, history } = useSelector((state) => state.order.data);
  const { orderStatuses } = useSelector((state) => state.settings);
  const isAdmin = useSelector(({ user: state }) => {
    return state.user.role_name === 'system_administrator';
  });

  return (
    <Wrapper theme={theme}>
      {(isKitchen || isAdmin) && (
        <WrStatus>
          <Dropdown
            style={{ paddingRight: "15px" }}
            height="42px"
            title="Змінити статус замовлення на"
            onChange={(event) => setStatus(event.target.value)}
            value={status}
            list={assignableOrderStatuses}
          />
          {/* must be hidden if user is not kitchen admin */}
          <MarginLeft15>
            <ButtonIcon
              title="Змінити"
              onClick={updateOrderStatus.bind(this, id, status)}
            />
          </MarginLeft15>
        </WrStatus>
      )}

      <ListHistory>
        {
          isArray(orderStatuses) && orderStatuses.map((item, index) => {
            let obj = isArray(history) && history.find((itemTime) => itemTime.status === item.name);
            let time = "";
            let timeStatus = false;
            let name = "";

            if (obj) {
              time = moment(obj?.set_at).format(TIME_DEFAULT.format);
              name = `${obj.user.first_name} ${obj.user.last_name}`;
              timeStatus = true;
            } else {
              time = TIME_DEFAULT.time;
            }

            return (
              <HistoryItem key={index} active={timeStatus}>
                <HistoryItemTitle>{item.title}</HistoryItemTitle>
                <HistoryName>{name}</HistoryName>
                <HistoryItemTime>{time}</HistoryItemTime>
              </HistoryItem>
            )
          })
        }
      </ListHistory>
    </Wrapper>
  );
};

const Wrapper = styled.div`
`;

const WrStatus = styled.div`
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 15px;
`;

const MarginLeft15 = styled.div`
  margin-left: 15px;
`;

const ListHistory = styled.ul`
`;

const HistoryItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  // color: ${(p) => p.theme.secondaryColor};
  color: ${(p) => p.active ? '#fff' : '#9D9D9D'};
  padding: 10px 0;
  &:not(:last-child) {
    border-bottom: 1px solid ${(p) => p.theme.maskDarkColor};
  }
`;

const HistoryItemTitle = styled.span`
  min-width: 200px;
`;

const HistoryName = styled.div`
  font-size: 14px;
  max-width: 150px;
  width: 150px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
`;

const HistoryItemTime = styled.span`
  margin-left: 10px;
  text-decoration: underline;
  font-size: 14px;
  width: 50px;
`;