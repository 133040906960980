import { forwardRef, useEffect } from "react";

// tables
import { 
    Overall,
    AllSales,
    RestaurantPayments,
    PaymentBrands,
    ByPaymentType,
    CourierPayments,

} from './tables';

export const ModalContent = forwardRef((props, ref) => {
    const { data } = props;

    useEffect(() => {
        let docTable = document.querySelectorAll('.js_tables');

        docTable.forEach((item) => {
            let btnВ = item.querySelector('.btn-shows');

            btnВ.addEventListener('click', (e) => handleClickShow(e, item));
        });

        const handleClickShow = (e, item) => {
            let isClassClose = item.classList.contains('close-table');

            if (isClassClose) item.classList.remove('close-table');
            if (!isClassClose) item.classList.add('close-table');
        }

        return () => {
            docTable.forEach((item) => {
                let btnВ = item.querySelector('.btn-shows');

                btnВ.addEventListener('click', (e) => handleClickShow(e, item));
            });
        }
    }, [data]);

    return (
        <div className="cont-modal-info" ref={ref}>
            <Overall data={data} />
            <br />

            <AllSales data={data} />
            <br />

            <RestaurantPayments data={data} />
            <br />

            <PaymentBrands data={data} />
            <br />

            <ByPaymentType isCash={true} data={data?.cash_payments.list ?? []} />
            <br />

            <ByPaymentType isCash={false} data={data?.online_payments.list ?? []} />
            <br />

            <CourierPayments data={data?.courier_payments.list ?? []} />
        </div>
    )
});
