import { useState } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { TextInput } from "components/ui-kit/inputs/TextInput";
import Radio from "components/ui-kit/Radio";
import Select from "components/ui-kit/Select";
import { LoadingModal } from "components/loadingOrder/loading";

import { validateTypesOfDelivery } from "utils/validation";
import {
    paymentsMappedSelect,
    searchPaymentsId,
    arrayIdPaymentsFromPayment,
    searchPaymentsIdArr,
    arrayPaymentsFromPayConvert
} from "utils/mappers/payments";

import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from '@mui/icons-material/Close';
import styles from "../scss/settings.module.scss";

import { RADIO_SCHEME, BLACK_LIST } from "constants/schemes";

export const DeliveryEdit = ({
    data,
    view,
    onSubmit,
    onSubmitUpdate,
    handleSubmitClose,
    updateFormDelivery,
    paymentsData = [],
}) => {
    const [error, setErrors] = useState({
        name: "",
        partner_price: "",
    });

    let titleButton = data?.id ? 'Змінити' : 'Додати';
    let title = data?.id ? `Редагування ${data.name}` : "Створення нового";

    const handlerSubmit = () => {
        validateTypesOfDelivery({
            data: {
                ...data
            },

            onSuccess: async () => {
                if (data?.id) {
                    onSubmitUpdate(data?.id);
                } else {
                    onSubmit();
                }

                setErrors({
                    name: "",
                    partner_price: "",
                });
            },

            onError: (validationErrors) => {
                setErrors(validationErrors);
            },
        });
    }

    return (
        <div className={styles.row_mrt}>
            <div className={`${styles.title_row} ${styles.marg_bot}`}>{title}</div>
            <div className={styles.wr_drop}>
                <TextInput
                    title="Назва"
                    type="text"
                    value={data.name}
                    isStyle={true}
                    error={error.name}
                    onChange={(e) => updateFormDelivery("name", e.target.value)}
                />
                <TextInput
                    title="Ціна доставки"
                    type="number"
                    value={data.partner_price}
                    isStyle={true}
                    error={error.partner_price}
                    onChange={(e) => updateFormDelivery("partner_price", e.target.value)}
                />
                <div className={styles.wr_drop}>
                    <Select
                        selectOptions={paymentsMappedSelect(paymentsData)}
                        selectedOption={!!data.id ? searchPaymentsId(data.payments, paymentsData) : searchPaymentsIdArr(data.payments, paymentsData)}
                        valueKey='payments'
                        label='Типи оплат'
                        isMulti
                        mode={'primary'}
                        onChange={({ value }) => updateFormDelivery("payments", !!data.id ? arrayPaymentsFromPayConvert(value) : arrayIdPaymentsFromPayment(value))}
                    />
                </div>
                <div>
                    <Radio
                        colors={"#000"}
                        items={BLACK_LIST}
                        label="Партнер, наприклад: глово, болт"
                        valueKey="partner_price"
                        value={data.partner_price}
                        onChange={(e) => updateFormDelivery("partner_price", +e.value.value)}
                    />
                </div>
                <div>
                    <Radio
                        colors={"#000"}
                        items={RADIO_SCHEME}
                        label="Активність"
                        valueKey="status"
                        value={data.status}
                        onChange={(e) => updateFormDelivery("status", +e.value.value)}
                    />
                </div>
            </div>
            <div className={styles.row_btn_tow}>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handlerSubmit}
                        title={titleButton}
                        colorBg="green"
                        icon={<SaveIcon />}
                    />
                </div>
                <div className={styles.item_btn}>
                    <ButtonIcon
                        onClick={handleSubmitClose}
                        icon={<CloseIcon />}
                    />
                </div>
            </div>
            {
                view.isLoadEditDelivery && (
                    <LoadingModal />
                )
            }
        </div>
    )
}

