import { isArray } from "lodash";
import React, { useEffect, useState } from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";
import CalendarRange from "components/ui-kit/CalendarRange";
import Table from "components/ui-kit/Table";
import ModalFormAddComplaint from "components/modals/ModalFormAddComplaint";
import ModalFormComplaintProcessing from "components/modals/ModalFormComplaintProcessing";
import Select from "components/ui-kit/Select";

import { statisticComplaints } from "constants/tables";
import { listenTypeProcessing } from "constants/complaints";

import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import CloseIcon from "@mui/icons-material/Close";
import styles from "./../StatisticPage.module.scss";

const defStat = {
    modalAdd: {
        isOpen: false,
    },
    modalProcessing: {
        isOpen: false,
        client_id: null,
        client_phone: null,
        complaint_id: null,
        client_full_name: null
    }
}

const Complaints = ({
    // state
    filter,
    list,
    view,
    complaint,
    resource,
    // dispatch
    getComplaints,
    updateFilterComplaints,
    downloadExcelComplaints
}) => {
    const [isLoadDownload, setIsLoadDownload] = useState(false);
    const [modalFormAdd, setModalFormAdd] = useState(defStat);
    const [modalProcessing, setModalProceessing] = useState(defStat.modalProcessing);
    const [isFilter, setIsFilter] = useState(false);

    const handlerSubmit = async (isBtn = false) => {
        await getComplaints();

        if (isBtn)
            closeFilter();
    }

    const handlerDownload = () => {
        downloadExcelComplaints(setIsLoadDownload);
    }

    const openModalAdd = () => {
        setModalFormAdd((prev) => ({
            ...prev,
            isOpen: true,
        }));
    }

    const onCloseModalAdd = () => {
        setModalFormAdd(defStat);
    }

    const handlerOpenModalProcessing = (item) => {
        if (item.processed == 0) {
            setModalProceessing({
                isOpen: true,
                client_id: item?.client_id || null,
                complaint_id: item.id,
                client_phone: item?.client_phone,
                client_full_name: item?.client_full_name,
            });
        }
    }

    const onCloseModalProcessing = () => {
        setModalProceessing(defStat.modalProcessing);
    }

    const closeFilter = () => {
        setIsFilter(false);
    }

    useEffect(() => {
        handlerSubmit();
    }, []);

    return (
        <div className={styles.statisticpage}>
            <div className={styles.contain}>
                <div className={styles.hed}>
                    <div className={styles.title_p}></div>
                    <div className={styles.hed_r}>
                        <ButtonIcon
                            title='Фільтр'
                            onClick={() => setIsFilter(true)}
                        />
                    </div>
                </div>

                {
                    !view?.isLoadPage ? (
                        isArray(list) && (list.length > 0) ? (
                            <div className={styles.contain_main}>
                                <Table
                                    data={list}
                                    scheme={statisticComplaints}
                                    isHover={true}
                                    isStictyhead={true}
                                    isBgActiveRow={"#00800078"}
                                    onClick={handlerOpenModalProcessing}
                                />
                            </div>
                        ) : (
                            <div className={styles.text_info}>Пусто... оберіть період і натисніть кнопку "Задіяти"</div>
                        )
                    ) : (
                        <div className={styles.modal_wr}>
                            <LoadingCircl mins={true} />
                        </div>
                    )
                }
            </div>

            {
                isFilter && (
                    <div className={`${styles.statisticpage__select}`}>
                        <div className={`${styles.opas} bg_opas`} onClick={closeFilter}></div>
                        <div className={`${styles.statisticpage__select_main}`}>
                            <div className={styles.headers}>
                                <div className={styles.title_white}>Фільтр</div>
                                <div className={styles.btn_close} onClick={closeFilter}>
                                    <CloseIcon style={{ fontSize: 36 }} />
                                </div>
                            </div>
                            <div className={`${styles.slick}`}>
                                <ButtonIcon
                                    onClick={openModalAdd}
                                    title='Додати власноруч'
                                    positionIcon="left"
                                    width="100%"
                                    colorBg="blue"
                                />

                                <div className={styles.wr_bot}>
                                    <Select
                                        selectOptions={listenTypeProcessing}
                                        selectedOption={filter.processed}
                                        valueKey='processed'
                                        label='Статус'
                                        mode={'secondary'}
                                        onChange={({ value }) => updateFilterComplaints("processed", value)}
                                    />
                                </div>

                                <div className={styles.wr_bot}>
                                    <CalendarRange
                                        title="Період з - по"
                                        valueIn={filter.date}
                                        ranges={filter.date}
                                        isAbs={true}
                                        onChangeCal={(date) => updateFilterComplaints("date", [date.selection])}
                                    />
                                </div>

                                <div className={`${styles.row_two_btn} ${styles.row_two_btn_marg_top}`}>
                                    <ButtonIcon
                                        onClick={() => handlerSubmit(true)}
                                        title='Задіяти'
                                        icon={<AddIcon />}
                                        positionIcon="left"
                                    />
                                    {
                                        isArray(list) && list.length > 0 && (
                                            <ButtonIcon
                                                onClick={handlerDownload}
                                                title='Excel'
                                                icon={<FileDownloadIcon />}
                                                positionIcon="left"
                                                colorBg="#3d91ff"
                                                isLoad={isLoadDownload}
                                            />
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

            <ModalFormAddComplaint
                isOpen={modalFormAdd.isOpen}
                onCloseModal={onCloseModalAdd}
                complaint={complaint}
                resource={resource}
            />

            <ModalFormComplaintProcessing
                isOpen={modalProcessing.isOpen}
                onCloseModal={onCloseModalProcessing}
                data={modalProcessing}
            />
        </div>
    )
}

export default Complaints;