import {
    Switch,
    Route,
    useRouteMatch,
} from "react-router-dom";

import MenuPage from "components/menuPage";

import styles from "./scss/settings.module.scss";

import { dataItemsMenu } from 'constants/menuPageSettings';

import PageHome from './Home';
import PageGeneral from './General';

const Settings = () => {
    let { path } = useRouteMatch();

    return (
        <div>
            <MenuPage items={dataItemsMenu} />

            <div className={styles.block}>
                <Switch>
                    <Route component={PageHome} exact path={`${path}${dataItemsMenu['common'].path}`} />
                    <Route component={PageGeneral} path={`${path}${dataItemsMenu['general'].path}`} />
                </Switch>
            </div>
        </div>
    )
}

export default Settings;