import { isArray, isObject } from "lodash";
import styled, { useTheme } from "styled-components";

export const TabReject = ({ rejected }) => {
    const theme = useTheme();

    if (isObject(rejected)) {
        const {
            reason,
            comment_reason,
            write_off_products,
            scrapped_products,
            guilty,
            comment,
        } = rejected;

        return (
            <Wrapper theme={theme}>
                <Row>
                    <Title>Причина: </Title>
                    <Reason>{reason ?? '-'}</Reason>
                </Row>
                <Row>
                    <Title>Назва до причини: </Title>
                    <Reason>{comment_reason ?? '-'}</Reason>
                </Row>
                <Row>
                    <Title>Списання продуктів ?</Title>
                    <Reason>{write_off_products ?? '-'}</Reason>
                </Row>
                <Row>
                    <Title>За кого списання ?</Title>
                    <Reason>{guilty ?? '-'}</Reason>
                </Row>
                <Row>
                    <Title>Коментарій: </Title>
                    <Reason>{comment ?? '-'}</Reason>
                </Row>
                {
                    isArray(scrapped_products) && (scrapped_products.length > 0) && (
                        <Row>
                            <Title>Страва на списання: </Title>
                            {
                                scrapped_products.map((item, index) => (
                                    <Reason key={index}>{`${index}: ${item}`}</Reason>
                                ))
                            }
                        </Row>
                    )
                }
            </Wrapper>
        )
    } else {
        return (
            <Empty>Пусто</Empty>
        )
    }
}

const Row = styled.div`
    &:not(:last-child) {
        margin-bottom: 10px;
    }
`;

const Title = styled.div`
    color: #EDA240;
    padding-bottom: 5px;
`;

const Reason = styled.div`
    color: #fff;
    font-size: 16px;
`;

const Wrapper = styled.div`
`;

const Empty = styled.div`
    color: #fff;
`;
