import { connect } from "react-redux";

import * as actionCooks from "store/actions/cook";

import { default as ModalListCooks } from "./ModalListCooks";

function mapStateToProps(state) {
    return {
        cooksInTheKitchen: state.cook.cooksInTheKitchen,
        isOpen: state.cook.modalCooks.isOpen,
        isLoad: state.cook.modalCooks.isLoad,
        formCooks: state.cook.formCooks,
        cookings: state.cook.orders.cooking,
    };
}

const mapDispatchToProps = {
    ...actionCooks,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalListCooks);
