import api from "../../apiSingleton";

import {
    GET_ORDERS_BY_STATUS_FOR_A_CHEF,
    GET_COOKS_IN_THE_KITCHEN,
    UPDATE_MODAL_COOKS,
    UPDATE_FORM_COOKS,
    FORM_COOKS_CLEAN_UP
} from '../constants/cook';

import { updateStatus } from './orders';
import { filterKitchenCodeOnId } from "utils/helpers/filterApiParams";

export function getOrderByStatusForChef() {
    return async (dispatch) => {
        const res = await api.cook.getOrdersStatusChef();

        if (res?.data) {
            await dispatch({
                type: GET_ORDERS_BY_STATUS_FOR_A_CHEF,
                payload: res.data,
            });
        }
    };
}

export function getCooksInTheKitchen() {
    return async (dispatch, state) => {
        const {
            user: {
                user,
                user: {
                    kitchen_code,
                    role_name,
                }
            },
            settings: {
                kitchens
            }
        } = state();

        let kitchensValue = filterKitchenCodeOnId(user.kitchens, kitchens);

        const params = { per_page: 100, page: 1, kitchen_code: kitchensValue.kitchen_code, role_name };

        const { data } = await api.users.get(params);

        await dispatch({
            type: GET_COOKS_IN_THE_KITCHEN,
            payload: data,
        });
    }
}

export function setCooksInTheKitchen(idOrder) {
    return async (dispatch, state) => {
        const { cook: { formCooks } } = state();

        await dispatch(updateModalCooks('isLoad', true));
        await api.cook.setCooksInTheKitchen(idOrder, { cooks: formCooks });
        await dispatch(updateModalCooks('isOpen', false));
        await dispatch(updateModalCooks('isLoad', false));
        await dispatch(updateStatus(idOrder, 'preparing'));
        await dispatch(fromCookCleanUpa());
    }
}

export function returnToTheKitchen(idOrder, setIsLoadModal, handlerCloseModal) {
    return async (dispatch) => {
        await setIsLoadModal(true);
        await dispatch(updateStatus(idOrder, 'cooking'));
        await setIsLoadModal(false);
        await handlerCloseModal();
    }
}

export function updateModalCooks(item, data) {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_MODAL_COOKS,
            payload: { data, item }
        });
    }
}

export function updateFormCooks(id) {
    return async (dispatch) => {
        dispatch({
            type: UPDATE_FORM_COOKS,
            payload: id,
        });
    }
}

export function fromCookCleanUpa() {
    return async (dispatch) => {
        dispatch({
            type: FORM_COOKS_CLEAN_UP,
        });
    }
}

