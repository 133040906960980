import { connect } from "react-redux";

import * as statisticActions from "store/actions/statistic";
import * as clientsActions from "store/actions/clients";
import { default as PageReviews } from "./PageReviews";

function mapStateToProps(state) {
    const {
        statistic: {
            reviews: {
                typePage,
                filter,
                ratings,
                responses,
                restoranName,
            }
        },
        settings: {
            restaurants
        },
        clients: {
            extensive,
        },
        view,
    } = state;

    return {
        typePage,
        filter,
        restaurants,
        ratings,
        responses,
        view,
        restoranName,
        extensive,
    };
}

const mapDispatchToProps = {
    ...statisticActions,
    ...clientsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(PageReviews);
