import produce from 'immer';

import {
    GET_ACTIVE_KITCHENS_CITY,
} from "../constants/order";

import {
    GET_DAA_PRINT_CHECK,
    SET_KITCHEN_SHIFT_STATUS,
    SET_KITCHEN_SHIFT_LOADING,
} from "../constants/kitchen";

const initialState = {
    kitchensListCity: [],
    dataPrintAll: null,
    kitchenShiftLoading: false,
    kitchenShiftStatus: {
        isShiftClosed: null,
        // shiftOpenedBy: null,
        shiftId: null,
    },
};

export default produce((draft, action) => {
    const { payload } = action;

    switch (action.type) {
        case GET_ACTIVE_KITCHENS_CITY: {
            draft.kitchensListCity = payload;
            break;
        }

        case GET_DAA_PRINT_CHECK: {
            draft.dataPrintAll = payload;
            break;
        }

        case SET_KITCHEN_SHIFT_STATUS: {
            draft.kitchenShiftStatus = payload;
            break;
        }

        case SET_KITCHEN_SHIFT_LOADING: {
            draft.kitchenShiftLoading = payload;
            break;
        }

        default:
            break;
    }
}, initialState);
