import React from "react";
import styled, { useTheme } from "styled-components";

const TableAnswer = ({
    scheme,
    data,
    onClient = () => { },
    onOrder = () => { },
}) => {
    const theme = useTheme();

    const renderHeader = () => {
        const HTMLelement = scheme.map((item, index) => {
            const { title, width } = item;
            return (
                <HeadCell key={index} style={{ width }}>
                    {title}
                </HeadCell>
            );
        });

        return <HeadRow>{HTMLelement}</HeadRow>;
    };

    const renderBody = () => {
        const renderCells = (item) =>
            scheme.map((cell, index) => {
                const { title, width } = cell.width;

                switch (cell.type) {
                    case "textClick": {
                        if (cell.fun.includes('client')) {
                            return <CellHover style={{ width }} key={index} title="Показати інформацію про клієнта" onClick={() => onClient(item)}>{item[cell.key]}</CellHover>;
                        } else if (cell.fun.includes('order')) {
                            return <CellHover style={{ width }} key={index} onClick={() => onOrder(item)}>{item[cell.key]}</CellHover>;
                        }
                    }
                    case "phone": {
                        return <CellHover style={{ width }} key={index}>
                            <a title="телефон" href={`tel:${item[cell.key]}`}>{item[cell.key]}</a>
                        </CellHover>;
                    }
                    case "text": {
                        return <Cell style={{ width }} key={index}>{item[cell.key]}</Cell>;
                    }
                }
            });

        const HTMLelement = data.map((item, index) => {
            return (
                <Row key={index}>
                    {renderCells(item, index)}
                </Row>
            );
        });

        return HTMLelement;
    };

    return (
        <>
            {!!data && data.length ? (
                <Wrapper theme={theme}>
                    <Thead>{renderHeader()}</Thead>
                    <Tbody>{renderBody()}</Tbody>
                </Wrapper>
            ) : null}
        </>
    );
};


const Wrapper = styled.table`
  display: table;
  width: 100%;
  border-collapse: collapse;
  font-size: 13px;
`;

const Thead = styled.thead`
    position: sticky;
    top: 49px;
`;

const Tbody = styled.tbody``;

const HeadRow = styled.tr``;

const Row = styled.tr`
`;

const HeadCell = styled.th`
  padding: 3px;
  background-color: ${(p) => p.theme.lightAccentColor};
  border: 1px solid ${(p) => p.theme.lightAccentColor};
  color: ${(p) => p.theme.secondaryColor};
  font-weight: 300;
`;

const Cell = styled.td`
  padding: 5px;
  border: 1px solid ${(p) => p.theme.maskColor};
  position: relative;
  a {
    display: block;
  }
  a::after  {
    content: '',
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const CellHover = styled.td`
  padding: 5px;
  border: 1px solid ${(p) => p.theme.maskColor};
  position: relative;
  cursor: pointer;
  a {
    display: block;
  }
  a::after  {
    content: '',
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &:hover {
    color: #eda240;
  }
`;

export default React.memo(TableAnswer);
