import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

export const Discounts = ({
  isEdit,
  removeOrderPromocode = () => { }
}) => {
  const {
    order: {
      data: {
        discounts
      },
    },
  } = useSelector(state => state);
  const dispatch = useDispatch();

  return (
    <Wrapper isEdit={isEdit}>
      <Table>
        <Header>
          <Label>Тип</Label>
          <Label>Сума</Label>
        </Header>
        {
          discounts.map((item, index) => (
            <Row key={index}>
              <Cell>
                {
                  (["promocode", "promoaction"].includes(item.discount_type)) && (
                    <MainInMod>
                      <RemoveCircleOutlineIcon style={{ height: 18, width: 18 }} />
                      <MrMod>
                        <CondMob>
                          <Button onClick={() => dispatch(removeOrderPromocode(index))}>Видалити</Button>
                        </CondMob>
                      </MrMod>
                    </MainInMod>
                  )
                }
                <div dangerouslySetInnerHTML={{ __html: item.discount_title }} />
              </Cell>
              <Cell>{item.sum} <Currency>грн</Currency></Cell>
            </Row>
          ))
        }
      </Table>
    </Wrapper>
  );
};

const CondMob = styled.div`
  padding: 10px;
  background: #FAFAFA;
  border-radius: 5px;
  box-shadow: 0 0 11px -1px;
`;

const MainInMod = styled.div`
  position: relative;
  svg {
    cursor: pointer;
  }
  &:hover {
    & > div {
      opacity: 1;
      visibility: visible;
    }
  }
`;

const MrMod = styled.div`
  position: absolute;
  left: 0;
  bottom: 100%;
  padding-bottom: 5px;
  width: 100px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  margin-right: 5px;
  padding: 0;
  background-color: transparent;
  color: red;

  &:hover {
    cursor: pointer;
    color: #5f76f8;
  }
`;

const Wrapper = styled.div`
  position: relative;
  margin: 10px 3px;
  border-radius: 10px;
  background-color: #fff;
  border: 1px solid #cdc9c3;
  box-shadow: 0px 0px 1px #e6e6e6;
  pointer-events: ${(p) => p.isEdit ? 'auto' : 'none'};
`;

const Table = styled.div`
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e6e6e6;
  border-radius: 8px 8px 0 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #cdc9c3;
  }
`;

const Label = styled.div`
  border-left: 1px solid #cdc9c3;
  padding: 8px 15px;

  &:last-child {
    width: 200px;
  }

  &:first-child {
    border-left: none;
  }
`;

const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  width: 100%;
  border-left: 1px solid #cdc9c3;

  &:first-child {
    border-left: none;
    width: 50%;
  }

  &:last-child {
    width: 200px;
  }
`;

const Currency = styled.span`
  margin-left: auto;
`;