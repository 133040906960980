import Loader from 'components/ui-kit/Spiner';
import styled, { useTheme } from "styled-components";

export const LoadingCircl = ({
    mins = false
}) => {
    const theme = useTheme();

    return (
        <Wr theme={theme} mins={mins}>
            <Loader color='orange' width={40} height={40} />
        </Wr>
    )
}

const Wr = styled.div`
    height: ${(p) => !p.mins ? 'calc(100vh - 80px)' : 'auto'} ;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
`;