import { connect } from "react-redux";

import { clearDeliveryRoute as close } from "store/actions/statistic";
import { default as ModalDeliveryRoute } from "./ModalDeliveryRoute";

function mapStateToProps({ statistic }) {
    const route = statistic.deliveryRoute;
    const isLoading = statistic.loadDeliveryRoute;
    const isOpen = !!(route.id && route.waypoints.length);
    return { route, isLoading, isOpen };
}

const mapDispatchToProps = { close };
export default connect(mapStateToProps, mapDispatchToProps)(ModalDeliveryRoute);
