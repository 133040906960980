import * as React from "react";

import ButtonIcon from "components/ui-kit/ButtonIcon";
import { Dropdown } from "components/ui-kit/inputs/Dropdown";
import CalendarRange from "components/ui-kit/CalendarRange";

import { reportsListDropdown, converFormatReports, searchAllDataOfId } from "utils/helpers/reports";

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { RestorantAndKitchen } from "components/RestoranAndKidchen";

export const ReportFilter = ({
    listSelect,
    activeSelect,
    filter,
    kitchens,
    restaurants,
    cities,
    categories,
    handleSelect = () => { },
    updateFilter = () => { },
    updateFilterArr = () => { },
    handlerSubmitRange = () => { },
    saveExcel = () => { }
}) => {
    return (
        <div className="r-rows">
            <div className="r-left-t">
                <div className="title_report">{activeSelect?.title ? activeSelect?.title : ''}</div>
                <div className="col-t">
                    <div className="col-t-d"  >
                        <Dropdown
                            type='text'
                            isBlack={true}
                            isLight={true}
                            fontSize={"14px"}
                            onChange={(e) => handleSelect(searchAllDataOfId(listSelect, e.target.value))}
                            value={converFormatReports(activeSelect)}
                            list={reportsListDropdown(listSelect)}
                        />
                    </div>
                    <div className="col-t-k">
                        <RestorantAndKitchen
                            kitchens={kitchens}
                            restaurants={restaurants}
                            cities={cities}
                            categories={categories}
                            filter={filter}
                            update={updateFilterArr}
                            handlerSubmit={handlerSubmitRange}
                            selectedReport={activeSelect}
                        />
                    </div>
                    {/* <div className="row_btns">
                        <ButtonIcon
                            title="Зберегти"
                            isMin={true}
                            width={"33%"}
                        />
                        <ButtonIcon
                            title="Видалити"
                            isMin={true}
                            width={"33%"}
                            colorBg="red"
                        />
                    </div> */}
                </div>
                {/* <div className="mt_2 flex-catb">
                    <ButtonIcon
                        title="Всі"
                        isMin={true}
                        width={"auto"}
                        isActive={true}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Блюда"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Бонуси"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Часові інтервали"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Час"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Гості"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Доставка"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Замовлення"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Клієнт доставка"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Корпорація"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="НДС"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Оплата"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Органазація"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Відгуки клієнтів"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Приготування"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Собівартість"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Скидки/надбавки"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Сотрудники"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                    <ButtonIcon
                        title="Счета"
                        isMin={true}
                        width={"auto"}
                        disabled={true}
                    />
                </div> */}
            </div>
            <div className="r-right-t">
                <div className="r-col">
                    <CalendarRange
                        title="Період з - по"
                        isStyle={true}
                        valueIn={filter.date}
                        ranges={filter.date}
                        isAbs={true}
                        isBtn={true}
                        isTitle={false}
                        handlerSubmit={handlerSubmitRange}
                        onChangeCal={(date) => updateFilter("date", [date.selection])}
                    />
                    <div className="btnse">
                        <ButtonIcon
                            title="Завантажити Excel звіт"
                            isMin={true}
                            width="100%"
                            icon={<FileDownloadIcon />}
                            colorBg="blue"
                            onClick={saveExcel}
                        />
                    </div>
                    {/* <div className="mt_2">
                        <Checkbox
                            title="Автооновлення"
                            isStyle={true}
                            isMin={true}
                        />
                    </div> */}
                </div>
            </div>
        </div>
    )
}