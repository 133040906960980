import styled, { useTheme } from "styled-components";

import ModalLayout from "../ModalLayout";
import ButtonIcon from "components/ui-kit/ButtonIcon";
import DropZone from "components/DropZone";
import ProcessDownload from "components/ProcessDownload";
import OneFile from "components/OneFile";
import Radio from "components/ui-kit/Radio";

import CancelIcon from "@mui/icons-material/Cancel";

import fileExsel from "assets/file/Lviv_city.xlsx";

import { RADIO_PARSE_TYPE } from 'constants/schemes';

const ModalFileDownload = ({
    // props
    isOpen = false,
    data,
    states = {},
    onCloseModal = () => { },
    handleSubmit = () => { },
    deleteFile = () => { },
    onDrop,
    updateFormCity,
}) => {
    const theme = useTheme();

    const handlerCloseModal = async () => {
        onCloseModal();
    }

    return (
        <ModalLayout
            theme={theme}
            modalIsOpen={isOpen}
            closeModal={handlerCloseModal}
        >

            {
                (!states.isResnonse && !states.isFile) && (
                    <Zona>
                        <DropZone onDrop={onDrop} />
                        <br />
                        <InfoText>Формат файлу: xlsx, xls. <a href={fileExsel}>Приклад файлу</a></InfoText>
                    </Zona>
                )
            }
            {
                states.isResnonse && (
                    <ProcessDownload process={states.process} />
                )
            }

            {
                states.isFile && (
                    <OneFile
                        name={states.fileName}
                        deleteFile={deleteFile}
                    />
                )
            }

            {
                states.isFile && (
                    <Mt>
                        <Radio
                            colors={"#000"}
                            items={RADIO_PARSE_TYPE}
                            label="Як додати вулиці ?"
                            valueKey="status"
                            value={data.parse_type || 0}
                            onChange={(e) => updateFormCity("parse_type", e.value.value)}
                        />
                    </Mt>
                )
            }

            <RowBtn>
                {
                    states.isFile && (
                        <Item>
                            <ButtonIcon
                                onClick={handleSubmit}
                                title={'Підтвердити'}
                                colorBg="green"
                            />
                        </Item>
                    )}
                <Item>
                    <ButtonIcon
                        onClick={handlerCloseModal}
                        title='Скасувати'
                        colorBg="red"
                        icon={<CancelIcon />}
                    />
                </Item>
            </RowBtn>
        </ModalLayout >
    )
}

const Mt = styled.div`
    margin-top: 30px;
`;

const Zona = styled.div`
`;

const InfoText = styled.div`
    a {
        color: blue;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const RowBtn = styled.div`
    display: flex;
    align-items: center;
    margin-top: 40px;
    justify-content: center;
`;

const Item = styled.div`
    padding: 0 10px;
`;


export default ModalFileDownload;