import { isArray } from "lodash";
import { useEffect } from "react";

import Table from "components/ui-kit/Table";
import CalendarRange from "components/ui-kit/CalendarRange";
import { LoadingCircl } from "components/ui-kit/LoadingScreen/LoadingCircl";

import { tableMagazineComplaints } from "constants/tables";

import styles from "./../scss/settings.module.scss";

const PageMagazineComplaints = ({
    // state
    list,
    filter,
    view,
    // dispatch
    getMagazineComplaints,
    updateFilterMagazineComplaints
}) => {
    const getList = () => {
        getMagazineComplaints();
    }

    useEffect(() => {
        getList();
    }, []);

    return (
        <div>
            <div className={styles.header_settin}>
                <CalendarRange
                    isAbs={true}
                    isStyle={true}
                    isTitle={false}
                    valueIn={filter.date}
                    ranges={filter.date}
                    onChangeCal={(date) => updateFilterMagazineComplaints("date", [date.selection])}
                    isBtn={true}
                    handlerSubmit={getList}
                />
            </div>
            <div className={styles.block}>
                {
                    !view?.isLoadPage ? (
                        isArray(list) && (list.length > 0) ? (
                            <Table
                                data={list}
                                scheme={tableMagazineComplaints}
                            />
                        ) : (
                            <div className={styles.text_info}>Пусто... оберіть період і натисніть кнопку "Задіяти"</div>
                        )
                    ) : (
                        <LoadingCircl />
                    )
                }
            </div>
        </div>
    )
}

export default PageMagazineComplaints;