import { useMemo, createRef, useRef, forwardRef, useImperativeHandle } from 'react';
import { Icon } from 'leaflet';
import { Marker, CircleMarker, Popup } from 'react-leaflet';
import { getLatLng } from './utils';

import order_point from "assets/icons/marker.svg";
import kitchen from "assets/icons/marker-home.svg";

const icons = { order_point, kitchen };

const DynamicMarker = forwardRef(({ point }, ref) => {
  const position = getLatLng(point);
  const [MarkerComponent, props] = useMemo(() => {
    switch (point.type) {
      case "point":
        return [CircleMarker, {
          radius: 10,
          center: position,
          pathOptions: {
            fill: true,
            opacity: 0.5,
            fillOpacity: 0.5,
            fillColor: '#3388ff',
            color: '#3388ff',
          }
        }];
      default:
        return [Marker, {
          position,
          icon: icons[point.type] && new Icon({
            iconUrl: icons[point.type],
            iconSize: [45 / 2, 35],
            iconAnchor: [45 / 4, 35]
          }),
        }];
    }
  }, [point]);

  return (
    <MarkerComponent ref={ref} {...props}>
      <Popup>ID: {point.id}</Popup>
    </MarkerComponent>
  );
});

const Markers = forwardRef(({ waypoints }, ref) => {
  const markers = useRef(waypoints.reduce(function (previous, current) {
    // create ref for each waypoint (marker)
    return { ...previous, [current.id]: createRef() }
  }, {}));

  useImperativeHandle(ref, () => {
    return {
      show: (id) => markers.current[id]?.current?.openPopup(),
    };
  }, []);

  return waypoints.map((point) => (
    <DynamicMarker
      key={`waypoint-${point.id}`}
      point={point}
      ref={markers.current[point.id]}
    />
  ));
});

const Waypoints = forwardRef(({ waypoints }, ref) => {
  const key = useMemo(() => waypoints.map(waypoint => waypoint.id).join('-'), [waypoints]);
  return (
    <Markers
      key={key}
      ref={ref}
      waypoints={waypoints}
    />
  );
});

export default Waypoints;
