import moment from "moment";
import React from "react";

export const Clock = () => {
    const [m, setM] = React.useState(moment());

    React.useEffect(() => {
        const interval = setInterval(() => {
            setM(moment());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="times-div">{m.format("HH:mm:ss")}</div>
    )
}