import { isArray } from "lodash";
import moment from "moment";

export const filterStatisc = async (filter) => {
    let kitchen_codes = '';

    const { startDate, endDate } = filter.date[0];
    const date_from = moment(startDate).format("Y-MM-DD");
    const date_to = moment(endDate).format("Y-MM-DD");

    let finalParams = { date_from, date_to };

    if (isArray(filter.kitchens)) {
        if (filter.kitchens.length > 0) {
            filter.kitchens.map((item, index) => {
                kitchen_codes += item.value;

                if ((filter.kitchens.length - 1) != index)
                    kitchen_codes += ',';
            });

            finalParams = { ...finalParams, ...{ kitchen_code: kitchen_codes } };
        }
    }

    return finalParams;
}

export const filterKitchenCodeOnId = (valueArray = [], kitchens = [], isAllInfo = false) => {
    let strKitchens = '';
    let allInfoKitchen = [];

    valueArray.map((item, index) => {
        kitchens.map((el) => {
            if (item === el.id) {
                if (isAllInfo) {
                    allInfoKitchen.push(el);
                }

                if (!isAllInfo) {
                    strKitchens += el.code;
                    if ((valueArray.length - 1) > index) {
                        strKitchens += ',';
                    }
                }
            }
        });
    });

    if (isAllInfo) {
        return allInfoKitchen;
    }

    return { kitchen_code: strKitchens };
}

export const isPicupKitchen = (arrKitchens = []) => {
    for (let i = 0; i < arrKitchens.length; i++) {
        if (!!arrKitchens[i]?.pickup && !!arrKitchens[i]?.status) {
            return true;
        }
    }

    return false;
}

export const filterRoleCodeOnId = (valueArray = [], roles = []) => {
    let strRoles = '';

    valueArray.map((item, index) => {
        roles.map((el) => {
            if (item === el.id) {
                strRoles += el.name;

                if ((valueArray.length - 1) > index) {
                    strRoles += ',';
                }
            }
        });
    });

    return { role_name: strRoles };
}