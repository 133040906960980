import api from "../../apiSingleton";

import {
    GET_PROMOACTIONS,
    SET_PROMOS_FILTER,
} from "../constants/promos";

export function getPromoactions() {
    return async (dispatch) => {
      const promos = await api.promos.getPromoactions();
      await dispatch({
        type: GET_PROMOACTIONS,
        payload: promos,
      });
    };
}

export function setPromosFilter(option, filter) {
  return {
    type: SET_PROMOS_FILTER,
    payload: { option, filter },
  }
}
