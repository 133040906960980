export const hours = [
    {
        title: '-',
        value: '-'
    },
    {
        title: '00',
        value: '00'
    },
    {
        title: '01',
        value: '01'
    },
    {
        title: '02',
        value: '02'
    },
    {
        title: '03',
        value: '03'
    },
    {
        title: '04',
        value: '04'
    },
    {
        title: '05',
        value: '05'
    },
    {
        title: '06',
        value: '06'
    },
    {
        title: '07',
        value: '07'
    },
    {
        title: '08',
        value: '08'
    },
    {
        title: '09',
        value: '09'
    },
    {
        title: '10',
        value: '10'
    },
    {
        title: '11',
        value: '11'
    },
    {
        title: '12',
        value: '12'
    },
    {
        title: '13',
        value: '13'
    },
    {
        title: '14',
        value: '14'
    },
    {
        title: '15',
        value: '15'
    },
    {
        title: '16',
        value: '16'
    },
    {
        title: '17',
        value: '17'
    },
    {
        title: '18',
        value: '18'
    },
    {
        title: '19',
        value: '19'
    },
    {
        title: '20',
        value: '20'
    },
    {
        title: '21',
        value: '21'
    },
    {
        title: '22',
        value: '22'
    },
    {
        title: '23',
        value: '23'
    },
];

export const minutes = [
    {
        title: '-',
        value: '-'
    },
    {
        title: '00',
        value: '00'
    },
    {
        title: '05',
        value: '05'
    },
    {
        title: '10',
        value: '10'
    },
    {
        title: '15',
        value: '15'
    },
    {
        title: '20',
        value: '20'
    },
    {
        title: '25',
        value: '25'
    },
    {
        title: '30',
        value: '30'
    },
    {
        title: '35',
        value: '35'
    },
    {
        title: '40',
        value: '40'
    },
    {
        title: '45',
        value: '45'
    },
    {
        title: '50',
        value: '50'
    },
    {
        title: '55',
        value: '55'
    },
];

export const deliveryTime = [
    {
        title: '-',
        value: '-'
    },
    {
        title: '29',
        value: '29'
    },
    {
        title: '59',
        value: '59'
    },
];