import { connect, useSelector } from "react-redux";
import { compose } from "redux";
import { isArray, isString } from "lodash";

import * as sesion from 'store/actions/session';
import * as view from 'store/actions/view';

import history from "constants/history";
import withRedicrect from "pages/withRedirect";
import { default as CommonLayout } from "./CommonLayout";

function mapStateToProps(state) {
  const { user } = state?.user;
  const { pathname } = history?.location;

  const tabs = user?.pages || [];

  const activeTabIndex = tabs?.findIndex((tab) => pathname.includes(tab?.value));
  const allowedPaths = isArray(tabs) && tabs.map((el) => el?.value);
  const isRedirect = activeTabIndex < 0 || (isString(allowedPaths) && !allowedPaths.includes(pathname));

  return {
    tabs,
    user,
    isRedirect,
    urlToRedirect: isArray(tabs) ? tabs[0]?.value : '/callcenter',
  };
}

const mapDispatchToProps = {
  ...sesion,
  ...view,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withRedicrect
)(CommonLayout);
