import { useEffect } from "react";

import styles from "./../scss/settings.module.scss";

// Bug List
import BugList from "./BugList";

// Bug view
import BugView from "./BugView";

const BugReports = ({
    // mapStateToProps
    selectedBugReport,
    // dispatch
    getBugReportAll,
}) => {
    useEffect(() => {
        getBugReportAll();
    }, []);

    return (
        <div className={styles.bloks}>
            <div className={`${styles.d_grid} ${styles.grid_gap} ${styles.col_2}`}>
                <div className={styles.col_border_r}>
                    <BugList />
                </div>
                <div>
                    {selectedBugReport?.id && (<BugView />)}
                </div>
            </div>
        </div>
    )
}

export default BugReports;