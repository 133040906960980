import styles from "./styles.module.scss";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const OneFile = ({
    name,
    deleteFile = () => { },
}) => {
    return (
        <div className={styles.wr}>
            <div className={styles.row}>
                <UploadFileIcon />
                <span>{name}</span>
                <button onClick={deleteFile}>
                    <DeleteForeverIcon />
                </button>
            </div>
        </div>
    )
}

export default OneFile;