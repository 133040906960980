import { connect } from 'react-redux';

import * as settingsActions from 'store/actions/settings';
import { default as ProvingGroundsPage } from './ProvingGrounds';

function mapStateToProps(state) {
    const { settings, view } = state;

    return {
        view,
        settingsProvings: state.settingsProvings,
        poligones: settings.poligones,
    };
}

const mapDispatchToProps = {
    ...settingsActions
};

export default connect(mapStateToProps, mapDispatchToProps)(ProvingGroundsPage);
